/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_INFO_DOWNLOAD } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPatch, axiosCreateFile, axiosPatchFile } from "@/utils/axios"

export async function getInfoDownloadAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_INFO_DOWNLOAD.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getInfoDownloadById({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_INFO_DOWNLOAD.FINDBYID}/${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getInfoDownloadByLang({ lang, sort = "YES" }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_INFO_DOWNLOAD.FINDBYLANG}?lang=${lang}&sort=${sort}`
    const { code, data, message } = await axiosGet(url, token)
    const fixedHeaders = [
      {
        ref_id: -2,
        title: "คำถามที่พบบ่อย"
      },
      {
        ref_id: -1,
        title: "Dimaond Site Ref."
      },
      {
        ref_id: 0,
        title: "บทความ"
      }
    ]

    if (code == 200) {
      return data.map((item) => {
        return {
          ...item,
          title: fixedHeaders.map((i) => i.ref_id).includes(item.ref_id)
            ? fixedHeaders.find((i) => i.ref_id === item.ref_id)?.title
            : item.title || "-"
        }
      })
    }
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function postInfoDownload({ infoDownload, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    // const payload = infoDownload
    const url = `${API}${EP_INFO_DOWNLOAD.CREATE}`
    let formData = new FormData()
    formData.append("menu_type", infoDownload.menu_type)
    formData.append("ref_id", infoDownload.ref_id)
    formData.append("sub_ref_id", infoDownload.sub_ref_id)
    formData.append("link", infoDownload.link)
    formData.append("lang", infoDownload.lang)
    if (file) formData.append("file", file, file.name)

    const { code, data, message } = await axiosCreateFile(url, formData, token)

    if (code == 201) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putInfoDownload({ infoDownload, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const url = `${API + EP_INFO_DOWNLOAD.UPDATE}/${infoDownload.info_download_id}`

    let formData = new FormData()
    formData.append("menu_type", infoDownload.menu_type)
    formData.append("ref_id", infoDownload.ref_id)
    formData.append("sub_ref_id", infoDownload.sub_ref_id)
    formData.append("link", infoDownload.link)
    formData.append("lang", infoDownload.lang)
    formData.append("sort", infoDownload.sort)
    formData.append("status", infoDownload.status)
    if (file) formData.append("file", file, file.name)

    const { code, data, message } = await axiosPatchFile(url, formData, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    // const { message } = error.response.data
    console.log(error)
    return errorHandle(error)
  }
}

export async function putInfoDownloadSort({ infoDownloads }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = infoDownloads.map((item) => {
      return {
        id: item.info_download_id,
        sort: item.sort
      }
    })
    const url = `${API}${EP_INFO_DOWNLOAD.UPDATE_SORT}`

    const { code, message, data } = await axiosPatch(url, payload, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putStatus({ infoDownload, status }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = { status: status }
    const url = `${API}${EP_INFO_DOWNLOAD.UPDATE_STATUS.replace(":id", infoDownload.info_download_id)}`

    const { code, data, message } = await axiosPatch(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function deleteInfoDownload({ infoDownload }, resultHandle, errorHandle, deleteResultHandle) {
  try {
    const token = await getAuthToken()
    const payload = { status: "DELETED" }
    const url = `${API}${EP_INFO_DOWNLOAD.UPDATE_STATUS.replace(":id", infoDownload.info_download_id)}`

    const { code, data, message } = await axiosPatch(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      deleteResultHandle()
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}
