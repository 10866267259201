/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_PRODUCT_CATEGORY_DIMENSION } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPost, axiosPatch } from "@/utils/axios"

export async function getProductCategoryDimension({ id }) {
  try {
    const token = await getAuthToken()
    const url = API + EP_PRODUCT_CATEGORY_DIMENSION.FINDALL.replace(":product_category_id", id)
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function postProductCategoryDimension({ dimensions }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = dimensions.map((dimension) => {
      return {
        product_category_id: dimension.product_category_id,
        product_group_dimension_id: dimension.product_group_dimension_id,
        value: dimension.value
      }
    })
    const url = `${API + EP_PRODUCT_CATEGORY_DIMENSION.CREATE}`
    const { code, data, message } = await axiosPost(url, payload, token)

    if (code == 201) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    // const { message } = error.response.data
    console.log(error)
    return errorHandle(error)
  }
}

export async function putProductCategoryDimension({ dimensions }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = dimensions.map((dimension) => {
      return {
        product_category_id: dimension.product_category_id,
        product_group_dimension_id: dimension.product_group_dimension_id,
        value: dimension.value
      }
    })
    const url = `${API + EP_PRODUCT_CATEGORY_DIMENSION.UPDATE}`
    const { code, data, message } = await axiosPatch(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    // const { message } = error.response.data
    console.log(error)
    return errorHandle(error)
  }
}
