import Vue from "vue"
import moment from "moment"
import App from "./App.vue"
import router from "./routers"
import store from "./store"
import "./plugins/base"
import "./plugins/chartist"
import "./plugins/vee-validate"
import "./plugins/vue-world-map"
import "./plugins/ckeditor"
import "./plugins/vue2-google-map"
import "./sass/font.sass"
import vuetify from "./plugins/vuetify"
import i18n from "./plugins/i18n"

Vue.config.productionTip = false

Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY")
  }
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount("#app")
