<template>
  <v-container>
    <base-material-card icon="mdi-web" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("social_media_icon") }}</div>
      </template>

      <v-dialog v-model="dialogDelete" max-width="300">
        <v-card>
          <v-card-title>
            <p>
              ยืนยันการลบ
            </p>
            <v-spacer />
            <v-icon aria-label="Close" @click="closeDelete">
              mdi-close
            </v-icon>
          </v-card-title>

          <v-card-text class="pb-6 text-center">
            <v-btn class="mr-1" color="error" dark @click="closeDelete"> {{ $t("cancel") }} </v-btn>
            <v-btn class="mr-1" color="success" dark @click="deleteItemConfirm"> {{ $t("delete") }} </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-tabs v-model="lang" vertical>
        <v-tab
          v-for="(language, i) in languages"
          :key="`social_media_icon-${i}`"
          @change="onTabChange(language.lang_code)"
        >
          {{ language.lang_code }}
        </v-tab>

        <v-tab-item touchless v-for="(language, i) in languages" :key="`social_media_icon-${i}`">
          <v-container fluid>
            <v-row class="text-no-wrap" align="center">
              <v-col>
                <v-data-table :headers="headers" :items="socialIcons" class="elevation-1">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <!-- <v-toolbar-title>{{ $t("social_media_icon") }}</v-toolbar-title> -->

                      <v-spacer></v-spacer>
                      <v-btn color="primary" dark class="mx-2" @click="sortItem">
                        {{ $t("sort") }}
                      </v-btn>

                      <v-btn color="primary" dark class="mx-2" @click="createItem(language.lang_code)">
                        {{ $t("create") }}
                      </v-btn>

                      <v-dialog v-model="dialogSort" max-width="900px">
                        <v-card>
                          <v-card-title class="headline">{{ $t("social_media_icon_sort") }}</v-card-title>
                          <v-form>
                            <v-card-text>
                              <v-container>
                                <base-dnd-table
                                  :headers="header_list"
                                  :items.sync="itemsSort"
                                  :dragOptions="dragOptions"
                                  @update:items="updateSortItems"
                                  order
                                >
                                  <tr v-for="item in socialIcons" :key="item.social_menu_id">
                                    <td scope="row" style="cursor: all-scroll">
                                      <v-icon>mdi-format-align-justify</v-icon>
                                    </td>
                                    <td scope="row">
                                      <v-list-item
                                        :href="`${path_file}${item.social_normal.file_path}`"
                                        target="_blank"
                                        :ripple="false"
                                        v-if="Object.keys(item.social_normal).length"
                                      >
                                        <v-list-item-avatar
                                          tile
                                          class="align-self-center mr-auto px-0"
                                          color="hsl(255, 3%, 18%,0)"
                                        >
                                          <v-img
                                            aspect-ratio="1"
                                            contain
                                            :src="`${path_file}${item.social_normal.file_path}`"
                                          />
                                        </v-list-item-avatar>
                                      </v-list-item>
                                    </td>
                                    <td>{{ item.status }}</td>
                                    <td>{{ item.lang }}</td>
                                    <td>{{ item.sort }}</td>
                                  </tr>
                                </base-dnd-table>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="primary" dark @click="closeSort">Cancel</v-btn>
                              <v-btn color="primary" dark @click="saveSort">OK</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>

                  <template v-slot:[`item.created_date`]="{ item }">
                    {{ item.created_date | formatDate }}
                  </template>

                  <template v-slot:[`item.status`]="{ item }">
                    <base-switch
                      v-model="item.status"
                      :height="26"
                      :width="99"
                      :font-size="5"
                      :color="{ checked: '#4CAF50', unchecked: '#FF5252' }"
                      :labels="{ checked: 'ACTIVE', unchecked: 'INACTIVE' }"
                      @change="setLoading(true), onChangeEventHandler(item, $event)"
                    />
                  </template>

                  <template v-slot:[`item.social_normal`]="{ item }">
                    <v-list-item-avatar tile class="align-self-center" color="hsl(255, 3%, 18%,0)">
                      <v-img
                        v-if="Object.keys(item.social_normal).length"
                        :src="`${path_file}${item.social_normal.file_path}`"
                      />
                    </v-list-item-avatar>
                  </template>

                  <template v-slot:[`item.social_hover`]="{ item }">
                    <v-list-item-avatar tile class="align-self-center" color="hsl(255, 3%, 18%,0)">
                      <v-img
                        v-if="Object.keys(item.social_hover).length"
                        :src="`${path_file}${item.social_hover.file_path}`"
                      />
                    </v-list-item-avatar>
                  </template>

                  <template v-slot:[`item.sort`]="{ item }">
                    {{ item.sort }}
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn class="px-2 ml-1" min-width="0" color="success" @click="editItem(item)" small>
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn class="px-2 ml-1" min-width="0" color="error" @click="deleteItem(item)" small>
                      <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANTS
 */
import { STATUS } from "@/constants/status"
import { LANGUAGES } from "@/constants/language"
import { PATHFILE } from "@/constants/config"

/**
 * CONTROLLER
 */
import {
  getSocialIconByLang,
  putSocialIconSort,
  putStatus,
  deleteSocialIcon
} from "@/controllers/home/home_social_icon.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "SocialIcon",
  data: () => ({
    socialIcons: [],
    status: STATUS,
    lang: 0,
    languages: LANGUAGES,
    headers: [
      {
        text: "Id",
        align: "start",

        value: "social_menu_id"
      },
      { text: "File", value: "social_normal" },
      // { text: "social_hover", value: "social_hover" },
      { text: "Sort", value: "sort" },
      { text: "Status", value: "status" },
      { text: "Actions", value: "actions", align: "center", width: "15%", sortable: false }
    ],
    path_file: PATHFILE,
    dialogSort: false,

    /**
     * SORT
     */
    header_list: [
      { text: "", value: "social_menu_id" },
      { text: "file", value: "social_normal" },
      // { text: "social_hover", value: "social_hover" },
      { text: "status", value: "status" },
      { text: "lang", value: "lang" },
      { text: "sort", value: "sort" }
    ],
    dialogDelete: false,
    delete_item: null,
    dragOptions: {
      animation: 200,
      group: "description",
      disabled: false,
      ghostClass: "ghost"
    }
  }),
  created() {
    this.setLoading(true)
    this.initialize("TH")
  },
  computed: {
    itemsSort: {
      get() {
        return this.socialIcons
      },
      set(items) {
        this.socialIcons = items
      }
    },
    textLang() {
      return this.lang === 1 ? "EN" : "TH"
    }
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize(lang) {
      try {
        this.socialIcons = await getSocialIconByLang({ lang })
        this.setLoading(false)
      } catch (e) {
        this.errorHandle(e)
      }
    },
    createItem(lang) {
      this.$router.push({ path: `home-social-icon-create?lang=${lang}` })
    },
    editItem(item) {
      this.$router.push({ path: `home-social-icon-detail/${item.social_menu_id}` })
    },
    sortItem() {
      this.dialogSort = true
    },
    async saveSort() {
      await putSocialIconSort({ socialIcons: this.socialIcons }, this.resultHandle, this.errorHandle)
      this.closeSort()
    },
    closeSort() {
      this.dialogSort = false
      this.initialize(this.textLang)
    },
    async onChangeEventHandler(item, { value }) {
      if (value) await putStatus({ socialIcon: item, status: "ACTIVE" }, this.resultHandle, this.errorHandle)
      else await putStatus({ socialIcon: item, status: "INACTIVE" }, this.resultHandle, this.errorHandle)
    },
    onTabChange(e) {
      this.initialize(e)
    },
    updateSortItems(items) {
      this.itemsSort = items.map((item) => {
        const { social_menu_id, link, social_normal, social_hover, sort, lang, status } = item
        return { social_menu_id, link, social_normal, social_hover, sort: sort + 1, lang, status }
      })
    },
    deleteItem(item) {
      this.delete_item = item
      this.dialogDelete = true
    },
    closeDelete() {
      this.delete_item = null
      this.dialogDelete = false
    },
    async deleteItemConfirm() {
      await deleteSocialIcon({ socialIcon: this.delete_item }, this.resultHandle, this.errorHandle)

      this.deleteResultHandle()
      this.dialogDelete = false
    },
    deleteResultHandle() {
      this.initialize(this.textLang)
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
