/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
; ("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_ESTIMATION_SHAPE } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut, axiosCreateFile, axiosPost, axiosUpdateFile} from "@/utils/axios"

export async function getEstimationShapeAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_ESTIMATION_SHAPE.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getEstimationShapeById({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_ESTIMATION_SHAPE.FINDBYID}/${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getEstimationShapeByLang({ lang }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_ESTIMATION_SHAPE.FINDBYLANG}/${lang}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function getByProductCategoryFilter({ id, lang = "th" }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_ESTIMATION_SHAPE.FINDBY_ESTIMATION_PRODUCT_CATEGORY_FILTER}?lang=${lang}&product_category_id=${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function getByProductCategoryId({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_ESTIMATION_SHAPE.FINDBY_ESTIMATION_PRODUCT_CATEGORY_ID}/${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}


export async function postEstimationShape({ estimationShape, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_ESTIMATION_SHAPE.CREATE}`
    let formData = new FormData()
    formData.append("title", estimationShape.title)
    formData.append("lang", estimationShape.lang)

    if (file) formData.append("file", file, file.name)

    const { code, data, message } = await axiosCreateFile(url, formData, token)

    if (code == 201) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putEstimationShape({ estimationShape, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_ESTIMATION_SHAPE.UPDATE}/${estimationShape.estimation_shape_id}`
    let formData = new FormData()
    formData.append("title", estimationShape.title)
    formData.append("lang", estimationShape.lang)
    formData.append("status", estimationShape.status)

    if (file) formData.append("file", file, file.name)

    const { code, data, message } = await axiosUpdateFile(url, formData, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putEstimationShapeSort({ estimationShapes }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = estimationShapes
    const url = `${API}${EP_ESTIMATION_SHAPE.UPDATE_SORT}`

    const { code, message, data } = await axiosPut(url, payload, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}
