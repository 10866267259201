const PAGES = [
  { page: "Product", page_code: "product" },
  { page: "About us", page_code: "about_us" },
  { page: "News", page_code: "news" },
  { page: "Support & Download", page_code: "support_download" },
  { page: "Dealer", page_code: "dealer" },
  { page: "Calculate", page_code: "calculate" },
  { page: "Career", page_code: "career" },
  { page: "Cookie", page_code: "cookie" },
  { page: "Contact", page_code: "contact" },
  { page: "Search", page_code: "search" }
]

export { PAGES }
