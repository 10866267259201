<template>
  <v-container>
    <!-- <pdf-content slot="pdf-content" :users="users" /> -->

    <vue-html2pdf
      enable-download
      manual-pagination
      :pdf-quality="1"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="100%"
      ref="html2Pdf"
    >
      <pdf-content slot="pdf-content" :users="users" />
    </vue-html2pdf>

    <base-material-card icon="mdi-account-search" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("user_management") }}</div>
      </template>

      <v-dialog v-model="dialogDelete" max-width="300">
        <v-card>
          <v-card-title>
            <p>
              ยืนยันการลบ
            </p>
            <v-spacer />
            <v-icon aria-label="Close" @click="closeDelete">
              mdi-close
            </v-icon>
          </v-card-title>

          <v-card-text class="pb-6 text-center">
            <v-btn class="mr-1" color="error" dark @click="closeDelete"> {{ $t("cancel") }} </v-btn>
            <v-btn class="mr-1" color="success" dark @click="deleteItemConfirm"> {{ $t("delete") }} </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <div class="row">
        <div class="col-12">
          <v-data-table :headers="headers" :items="users" sort-by="calories" class="elevation-1" :search="search">
            <template v-slot:top>
              <v-toolbar flat>
                <v-text-field
                  class="mt-2"
                  v-model="search"
                  :label="$t('search')"
                  single-line
                  append-icon="mdi-magnify"
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-btn dark class="mb-2 mx-1" color="primary" @click="generateReport">
                  Export <v-icon small>mdi-download</v-icon>
                </v-btn>
                <v-btn color="primary" dark class="mb-2" @click="createItem">
                  New User
                </v-btn>
              </v-toolbar>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-btn class="px-2 ml-1" min-width="0" color="success" @click="editItem(item)" small>
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn class="px-2 ml-1" min-width="0" color="error" @click="deleteItem(item)" small>
                <v-icon small> mdi-delete </v-icon>
              </v-btn>
            </template>

            <template v-slot:[`item.profile_img`]="{ item }">
              <v-list-item-avatar class="align-self-center" color="grey">
                <v-img v-if="item.profile_img" :src="`${path_file}${item.profile_img}`" />
              </v-list-item-avatar>
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <base-switch
                v-model="item.status"
                :height="26"
                :width="99"
                :font-size="5"
                :color="{ checked: '#4CAF50', unchecked: '#FF5252' }"
                :labels="{ checked: 'ACTIVE', unchecked: 'INACTIVE' }"
                @change="setLoading(true), onChangeEventHandler(item, $event)"
              />
            </template>

            <template v-slot:no-data>
              <v-btn color="primary" @click="initialize">
                Reset
              </v-btn>
            </template>
          </v-data-table>
        </div>
      </div>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { PATHFILE } from "@/constants/config"

/**
 * COMPONENTS
 */
import PdfContent from "@/components/PdfContent.vue"

/**
 * CONTROLLER
 */
import { findAllUser, putStatus, deleteUser } from "@/controllers/user/user.controller"

/**
 * LIBS
 */
import { mapMutations } from "vuex"
import VueHtml2pdf from "vue-html2pdf"

export default {
  name: "UserManagement",
  data: () => ({
    dialog: false,
    dialogDelete: false,
    path_file: PATHFILE,
    users: [],
    search: "",
    dialogDelete: false,
    delete_item: null,
    headers: [
      {
        text: "ID",
        align: "start",
        
        value: "user_id"
      },
      { text: "E-mail", value: "email" },
      { text: "First name", value: "firstname" },
      { text: "Last Name", value: "lastname" },
      { text: "Image Profile", value: "profile_img" },
      { text: "Status", value: "status" },
      { text: "Actions", value: "actions", align: "center", width: "15%", sortable: false }
    ]
  }),

  components: {
    VueHtml2pdf,
    PdfContent
  },

  created() {
    this.setLoading(true)
    this.initialize()
  },

  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.users = await findAllUser()
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    createItem() {
      this.$router.push({ path: `usermanagement/usermanagement-create` })
    },
    editItem(item) {
      this.$router.push({ path: `usermanagement/usermanagement-edit/${item.user_id}` })
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    },
    async onChangeEventHandler(item, { value }) {
      if (value) await putStatus({ user: item, status: "ACTIVE" }, this.resultHandle, this.errorHandle)
      else await putStatus({ user: item, status: "INACTIVE" }, this.resultHandle, this.errorHandle)
    },
    deleteItem(item) {
      this.delete_item = item
      this.dialogDelete = true
    },
    closeDelete() {
      this.delete_item = null
      this.dialogDelete = false
    },
    async deleteItemConfirm() {
      await deleteUser({ user: this.delete_item }, this.resultHandle, this.errorHandle, this.deleteResultHandle)
      this.dialogDelete = false
    },
    deleteResultHandle() {
      this.users = this.users.filter((user) => user.user_id !== this.delete_item.user_id)
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
