/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_SHADE_COLOR } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut, axiosPost, axiosDelete } from "@/utils/axios"

export async function getShadeColorAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_SHADE_COLOR.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getShadeColorByID({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_SHADE_COLOR.FINDBYID}/${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getShadeColorByLang({ lang }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_SHADE_COLOR.FINDBYLANG}/${lang}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function getShadeColorForSelect({ lang }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_SHADE_COLOR.FOR_SELECT}/${lang}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function getByProductCategory({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_SHADE_COLOR.FINDBY_CATEGORY}/${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function postShadeColor({ shadeColor }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = shadeColor
    const url = `${API}${EP_SHADE_COLOR.CREATE}`
    const { code, data, message } = await axiosPost(url, payload, token)

    if (code == 201) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putShadeColor({ shadeColor }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = shadeColor
    const url = `${API + EP_SHADE_COLOR.UPDATE}/${shadeColor.shade_color_id}`
    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    // const { message } = error.response.data
    console.log(error)
    return errorHandle(error)
  }
}

export async function putShadeColorSort({ shadeColors }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = shadeColors
    const url = `${API}${EP_SHADE_COLOR.UPDATE_SORT}`

    const { code, message, data } = await axiosPut(url, payload, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putStatus({ shadeColor, status }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    const payload = { status: status }
    const url = `${API}${EP_SHADE_COLOR.UPDATE_STATUS}/${shadeColor.shade_color_id}`

    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function deleteShadeColor({ shadeColor }, resultHandle, errorHandle, deleteResultHandle) {
  try {
    const token = await getAuthToken()

    const url = `${API}${EP_SHADE_COLOR.DELETE}/${shadeColor.shade_color_id}`

    const { code, data, message } = await axiosDelete(url, token)

    if (code == 200) {
      deleteResultHandle()
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}
