<template>
  <v-container>
    <base-material-card icon="mdi-home" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("home_highlight_list_create") }}</div>
      </template>
      <v-form @submit.prevent="setLoading(true), save()" ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="text-no-wrap" align="center">
            <v-col cols="12">
              <v-select
                v-model="highlightList.product_category_id"
                :items="productCategories"
                item-text="title"
                item-value="product_category_id"
                :label="$t('product_category')"
                :rules="[rules.required]"
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="highlightList.lang"
                :items="languages"
                item-text="language"
                item-value="lang_code"
                :label="$t('language')"
                :rules="[rules.required]"
                outlined
                @change="getProductCategory"
              ></v-select>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" min-width="100" class="pl-0" :loading="$store.state.isloading" @click="cancel">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"

/**
 * FUNCTIONS
 */
import { getProductCategoryByLang } from "@/controllers/product/product_category.controller"
import { postHighlightList } from "@/controllers/home/home_product_highlight_list.controller"

/**
 * LIBs
 */
import { mapMutations } from "vuex"

/**
 * UTILS
 */
import { rules } from "@/utils/validates"

export default {
  data: () => ({
    rules: rules,
    highlightList: {},
    productCategories: [],
    languages: LANGUAGES,
    fileAccept: "image/png,image/gif,image/jpeg,image/webp"
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.highlightList.lang = this.$route.query.lang
        await this.getProductCategory(this.$route.query.lang)
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async getProductCategory(lang) {
      this.highlightList.product_category_id = null
      this.productCategories = await getProductCategoryByLang({ lang })
    },

    async save() {
      const isValid = this.$refs.form.validate()
      if (isValid) {
        await postHighlightList(
          {
            highlightList: this.highlightList
          },
          this.resultHandle,
          this.errorHandle
        )
      }
      this.setLoading(false)
    },
    cancel() {
      this.setLoading(true)
      this.$router.back()
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
      this.$router.back()
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style scoped>
.carousel-icon-close {
  margin: auto !important;
  left: 0 !important;
  right: 0 !important;
  /* bottom: 0 !important; */
  top: 0 !important;
}
</style>
