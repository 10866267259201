/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
"use strict"

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_USERS } from "@/constants/endpoint"
/**
 * UTILS & SERVICE
 */
import { axiosPost, axiosUploadFile } from "@/utils/axios"
import routers from "@/routers"
import store from "@/store"

export async function getAuthToken() {
  if (!store.state.token) {
    const token = localStorage.getItem("token") || null
    store.commit("SET_TOKEN", token)
  }
  return store.state.token
}

export async function setAuthToken(token) {
  localStorage.setItem("token", token)
  store.commit("SET_TOKEN", token)
}

export async function clearAuthToken() {
  localStorage.removeItem("token")
  store.commit("CLEAR_TOKEN")
}

export async function login({ email, password }, resultHandle, errorHandle) {
  try {
    if (email == "") return errorHandle(`Please type username`)
    if (password == "") return errorHandle(`Please type password`)
    // const { code, message, data } = await axios.post(APITOKEN)
    const url = API + EP_USERS.LOGIN
    const body = { email: email, password: password }
    const response = await axios.post(url, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${APITOKEN}`
      }
    })

    const { code, data, message } = response.data
    if (code == 200) {
      await setAuthToken(data)
      return resultHandle()
    } else {
      return errorHandle(message)
    }
  } catch (e) {
    const { code, message } = e.response.data
    return errorHandle(message)
  }
}

export async function register({ email, password, firstname, lastname, confirm_password }, resultHandle, errorHandle) {
  try {
    if (email == "") return errorHandle(`Please type username`)
    if (password == "") return errorHandle(`Please type password`)

    const url = API + EP_USERS.REGISTER
    const body = { email, password, confirm_password, firstname, lastname }
    const response = await axios.post(url, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${APITOKEN}`
      }
    })

    const { code, data, message } = response.data

    if (code == 201) {
      await setAuthToken(data)
      return resultHandle()
    }

    return errorHandle(message)
  } catch (error) {
    return errorHandle(error)
  }
}

export async function logout(resultHandle) {
  try {
    await clearAuthToken()
    return resultHandle()
  } catch (error) {}
}

export async function Unauthorized() {
  try {
    await localStorage.clear()

    routers.push("login")
  } catch (error) {
    console.log(error)
  }
}

// export async function uploadFile(files) {
//   try {
//     // if (!files.length) return `Please type files`
//     let formData = new FormData()
//     formData.append("customer", "customer333")
//     for (let file of files) {
//       formData.append("files", file, file.name)
//     }
//     const { code, message, data } = await axios
//       .post(API + EP_TEST.UPLOADFILE, formData, {
//         headers: {
//           "Content-Type": "multipart/form-data"
//         }
//       })
//       .then(function(res) {
//         console.log(res)
//       })
//       .catch(function(err) {
//         console.log(err)
//       })
//     // const { code, message, data } = await axiosUploadFile(API + EP_TEST.UPLOADFILE, { files: fileUpload }, APITOKEN)
//     // if (code == 200) {
//     //   // setAuthToken(data)
//     //   return resultHandle()
//     // } else {
//     //   return errorHandle(message)
//     // }
//   } catch (error) {
//     const { code, message } = error.response.data
//     return errorHandle(message)
//   }
// }
