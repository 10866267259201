/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_DEALER } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut, axiosUpdateFile, axiosCreateFile, axiosDelete } from "@/utils/axios"

export async function getDealerAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_DEALER.FINDALL
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getDealerByID({ dealer_id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_DEALER.FINDBYID}/${dealer_id}`
    const { code, data, message } = await axiosGet(url, token)
    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getDealerByLang({ lang }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_DEALER.FINDBYLANG}/${lang}`
    const { code, data, message } = await axiosGet(url, token)
    if (code == 200) {
      return data
    }
  } catch (error) {
    return error
  }
}

export async function getCountry({ lang }) {
  try {
    const token = await getAuthToken()

    const url = `${API}${EP_DEALER.FINDCOUNTRY}/${lang}`
    const { code, data, message } = await axiosGet(url, token)
    if (code == 200) {
      return data
    }
    return
  } catch (error) {
    return error
  }
}

export async function getProvince({ country, lang }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_DEALER.FINDPROVINCE}/${country}/${lang}`
    const { code, data, message } = await axiosGet(url, token)
    if (code == 200) {
      return data
    }
  } catch (error) {
    return error
  }
}

export async function getDistrict({ province_id, lang }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_DEALER.FINDDISTRICT}/${province_id}/${lang}`
    const { code, data, message } = await axiosGet(url, token)
    if (code == 200) {
      return data
    }
  } catch (error) {
    return error
  }
}

export async function getSubDistrict({ district_id, lang }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_DEALER.FINDSUBDISTRICT}/${district_id}/${lang}`
    const { code, data, message } = await axiosGet(url, token)
    if (code == 200) {
      return data
    }
  } catch (error) {
    return error
  }
}

export async function postDealer({ dealer, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    let formData = new FormData()
    if (dealer.shop_name) formData.append("shop_name", dealer.shop_name)
    if (dealer.tel) formData.append("tel", dealer.tel)
    if (dealer.no_address) formData.append("no_address", dealer.no_address)
    if (dealer.address) formData.append("address", dealer.address)
    if (dealer.building) formData.append("building", dealer.building)
    if (dealer.floor) formData.append("floor", dealer.floor)
    if (dealer.unit) formData.append("unit", dealer.unit)
    if (dealer.moo) formData.append("moo", dealer.moo)
    if (dealer.alley) formData.append("alley", dealer.alley)
    if (dealer.soi) formData.append("soi", dealer.soi)
    if (dealer.road) formData.append("road", dealer.road)
    if (dealer.sub_district) formData.append("sub_district", dealer.sub_district)
    if (dealer.district) formData.append("district", dealer.district)
    if (dealer.province) formData.append("province", dealer.province)
    if (dealer.postal_code) formData.append("postal_code", dealer.postal_code)
    if (dealer.country) formData.append("country", dealer.country)
    if (dealer.lead != 2) formData.append("lead", dealer.lead)
    if (dealer.place_name) formData.append("place_name", dealer.place_name)
    if (dealer.place_id) formData.append("place_id", dealer.place_id)
    // formData.append("modern_trade", dealer.modern_trade)
    if (dealer.link) formData.append("link", dealer.link)
    if (dealer.lat) formData.append("lat", dealer.lat)
    if (dealer.lng) formData.append("lng", dealer.lng)
    if (dealer.i_frame) formData.append("i_frame", dealer.i_frame)
    if (dealer.search) formData.append("search", dealer.search)
    if (dealer.lang) formData.append("lang", dealer.lang)
    if (file) formData.append("file", file, file.name)

    const url = `${API}${EP_DEALER.CREATE}`

    const { code, data, message } = await axiosCreateFile(url, formData, token)
    if (code == 201) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putDealer({ dealer, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    let formData = new FormData()
    if (dealer.shop_name) formData.append("shop_name", dealer.shop_name)
    if (dealer.tel) formData.append("tel", dealer.tel)
    if (dealer.no_address) formData.append("no_address", dealer.no_address)
    if (dealer.address) formData.append("address", dealer.address)
    if (dealer.building) formData.append("building", dealer.building)
    if (dealer.floor) formData.append("floor", dealer.floor)
    if (dealer.unit) formData.append("unit", dealer.unit)
    if (dealer.moo) formData.append("moo", dealer.moo)
    if (dealer.alley) formData.append("alley", dealer.alley)
    if (dealer.soi) formData.append("soi", dealer.soi)
    if (dealer.road) formData.append("road", dealer.road)
    if (dealer.sub_district) formData.append("sub_district", dealer.sub_district)
    if (dealer.district) formData.append("district", dealer.district)
    if (dealer.province) formData.append("province", dealer.province)
    if (dealer.postal_code) formData.append("postal_code", dealer.postal_code)
    if (dealer.country) formData.append("country", dealer.country)
    if (dealer.lead != 2) formData.append("lead", dealer.lead)
    if (dealer.place_name) formData.append("place_name", dealer.place_name)
    if (dealer.place_id) formData.append("place_id", dealer.place_id)
    if (dealer.search) formData.append("search", dealer.search)
    // formData.append("modern_trade", dealer.modern_trade)
    if (dealer.link) formData.append("link", dealer.link)
    if (dealer.lat) formData.append("lat", dealer.lat)
    if (dealer.lng) formData.append("lng", dealer.lng)
    if (dealer.i_frame) formData.append("i_frame", dealer.i_frame)
    if (dealer.status) formData.append("status", dealer.status)
    if (dealer.lang) formData.append("lang", dealer.lang)
    if (file) formData.append("file", file, file.name)

    const url = `${API}${EP_DEALER.UPDATE}/${dealer.dealer_id}`
    const { code, data, message } = await axiosUpdateFile(url, formData, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putStatus({ dealer, status }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    const payload = { status: status }
    const url = `${API}${EP_DEALER.UPDATE_STATUS}/${dealer.dealer_id}`

    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function deleteDealer({ dealer }, resultHandle, errorHandle, deleteResultHandle) {
  try {
    const token = await getAuthToken()

    const url = `${API}${EP_DEALER.DELETE}/${dealer.dealer_id}`

    const { code, data, message } = await axiosDelete(url, token)

    if (code == 200) {
      deleteResultHandle()
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}
