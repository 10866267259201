<template>
  <v-container>
    <base-material-card icon="mdi-home" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("menu_create") }}</div>
      </template>

      <div class="text-center">
        <base-upload-image v-model="file" :defaultValue="menu.file" width="920px" height="245px" class="mb-2" />
        <span class="red--text">*แนะนำขนาดรูปภาพ 920 x 245 px</span>
      </div>
      <v-form @submit.prevent="setLoading(true), save()">
        <v-container fluid>
          <v-row class="text-no-wrap" align="center">
            <!-- <v-col cols="12" md="12">
              <v-text-field v-model="menu.title" label="title" outlined></v-text-field>
            </v-col> -->

            <v-col cols="12">
              <v-autocomplete
                v-model="menu.product_group_id"
                :items="productGroups"
                item-text="title"
                item-value="product_group_id"
                :label="$t('product_group')"
                outlined
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field v-model="menu.link" label="link" outlined></v-text-field>
            </v-col>

            <v-col cols="12"
              ><v-select
                v-model="menu.lang"
                :items="languages"
                item-text="language"
                item-value="lang_code"
                label="ภาษา"
                outlined
              ></v-select
            ></v-col>
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" min-width="100" class="pl-0" :loading="$store.state.isloading" @click="cancel">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"

/**
 * CONTROLLERS
 */
import { getProductGroupByLang } from "@/controllers/product/product_group.controller.js"
import { postMenu } from "@/controllers/menu/menu.controller"

import { mapMutations } from "vuex"
export default {
  name: "MenuCreate",
  data: () => ({
    languages: LANGUAGES,
    status: STATUS,
    productGroups: [],
    menu: {},
    file: null
  }),

  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.menu.lang = this.$route.query.lang
        this.productGroups = await getProductGroupByLang({ lang: this.$route.query.lang })
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async save() {
      await postMenu({ menu: this.menu, file: this.file }, this.resultHandle, this.errorHandle)
      this.setLoading(true)
      this.$router.back()
    },
    cancel() {
      this.setLoading(true)
      this.$router.back()
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
