/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
"use strict"

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_APPLY_JOB } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut, axiosPost, axiosDelete } from "@/utils/axios"

export async function getApplyJobAll({ apply_job_id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_APPLY_JOB.FINDALL}/${apply_job_id}`
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    console.error(error)
    return error
  }
}

export async function deleteApplyJob({ career_id, apply_job }, resultHandle, errorHandle, deleteResultHandle) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_APPLY_JOB.DELETE}/${career_id}/${apply_job.apply_job_id}`
    const { code, data, message } = await axiosDelete(url, token)

    if (code == 200) {
      deleteResultHandle()
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}
