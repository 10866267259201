<template>
  <v-container>
    <base-material-card icon="mdi-home" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("home_carousel_detail") }}</div>
      </template>

      <base-upload-image v-model="file" :defaultValue="carousel.file" class="mb-2" width="380px" height="230px" />
      <p class="red--text text-center">*แนะนำขนาดรูปภาพ 380 x 230 px</p>

      <v-form @submit.prevent="setLoading(true), save()" ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="text-no-wrap" align="center">
            <v-col cols="12" md="12">
              <v-text-field :label="$t('link')" v-model="carousel.link" outlined />
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="carousel.lang"
                :items="languages"
                item-text="language"
                item-value="lang_code"
                :label="$t('language')"
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="carousel.status"
                :items="status"
                item-text="text"
                item-value="value"
                :label="$t('status')"
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" min-width="100" class="pl-0" :loading="$store.state.isloading" @click="cancel">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"
import { PATHFILE } from "@/constants/config"

/**
 * FUNCTIONS
 */
import { putCarousel, getCarouselById } from "@/controllers/home/home_carousel.controller"

/**
 * LIBs
 */
import { mapMutations } from "vuex"

/**
 * UTILS
 */
import { rules } from "@/utils/validates"

export default {
  data: () => ({
    rules: rules,
    carousel: {},
    pathFile: PATHFILE,
    languages: LANGUAGES,
    status: STATUS,
    file: null,
    previewImages: []
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        if (!this.$route.params.id) this.$route.back()
        this.getCarousel()
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async getCarousel() {
      this.carousel = await getCarouselById({ id: this.$route.params.id })
      if (this.carousel.files && this.carousel.files.length) {
        this.previewImages = this.carousel.files.map((file) => {
          return PATHFILE + file.file_path
        })
      }
    },
    async save() {
      const isValid = this.$refs.form.validate()
      if (isValid) {
        await putCarousel(
          {
            carousel: this.carousel,
            file: this.file
          },
          this.resultHandle,
          this.errorHandle
        )
        this.setLoading(true)
        this.$router.back()
      }
      this.setLoading(false)
    },
    cancel() {
      this.setLoading(true)
      this.$router.back()
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style scoped>
.carousel-icon-close {
  margin: auto !important;
  left: 0 !important;
  right: 0 !important;
  /* bottom: 0 !important; */
  top: 30% !important;
}
</style>
