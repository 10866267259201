<template>
  <v-container>
    <base-material-card icon="mdi-home-map-marker" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("district_create") }}</div>
      </template>

      <v-form @submit.prevent="setLoading(true), save()">
        <v-container fluid>
          <v-row class="text-no-wrap" align="center">
            <v-col cols="12" md="12">
              <v-text-field v-model="district.district" :label="$t('district')" outlined></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field v-model="district.district_en" :label="$t('district_en')" outlined></v-text-field>
            </v-col>
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" min-width="100" class="pl-0" :loading="$store.state.isloading" @click="cancel">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"

/**
 * FUNCTIONS
 */
import { getDistrictById, putDistrict } from "@/controllers/address/district.controller"

/**
 * LIBs
 */
import { mapMutations } from "vuex"

/**
 * UTILS
 */
import { rules } from "@/utils/validates"

export default {
  data: () => ({
    rules: rules,
    district: {
      province_id: null,
      district: ""
    },
    languages: LANGUAGES
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.district = await getDistrictById({ id: this.$route.params.id })
        this.district.province_id = this.$route.params.provinceId
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async save() {
      await putDistrict({ district: this.district }, this.resultHandle, this.errorHandle)
      this.setLoading(true)
      this.$router.back()
    },
    cancel() {
      this.setLoading(true)
      this.$router.back()
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>
