<template>
  <v-container>
    <base-material-card icon="mdi-account-search" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("user_detail") }}</div>
      </template>
      <base-upload-image v-model="file" :defaultValue="user.file" class="mb-2" width="200px" height="200px" />
      <p class="text-center red--text">*แนะนำขนาดรูปภาพ 200 x 200 px</p>

      <form @submit.prevent="setLoading(true), save()">
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field v-model="user.email" :rules="[rules.required]" :label="$t('email')" outlined />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="user.firstname" :rules="[rules.required]" :label="$t('firstname')" outlined />
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field v-model="user.lastname" :label="$t('lastname')" outlined />
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                type="password"
                v-model="user.password"
                :rules="[rules.password]"
                :label="$t('password')"
                outlined
              />
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                type="password"
                v-model="user.confirm_password"
                :rules="[rules.password, rules.match(user.password, user.confirm_password)]"
                :label="$t('confirm_password')"
                outlined
              />
            </v-col>

            <v-col cols="12" md="12">
              <v-select
                v-model="user.position"
                :items="roles"
                item-text="title"
                item-value="role_id"
                :label="$t('role')"
                outlined
              ></v-select>
            </v-col>

            <!-- <v-col cols="6" md="6">
              <v-switch :false-value="0" :true-value="1" v-model="user.lead" inset :label="$t('lead')"></v-switch>
            </v-col> -->

            <!-- <v-col cols="6" md="6">
              <v-switch
                :false-value="0"
                :true-value="1"
                v-model="user.modern_trade"
                inset
                :label="$t('modern_trade')"
              ></v-switch>
            </v-col> -->

            <v-col cols="12" md="12">
              <v-select
                v-model="user.status"
                :items="status"
                item-text="text"
                item-value="value"
                label="สถานะ"
                outlined
              ></v-select>
            </v-col>
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" min-width="100" class="pl-0" :loading="$store.state.isloading" @click="cancel()">
              {{ $t("cancel") }}
            </v-btn>

            <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </form>
    </base-material-card>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex"
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"

/**
 * CONTROLLER
 */
import { getUserByID, putUser, getRole } from "@/controllers/user/user.controller"

/**
 * Utils
 */
import { rules } from "@/utils/validates"

export default {
  name: "UserDetail",
  data: () => ({
    rules,
    user: {},
    languages: LANGUAGES,
    status: STATUS,
    file: null,
    roles: []
  }),
  async created() {
    this.setLoading(true)
  },
  async mounted() {
    this.roles = await getRole()
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.user = await getUserByID({ id: this.$route.params.id })
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async save() {
      this.setLoading(true)
      await putUser({ user: this.user, file: this.file }, this.resultHandle, this.errorHandle)
    },
    cancel() {
      this.setLoading(true)
      this.$router.back()
    },
    resultHandle(message) {
      this.setNoti({ snackbar: true, color: "success", message: message })
      this.$router.back()
      this.setLoading(false)
    },
    errorHandle(message) {
      this.setNoti({ snackbar: true, color: "error", message: message })
      this.setLoading(false)
    }
  }
}
</script>

<style></style>
