<template>
  <v-container>
    <base-material-card icon="mdi-swap-horizontal" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("compare_info") }} - {{ title }}</div>
      </template>

      <v-form @submit.prevent="setLoading(true), save()" ref="form" lazy-validation>
        <v-container fluid>
          <v-row
            class="text-no-wrap"
            align="center"
            v-for="(dimension, index) in dimensions"
            :key="`dimenion-${index + 1}`"
          >
            <v-col cols="12" md="4" class="d-flex">
              <span class="my-auto"></span>
              {{ dimension.title }}
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="dimension.value"
                :label="dimension.title"
                :rules="[rules.required]"
                hide-details="auto"
                outlined
              ></v-text-field>
            </v-col>
            <v-flex xs12></v-flex>
            <!-- <v-col class="ml-md-auto" cols="12" md="12">
              <v-select
                v-model="dimension.status"
                :items="status"
                item-text="text"
                item-value="value"
                :label="$t('status')"
                outlined
              ></v-select>
            </v-col> -->
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" min-width="100" class="pl-0" :loading="$store.state.isloading" @click="cancel">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * FUNCTIONS
 */
import { getProductCategoryByID } from "@/controllers/product/product_category.controller"
import { getProductGroupDimensionAll } from "@/controllers/product/product_group_dimension.controller"
import {
  getProductCategoryDimension,
  postProductCategoryDimension,
  putProductCategoryDimension
} from "@/controllers/product/product_category_dimension.controller"

/**
 * LIBs
 */
import { mapMutations } from "vuex"

/**
 * UTILS
 */
import { rules } from "@/utils/validates"

export default {
  data: () => ({
    rules: rules,
    productGroupId: null,
    groupDimensions: [],
    dimensions: [],
    type: "POST",
    title: ""
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        if (!this.$route.params.id) this.$route.back()
        const { title } = await getProductCategoryByID({ id: this.$route.params.productCategoryId })
        this.title = title
        let _dimensions = []
        const dimensions = await getProductCategoryDimension({ id: this.$route.params.productCategoryId })
        dimensions.length ? (this.type = "UPDATE") : (this.type = "CREATE")

        const dimensionIds = dimensions.map((dimension) => dimension.product_group_dimension_id)
        const groups = await getProductGroupDimensionAll({ id: this.$route.params.id, status: "ACTIVE" })
        groups.forEach((item) => {
          let value = ""
          if (dimensionIds.includes(item.product_group_dimension_id)) {
            value = dimensions.find(
              (dimension) => dimension.product_group_dimension_id === item.product_group_dimension_id
            )?.value
          }

          _dimensions.push({
            product_category_id: this.$route.params.productCategoryId,
            product_group_dimension_id: item.product_group_dimension_id,
            title: item.title,
            value: value
          })
        })
        this.dimensions = _dimensions
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async save() {
      const isValid = this.$refs.form.validate()
      if (isValid) {
        if (this.type === "CREATE") {
          await postProductCategoryDimension(
            {
              dimensions: this.dimensions
            },
            this.resultHandle,
            this.errorHandle
          )
        } else {
          await putProductCategoryDimension(
            {
              dimensions: this.dimensions
            },
            this.resultHandle,
            this.errorHandle
          )
        }
        this.setLoading(true)
        this.$router.back()
      }
      this.setLoading(false)
    },
    cancel() {
      this.setLoading(true)
      this.$router.back()
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>
