<template>
  <v-container>
    <base-material-card icon="mdi-book-information-variant" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">
          {{ $t("site_ref_create") }}
        </div>
      </template>
      <v-form @submit.prevent="setLoading(true), save()">
        <v-container fluid>
          <v-row class="text-no-wrap" align="center">
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="siteRef.product_group_id"
                :items="productGroups"
                item-text="title"
                item-value="product_group_id"
                :label="$t('product_group')"
                @change="selectProductGroup"
                outlined
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="siteRef.product_category_id"
                :items="productCategories"
                item-text="title"
                item-value="product_category_id"
                :label="$t('product_category')"
                @change="selectProductCategory"
                outlined
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="siteRef.shade_color_id"
                :items="shadeColors"
                item-text="title"
                item-value="shade_color_id"
                :label="$t('shade_color')"
                @change="selectShadeColor"
                outlined
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="siteRef.color_id"
                :items="colors"
                item-text="title"
                item-value="color_id"
                :label="$t('color')"
                outlined
              >
              </v-autocomplete>
            </v-col>

            <v-flex xs12></v-flex>

            <v-col cols="12" lg="6">
              <base-file-input v-model="files" @change="onChangeHandler" chip label="images" multiple outlined />
              <v-carousel cycle height="400" hide-delimiter-background show-arrows-on-hover v-if="previewImages.length">
                <v-carousel-item v-for="(slide, i) in previewImages" :key="`image-${i}`">
                  <v-sheet height="100%">
                    <v-row class="fill-height" align="center" justify="center">
                      <v-img :src="slide" />
                    </v-row>
                  </v-sheet>
                </v-carousel-item>
              </v-carousel>
            </v-col>
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" min-width="100" class="pl-0" :loading="$store.state.isloading" @click="cancel">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"

/**
 * FUNCTIONS
 */
import { getProductGroupByLang } from "@/controllers/product/product_group.controller"
import { getByProductGroup as getProductCategory } from "@/controllers/product/product_category.controller"
import { getByProductCategory as getShadeColor } from "@/controllers/product/shade_color.controller"
import { getByShadeColor as getColor } from "@/controllers/product/color.controller"

import { postSiteRef } from "@/controllers/site_ref/site_ref.controller"

/**
 * LIBs
 */
import { mapMutations } from "vuex"

export default {
  data: () => ({
    siteRef: {
      title: null,
      product_group_id: null,
      product_category_id: null,
      shade_color_id: null,
      color_id: null,
      lang: null
    },
    files: null,
    languages: LANGUAGES,
    productGroups: [],
    productCategories: [],
    shadeColors: [],
    colors: [],
    previewImages: []
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.siteRef.lang = this.$route.query.lang
        this.siteRef.product_group_id = parseInt(this.$route.params.id)
        this.siteRef.product_category_id = parseInt(this.$route.params.productCategoryId)
        await this.fetchProductGroup(this.$route.query.lang)
        if (this.siteRef.product_group_id) await this.fetchProductCategory(this.siteRef.product_group_id)
        if (this.siteRef.product_category_id) await this.fetchShadeColor(this.siteRef.product_category_id)
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async fetchProductGroup(lang = "TH") {
      try {
        this.productGroups = await getProductGroupByLang({ lang })
        console.log(this.productGroups)
      } catch (e) {
        this.errorHandle(e)
      }
    },
    async fetchProductCategory(id) {
      try {
        this.productCategories = await getProductCategory({ id })
      } catch (e) {
        this.errorHandle(e)
      }
    },
    async fetchShadeColor(id) {
      try {
        this.shadeColors = await getShadeColor({ id })
      } catch (e) {
        this.errorHandle(e)
      }
    },
    async fetchColor(id) {
      try {
        this.colors = await getColor({ id })
      } catch (e) {
        this.errorHandle(e)
      }
    },
    selectProductGroup(e) {
      this.fetchProductCategory(e)
    },
    selectProductCategory(e) {
      this.fetchShadeColor(e)
    },
    selectShadeColor(e) {
      this.fetchColor(e)
    },
    onChangeHandler(e) {
      this.previewImages = []
      if (e) {
        e.forEach((file) => {
          this.previewImages.push(URL.createObjectURL(file))
        })
      }
    },
    async save() {
      await postSiteRef({ siteRef: this.siteRef, files: this.files }, this.resultHandle, this.errorHandle)
      console.log(this.siteRef)
      this.setLoading(true)
      this.$router.back()
    },
    cancel() {
      this.setLoading(true)
      this.$router.back()
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>
