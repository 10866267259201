/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_MENU_STATUS } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPost, axiosPatch } from "@/utils/axios"

export async function getMenusAll({ know_as, lang }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_MENU_STATUS.FINDALL}${know_as ? `?know_as=${know_as}` : ''}${`${lang ? `&lang=${lang}` : ''}`}`

    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    console.log(error)
    return error
  }
}

export async function putMenusStatus({ id, status }) {
    try {
      const token = await getAuthToken()
      const url = `${API}${EP_MENU_STATUS.UPDATE_STATUS.replace(":id", id)}`

      const payload = {
        status: status
      }
  
      const { code, data } = await axiosPatch(url, payload, token)

      if (code == 200) {
        return data
      }
        
    } catch (error) {
      console.log(error)
      return error
    }
}

// export async function putMenusStatus() {
//     try {
        
//     } catch (error) {
        
//     }
// }
