<template>
  <v-container>
    <base-material-card>
      <template v-slot:heading>
        <div class="display-2 font-weight-light">Edit Profile{{ $route.query.item }}</div>

        <div class="subtitle-1 font-weight-light">
          Complete your profile
        </div>
      </template>
      <v-form>
        <v-container class="py-0">
          <v-row>
            <v-col cols="12" md="12">
              <v-file-input
                multiple
                :rules="rules.ruleFile"
                accept="image/*"
                placeholder="Pick an avatar"
                prepend-icon="mdi-camera"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </base-material-card>
  </v-container>
</template>

<script>
export default {
  name: "FAQManagement",
  data: () => ({
    rules: {
      ruleFile: [
        (files) => {
          let sizeAll = 0
          return (
            !files ||
            !files.some((file) => {
              sizeAll += file.size
              return sizeAll > 26_214_400
            }) ||
            "File size should be less than 25 MB!"
          )
        }
      ]
    }
  })
}
</script>
