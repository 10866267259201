/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_ICON } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPost, axiosPut, axiosUpdateFile } from "@/utils/axios"

export async function getIconAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_ICON.FINDALL
    const { code, data, message } = await axiosGet(url, token)
    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getIconByID({ icon_id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_ICON.FINDBYID}/${icon_id}`
    const { code, data, message } = await axiosGet(url, token)
    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function postIcon({ icon }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = {
      link: icon.link,
      status: icon.status,
      lang: icon.lang
    }
    const url = `${API}${EP_ICON.CREATE}`
    const { code, data, message } = await axiosPost(url, payload, token)
    if (code == 201) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putIcon({ icon, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    let formData = new FormData()
    formData.append("status", icon.status)
    formData.append("lang", icon.lang)
    formData.append("file", file)

    const url = `${API}${EP_ICON.UPDATE}/${icon.icon_id}`
    const { code, data, message } = await axiosUpdateFile(url, formData, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putIconSort({ icons }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = icons
    const url = `${API}${EP_ICON.UPDATE_SORT}`

    const { code, message, data } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}
