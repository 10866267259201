<template>
  <v-container fluid>
    <v-dialog v-model="dialogDelete" max-width="300">
      <v-card>
        <v-card-title>
          <p>
            ยืนยันการลบ
          </p>
          <v-spacer />
          <v-icon aria-label="Close" @click="closeDelete">
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="pb-6 text-center">
          <v-btn class="mr-1" color="error" dark @click="closeDelete"> {{ $t("cancel") }} </v-btn>
          <v-btn class="mr-1" color="success" dark @click="deleteItemConfirm"> {{ $t("delete") }} </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <base-material-card icon="mdi-account-hard-hat" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("apply_job") }}</div>
      </template>
      <v-container>
        <v-row class="text-no-wrap" align="center">
          <v-col>
            <v-data-table :headers="headers" :items="apply_job" class="elevation-1">
              <!-- <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title> {{ $t("apply_job") }} </v-toolbar-title>

                  <v-spacer></v-spacer>
                </v-toolbar>
              </template> -->

              <template v-slot:[`item.file`]="{ item }">
                <a v-if="Object.keys(item.file).length" :href="`${path_file}${item.file.file_path}`" target="_blank">
                  <v-icon small class="mr-2" color="info">
                    mdi-file
                  </v-icon>
                </a>
              </template>

              <template v-slot:[`item.created_at`]="{ item }">
                {{ item.created_at | formatDate }}
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-btn class="px-2 ml-1" min-width="0" color="error" @click="deleteItem(item)" small>
                  <v-icon small> mdi-delete </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" min-width="100" class="pl-0" :loading="$store.state.isloading" @click="cancel">
            {{ $t("back") }}
          </v-btn>
        </v-card-actions>
      </v-container>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"
import { PATHFILE } from "@/constants/config"

/**
 * CONTROLLER
 */
import { getApplyJobAll, deleteApplyJob } from "@/controllers/career/apply_job.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "ApplyJob",
  data: () => ({
    languages: LANGUAGES,
    status: STATUS,
    path_file: PATHFILE,
    apply_job: [],
    dialogDelete: false,
    delete_item: null
  }),
  computed: {
    headers() {
      return [
        {
          text: "ID",
          align: "start",
          
          value: "apply_job_id"
        },
        { text: this.$t("firstname_lastname"), value: "name" },
        { text: "tel", value: "tel" },
        { text: "email", value: "email" },
        { text: "file", value: "file" },
        { text: "created_at", value: "created_at" },
        // { text: "status", value: "status" }
        // { text: "lang", value: "lang" }
        { text: "Actions", value: "actions", align: "center", width: "15%", sortable: false }
      ]
    }
  },
  created() {
    this.setLoading(true)
    this.initialize()
  },
  filters: {
    formatDate(datetime) {
      const date = new Date(datetime).getDate()
      const month = new Date(datetime).getMonth()
      const year = new Date(datetime).getFullYear()
      return `${date.toString().padStart(2, "0")}-${(month + 1).toString().padStart(2, "0")}-${year}`
    }
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.apply_job = await getApplyJobAll({
          apply_job_id: this.$route.params.apply_job_id
        })
        this.setLoading(false)
      } catch (e) {
        this.errorHandle(e)
      }
    },
    deleteItem(item) {
      this.delete_item = item
      this.dialogDelete = true
    },
    closeDelete() {
      this.delete_item = null
      this.dialogDelete = false
    },
    async deleteItemConfirm() {
      await deleteApplyJob(
        { career_id: this.$route.params.apply_job_id, apply_job: this.delete_item },
        this.resultHandle,
        this.errorHandle,
        this.deleteResultHandle
      )
      this.dialogDelete = false
    },
    deleteResultHandle() {
      this.apply_job = this.apply_job.filter((apply_job) => apply_job.apply_job_id !== this.delete_item.apply_job_id)
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    },
    cancel() {
      this.setLoading(true)
      this.$router.back()
    }
  }
}
</script>

<style></style>
