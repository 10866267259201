<template>
  <v-container>
    <base-material-card icon="mdi-domain" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("activity_detail") }}</div>
      </template>
      <form @submit.prevent="setLoading(true), save()">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
                <base-upload-image
                  v-model="file"
                  :defaultValue="activity.file"
                  width="180px"
                  height="90px"
                  class="mb-2"
                />
                <p class="text-center red--text">*แนะนำขนาดรูปภาพ 180 x 90 px</p>
              </v-col>
              <v-col cols="12" md="12">
                <base-ck-editor
                  v-model="activity.activity"
                  :text.sync="activity.activity"
                  :placeholder="$t('activity')"
                />
              </v-col>

              <!-- <v-col>
                <v-select
                  v-model="activity.status"
                  :items="status"
                  item-text="text"
                  item-value="value"
                  :label="$t('status')"
                  outlined
                ></v-select>
              </v-col> -->

              <v-col>
                <v-select
                  v-model="activity.lang"
                  :items="languages"
                  item-text="language"
                  item-value="lang_code"
                  :label="$t('language')"
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            min-width="100"
            class="pl-0"
            :loading="$store.state.isloading"
            @click="setLoading(true), close()"
          >
            {{ $t("cancel") }}
          </v-btn>

          <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </form>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANTS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"

/**
 * CONTROLLER
 */
import { postActivity } from "@/controllers/about_us/activity.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "ActivityCreate",
  data: () => ({
    activity: {},
    status: STATUS,
    languages: LANGUAGES,
    file: null
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.activity.lang = this.$route.query.lang
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async save() {
      await postActivity({ activity: this.activity, file: this.file }, this.resultHandle, this.errorHandle)
      this.$router.back()
    },
    close() {
      this.$router.back()
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
