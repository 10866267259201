/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
"use strict"

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_BRANCH } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut, axiosCreateFile, axiosDelete } from "@/utils/axios"

export async function getBranchAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_BRANCH.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getBranchByID({ branch_id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_BRANCH.FINDBYID}/${branch_id}`
    const { code, data, message } = await axiosGet(url, token)
    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getBranchByLang({ lang }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_BRANCH.FINDBYLANG}/${lang}`
    const { code, data } = await axiosGet(url, token)
    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function postBranch({ branch, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    let formData = new FormData()
    if (branch.branch) formData.append("branch", branch.branch)
    if (branch.address) formData.append("address", branch.address)
    if (branch.no_address) formData.append("no_address", branch.no_address)
    if (branch.building) formData.append("building", branch.building)
    if (branch.floor) formData.append("floor", branch.floor)
    if (branch.unit) formData.append("unit", branch.unit)
    if (branch.moo) formData.append("moo", branch.moo)
    if (branch.alley) formData.append("alley", branch.alley)
    if (branch.soi) formData.append("soi", branch.soi)
    if (branch.road) formData.append("road", branch.road)
    if (branch.sub_district) formData.append("sub_district", branch.sub_district)
    if (branch.district) formData.append("district", branch.district)
    if (branch.province) formData.append("province", branch.province)
    if (branch.place_name) formData.append("place_name", branch.place_name)
    if (branch.place_id) formData.append("place_id", branch.place_id)
    if (branch.search) formData.append("search", branch.search)
    if (branch.postal_code) formData.append("postal_code", branch.postal_code)
    if (branch.country) formData.append("country", branch.country)
    if (branch.lead) formData.append("lead", branch.lead)
    if (branch.modern_trade) formData.append("modern_trade", branch.modern_trade)
    if (branch.link) formData.append("link", branch.link)
    if (branch.tel) formData.append("tel", branch.tel)
    if (branch.fax) formData.append("fax", branch.fax)
    if (branch.lat) formData.append("lat", branch.lat)
    if (branch.lng) formData.append("lng", branch.lng)
    if (branch.i_frame) formData.append("i_frame", branch.i_frame)
    if (branch.lang) formData.append("lang", branch.lang)
    if (file) formData.append("file", file, file.name)

    const url = `${API}${EP_BRANCH.CREATE}`

    const { code, data, message } = await axiosCreateFile(url, formData, token)
    if (code == 201) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return message
  } catch (error) {
    // const { message } = error.response.data
    console.log(error)
    return errorHandle(error)
  }
}

export async function putBranch({ branch, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    let formData = new FormData()
    if (branch.branch) formData.append("branch", branch.branch)
    if (branch.address) formData.append("address", branch.address)
    if (branch.no_address) formData.append("no_address", branch.no_address)
    if (branch.building) formData.append("building", branch.building)
    if (branch.floor) formData.append("floor", branch.floor)
    if (branch.unit) formData.append("unit", branch.unit)
    if (branch.moo) formData.append("moo", branch.moo)
    if (branch.alley) formData.append("alley", branch.alley)
    if (branch.soi) formData.append("soi", branch.soi)
    if (branch.road) formData.append("road", branch.road)
    if (branch.sub_district) formData.append("sub_district", branch.sub_district)
    if (branch.district) formData.append("district", branch.district)
    if (branch.province) formData.append("province", branch.province)
    if (branch.place_name) formData.append("place_name", branch.place_name)
    if (branch.place_id) formData.append("place_id", branch.place_id)
    if (branch.search) formData.append("search", branch.search)
    if (branch.postal_code) formData.append("postal_code", branch.postal_code)
    if (branch.country) formData.append("country", branch.country)
    if (branch.lead) formData.append("lead", branch.lead)
    if (branch.modern_trade) formData.append("modern_trade", branch.modern_trade)
    if (branch.link) formData.append("link", branch.link)
    if (branch.tel) formData.append("tel", branch.tel)
    if (branch.fax) formData.append("fax", branch.fax)
    if (branch.lat) formData.append("lat", branch.lat)
    if (branch.lng) formData.append("lng", branch.lng)
    if (branch.i_frame) formData.append("i_frame", branch.i_frame)
    if (branch.lang) formData.append("lang", branch.lang)
    if (branch.status) formData.append("status", branch.status)
    if (file) formData.append("file", file, file.name)

    const url = `${API}${EP_BRANCH.UPDATE}/${branch.branch_id}`
    const { code, data, message } = await axiosPut(url, formData, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return message
  } catch (error) {
    // const { message } = error.response.data
    // console.log(error)
    return errorHandle(error)
  }
}

export async function putStatus({ branch, status }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    const payload = { status: status }
    const url = `${API}${EP_BRANCH.UPDATE_STATUS}/${branch.branch_id}`

    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function deleteBranch({ branch }, resultHandle, errorHandle, deleteResultHandle) {
  try {
    const token = await getAuthToken()

    const url = `${API}${EP_BRANCH.DELETE}/${branch.branch_id}`

    const { code, data, message } = await axiosDelete(url, token)

    if (code == 200) {
      deleteResultHandle()
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}
