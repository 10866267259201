<template>
  <v-container>
    <base-material-card icon="mdi-marker" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("content") }}</div>
      </template>

      <v-tabs vertical>
        <v-tab v-for="(content, i) in contents" :key="`contents-${i}`">
          {{ content.lang }}
        </v-tab>

        <v-tab-item v-for="(content, i) in contents" :key="`contents-${i}`">
          <form @submit.prevent="setLoading(true), saveHandle(i)">
            <v-container fluid>
              <v-row class="text-no-wrap" align="center">
                <v-col class="text-center" cols="6" md="6">
                  <span>{{ $t("file") }}</span>
                  <base-upload-image v-model="file[i]" :defaultValue="content.file" />
                </v-col>
                <v-col class="text-center" cols="6" md="6">
                  <span>{{ $t("file_sub") }}</span>
                  <base-upload-image v-model="file_sub[i]" :defaultValue="content.file_sub" />
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field label="title" v-model="content.title" outlined />
                </v-col>

                <v-col cols="auto" md="12">
                  <v-text-field label="title sub" v-model="content.title_sub" outlined />
                </v-col>

                <v-col cols="12" md="12">
                  <v-textarea v-model="content.content" label="content" outlined />
                </v-col>

                <v-col cols="12" md="12">
                  <v-text-field label="link" v-model="content.link" outlined />
                </v-col>

                <v-col cols="12" md="12">
                  <v-select
                    v-model="content.status"
                    :items="status"
                    item-text="text"
                    item-value="value"
                    label="สถานะ"
                    outlined
                  ></v-select>
                </v-col>

                <v-col cols="12" md="12">
                  <v-select
                    v-model="content.lang"
                    :items="languages"
                    item-text="language"
                    item-value="lang_code"
                    label="ภาษา"
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
                  {{ $t("save") }}
                </v-btn>
              </v-card-actions>
            </v-container>
          </form>
        </v-tab-item>
      </v-tabs>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANTS
 */
import { STATUS } from "@/constants/status"
import { LANGUAGES } from "@/constants/language"

/**
 * CONTROLLER
 */
import { getContentAll, putContent } from "@/controllers/content/content.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "Content",
  data: () => ({
    contents: [],
    file: [],
    file_sub: [],
    status: STATUS,
    languages: LANGUAGES
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.contents = await getContentAll()
        this.setLoading(false)
      } catch (e) {
        this.errorHandle(e)
      }
    },
    async saveHandle(i) {
      try {
        await putContent(
          { content: this.contents[i], file: this.file[i], file_sub: this.file_sub[i] },
          this.resultHandle,
          this.errorHandle
        )
      } catch (e) {
        console.log(e)
      }
    },

    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
