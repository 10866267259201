/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
"use strict"

/**
 * Module dependencies.
 */

/**
 * CONSTANTS
 */
import { API } from "@/constants/config"
import { EP_STRATEGY } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut } from "@/utils/axios"

export async function getStrategyAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_STRATEGY.FINDALL
    const { code, data } = await axiosGet(url, token)
    if (code == 200) return data
  } catch (error) {
    const { code, message } = error.response.data
    return errorHandle(message)
  }
}

export async function putStrategy({ strategy }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    const payload = {
      strategy: strategy.strategy,
      status: strategy.status,
      lang: strategy.lang
    }

    const url = `${API}${EP_STRATEGY.UPDATE}/${strategy.strategy_id}`
    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    return errorHandle(message)
  }
}
