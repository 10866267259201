<template>
  <v-container>
    <base-material-card icon="mdi-wall" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("color_create") }}</div>
      </template>

      <v-form @submit.prevent="setLoading(true), save()" ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="text-no-wrap" align="center">
            <!-- <v-col cols="12">
              <base-upload-image v-model="file" :defaultValue="color.file" />
            </v-col> -->
            <v-col cols="12" md="12">
              <v-text-field
                v-model="color.title"
                :label="$t('title')"
                :rules="[rules.required]"
                outlined
              ></v-text-field>
            </v-col>
            <v-flex xs12></v-flex>
            <v-col cols="12" sm="6">
              <v-select
                v-model="color.group_color_id"
                :items="groupColors"
                item-text="title"
                item-value="group_color_id"
                :label="$t('group_color')"
                :rules="[rules.required]"
                outlined
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="color.shade_color_id"
                :items="shadeColors"
                item-text="title"
                item-value="shade_color_id"
                :label="$t('shade_color')"
                :rules="[rules.required]"
                outlined
              >
              </v-select>
            </v-col>
            <v-flex xs12></v-flex>
            <v-col cols="12" sm="6">
              <v-select
                v-model="color.status"
                :items="status"
                item-text="text"
                item-value="value"
                :label="$t('status')"
                :rules="[rules.required]"
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="color.lang"
                :items="languages"
                item-text="language"
                item-value="lang_code"
                :label="$t('language')"
                :rules="[rules.required]"
                outlined
              >
              </v-select>
            </v-col>
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" min-width="100" class="pl-0" :loading="$store.state.isloading" @click="cancel">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"

/**
 * FUNCTIONS
 */
import { getGroupColorByLang } from "@/controllers/product/group_color.controller"
import { getShadeColorByLang } from "@/controllers/product/shade_color.controller"
import { getColorByID, putColor } from "@/controllers/product/color.controller"

/**
 * LIBs
 */
import { mapMutations } from "vuex"

/**
 * UTILS
 */
import { rules } from "@/utils/validates"

export default {
  data: () => ({
    rules: rules,
    color: {},
    file: null,
    languages: LANGUAGES,
    status: STATUS,
    groupColors: [],
    shadeColors: []
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async fetchGroupColor(lang = "TH") {
      try {
        this.groupColors = await getGroupColorByLang({ lang })
      } catch (e) {
        this.errorHandle(e)
      }
    },
    async fetchShadeColor(lang = "TH") {
      try {
        this.shadeColors = await getShadeColorByLang({ lang })
      } catch (e) {
        this.errorHandle(e)
      }
    },
    async initialize() {
      try {
        this.color = await getColorByID({
          id: this.$route.params.id
        })

        await this.fetchGroupColor(this.color.lang)
        await this.fetchShadeColor(this.color.lang)
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async save() {
      const isValid = this.$refs.form.validate()
      if (isValid) {
        await putColor({ color: this.color, file: this.file }, this.resultHandle, this.errorHandle)
        this.setLoading(true)
        this.$router.back()
      }
      this.setLoading(false)
    },
    cancel() {
      this.setLoading(true)
      this.$router.back()
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>
