<template>
  <base-material-card icon="mdi-domain" color="primary">
    <template v-slot:after-heading>
      <div class="font-weight-light card-title mt-2">
        {{ $t("core_value") }}
      </div>
    </template>

    <v-tabs vertical>
      <v-tab v-for="(core_value, i) in core_values" :key="`core_value-${i}`">
        {{ core_value.lang }}
      </v-tab>

      <v-tab-item touchless v-for="(core_value, i) in core_values" :key="`core_value-${i}`">
        <form @submit.prevent="save(i)">
          <v-container fluid>
            <v-row class="text-no-wrap" align="center">
              <v-col>
                <base-ck-editor
                  v-model="core_value.core_value"
                  :text.sync="core_value.core_value"
                  placeholder="วิสัยทัศน์"
                />
              </v-col>
            </v-row>

            <v-row class="text-no-wrap" align="center">
              <v-col>
                <v-select
                  v-model="core_value.status"
                  :items="status"
                  item-text="text"
                  item-value="value"
                  label="สถานะ"
                  outlined
                ></v-select>
              </v-col>
            </v-row>
            <!-- <v-row class="text-no-wrap" align="center">
               <v-col>
                <v-select
                  v-model="core_value.lang"
                  :items="languages"
                  item-text="language"
                  item-value="lang_code"
                  label="ภาษา"
                  outlined
                ></v-select>
              </v-col>
            </v-row> -->
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-container>
        </form>
      </v-tab-item>
    </v-tabs>
  </base-material-card>
</template>

<script>
/**
 * CONSTANTS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"

export default {
  name: "CoreValue",
  props: {
    value: {
      type: Array,
      default: null
    }
  },
  computed: {
    core_values: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    }
  },
  data: () => ({
    languages: LANGUAGES,
    status: STATUS
  }),
  methods: {
    async save(i) {
      try {
        this.$emit("submit", i)
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style></style>
