<template>
  <v-container>
    <base-material-card icon="mdi-home" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("home_market_place") }}</div>
      </template>
      <v-tabs vertical>
        <v-tab
          v-for="(language, i) in languages"
          :key="`home_market_place-${i}`"
          @change="getMarketplace(language.lang_code)"
        >
          {{ language.lang_code }}
        </v-tab>

        <v-tab-item touchless v-for="(language, i) in languages" :key="`home_market_place-${i}`">
          <v-form @submit.prevent="setLoading(true), save()" ref="form" lazy-validation>
            <v-container fluid>
              <v-row class="text-no-wrap" align="center">
                <v-col cols="12" md="12">
                  <base-ck-editor
                    v-model="marketplace.content"
                    textable
                    :counter="counter"
                    @onCounter="counter = $event"
                  />
                </v-col>

                <v-col v-if="counter > 200" class="py-0 px-5 text-left red--text">
                  ระบุไม่เกิน 200
                </v-col>

                <v-spacer></v-spacer>

                <v-col class="py-0 px-5 text-right">
                  <span> {{ counter }}/200 </span>
                </v-col>

                <v-col cols="12" md="12">
                  <v-spacer></v-spacer>
                  <span> </span>
                </v-col>

                <v-col cols="12">
                  <v-select
                    v-model="marketplace.status"
                    :items="status"
                    item-text="text"
                    item-value="value"
                    :label="$t('status')"
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" min-width="100" class="pl-0" :loading="$store.state.isloading" @click="cancel">
                  {{ $t("cancel") }}
                </v-btn>
                <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
                  {{ $t("save") }}
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-form>
        </v-tab-item>
      </v-tabs>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"
import { PATHFILE } from "@/constants/config"

/**
 * FUNCTIONS
 */
import { putMarketplace, getMarketplaceByLang } from "@/controllers/home/home_marketplace.controller"

/**
 * LIBs
 */
import { mapMutations } from "vuex"

/**
 * UTILS
 */
import { rules } from "@/utils/validates"

export default {
  data: () => ({
    rules: rules,
    marketplace: {},
    pathFile: PATHFILE,
    languages: LANGUAGES,
    status: STATUS,
    file: null,
    counter: null
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.getMarketplace()
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async getMarketplace(lang = "TH") {
      this.marketplace = await getMarketplaceByLang({ lang })
    },
    async save() {
      if (this.counter <= 200) {
        await putMarketplace(
          {
            marketplace: this.marketplace
          },
          this.resultHandle,
          this.errorHandle
        )
      } else {
        this.setLoading(false)
      }
    },
    cancel() {
      this.setLoading(true)
      this.$router.back()
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style scoped>
.carousel-icon-close {
  margin: auto !important;
  left: 0 !important;
  right: 0 !important;
  /* bottom: 0 !important; */
  top: 30% !important;
}
</style>
