<template>
  <v-container>
    <policy v-model="policies" @submit="setLoading(true), savePolicyHandle($event)" />

    <standard
      v-model="standards"
      @submit="setLoading(true), saveStandardHandle($event)"
      @submitSort="saveStandardSort($event)"
      @onStatusChange="onChangeEventHandlerStandard"
      @onDelete="deleteItemConfirmStandardCategory"
    />

    <activity
      v-model="activity"
      @submitSort="saveActivitySort"
      @onTabChange="onTabChange"
      @onStatusChange="onChangeEventHandlerActivity"
      @onDelete="deleteItemConfirmActivity"
    />
  </v-container>
</template>

<script>
/**
 * COMPONENT
 */
import Policy from "./Policy"
import Standard from "./Standard"
import Activity from "./Activity"

/**
 * CONTROLLER
 */
import { getPolicyAll, putPolicy } from "@/controllers/about_us/policy.controller"
import {
  putStandardCategory,
  putStandardCategorySort,
  putStandardStatus,
  deleteStandardCategory
} from "@/controllers/about_us/standard_category.controller"
import { getStandardAll, putStandard } from "@/controllers/about_us/standard.controller"
import {
  putActivitySort,
  getActivityByLang,
  putActivityStatus,
  deleteActivity
} from "@/controllers/about_us/activity.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  components: {
    Policy,
    Standard,
    Activity
  },
  data: () => ({
    policies: [],
    standards: [],
    activity: []
  }),
  created() {
    this.setLoading(true)
    this.initialize("TH")
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize(lang) {
      try {
        this.policies = await getPolicyAll()
        this.standards = await getStandardAll()
        this.activity = await getActivityByLang({ lang }, this.errorHandle)
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async saveStandardCategory(standard_category) {
      try {
        await putStandardCategory(
          { standard_category: standard_category, file: this.image },
          this.resultHandle,
          this.errorHandle
        )
      } catch (e) {
        console.log(e)
      }
    },
    async savePolicyHandle(i) {
      try {
        await putPolicy({ policy: this.policies[i] }, this.resultHandle, this.errorHandle)
      } catch (e) {
        console.log(e)
      }
    },
    async saveStandardHandle(i) {
      try {
        await putStandard(
          {
            standard: {
              standard_id: this.standards[i].standard_id,
              status: this.standards[i].status,
              standard: this.standards[i].standard,
              lang: this.standards[i].lang
            }
          },
          this.resultHandle,
          this.errorHandle
        )
      } catch (e) {
        console.log(e)
      }
    },
    async saveStandardSort(e) {
      putStandardCategorySort(
        { standard_category: this.standards[e].standard_category },
        this.resultHandle,
        this.errorHandle
      )
      this.setLoading(false)
    },
    async saveActivitySort() {
      putActivitySort({ activity: this.activity }, this.resultHandle, this.errorHandle)
      this.setLoading(false)
    },
    async onTabChange(e) {
      this.activity = await getActivityByLang({ lang: e }, this.errorHandle)
    },
    async onChangeEventHandlerStandard(e) {
      const {
        value: { value },
        item
      } = e

      if (value) await putStandardStatus({ standard: item, status: "ACTIVE" }, this.resultHandle, this.errorHandle)
      else await putStandardStatus({ standard: item, status: "INACTIVE" }, this.resultHandle, this.errorHandle)
    },
    async onChangeEventHandlerActivity(e) {
      const {
        value: { value },
        item
      } = e

      if (value) await putActivityStatus({ activity: item, status: "ACTIVE" }, this.resultHandle, this.errorHandle)
      else await putActivityStatus({ activity: item, status: "INACTIVE" }, this.resultHandle, this.errorHandle)
    },
    async deleteItemConfirmStandardCategory({ delete_item, delete_index }) {
      await deleteStandardCategory(
        { standard_category: delete_item, delete_index },
        this.resultHandle,
        this.errorHandle,
        this.deleteResultHandleStandardCategory
      )
      this.dialogDelete = false
    },
    deleteResultHandleStandardCategory({ standard_category, delete_index }) {
      this.standards[delete_index].standard_category = this.standards[delete_index].standard_category.filter(
        (standard) => standard.standard_category_id !== standard_category.standard_category_id
      )
    },
    async deleteItemConfirmActivity({ delete_item, delete_index }) {
      await deleteActivity(
        { activity: delete_item, delete_index },
        this.resultHandle,
        this.errorHandle,
        this.deleteResultHandleActivity
      )
      this.dialogDelete = false
    },
    deleteResultHandleActivity({ activity, delete_index }) {
      this.standards[delete_index].standard_category = this.standards[delete_index].standard_category.filter(
        (standard) => standard.standard_category_id !== activity.activity_id
      )
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
