<template>
  <v-container>
    <download-header
      v-model="download_headers"
      @submitSort="setLoading(true), saveHeaderSort()"
      @onTabChange="setLoading(true), onTabChange($event)"
      @onChangeStatus="setLoading(true), onChangeEventHandler($event)"
      @onDelete="setLoading(true), deleteItemConfirm($event)"
    />

    <!-- <download-list v-model="downloads" @submitSort="setLoading(true), saveSort()" /> -->
  </v-container>
</template>

<script>
/**
 * CONTROLLER
 */
import DownloadHeader from "@/views/pages/download/DownloadHeader.vue"
import DownloadList from "@/views/pages/download/DownloadList.vue"

/**
 * CONTROLLER
 */
import {
  putDownloadHeaderSort,
  getDownloadHeaderByLang,
  putStatus,
  deleteDownloadHeader
} from "@/controllers/download/download_header.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "Download",
  components: {
    DownloadHeader,
    DownloadList
  },
  data: () => ({
    download_headers: []
  }),
  created() {
    this.setLoading(true)
    this.initialize("TH")
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize(lang) {
      try {
        this.download_headers = await getDownloadHeaderByLang({ lang: lang }, this.errorHandle)
        this.setLoading(false)
      } catch (e) {
        this.errorHandle(e)
      }
    },
    saveDownload() {},
    saveDownloadHeader() {},
    async onChangeEventHandler(e) {
      const { item, value } = e
      if (value) await putStatus({ download_header: item, status: "ACTIVE" }, this.resultHandle, this.errorHandle)
      else await putStatus({ download_header: item, status: "INACTIVE" }, this.resultHandle, this.errorHandle)
    },
    async saveHeaderSort() {
      await putDownloadHeaderSort({ download_headers: this.download_headers }, this.resultHandle, this.errorHandle)
      this.setLoading(false)
    },
    async deleteItemConfirm({ delete_item, deleteResultHandle }) {
      await deleteDownloadHeader(
        { download_header: delete_item },
        this.resultHandle,
        this.errorHandle,
        deleteResultHandle
      )
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    },
    onTabChange(e) {
      this.initialize(e)
    }
  }
}
</script>

<style></style>
