<template>
  <v-container>
    <base-material-card icon="mdi-handshake" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("dealer") }}</div>
      </template>

      <v-dialog v-model="dialogDelete" max-width="300">
        <v-card>
          <v-card-title>
            <p>
              ยืนยันการลบ
            </p>
            <v-spacer />
            <v-icon aria-label="Close" @click="closeDelete">
              mdi-close
            </v-icon>
          </v-card-title>

          <v-card-text class="pb-6 text-center">
            <v-btn class="mr-1" color="error" dark @click="closeDelete"> {{ $t("cancel") }} </v-btn>
            <v-btn class="mr-1" color="success" dark @click="deleteItemConfirm"> {{ $t("delete") }} </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-tabs vertical>
        <v-tab v-for="(language, i) in languages" :key="`dealer-${i}`" @change="onTabChange(language.lang_code)">
          {{ language.lang_code }}
        </v-tab>

        <v-tab-item touchless v-for="(language, i) in languages" :key="`dealer-${i}`">
          <v-container fluid>
            <v-row class="text-wrap" align="center">
              <v-col>
                <v-data-table :headers="headers" :items="dealers" class="elevation-1" :search="search">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-text-field
                        class="mt-2"
                        v-model="search"
                        :label="$t('search')"
                        single-line
                        append-icon="mdi-magnify"
                      ></v-text-field>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" dark class="mb-2" @click="createDealer(language.lang_code)">
                        {{ $t("dealer_create") }}
                      </v-btn>
                    </v-toolbar>
                  </template>

                  <template v-slot:[`item.shop_name`]="{ item }">
                    <div class="text-overflow">
                      {{ item.shop_name }}
                    </div>
                  </template>

                  <template v-slot:[`item.address`]="{ item }">
                    <div class="text-overflow">
                      {{ item.address }}
                    </div>
                  </template>

                  <template v-slot:[`item.lead`]="{ item }">
                    <p class="mb-0">{{ item.lead == 1 ? "AGENT" : item.lead == 0 ? "MODERNTRADE" : null }}</p>
                  </template>

                  <template v-slot:[`item.status`]="{ item }">
                    <base-switch
                      v-model="item.status"
                      :height="26"
                      :width="99"
                      :font-size="5"
                      :color="{ checked: '#4CAF50', unchecked: '#FF5252' }"
                      :labels="{ checked: 'ACTIVE', unchecked: 'INACTIVE' }"
                      @change="setLoading(true), onChangeEventHandler(item, $event)"
                    />
                  </template>

                  <template v-slot:[`item.link`]="{ item }">
                    <a v-if="item.link" target="_blank" :href="item.link">{{ item.link }}</a>
                  </template>

                  <template v-slot:[`item.file`]="{ item }">
                    <v-list-item-avatar tile class="align-self-center" color="hsl(255, 3%, 18%, 0)">
                      <v-img
                        aspect-ratio="1"
                        contain
                        v-if="Object.keys(item.file).length"
                        :src="`${path_file}${item.file.file_path}`"
                      />
                    </v-list-item-avatar>
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn class="px-2 ma-1" min-width="0" color="success" @click="editItem(item)" small>
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn class="px-2 ma-1" min-width="0" color="error" @click="deleteItem(item)" small>
                      <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANTS
 */
import { STATUS } from "@/constants/status"
import { LANGUAGES } from "@/constants/language"
import { PATHFILE } from "@/constants/config"

/**
 * CONTROLLER
 */
import { getDealerByLang, putStatus, deleteDealer } from "@/controllers/dealer/dealer.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "Dealer",
  data: () => ({
    dealers: [],
    status: STATUS,
    languages: LANGUAGES,
    path_file: PATHFILE,
    dialogDelete: false,
    delete_item: null,
    search: null,
    headers: [
      {
        text: "ID",
        align: "start",
        
        value: "dealer_id"
      },
      { text: "shop_name", value: "shop_name" },
      { text: "tel", value: "tel" },
      { text: "address", value: "address" },
      { text: "Type", value: "lead" },
      { text: "file", value: "file" },
      { text: "status", value: "status" },
      { text: "Actions", value: "actions", align: "center", width: "15%",  }
    ]
  }),
  created() {
    this.setLoading(true)
    this.initialize("TH")
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize(lang) {
      try {
        this.dealers = await getDealerByLang({ lang })
        this.setLoading(false)
      } catch (e) {
        this.errorHandle(e)
      }
    },

    editItem(item) {
      this.$router.push({ path: `dealer-detail/${item.dealer_id}` })
    },
    createDealer(lang) {
      this.$router.push({ path: `dealer-create?lang=${lang}` })
    },
    onTabChange(e) {
      this.initialize(e)
    },
    async onChangeEventHandler(item, { value }) {
      if (value) await putStatus({ dealer: item, status: "ACTIVE" }, this.resultHandle, this.errorHandle)
      else await putStatus({ dealer: item, status: "INACTIVE" }, this.resultHandle, this.errorHandle)
    },
    deleteItem(item) {
      this.delete_item = item
      this.dialogDelete = true
    },
    closeDelete() {
      this.delete_item = null
      this.dialogDelete = false
    },
    async deleteItemConfirm() {
      await deleteDealer({ dealer: this.delete_item }, this.resultHandle, this.errorHandle, this.deleteResultHandle)
      this.dialogDelete = false
    },
    deleteResultHandle() {
      this.dealers = this.dealers.filter((dealer) => dealer.dealer_id !== this.delete_item.dealer_id)
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style lang="scss">
tr:hover {
  .text-overflow {
    -webkit-line-clamp: 10;
  }
}
.text-overflow {
  /* white-space: wrap !important; */
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}
</style>
