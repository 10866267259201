<template>
  <v-app>
    <base-load v-model="isloading" />

    <base-notification v-model="snackbar" :color="color" :message="message" />

    <dashboard-core-app-bar v-model="expandOnHover" />

    <dashboard-core-drawer :expand-on-hover.sync="expandOnHover" />

    <dashboard-core-view />

    <dashboard-core-settings v-model="expandOnHover" />
  </v-app>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "DashboardIndex",
  data: {
    loading: true
  },
  computed: {
    ...mapState(["isloading", "color", "snackbar", "message"])
  },
  components: {
    DashboardCoreAppBar: () => import("@/components/core/AppBar"),
    DashboardCoreDrawer: () => import("@/components/core/Drawer"),
    DashboardCoreSettings: () => import("@/components/core/Settings"),
    DashboardCoreView: () => import("@/components/core/View")
  },

  data: () => ({
    expandOnHover: false
  })
}
</script>

<style>
a {
  text-decoration: none;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f100;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: hsl(0, 0%, 53%);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.v-data-table-header tr {
  white-space: nowrap;
}
.v-data-table-header th {
  text-transform: uppercase;
}

.map-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  width: 100%;
  height: 100%;
}

tr:hover .text-overflow {
  -webkit-line-clamp: 10;
}

.text-overflow {
  /* white-space: wrap !important; */
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 4px;
  margin-bottom: 4px;
}
.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #e8e8e8;
}
.separator:not(:empty)::before {
  margin-right: 0.5em;
}
.separator:not(:empty)::after {
  margin-left: 0.5em;
}

.w-100 {
  width: 100% !important;
}
.w-75 {
  width: 75% !important;
}
.w-50 {
  width: 50% !important;
}
.w-25 {
  width: 25% !important;
}
</style>
