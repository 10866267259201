<template>
  <v-container>
    <base-material-card icon="mdi-domain" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">
          {{ $t("standard_category_create") }}
        </div>
      </template>

      <form @submit.prevent="setLoading(true), save()">
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <base-upload-image
                v-model="file"
                :defaultValue="standard_category.file"
                width="180px"
                height="180px"
                class="mb-2"
              />
              <p class="text-center red--text">*แนะนำขนาดรูปภาพ 180 x 180 px</p>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field v-model="standard_category.title" :label="$t('title')" outlined></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <base-ck-editor
                v-model="standard_category.standard_category"
                :text.sync="standard_category.standard_category"
                :placeholder="$t('standard_category')"
              />
            </v-col>

            <!-- <v-col>
              <v-select
                v-model="standard_category.status"
                :items="status"
                item-text="text"
                item-value="value"
                :label="$t('status')"
                outlined
              ></v-select>
            </v-col> -->

            <!-- <v-col>
              <v-select
                v-model="standard_category.lang"
                :items="languages"
                item-text="language"
                item-value="lang_code"
                :label="$t('language')"
                outlined
              ></v-select>
            </v-col> -->
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            min-width="100"
            class="pl-0"
            :loading="$store.state.isloading"
            @click="setLoading(true), close()"
          >
            {{ $t("cancel") }}
          </v-btn>

          <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </form>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANT
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"

/**
 * CONTROLLER
 */
import { postStandardCategory } from "@/controllers/about_us/standard_category.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "StandardCreate",
  data: () => ({
    languages: LANGUAGES,
    status: STATUS,
    standard_category: {},
    file: null
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.standard_category.lang = this.$route.query.lang
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async save() {
      await postStandardCategory(
        { standard_category: this.standard_category, standard_id: this.$route.params.standard_id, file: this.file },
        this.resultHandle,
        this.errorHandle
      )
      this.$router.back()
    },
    close() {
      this.$router.back()
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
