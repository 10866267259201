/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_HOME_CAROUSEL } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPost, axiosPut, axiosCreateFile, axiosUpdateFile, axiosDelete } from "@/utils/axios"

export async function getCarouselAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_HOME_CAROUSEL.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getCarouselById({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_HOME_CAROUSEL.FINDBYID}/${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getCarouselByLang({ lang }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_HOME_CAROUSEL.FINDBYLANG}/${lang}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function getCarouselForSelect({ lang }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_HOME_CAROUSEL.FOR_SELECT}/${lang}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function postCarousel({ carousel, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_HOME_CAROUSEL.CREATE}`
    let formData = new FormData()
    formData.append("link", carousel.link)
    formData.append("lang", carousel.lang)
    if (file) formData.append("file", file, file.name)

    const { code, data, message } = await axiosCreateFile(url, formData, token)

    if (code == 201) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putCarousel({ carousel, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const url = `${API + EP_HOME_CAROUSEL.UPDATE}/${carousel.home_carousel_id}`
    let formData = new FormData()
    formData.append("link", carousel.link)
    formData.append("lang", carousel.lang)
    formData.append("status", carousel.status)
    if (file) formData.append("file", file, file.name)

    const { code, data, message } = await axiosUpdateFile(url, formData, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    // const { message } = error.response.data
    console.log(error)
    return errorHandle(error)
  }
}

export async function putCarouselSort({ carousels }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = carousels
    const url = `${API}${EP_HOME_CAROUSEL.UPDATE_SORT}`

    const { code, message, data } = await axiosPut(url, payload, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putStatus({ carousel, status }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    const payload = { status: status }
    const url = `${API}${EP_HOME_CAROUSEL.UPDATE_STATUS}/${carousel.home_carousel_id}`

    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function deleteCarousel({ carousel }, resultHandle, errorHandle, deleteResultHandle) {
  try {
    const token = await getAuthToken()

    const url = `${API}${EP_HOME_CAROUSEL.DELETE}/${carousel.home_carousel_id}`

    const { code, data, message } = await axiosDelete(url, token)

    if (code == 200) {
      deleteResultHandle()
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}
