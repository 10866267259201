<template>
  <v-container>
    <support-header
      v-model="support_headers"
      @submit="setLoading(true), saveHandle($event)"
      @submitSort="setLoading(true), saveHeaderSort()"
      @onTabChange="onTabChange"
      @onChangeStatus="setLoading(true), onChangeEventHandler($event)"
      @onDelete="setLoading(true), deleteItemConfirm($event)"
    />

    <!-- <support-list
      v-model="supports"
      @submit="setLoading(true), saveHandle($event)"
      @submitSort="setLoading(true), saveSort()"
    /> -->
  </v-container>
</template>

<script>
/**
 * COMPONENTS
 */
import SupportHeader from "@/views/pages/support/SupportHeader.vue"

/**
 * CONTROLLER
 */
import {
  getSupportHeaderAll,
  getSupportHeaderByLang,
  putSupportHeaderSort,
  putStatus,
  deleteSupportHeader
} from "@/controllers/support/support_header.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "Support",
  components: {
    SupportHeader
  },
  data: () => ({
    support_headers: []
  }),
  created() {
    this.setLoading(true)
    this.initialize("TH")
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize(lang) {
      try {
        // this.supports = await getSupportAll()
        this.support_headers = await getSupportHeaderByLang({ lang })

        this.setLoading(false)
      } catch (e) {
        this.errorHandle(e)
      }
    },
    onTabChange(e) {
      this.initialize(e)
    },
    async saveHeaderSort() {
      await putSupportHeaderSort({ support_headers: this.support_headers }, this.resultHandle, this.errorHandle)
      this.setLoading(false)
    },
    async onChangeEventHandler(e) {
      const { item, value } = e
      if (value) await putStatus({ support_header: item, status: "ACTIVE" }, this.resultHandle, this.errorHandle)
      else await putStatus({ support_header: item, status: "INACTIVE" }, this.resultHandle, this.errorHandle)
    },
    async deleteItemConfirm({ delete_item, deleteResultHandle }) {
      await deleteSupportHeader(
        { support_header: delete_item },
        this.resultHandle,
        this.errorHandle,
        deleteResultHandle
      )
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
