/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
; ("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_SEO } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPost, axiosPut } from "@/utils/axios"

export async function getSeoAll() {
    try {
        const token = await getAuthToken()
        const url = API + EP_SEO.FINDALL
        const { code, data } = await axiosGet(url, token)

        if (code == 200) {
            return data
        }
    } catch (error) {
        const { code, message } = error.response.data
        return message
    }
}

export async function getSeoByLang({ lang }) {
    try {
        const token = await getAuthToken()
        const url = `${API}${EP_SEO.FINDBYLANG}/${lang}`
        const { code, data, message } = await axiosGet(url, token)

        if (code == 200) return data
        errorHandle(message)
        return message
    } catch (error) {
        const { message } = error.response.data
        console.log(error)
        return errorHandle(message)
    }
}

export async function getSeoByID({ id }) {
    try {
        const token = await getAuthToken()
        const url = `${API}${EP_SEO.FINDBYID}/${id}`
        const { code, data, message } = await axiosGet(url, token)

        if (code == 200) return data
        errorHandle(message)
        return message
    } catch (error) {
        const { message } = error.response.data
        console.log(error)
        return errorHandle(message)
    }
}

// export async function postSeo({ seo }, resultHandle, errorHandle) {
//     try {
//         const token = await getAuthToken()
//         const payload = seo
//         const url = `${API}${EP_SEO.CREATE}`

//         const { code, data, message } = await axiosPost(url, payload, token)

//         if (code == 201) {
//             resultHandle(message)
//             return data
//         }
//         errorHandle(message)
//         return data
//     } catch (error) {
//         console.log(error)
//         return errorHandle(error)
//     }
// }

export async function putSeo({ seo }, resultHandle, errorHandle) {
    try {
        const token = await getAuthToken()
        const payload = seo
        const url = `${API}${EP_SEO.UPDATE}/${seo.seo_id}`

        const { code, data, message } = await axiosPut(url, payload, token)

        if (code == 200) {
            resultHandle(message)
            return data
        }
        errorHandle(message)
        return data
    } catch (error) {
        console.log(error)
        return errorHandle(error)
    }
}

