<template>
  <v-container>
    <base-material-card icon="mdi-file-chart" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("log") }}</div>
      </template>
      <v-container fluid>
        <v-row align="center">
          <v-col>
            <v-data-table
              :headers="headers"
              :items="logs"
              :loading="$store.state.isloading"
              :server-items-length="total"
              :options.sync="options"
              class="elevation-1"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" dark class="mx-2" @click="export_xls">
                    {{ $t("log_export") }}
                  </v-btn>
                </v-toolbar>
              </template>
              <template v-slot:[`item.user_agent`]="{ item }">
                <div class="text-overflow">{{ item.user_agent }}</div>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                {{ item.created_at | formatDateTime }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANTS
 */
import { STATUS } from "@/constants/status"
import { LANGUAGES } from "@/constants/language"
import { PATHFILE } from "@/constants/config"

/**
 * CONTROLLER
 */
import { getLogAll } from "@/controllers/log/log.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"
import XLSX from "xlsx"

export default {
  name: "Log",
  data: () => ({
    logs: [],
    status: STATUS,
    languages: LANGUAGES,
    page: 1,
    itemsPerPage: 10,
    options: {},
    total: null,
    headers: [
      { text: "ID", align: "start", value: "log_id", sortable: false },
      { text: "IP", value: "ip", sortable: false },
      { text: "user_agent", value: "user_agent", sortable: false },
      { text: "created_at", value: "created_at", width: "15%", sortable: false },
      { text: "path", value: "path", sortable: false }
    ],
    path_file: PATHFILE
  }),
  created() {
    this.setLoading(true)
    this.initialize(this.page, this.itemsPerPage)
  },
  filters: {
    formatDateTime(e) {
      const datatime = new Date(e)
      const date = datatime.getDate()
      const month = datatime.getMonth()
      const year = datatime.getFullYear()
      const hour = datatime.getHours()
      const minute = datatime.getMinutes()
      const second = datatime.getSeconds()
      return `${date.toString().padStart(2, "0")}/${(month + 1)
        .toString()
        .padStart(2, "0")}/${year} ${hour}:${minute}:${second}`
    }
  },
  watch: {
    options: {
      handler(e) {
        const { page, itemsPerPage } = e
        this.page = page
        this.itemsPerPage = itemsPerPage
        this.initialize(this.page, this.itemsPerPage)
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize(page, itemsPerPage) {
      try {
        const { logs, count } = await getLogAll({ page, itemsPerPage })
        this.logs = logs
        this.total = count.count
        this.setLoading(false)
      } catch (e) {
        this.errorHandle(e)
      }
    },
    async export_xls() {
      const { logs } = await getLogAll({ page: 1, itemsPerPage: -1 })
      console.log()
      const dataWS = XLSX.utils.json_to_sheet(logs)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, dataWS)
      XLSX.writeFile(wb, `log_${Date.now()}.xlsx`)
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
