/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_ESTIMATION_CALCULATE } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPost } from "@/utils/axios"

export async function getField({ categoryId, shapeId, lang = "TH" }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_ESTIMATION_CALCULATE.FINDFIELD}?lang=${lang}${categoryId &&
      `&product_category_id=${categoryId}`}${shapeId && `&estimation_shape_id=${shapeId}`}`

    const { code, data, message } = await axiosGet(url, token)
    return data
  } catch (e) {
    console.log(e)
  }
}

export async function calculateEstimation({ fields, categoryId, shapeId, colorId }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    // let parseField = {}
    // const keys = Object.keys(fields)
    // for (let i = 0; i < keys.length; i++) {
    //     parseField[`${keys[i]}`] = parseInt(fields[`${keys[i]}`]);
    // }
    const payload = fields

    let category = "",
      shape = "",
      color = ""
    if (categoryId) category = `estimation_product_category_id=${categoryId}`
    if (shapeId) shape = `&estimation_shape_id=${shapeId}`
    if (colorId) color = `&estimation_color_id=${colorId}`

    const url = `${API}${EP_ESTIMATION_CALCULATE.CREATE}?${category}${shape}${color}&lang=th`
    const { code, data, message } = await axiosPost(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function getProduct({ color_id, product_category_id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_ESTIMATION_CALCULATE.FINDPRODUCT}?color_id=${color_id}&product_category_id=${product_category_id.estimation_product_category_id}`

    const { code, data, message } = await axiosGet(url, token)
    return data
  } catch (e) {
    console.log(e)
  }
}
