/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_HOME_PRODUCT_SERVICE } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosUploadFile, axiosPatchFile, axiosPatch, axiosDelete } from "@/utils/axios"

export async function getHomeProductServiceAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_HOME_PRODUCT_SERVICE.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getHomeProductServiceById({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_HOME_PRODUCT_SERVICE.FINDBYID}/${id}`
    const { code, data, message } = await axiosGet(url, token)
    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getHomeProductServiceByLang({ lang, sort = "YES" }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_HOME_PRODUCT_SERVICE.FINDALL}?lang=${lang}${sort ? `&sort=${sort}` : ""}`
    const { code, data, message } = await axiosGet(url, token)
    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function postHomeProductService({ content, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    let formData = new FormData()
    const url = `${API}${EP_HOME_PRODUCT_SERVICE.CREATE}`
    formData.append("title", content.title)
    formData.append("content", content.content)
    formData.append("link", content.link)
    formData.append("lang", content.lang)

    if (file) formData.append("file", file, file.name)

    const { code, data, message } = await axiosUploadFile(url, formData, token)

    if (code == 201) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putHomeProductService({ content, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    let formData = new FormData()
    formData.append("title", content.title)
    formData.append("content", content.content)
    formData.append("link", content.link)
    formData.append("lang", content.lang)
    formData.append("status", content.status)

    if (file) formData.append("file", file, file.name)

    const url = `${API + EP_HOME_PRODUCT_SERVICE.UPDATE}/${content.product_service_id}`

    const { code, data, message } = await axiosPatchFile(url, formData, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    // const { message } = error.response.data
    console.log(error)
    return errorHandle(error)
  }
}

export async function putHomeProductServiceSort({ contents }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = contents.map((item) => {
      return {
        id: item.product_service_id,
        sort: item.sort
      }
    })
    const url = `${API}${EP_HOME_PRODUCT_SERVICE.UPDATE_SORT.replace(":id")}`

    const { code, message, data } = await axiosPatch(url, payload, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putStatus({ content, status }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = { status: status }
    const url = `${API}${EP_HOME_PRODUCT_SERVICE.UPDATE_STATUS.replace(":id", content.product_service_id)}`

    const { code, data, message } = await axiosPatch(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function deleteHomeProductService({ content }, resultHandle, errorHandle, deleteResultHandle) {
  try {
    const token = await getAuthToken()

    const url = `${API}${EP_HOME_PRODUCT_SERVICE.UPDATE_STATUS.replace(":id", content.product_service_id)}`
    const payload = { status: "DELETED" }

    const { code, data, message } = await axiosPatch(url, payload, token)

    if (code == 200) {
      deleteResultHandle()
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}
