/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_SUPPORT } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut, axiosUpdateFile, axiosCreateFile, axiosDelete } from "@/utils/axios"

export async function getSupportAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_SUPPORT.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getSupportBySupportHeaderID({ support_header_id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_SUPPORT.FINDBYSUPPORTHEADER}/${support_header_id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
    return error
  }
}

export async function getSupportByID({ support_id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_SUPPORT.FINDBYID}/${support_id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    console.log(error)
    return error
  }
}

export async function postSupport({ support, cover_support, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    let formData = new FormData()
    formData.append("support_header_id", support.support_header_id)
    formData.append("product_group_id", support.product_group_id)
    formData.append("title", support.title)
    // formData.append("filter", support.filter)
    formData.append("created_date", support.created_date)
    formData.append("lang", support.lang)
    formData.append("link", support.link)
    formData.append("file", file)
    formData.append("cover_support", cover_support)

    const url = `${API}${EP_SUPPORT.CREATE}`
    const { code, data, message } = await axiosCreateFile(url, formData, token)
    if (code == 201) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putSupport({ support, cover_support, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    let formData = new FormData()
    formData.append("support_header_id", support.support_header_id)
    formData.append("product_group_id", support.product_group_id)
    formData.append("title", support.title)
    // formData.append("filter", support.filter)
    formData.append("created_date", support.created_date)
    formData.append("status", support.status)
    formData.append("lang", support.lang)
    formData.append("link", support.link)
    formData.append("file", file)
    formData.append("cover_support", cover_support)

    const url = `${API}${EP_SUPPORT.UPDATE}/${support.support_id}`
    const { code, data, message } = await axiosUpdateFile(url, formData, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putSupportSort({ supports }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = supports
    const url = `${API}${EP_SUPPORT.UPDATE_SORT}`

    const { code, message, data } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putStatus({ support, status }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    const payload = { status: status }
    const url = `${API}${EP_SUPPORT.UPDATE_STATUS}/${support.support_id}`

    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function deleteSupport({ support }, resultHandle, errorHandle, deleteResultHandle) {
  try {
    const token = await getAuthToken()

    const url = `${API}${EP_SUPPORT.DELETE}/${support.support_id}`

    const { code, data, message } = await axiosDelete(url, token)

    if (code == 200) {
      deleteResultHandle()
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}
