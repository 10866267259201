<template>
  <base-material-card icon="mdi-domain" color="primary">
    <template v-slot:after-heading>
      <div class="font-weight-light card-title mt-2">{{ formTitle }}</div>
    </template>

    <form @submit.prevent="setLoading(true), save()">
      <v-container>
        <v-row>
          <v-col cols="12" md="12" class="text-center grey--text">
            <v-hover v-slot="{ hover }">
              <v-list-item-avatar size="100" tile @click="inputFile">
                <v-img
                  :src="
                    url
                      ? url
                      : standard_category.file
                      ? `${path_file}${standard_category.file.file_path}`
                      : profileDefault
                  "
                  style="background-color: HSL(255, 3%, 18%);"
                >
                  <v-fade-transition>
                    <div
                      justify="center"
                      align="center"
                      v-if="hover"
                      class="text-center d-flex darken-2 white--text"
                      style="height: 100%; width:100%; background-color: HSL(0, 0%, 74%,0.5);"
                    >
                      <v-row class="text-no-wrap" align="center">
                        <v-col class="text-center">
                          <v-icon large>mdi-pencil</v-icon>
                        </v-col>
                      </v-row>
                    </div>
                  </v-fade-transition>
                </v-img>
              </v-list-item-avatar>
            </v-hover>
          </v-col>

          <div class="d-none">
            <v-file-input label="Image Profile" ref="fileInput" @change="Preview_image" v-model="image"></v-file-input>
          </div>

          <v-col cols="12" md="12">
            <v-text-field v-model="standard_category.title" label="title" outlined></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <base-ck-editor
              v-model="standard_category.standard_category"
              :text.sync="standard_category.standard_category"
              placeholder="standard_category"
            />
          </v-col>
          <v-col>
            <v-select
              v-model="standard_category.status"
              :items="status"
              item-text="text"
              item-value="value"
              label="สถานะ"
              outlined
            ></v-select>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" min-width="100" class="pl-0" :loading="$store.state.isloading" @click="cancel">
            CANCEL
          </v-btn>
          <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
            SAVE
          </v-btn>
        </v-card-actions>
      </v-container>
    </form>
  </base-material-card>
</template>

<script>
import { getStandardCategoryByID, putStandardCategory } from "@/controllers/about_us/standard_category.controller"

// import { getStandardAll, putStandard } from "@/controllers/about_us/standard.controller"
// import { getStandardCategoryAll, putStandardCategory } from "@/controllers/about_us/standard_category.controller"
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"
import { PATHFILE } from "@/constants/config"
import { mapMutations } from "vuex"
export default {
  name: "StandardCategory",
  data: () => ({
    path_file: PATHFILE,
    anguages: LANGUAGES,
    status: STATUS,
    standard_category: [],
    image: null,
    url: null,
    profileDefault: require("@/assets/profile.png")
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Standard Category" : "Edit Standard Category"
    }
  },
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.standard_category = await getStandardCategoryByID({
          standard_category_id: this.$route.params.standard_category_id
        })
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async save() {
      await putStandardCategory(
        { standard_category: this.standard_category, file: this.image },
        this.resultHandle,
        this.errorHandle
      )

      this.setLoading(true)
      this.$router.back()
    },
    cancel() {
      this.setLoading(true)
      this.$router.back()
    },
    Preview_image() {
      if (this.image) this.url = URL.createObjectURL(this.image)
      else this.image = null
    },
    inputFile() {
      this.$refs.fileInput.$refs.input.click()
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
