/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
; ("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_NEWS } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut, axiosPost, axiosUpdateFile, axiosCreateFile, axiosDelete } from "@/utils/axios"

export async function getNewsAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_NEWS.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getNewsByID({ news_id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_NEWS.FINDBYID}/${news_id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getNewsByLang({ lang }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_NEWS.FINDBYLANG}/${lang}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function postNews({ news, file, cover }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    let formData = new FormData()
    formData.append("title", news.title)
    formData.append("news", news.news)
    formData.append("created_date", news.created_date)
    formData.append("lang", news.lang)
    if (file) formData.append("file", file, file.name)
    if (cover) formData.append("cover_news", cover, cover.name)

    const url = `${API}${EP_NEWS.CREATE}`

    const { code, data, message } = await axiosCreateFile(url, formData, token)

    if (code == 201) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    // const { message } = error.response.data
    console.log(error)
    return errorHandle(error)
  }
}

export async function putNewsSort({ news }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = news
    const url = `${API}${EP_NEWS.UPDATE_SORT}`

    const { code, message, data } = await axiosPut(url, payload, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putNews({ news, file, cover }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    let formData = new FormData()
    formData.append("title", news.title)
    formData.append("news", news.news)
    formData.append("created_date", news.created_date)
    formData.append("lang", news.lang)
    formData.append("status", news.status)
    if (file) formData.append("file", file, file.name)
    if (cover) formData.append("cover_news", cover, cover.name)

    const url = `${API}${EP_NEWS.UPDATE}/${news.news_id}`

    const { code, data, message } = await axiosUpdateFile(url, formData, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    // const { message } = error.response.data
    console.log(error)
    return errorHandle(error)
  }
}

export async function putStatus({ news, status }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    const payload = { status: status }
    const url = `${API}${EP_NEWS.UPDATE_STATUS}/${news.news_id}`

    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function deleteNews({ news }, resultHandle, errorHandle, deleteResultHandle) {
  try {
    const token = await getAuthToken()

    const url = `${API}${EP_NEWS.DELETE}/${news.news_id}`

    const { code, data, message } = await axiosDelete(url, token)

    if (code == 200) {
      deleteResultHandle()
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}
