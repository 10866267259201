/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_COLOR } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut, axiosCreateFile, axiosPost, axiosUpdateFile, axiosDelete } from "@/utils/axios"

export async function getColorAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_COLOR.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getColorByID({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_COLOR.FINDBYID}/${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getColorByLang({ lang }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_COLOR.FINDBYLANG}/${lang}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function getByShadeColor({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_COLOR.FINDBY_SHADE}/${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function getByProductCategory({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_COLOR.FINDBY_PRODUCT_CATEGORY}/${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function postColor({ color, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_COLOR.CREATE}`
    let formData = new FormData()
    formData.append("group_color_id", color.group_color_id)
    formData.append("shade_color_id", color.shade_color_id)
    formData.append("title", color.title)
    formData.append("lang", color.lang)

    if (file) formData.append("file", file, file.name)

    const { code, data, message } = await axiosCreateFile(url, formData, token)

    if (code == 201) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putColor({ color, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_COLOR.CREATE}/${color.color_id}`
    let formData = new FormData()
    formData.append("group_color_id", color.group_color_id)
    formData.append("shade_color_id", color.shade_color_id)
    formData.append("title", color.title)
    formData.append("lang", color.lang)
    formData.append("status", color.status)

    if (file) formData.append("file", file, file.name)

    const { code, data, message } = await axiosUpdateFile(url, formData, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putColorSort({ colors }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = colors
    const url = `${API}${EP_COLOR.UPDATE_SORT}`

    const { code, message, data } = await axiosPut(url, payload, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putStatus({ color, status }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    const payload = { status: status }
    const url = `${API}${EP_COLOR.UPDATE_STATUS}/${color.color_id}`

    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function deleteColor({ color }, resultHandle, errorHandle, deleteResultHandle) {
  try {
    const token = await getAuthToken()

    const url = `${API}${EP_COLOR.DELETE}/${color.color_id}`

    const { code, data, message } = await axiosDelete(url, token)

    if (code == 200) {
      deleteResultHandle()
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}
