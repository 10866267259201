<template>
  <v-container>
    <base-material-card icon="mdi-calculator-variant" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("estimation_product_category_detail") }}</div>
      </template>
      <base-upload-image
        v-model="file"
        :defaultValue="estimationProductCategory.file"
        class="mb-2"
        width="320px"
        height="165px"
      />
      <p class="text-center red--text">*แนะนำขนาดรูปภาพ 320 x 165 px</p>
      <v-form @submit.prevent="setLoading(true), save()" ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="text-no-wrap" align="center">
            <v-col cols="12" md="12">
              <v-text-field
                v-model="estimationProductCategory.title"
                :label="$t('title')"
                :rules="[rules.required]"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="estimationProductCategory.title_en"
                :label="$t('title_en')"
                :rules="[rules.required]"
                outlined
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12" sm="12">
              <v-select
                v-model="estimationProductCategory.status"
                :items="status"
                item-text="text"
                item-value="value"
                :label="$t('status')"
                :rules="[rules.required]"
                outlined
              ></v-select>
            </v-col> -->
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" min-width="100" class="pl-0" :loading="$store.state.isloading" @click="cancel">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"

/**
 * FUNCTIONS
 */
import {
  getEstimationProductCategoryById,
  putEstimationProductCategory
} from "@/controllers/estimation/estimation_product_category.controller"
import { getEstimationShapeByLang } from "@/controllers/estimation/estimation_shape.controller"
import { getEstimationColorByLang } from "@/controllers/estimation/estimation_color.controller"

/**
 * LIBs
 */
import { mapMutations } from "vuex"

/**
 * UTILS
 */
import { rules } from "@/utils/validates"

export default {
  data: () => ({
    rules: rules,
    estimationProductCategory: {
      title: null,
      status: "ACTIVE",
      lang: "TH"
    },
    file: null,
    languages: LANGUAGES,
    status: STATUS,
    shadeColors: [],
    colors: []
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.estimationProductCategory = await getEstimationProductCategoryById({
          id: this.$route.params.id
        })
        this.estimationProductCategory.estimation_product_group_id = this.$route.params.estimationProductGroupId
        this.shadeColors = await getEstimationShapeByLang({ lang: this.estimationProductCategory.lang })
        this.colors = await getEstimationColorByLang({ lang: this.estimationProductCategory.lang })
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async save() {
      const isValid = this.$refs.form.validate()
      if (isValid) {
        await putEstimationProductCategory(
          { estimationProductCategory: this.estimationProductCategory, file: this.file },
          this.resultHandle,
          this.errorHandle
        )
        this.setLoading(true)
        this.$router.back()
      }
      this.setLoading(false)
    },
    cancel() {
      this.setLoading(true)
      this.$router.back()
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>
