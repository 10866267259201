<template>
  <div>
    <v-navigation-drawer
      id="core-navigation-drawer"
      v-model="drawer"
      :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
      :expand-on-hover="expandOnHover"
      :right="$vuetify.rtl"
      :src="barImage ? barImage : null"
      mobile-breakpoint="960"
      app
      mini-variant-width="80"
      width="300"
      v-bind="$attrs"
    >
      <template v-slot:img="props">
        <v-img
          :gradient="`to bottom, ${scrimColor != undefined ? scrimColor : 'rgb(54,54,54), rgb(54,54,54)'}`"
          v-bind="props"
        />
      </template>

      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="text-uppercase font-weight-regular display-2">
            <!-- <span class="logo-mini">
            <v-img contain :src="require('../../assets/logo_dbp.png')" style="height: 20px"></v-img>
          </span> -->
            <span class="logo-normal">
              <v-img contain :src="require('../../assets/logo_dbp.png')" style="weigth: auto; height: 60px"></v-img>
            </span>
            <!-- <span class="logo-mini">{{ $t("ra") }}</span>
          <span class="logo-normal">{{ $t("REVAMP ADMIN") }}</span> -->
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="mb-1" />

      <v-list dense nav>
        <!-- <base-item-group :item="profile" /> -->
        <base-item :item="profile" @click.native="openDialog()" />
      </v-list>

      <v-divider class="mb-2" />

      <v-list expand nav>
        <div />
        <template v-for="(item, i) in computedItems">
          <base-item-group v-if="item.children" :key="`group-${i}`" :item="item">
            <!--  -->
          </base-item-group>

          <base-item v-else :key="`item-${i}`" :item="item" />
        </template>
        <div />
      </v-list>
    </v-navigation-drawer>
    <v-dialog v-model="isActive" :scrollable="false" max-width="1000px">
      <base-material-card class="py-10" icon="mdi-account-search" color="primary">
        <template v-slot:after-heading>
          <div class="font-weight-light card-title mt-2">{{ $t("user_detail") }}</div>
        </template>
        <v-card-title>
          <v-icon @click="isActive = false">mdi-close</v-icon>
        </v-card-title>
        <v-form @submit.prevent="save()" ref="createForm" lazy-validation>
          <v-container>
            <base-upload-image v-model="file" :defaultValue="account.file" class="mb-2" width="150px" height="150px" />
            <p class="text-center red--text">*แนะนำขนาดรูปภาพ 200 x 200 px</p>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field v-model="account.email" :rules="[rules.required]" :label="$t('email')" outlined />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="account.firstname" :rules="[rules.required]" :label="$t('firstname')" outlined />
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field v-model="account.lastname" :rules="[rules.required]" :label="$t('lastname')" outlined />
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  :type="isVisiblePassword ? 'text' : 'password'"
                  v-model="account.password"
                  :rules="[rules.password]"
                  :label="$t('password')"
                  :append-icon="isVisiblePassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append.prevent="isVisiblePassword = !isVisiblePassword"
                  outlined
                />
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  :type="isVisibleConfirm ? 'text' : 'password'"
                  v-model="account.confirm_password"
                  :rules="[rules.password, rules.match(account.password, account.confirm_password)]"
                  :label="$t('confirm_password')"
                  :append-icon="isVisibleConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append.prevent="isVisibleConfirm = !isVisibleConfirm"
                  outlined
                />
              </v-col>
              <v-col class="d-flex col-12">
                <v-spacer></v-spacer>

                <v-btn
                  color="primary"
                  min-width="100"
                  class="pl-0 mr-2"
                  :loading="$store.state.isloading"
                  @click="cancel()"
                >
                  {{ $t("cancel") }}
                </v-btn>

                <v-btn
                  color="primary"
                  type="submit"
                  min-width="100"
                  class="pl-0 mr-0"
                  :loading="$store.state.isloading"
                  @click="save()"
                >
                  {{ $t("save") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </base-material-card>
    </v-dialog>
  </div>
</template>

<script>
// Utilities
import { mapState } from "vuex"
import { DrawerList, DrawerListAgency, DrawerListHR, DrawerListStaff } from "@/constants/default"
import { PATHFILE } from "@/constants/config"
import { rules } from "@/utils/validates"

import { getUserBytoken } from "@/controllers/user/user.controller"

/**
 * CONTROLLER
 */
import { putUser, getUserByID } from "@/controllers/user/user.controller"

/**
 * LIBc
 */
import { mapMutations } from "vuex"

export default {
  name: "DashboardCoreDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    rules,
    user: {},
    account: {},
    file: null,
    pathFile: PATHFILE,
    isActive: false,
    isVisiblePassword: false,
    isVisibleConfirm: false
  }),
  created() {
    this.initialize()
  },

  computed: {
    ...mapState(["drawer", "barColor", "barImage", "scrimColor"]),
    drawer: {
      get() {
        return this.$store.state.drawer
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val)
      }
    },
    computedItems() {
      return this.items.map(this.mapItem)
    },
    items: {
      get() {
        const { position } = this.user
        switch (position) {
          case 1:
            return DrawerList
          case 2:
            return DrawerListAgency
          case 3:
            return DrawerListHR
          case 4:
            return DrawerListStaff
          default:
            return []
        }
      },
      set(val) {
        this.items = val
      }
    },
    profile() {
      return {
        avatar: true,
        img: `${this.pathFile}${this.user.profile_img}`,
        title: `${this.user.firstname} ${this.user.lastname}`
        // group: ""
        // children: [
        //   {
        //     href: "",
        //     title: this.$t("my-profile")
        //   },
        //   {
        //     to: "",
        //     title: this.$t("edit-profile")
        //   },
        //   {
        //     to: "",
        //     title: this.$t("settings")
        //   }
        // ]
      }
    }
  },

  watch: {
    "$vuetify.breakpoint.smAndDown"(val) {
      this.$emit("update:expandOnHover", !val)
    }
  },

  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title)
      }
    },
    async initialize() {
      try {
        this.user = await getUserBytoken()
      } catch (e) {}
    },
    async openDialog() {
      this.account = await getUserByID({ id: this.user.user_id })
      this.isActive = true
    },
    async save() {
      const isValid = this.$refs.createForm.validate()
      if (!isValid) return
      this.setLoading(true)
      await putUser({ user: this.account, file: this.file }, this.resultHandle, this.errorHandle)
      this.initialize()
      this.isActive = false
    },
    cancel() {
      this.isActive = false
    },
    resultHandle(message) {
      this.setNoti({ snackbar: true, color: "success", message: message })
      this.setLoading(false)
      this.isActive = false
    },
    errorHandle(message) {
      this.setNoti({ snackbar: true, color: "error", message: message })
      this.setLoading(false)
    }
  }
}
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important

    .v-list-group--sub-group
      display: block !important

  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>
