/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
"use strict"

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_CAREER_BANNER } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut, axiosUpdateFile } from "@/utils/axios"

export async function getCareerBannerAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_CAREER_BANNER.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return errorHandle(message)
  }
}

export async function putCareerBanner({ career_banner, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    let formData = new FormData()
    formData.append("title", career_banner.title)
    formData.append("title_sub", career_banner.title_sub)
    formData.append("description", career_banner.description)
    formData.append("link", career_banner.link)
    formData.append("status", career_banner.status)
    formData.append("lang", career_banner.lang)
    if (file) formData.append("file", file, file.name)

    const url = `${API}${EP_CAREER_BANNER.UPDATE}/${career_banner.career_banner_id}`

    const { code, data, message } = await axiosUpdateFile(url, formData, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}
