/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_PRODUCT_GROUP_DIMENSION } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPost, axiosPatch, axiosDelete } from "@/utils/axios"

export async function getProductGroupDimensionAll({ id, status }) {
  try {
    const token = await getAuthToken()
    const url =
      API + EP_PRODUCT_GROUP_DIMENSION.FINDALL.replace(":product_group_id", id) + `${status ? `&status=${status}` : ""}`
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getProductGroupDimensionByID({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_PRODUCT_GROUP_DIMENSION.FINDBYID.replace(":product_group_dimension_id", id)}`
    const { code, data, message } = await axiosGet(url, token)
    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function postProductGroupDimension({ dimension }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = dimension
    const url = `${API}${EP_PRODUCT_GROUP_DIMENSION.CREATE}`
    const { code, data, message } = await axiosPost(url, payload, token)

    if (code == 201) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putProductGroupDimension({ dimension }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = dimension
    const url = `${API +
      EP_PRODUCT_GROUP_DIMENSION.UPDATE.replace(":product_group_dimension_id", dimension.product_group_dimension_id)}`
    const { code, data, message } = await axiosPatch(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    // const { message } = error.response.data
    console.log(error)
    return errorHandle(error)
  }
}

export async function putProductGroupDimensionSort({ dimensions, groupId }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = dimensions.map((dimension) => {
      return {
        id: dimension.product_group_dimension_id,
        sort: dimension.sort
      }
    })
    const url = `${API}${EP_PRODUCT_GROUP_DIMENSION.UPDATE_SORT.replace(":product_group_id", groupId)}`

    const { code, message, data } = await axiosPatch(url, payload, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putStatus({ dimension, status }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = { status: status }
    const url = `${API}${EP_PRODUCT_GROUP_DIMENSION.UPDATE_STATUS.replace(
      ":product_group_dimension_id",
      dimension.product_group_dimension_id
    )}`

    const { code, data, message } = await axiosPatch(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function deleteProductGroupDimension({ dimension }, resultHandle, errorHandle, deleteResultHandle) {
  try {
    const token = await getAuthToken()
    const payload = { status: "DELETED" }
    const url = `${API}${EP_PRODUCT_GROUP_DIMENSION.UPDATE_STATUS.replace(
      ":product_group_dimension_id",
      dimension.product_group_dimension_id
    )}`

    const { code, data, message } = await axiosPatch(url, payload, token)

    if (code == 200) {
      deleteResultHandle()
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}
