<template>
  <div id="settings-wrapper">
    <v-card
      id="settings"
      class="py-2 px-4"
      color="rgba(0, 0, 0, .3)"
      dark
      flat
      link
      min-width="100"
      style="position: fixed; top: 200px; right: -35px; border-radius: 8px;"
    >
      <v-icon large>
        mdi-cog-outline
      </v-icon>
    </v-card>

    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      activator="#settings"
      bottom
      content-class="v-settings"
      left
      nudge-left="8"
      offset-x
      origin="top right"
      transition="scale-transition"
    >
      <v-card class="text-center mb-0" width="300">
        <v-card-text>
          <strong class="mb-3 d-inline-block">SIDEBAR FILTERS</strong>

          <v-item-group v-model="color">
            <v-item v-for="color in colors" :key="color" :value="color">
              <template v-slot="{ active, toggle }">
                <v-avatar
                  :class="active && 'v-settings__item--active'"
                  :color="color"
                  class="v-settings__item"
                  size="25"
                  @click="toggle"
                />
              </template>
            </v-item>
          </v-item-group>

          <v-divider class="my-4 secondary" />

          <strong class="mb-3 d-inline-block">SIDEBAR BACKGROUND</strong>

          <v-item-group v-model="scrim">
            <v-item v-for="scrim in scrims" :key="scrim" :value="scrim" class="mx-1">
              <template v-slot="{ active, toggle }">
                <v-avatar
                  :class="active && 'v-settings__item--active'"
                  :color="scrim"
                  class="v-settings__item"
                  size="24"
                  @click="toggle"
                />
              </template>
            </v-item>
          </v-item-group>

          <v-divider class="my-4 secondary" />

          <v-row align="center" no-gutters>
            <v-col cols="auto">
              Dark Mode
            </v-col>

            <v-spacer />

            <v-col cols="auto">
              <v-switch
                v-model="$vuetify.theme.dark"
                @change="onSetDarkMode"
                class="ma-0 pa-0"
                color="secondary"
                hide-details
              />
            </v-col>
          </v-row>

          <!-- <v-divider class="my-4 secondary" />

          <v-row align="center" no-gutters>
            <v-col cols="auto">
              Sidebar Hide
            </v-col>

            <v-spacer />

            <v-col cols="auto">
              <v-switch v-model="drawer" class="ma-0 pa-0" color="secondary" hide-details />
            </v-col>
          </v-row> -->

          <!-- <v-divider class="my-4 secondary" />

          <v-row align="center" no-gutters>
            <v-col cols="auto">
              Sidebar Image
            </v-col>

            <v-spacer />

            <v-col cols="auto">
              <v-switch v-model="showImg" class="ma-0 pa-0" color="secondary" hide-details />
            </v-col>
          </v-row> -->

          <v-divider class="my-4 secondary" />

          <strong class="mb-3 d-inline-block">IMAGES</strong>
          <v-item-group v-model="image" class="d-flex justify-space-between mb-3">
            <v-item v-for="image in images" :key="image" :value="image" class="mx-1">
              <template v-slot="{ active, toggle }">
                <v-sheet
                  :class="active && 'v-settings__item--active'"
                  class="d-inline-block v-settings__item"
                  @click="toggle"
                >
                  <v-img :src="image" height="100" width="50" />
                </v-sheet>
              </template>
            </v-item>
          </v-item-group>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
// Mixins
import Proxyable from "vuetify/lib/mixins/proxyable"
import { mapMutations, mapState } from "vuex"

/**
 * ASSETS
 */
import Sidebar1 from "@/assets/sidebar-1.jpg"
import Sidebar2 from "@/assets/sidebar-2.jpg"
import Sidebar3 from "@/assets/sidebar-3.jpg"
import Sidebar4 from "@/assets/sidebar-4.jpg"

export default {
  name: "DashboardCoreSettings",

  mixins: [Proxyable],

  data: () => ({
    color: null,
    colors: ["#9C27b0", "#00CAE3", "#4CAF50", "#ff9800", "#EC6921", "#E91E63", "#FF5252"],
    image: Sidebar1,
    images: [Sidebar1, Sidebar2, Sidebar3, Sidebar4],
    menu: false,
    saveImage: "",
    scrim: "rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)",
    scrims: [
      "rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)",
      "rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)",
      "rgba(244, 67, 54, .8), rgba(244, 67, 54, .8)"
    ],
    // showImg: null,
    sidebar: null
  }),

  computed: {
    ...mapState(["barImage", "darkMode", "barColor", "scrimColor", "drawer"])
  },

  watch: {
    color(val) {
      if (val != undefined) {
        this.color = val
        this.$vuetify.theme.themes[this.darkMode == true ? "dark" : "light"].primary = val
        this.setBarColor(val)
      }
    },
    image(val) {
      if (val) this.setBarImage(val)
      else this.setBarImage("#")
    },
    scrim(val) {
      if (typeof val != undefined) this.$store.commit("SET_SCRIM", val)
      else this.$store.commit("SET_SCRIM", "rgb(54,54,54), rgb(54,54,54)")
    },
    sidebar(val) {
      this.$store.commit("SET_DRAWER", val)
    }
  },

  methods: {
    ...mapMutations({
      setBarImage: "SET_BAR_IMAGE",
      setDarkMode: "SET_DARK_MODE",
      setBarColor: "SET_BAR_COLOR",
      setDrawer: "SET_DRAWER"
    }),
    onSetDarkMode(val) {
      let primary = null
      if (val) primary = this.$vuetify.theme.themes["dark"].primary
      else primary = this.$vuetify.theme.themes["light"].primary
      this.color = primary
      this.$vuetify.theme.themes[!val ? "dark" : "light"].primary = primary
      this.setBarColor(primary)
      this.setDarkMode(val)
    }
  },
  created: function() {
    /**
     * Set Dark Mode
     */
    if (this.darkMode == "true") {
      this.setDarkMode(true)
    } else if (this.darkMode == "false") {
      this.setDarkMode(false)
    } else if (this.darkMode == true) {
      this.setDarkMode(true)
    } else {
      this.setDarkMode(false)
    }
    const theme = this.darkMode
    if (theme)
      if (theme == true) this.$vuetify.theme.dark = true
      else this.$vuetify.theme.dark = false

    /**
     * MAP State To Props
     */

    /**
     * Bar Color
     */
    this.$vuetify.theme.themes[theme ? "dark" : "light"].primary =
      this.barColor == "undefined" ? "#EC6921" : this.barColor
    this.color = this.barColor == "undefined" ? "#EC6921" : this.barColor

    /**
     * Image Bar
     */
    this.image = this.barImage

    /**
     * Scrim Color
     */
    this.scrim = this.scrimColor
    // this.sidebar = this.drawer

    /**
     * Show Image
     */
    // this.showImg = this.barImage == "#" ? false : true
  }
}
</script>

<style lang="sass">
.v-settings
  .v-item-group > *
    cursor: pointer

  &__item
    border-width: 3px
    border-style: solid
    border-color: transparent !important

    &--active
      border-color: #00cae3 !important
</style>
