<template>
  <v-container>
    <base-material-card icon="mdi-home-map-marker" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("province") }}</div>
      </template>

      <v-dialog v-model="dialogDelete" max-width="300">
        <v-card>
          <v-card-title>
            <p>
              ยืนยันการลบ
            </p>
            <v-spacer />
            <v-icon aria-label="Close" @click="closeDelete">
              mdi-close
            </v-icon>
          </v-card-title>

          <v-card-text class="pb-6 text-center">
            <v-btn class="mr-1" color="error" dark @click="closeDelete"> {{ $t("cancel") }} </v-btn>
            <v-btn class="mr-1" color="success" dark @click="deleteItemConfirm"> {{ $t("delete") }} </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-container fluid>
        <v-row class="text-no-wrap" align="center">
          <v-col>
            <v-data-table :headers="headers" :items="provinces" class="elevation-1" :search="search">
              <template v-slot:top>
                <v-toolbar flat>
                  <!-- <v-toolbar-title>{{ $t("country") }}</v-toolbar-title> -->
                  <v-text-field
                    class="mt-2"
                    v-model="search"
                    :label="$t('search')"
                    single-line
                    append-icon="mdi-magnify"
                    hide-details
                  ></v-text-field>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" dark class="ml-auto mx-2" @click="createItem()">
                    {{ $t("province_create") }}
                  </v-btn>
                </v-toolbar>
              </template>

              <template v-slot:[`item.status`]="{ item }">
                <v-chip :color="getColor(item.status)" dark>
                  {{ item.status }}
                </v-chip>
              </template>
              <template v-slot:[`item.sort`]="{ item }">
                {{ item.sort + 1 }}
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-btn class="px-2 ml-1" min-width="0" color="success" @click="editItem(item)" small>
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
                <v-btn class="px-2 ml-1" min-width="0" color="warning" @click="infoItem(item)" small>
                  <v-icon small>mdi-eye</v-icon>
                </v-btn>
                <!-- <v-btn class="px-2 ml-1" min-width="0" color="error" @click="deleteItem(item)" small>
                  <v-icon small> mdi-delete </v-icon>
                </v-btn> -->
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANTS
 */
import { STATUS } from "@/constants/status"
import { LANGUAGES } from "@/constants/language"
import { PATHFILE } from "@/constants/config"

/**
 * CONTROLLER
 */
import { getProvinceByCountryId as getProvince, deleteProvince } from "@/controllers/address/province.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "News",
  data: () => ({
    provinces: [],
    status: STATUS,
    languages: LANGUAGES,
    headers: [
      {
        text: "Id",
        align: "start",
        
        value: "province_id"
      },
      { text: "title", value: "province" },
      { text: "title_en", value: "province_en" },
      { text: "Actions", value: "actions", align: "center", width: "15%", sortable: false }
    ],
    dialogDelete: false,
    delete_item: null,
    path_file: PATHFILE,
    search: ""
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.provinces = await getProvince({ id: this.$route.params.countryId })
        this.setLoading(false)
      } catch (e) {
        this.errorHandle(e)
      }
    },
    createItem() {
      this.$router.push({ path: `province-create` })
    },
    editItem(item) {
      this.$router.push({ path: `province-detail/${item.province_id}` })
    },
    infoItem(item) {
      this.$router.push({ path: `province/${item.province_id}/district` })
    },
    getColor(status) {
      if (status === "ACTIVE") return `success`
      else if (status === "INACTIVE") return `error`
    },
    onTabChange(e) {
      this.initialize(e)
    },
    deleteItem(item) {
      this.delete_item = item
      this.dialogDelete = true
    },
    closeDelete() {
      this.delete_item = null
      this.dialogDelete = false
    },
    async deleteItemConfirm() {
      await deleteProvince({ province: this.delete_item }, this.resultHandle, this.errorHandle, this.deleteResultHandle)
      this.dialogDelete = false
    },
    deleteResultHandle() {
      this.provinces = this.provinces.filter((province) => province.province_id !== this.delete_item.province_id)
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
