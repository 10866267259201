<template>
  <section class="pdf-content">
    <section class="report-info">
      <!-- <section class="pdf-content">
    <section class="report-info"> -->
      <div class="logo-container">
        <img class="logo-image" :src="require('../assets/logo_dbp.png')" alt="User Image" ref="userImage" />
      </div>

      <p class="text-header text-center">รายชื่อผู้ใช้งาน ระบบจัดการเว็บไซต์ DBP</p>

      <!-- <section class=".report-info">  -->
      <!-- <div v-for="(user, i) in users" :key="`user-${i}`">
        {{ user }}
      </div> -->
      <table class="table center w-90 ">
        <thead>
          <tr>
            <th style="width:15%">E-mail</th>
            <th style="width:13%">ชื่อจริง</th>
            <th style="width:13%">นามสกุล</th>
            <th style="width:12%">บทบาท</th>
            <th style="width:8%">Status</th>
            <th style="width:19%">Create Date</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(user, i) in users" :key="`table-${i}`">
            <td class="text-center">{{ user.email }}</td>
            <td class="text-center">{{ user.firstname }}</td>
            <td class="text-center">{{ user.lastname }}</td>
            <td class="text-center">{{ user.position_text }}</td>
            <td class="text-center">{{ user.status }}</td>
            <td class="text-center">{{ user.created_at | formatDate }}</td>
          </tr>
        </tbody>
      </table>
      <!-- </section>
     </section> -->
    </section>
  </section>
</template>

<script>
/**
 * CONSTANS
 */
import { PATHFILE } from "@/constants/config"

export default {
  props: {
    users: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      path_file: PATHFILE
    }
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.$emit("domRendered")
      }, 100000)
    })
  },
  filters: {
    formatDate(e) {
      const datatime = new Date(e)
      const date = datatime.getDate()
      const month = datatime.getMonth()
      const year = datatime.getFullYear()
      const hour = datatime.getHours()
      const minute = datatime.getMinutes()
      const second = datatime.getSeconds()
      return `${date.toString().padStart(2, "0")}/${(month + 1).toString().padStart(2, "0")}/${year}, ${hour +
        1}:${minute + 1}:${second + 1}`
    }
  }
}
</script>

<style lang="scss" scoped>
body {
  font-family: "Prompt", sans-serif;
  font-size: 1px;
}

.text-center {
  text-align: center;
}

.w-100 {
  width: 100%;
}

.w-90 {
  width: 90%;
}

.w-80 {
  width: 80%;
}

.w-75 {
  width: 75%;
}

.w-50 {
  width: 50%;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

table,
th,
td {
  border-style: ridge;
  border-collapse: collapse;
  border: 0.1px solid black;
}

.table {
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

th {
  background-color: #ec6921;
  padding: 5px;
  border-collapse: collapse;
}

td {
  padding: 0px;
  border-collapse: collapse;
  align-items: center;
}

.logo-container {
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;
  .logo-image {
    display: block;
    width: 150px;

    margin-left: auto;
    margin-right: auto;
  }
}

.text-header {
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}
.pdf-content {
  width: 100%;
  background: #fff;
}
// .pdf-content {
//   width: 100%;
//   background: #fff;

//   .report-info {
//     padding-bottom: 0px;
//     width: 100%;

//     .image-container {
//       width: 75px;
//       height: 75px;
//       border-radius: 50%;
//       overflow: hidden;
//       position: relative;
//       margin-top: 25px;
//       margin-right: 25px;
//       border: 1px solid #dadada;
//       .user-image {
//         height: 100%;
//         min-width: 100%;
//         margin-left: 50%;
//         transform: translateX(-50%);
//       }
//     }
//     .user-details {
//       display: flex;
//       flex-direction: column;
//       flex-shrink: 0;
//       .user-name {
//         margin-bottom: 10px;
//         flex-shrink: 0;
//       }
//       .detail-container {
//         font-size: 13px;
//         flex-shrink: 0;
//         &:not(:last-child) {
//           margin-bottom: 10px;
//         }
//         .detail-label {
//           font-weight: 600;
//           margin-right: 5px;
//           font-size: 13px;
//         }
//       }
//     }
//   }

//   .chart-container {
//     width: 100%;
//     height: 320px;
//     padding: 20px;
//     pointer-events: none;
//     &:not(:last-child) {
//       margin-bottom: 15px;
//     }
//   }
// }
</style>
