/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_CONTENT } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut, axiosUpdateFile } from "@/utils/axios"

export async function getContentAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_CONTENT.FINDALL
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getContentByID({ content_id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_CONTENT.FINDBYID}/${content_id}`
    const { code, data, message } = await axiosGet(url, token)
    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putContent({ content, file, file_sub }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    let formData = new FormData()
    formData.append("title", content.title)
    formData.append("title_sub", content.title_sub)
    formData.append("content", content.content)
    formData.append("link", content.link)
    formData.append("status", content.status)
    formData.append("lang", content.lang)
    formData.append("file", file)
    formData.append("file_sub", file_sub)

    const url = `${API}${EP_CONTENT.UPDATE}/${content.content_id}`
    const { code, data, message } = await axiosUpdateFile(url, formData, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}
