<template>
  <base-material-card icon="mdi-account-hard-hat" color="primary">
    <template v-slot:after-heading>
      <div class="font-weight-light card-title mt-2">{{ $t("career_create") }}</div>
    </template>

    <form @submit.prevent="setLoading(true), save()">
      <v-container>
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field v-model="career.position" :label="$t('position')" outlined></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            <v-text-field v-model="career.location" :label="$t('location')" outlined></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            <base-ck-editor
              v-model="career.description"
              :text.sync="career.description"
              :placeholder="$t('description')"
            />
          </v-col>

          <v-col cols="12" md="12">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="career.created_date"
                  :label="$t('created_date')"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker v-model="career.created_date" @input="menu = false" locale="th" no-title scrollable />
            </v-menu>
          </v-col>

          <v-col
            ><v-select
              v-model="career.lang"
              :items="languages"
              item-text="language"
              item-value="lang_code"
              :label="$t('language')"
              outlined
            ></v-select
          ></v-col>
        </v-row>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" min-width="100" class="pl-0" :loading="$store.state.isloading" @click="cancel">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-container>
    </form>
  </base-material-card>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"

/**
 * CONTROLLER
 */
import { postCareer } from "@/controllers/career/career.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "CareerCreate",
  data: () => ({
    languages: LANGUAGES,
    status: STATUS,
    menu: false,
    career: {}
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async save() {
      await postCareer({ career: this.career }, this.resultHandle, this.errorHandle)
      this.$router.back()
    },
    cancel() {
      this.setLoading(true)
      this.$router.back()
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
