import Vue from "vue"
import Vuetify from "vuetify/lib"
import i18n from "@/plugins/i18n"
import "@/sass/overrides.sass"

Vue.use(Vuetify)

const theme = {
  options: {
    customProperties: true
  },
  primary: "#EC6921",
  secondary: "#424242",
  accent: "#82B1FF",
  error: "#FF5252",
  info: "#2196F3",
  success: "#4CAF50",
  warning: "#FFC107"
}

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  theme: {
    themes: {
      dark: theme,
      light: theme
    }
  }
})
