/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_SITE_REF_PRODUCT_CATEGORY } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut, axiosCreateFile, axiosPost, axiosUpdateFile, axiosDelete } from "@/utils/axios"

export async function getSiteRefProductCategoryAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_SITE_REF_PRODUCT_CATEGORY.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getSiteRefProductCategoryByID({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_SITE_REF_PRODUCT_CATEGORY.FINDBYID}/${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getSiteRefProductCategoryByLang({ lang }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_SITE_REF_PRODUCT_CATEGORY.FINDBYLANG}/${lang}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function getBySiteRefProductGroup({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_SITE_REF_PRODUCT_CATEGORY.FINDBY_PD_GROUP}/${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}
export async function putSiteRefProductCategory({ productCategory, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    const url = `${API}${EP_SITE_REF_PRODUCT_CATEGORY.UPDATE}/${productCategory.product_category_id}`
    let formData = new FormData()
    formData.append("status", productCategory.status)

    if (file) formData.append("file", file, file.name)
    const { code, data, message } = await axiosUpdateFile(url, formData, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putSiteRefProductCategorySort({ productCategories }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = productCategories
    const url = `${API}${EP_SITE_REF_PRODUCT_CATEGORY.UPDATE_SORT}`

    const { code, message, data } = await axiosPut(url, payload, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putStatus({ product_category, status }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    const payload = { status: status }
    const url = `${API}${EP_SITE_REF_PRODUCT_CATEGORY.UPDATE_STATUS}/${product_category.product_category_id}`

    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function deleteSiteRefProductCategory(
  { product_category },
  resultHandle,
  errorHandle,
  deleteResultHandle
) {
  try {
    const token = await getAuthToken()

    const url = `${API}${EP_SITE_REF_PRODUCT_CATEGORY.DELETE}/${product_category.product_category_id}`

    const { code, data, message } = await axiosDelete(url, token)

    if (code == 200) {
      deleteResultHandle()
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}
