<template>
  <v-container>
    <base-material-card icon="mdi-calculator-variant" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("estimation_formula_res") }}</div>
      </template>
      <v-container fluid>
        <v-row class="text-no-wrap" align="center">
          <v-col>
            <v-data-table :headers="headers" :items="formulas" class="elevation-1">
              <template v-slot:top>
                <v-toolbar flat>
                  <v-spacer></v-spacer>
                </v-toolbar>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-btn class="px-2 ml-1" min-width="0" color="success" @click="editItem(item)" small>
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          min-width="100"
          class="pl-0"
          :loading="$store.state.isloading"
          @click="setLoading(true), cancel()"
        >
          {{ $t("back") }}
        </v-btn>
      </v-card-actions>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANTS
 */
import { STATUS } from "@/constants/status"
import { LANGUAGES } from "@/constants/language"
import { PATHFILE } from "@/constants/config"

/**
 * CONTROLLER
 */
import { getFormulaResColorByFormulaResId } from "@/controllers/estimation/estimation_formula_color.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "EstimationFormulaRes",
  data: () => ({
    formulas: [],
    status: STATUS,
    languages: LANGUAGES,
    headers: [
      {
        text: "Id",
        align: "start",
        
        value: "estimation_res_color_id"
      },
      { text: "title", value: "title" },
      { text: "title_en", value: "title_en" },
      // { text: "lang", value: "lang" },
      { text: "Actions", value: "actions", align: "center", width: "15%", sortable: false }
    ],
    path_file: PATHFILE
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.formulas = await getFormulaResColorByFormulaResId({ id: this.$route.params.estimationFormulaResId })
        this.setLoading(false)
      } catch (e) {
        this.errorHandle(e)
      }
    },
    editItem(item) {
      this.$router.push({
        path: `${this.$route.params.estimationFormulaResId}/estimation-formula-res-color-detail/${item.estimation_res_color_id}`
      })
    },
    cancel() {
      this.$router.back()
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
