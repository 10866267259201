/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
"use strict"

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_COMPANY } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut } from "@/utils/axios"

export async function getCompanyAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_COMPANY.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) return data
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function putCompany({ company }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = {
      company_name: company.company_name,
      company_shortname: company.company_shortname,
      company_no: company.company_no,
      type_business: company.type_business,
      registered_capital: company.registered_capital,
      lang: company.lang,
      status: company.status
    }
    const url = `${API}${EP_COMPANY.UPDATE}/${company.company_id}`
    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    return errorHandle(message)
  }
}
