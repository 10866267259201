<template>
  <v-container>
    <base-material-card icon="mdi-download" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("info_download_detail") }}</div>
      </template>
      <div class="text-center">
        <base-upload-image v-model="file" :defaultValue="infoDownload.file" width="400px" height="400px" class="mb-2" />
        <span class="red--text">*แนะนำขนาดรูปภาพ 400 x 400 px</span>
      </div>
      <v-form @submit.prevent="save()" ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="text-no-wrap" align="center">
            <v-col cols="12" md="12">
              <v-select
                v-model="infoDownload.ref_id"
                :items="infoDownloadHeaders"
                item-text="title"
                item-value="ref_id"
                :label="$t('title')"
                :rules="[rules.noEmpty]"
                @change="selectRefId"
                return-object
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12" md="12" v-if="visibleSubHeader">
              <v-select
                v-model="infoDownload.sub_ref_id"
                :items="itemsHeader"
                item-text="title"
                item-value="id"
                :label="headerLabel"
                :rules="[rules.noEmpty]"
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12" md="12">
              <v-select
                v-model="infoDownload.lang"
                :items="languages"
                item-text="language"
                item-value="lang_code"
                :label="$t('language')"
                :rules="[rules.required]"
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12" md="12">
              <v-select
                v-model="infoDownload.status"
                :items="status"
                item-text="text"
                item-value="value"
                :label="$t('status')"
                :rules="[rules.required]"
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" min-width="100" class="pl-0" :loading="$store.state.isloading" @click="cancel">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"
import { PATHFILE } from "@/constants/config"
import { SUPPORT_DOWNLOAD_HEADERS } from "@/constants/infoDownloads"

const fixedHeaders = [
  {
    ref_id: -2,
    title: "คำถามที่พบบ่อย"
  },
  {
    ref_id: -1,
    title: "Dimaond Site Ref."
  },
  {
    ref_id: 0,
    title: "บทความ"
  }
]

/**
 * FUNCTIONS
 */
import { getSupportHeaderByLang } from "@/controllers/support/support_header.controller"
import { getDownloadHeaderByLang } from "@/controllers/download/download_header.controller"
import { getInfoDownloadById, putInfoDownload } from "@/controllers/info_download/info_download.controller"

/**
 * LIBs
 */
import { mapMutations } from "vuex"

/**
 * UTILS
 */
import { rules } from "@/utils/validates"

export default {
  data: () => ({
    rules,
    infoDownload: {
      info_download_id: null,
      menu_type: null,
      ref_id: null,
      sub_ref_id: null,
      link: null,
      lang: null,
      status: null,
      sort: null
    },
    pathFile: PATHFILE,
    languages: LANGUAGES,
    infoDownloadHeaders: SUPPORT_DOWNLOAD_HEADERS,
    itemsHeader: [],
    status: STATUS,
    file: null
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  computed: {
    visibleSubHeader() {
      return this.infoDownload.ref_id && !fixedHeaders.map((item) => item.ref_id).includes(this.infoDownload.ref_id)
    },
    headerLabel() {
      return this.infoDownload.ref_id && this.infoDownload.ref_id === 1
        ? this.$t("support_header")
        : this.infoDownload.ref_id && this.infoDownload.ref_id === 2
        ? this.$t("download_header")
        : ""
    }
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.infoDownload = await getInfoDownloadById({ id: this.$route.params.id })
        this.getItemsHeader()
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async selectRefId({ ref_id, type }) {
      /** Set value from select */
      this.infoDownload.ref_id = ref_id
      this.infoDownload.menu_type = type

      /** Clear sub_ref_id */
      this.infoDownload.sub_ref_id = null

      this.getItemsHeader()
    },

    async getItemsHeader() {
      if (this.infoDownload.ref_id && !fixedHeaders.map((i) => i.ref_id).includes(this.infoDownload.ref_id)) {
        if (this.infoDownload.ref_id === 1) {
          this.itemsHeader = await getSupportHeaderByLang({ lang: this.infoDownload.lang })
          this.itemsHeader = this.itemsHeader
            .filter((item) => item.status === "ACTIVE")
            .map((item) => {
              return {
                id: item.support_header_id,
                title: item.title || "-"
              }
            })
        } else if (this.infoDownload.ref_id === 2) {
          this.itemsHeader = await getDownloadHeaderByLang({ lang: this.infoDownload.lang })
          this.itemsHeader = this.itemsHeader
            .filter((item) => item.status === "ACTIVE")
            .map((item) => {
              return {
                id: item.download_header_id,
                title: item.title || "-"
              }
            })
        }
      }
    },
    async save() {
      const isValid = this.$refs.form.validate()
      if (!isValid) return
      this.setLoading(true)

      const id = fixedHeaders.map((item) => item.ref_id).includes(this.infoDownload.ref_id)
        ? this.infoDownload.ref_id
        : this.infoDownload.sub_ref_id
      const view = this.infoDownloadHeaders.find((item) => item.ref_id === this.infoDownload.ref_id)?.view

      await putInfoDownload(
        {
          infoDownload: {
            info_download_id: this.infoDownload.info_download_id,
            menu_type: this.infoDownload.menu_type,
            ref_id: this.infoDownload.ref_id,
            sub_ref_id: this.infoDownload.sub_ref_id,
            link: `/support-and-download?view=${view}&id=${id}&lang=${this.infoDownload.lang}`,
            lang: this.infoDownload.lang,
            status: this.infoDownload.status,
            sort: this.infoDownload.sort
          },
          file: this.file
        },
        this.resultHandle,
        this.errorHandle
      )
    },
    cancel() {
      this.setLoading(true)
      this.$router.back()
    },

    /** Callback Function */
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
      this.$router.back()
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    },
    onTabChange(e) {
      this.initialize(e)
    }
  }
}
</script>

<style scoped>
.carousel-icon-close {
  margin: auto !important;
  left: 0 !important;
  right: 0 !important;
  /* bottom: 0 !important; */
  top: 0 !important;
}
</style>
