/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_ESTIMATION_FORMULA_RES_COLOR } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut } from "@/utils/axios"

export async function getFormulaResColorByFormulaResId({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_ESTIMATION_FORMULA_RES_COLOR.FINDBY_FORMULA_RES_ID}/${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
    return data
  } catch (error) {
    console.log(error)
  }
}

export async function getFormulaResColorById({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_ESTIMATION_FORMULA_RES_COLOR.FINDBY_FORMULA_BY_ID}/${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
    return data
  } catch (error) {
    console.log(error)
  }
}

export async function putFormulaResColor({ formula }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = formula
    const url = `${API}${EP_ESTIMATION_FORMULA_RES_COLOR.UPDATE}/${formula.estimation_res_color_id}`
    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    return data
  } catch (error) {
    console.log(error)
    errorHandle(message)
  }
}
