<template>
  <v-container>
    <base-material-card icon="mdi-account-hard-hat" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("career_executive_detail") }}</div>
      </template>

      <form @submit.prevent="setLoading(true), save()">
        <v-container>
          <v-row>
            <v-col cols="12" md="12" class="text-center grey--text">
              <base-upload-image v-model="file" :defaultValue="career_executive.file" width="320px" height="200px" />
              <p class="text-center red--text">*แนะนำขนาดรูปภาพ 320 x 200 px</p>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field v-model="career_executive.name" label="ชื่อ" outlined></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <base-ck-editor v-model="career_executive.bio" placeholder="Bio" />
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field v-model="career_executive.posion_th" label="ตำแหน่งภาษาไทย" outlined></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field v-model="career_executive.posion_en" label="ตำแหน่งภาษาอังกฤษ" outlined></v-text-field>
            </v-col>

            <v-col>
              <v-select
                v-model="career_executive.status"
                :items="status"
                item-text="text"
                item-value="value"
                label="สถานะ"
                outlined
              ></v-select>
            </v-col>
            <v-col
              ><v-select
                v-model="career_executive.lang"
                :items="languages"
                item-text="language"
                item-value="lang_code"
                label="ภาษา"
                outlined
              ></v-select
            ></v-col>
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" min-width="100" class="pl-0" :loading="$store.state.isloading" @click="cancel">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </form>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"

/**
 * CONTROLLER
 */
import { getCareerExecutiveByID, putCareerExecutive } from "@/controllers/career/career_executive.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "CareerExecutiveDetail",
  data: () => ({
    languages: LANGUAGES,
    status: STATUS,
    career_executive: {},
    file: null
  }),

  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.career_executive = await getCareerExecutiveByID({
          career_executive_id: this.$route.params.career_executive_id
        })
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async save() {
      await putCareerExecutive(
        { career_executive: this.career_executive, file: this.file },
        this.resultHandle,
        this.errorHandle
      )
      this.setLoading(true)
      this.$router.back()
    },
    cancel() {
      this.setLoading(true)
      this.$router.back()
    },

    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
