import Vue from "vue"
import Router from "vue-router"

/**
 * CONTROLLER
 */
import { getAuthToken } from "../controllers/auth/login.controller"
import { getUserBytoken } from "@/controllers/user/user.controller"

/**
 * CONSTANT
 */
import { ROLE } from "../constants/role"

/**
 * Auth
 */
import Login from "@/views/pages/auth/Login"

/**
 * Suport & Download
 */
import InfoDownload from "@/views/pages/support_downloads/info_download/InfoDownload.vue"
import InfoDownloadCreate from "@/views/pages/support_downloads/info_download/InfoDownloadCreate.vue"
import InfoDownloadDetail from "@/views/pages/support_downloads/info_download/InfoDownloadDetail.vue"
import FAQ from "@/views/pages/support_downloads/supports/FAQManagement"
import DiamondSiteRef from "@/views/pages/support_downloads/supports/DiamondSiteRefManagement"
// import uploadFile from "@/views/pages/support_downloads/supports/uploadFile"
// import Modal from "@/views/pages/support_downloads/supports/Modal"
// import DragDrop from "@/views/pages/support_downloads/supports/DragDrop"

/**
 * About us
 */
import Company from "../views/pages/about_us/Company"
import VisionMissionCoreValue from "../views/pages/about_us/vision_mission_corevalue/Index.vue"
// import Vision from "../views/pages/about_us/Vision"
// import Mission from "../views/pages/about_us/Mission"
// import CoreValue from "../views/pages/about_us/CoreValue.vue"
import Strategy from "../views/pages/about_us/Strategy.vue"
import CSR from "../views/pages/about_us/CSR.vue"

/**
 * ACTIVITY
 */
import ActivityCreate from "../views/pages/about_us/policy_standard/ActivityCreate.vue"
import ActivityDetail from "../views/pages/about_us/policy_standard/ActivityDetail.vue"

/**
 *
 */
import StandardCreate from "../views/pages/about_us/policy_standard/StandardCreate.vue"
import StandardDetail from "../views/pages/about_us/policy_standard/StandardDetail.vue"

/**
 * POLICY
 */
import PolicyStandard from "../views/pages/about_us/policy_standard/Index.vue"
import StandardCategory from "../views/pages/about_us/StandardCategory.vue"

/**
 * Career
 */
import Career from "../views/pages/career/Index.vue"
import CareerDetail from "../views/pages/career/CareerDetail.vue"
import CareerCreate from "../views/pages/career/CareerCreate.vue"
import CareerExecutive from "../views/pages/career/CareerExecutiveDetail.vue"
import CareerExecutiveCreate from "../views/pages/career/CareerExecutiveCreate.vue"

/**
 * Apply Job
 */
import ApplyJob from "../views/pages/career/ApplyJob.vue"

/**
 * Contact
 */
import Contact from "../views/pages/contact/Contact.vue"
import ContactDetail from "../views/pages/contact/ContactDetail.vue"

/**
 * BRANCH
 */
import Branch from "../views/pages/branch/Branch.vue"
import BranchCreate from "../views/pages/branch/BranchCreate.vue"
import BranchDetail from "../views/pages/branch/BranchDetail.vue"

/**
 * Cookie
 */
import Cookie from "../views/pages/cookie/Cookie.vue"

/**
 * News & Activity
 */
import News from "../views/pages/news/News.vue"
import NewsCreate from "../views/pages/news/NewsCreate.vue"
import NewsDetail from "../views/pages/news/NewsDetail.vue"

/**
 * Dealer
 */
import Dealer from "../views/pages/dealer/Dealer.vue"
import DealerCreate from "../views/pages/dealer/DealerCreate.vue"
import DealerDetail from "../views/pages/dealer/DealerDetail.vue"

/**
 * Support
 */
import Support from "../views/pages/support/Index.vue"
import SupportList from "../views/pages/support/SupportList.vue"
import SupportCreate from "../views/pages/support/SupportCreate.vue"
import SupportDetail from "../views/pages/support/SupportDetail.vue"
import SupportHeaderCreate from "../views/pages/support/SupportHeaderCreate.vue"
import SupportHeaderDetail from "../views/pages/support/SupportHeaderDetail.vue"

/**
 * Download
 */
import Download from "../views/pages/download/Index.vue"
import DownloadList from "../views/pages/download/DownloadList.vue"

import DownloadCreate from "../views/pages/download/DownloadCreate.vue"
import DownloadDetail from "../views/pages/download/DownloadDetail.vue"
import DownloadHeaderCreate from "../views/pages/download/DownloadHeaderCreate.vue"
import DownloadHeaderDetail from "../views/pages/download/DownloadHeaderDetail.vue"

/**
 * FAQ
 */
import Faq from "../views/pages/faq/FAQ.vue"
import FaqCreate from "../views/pages/faq/FAQCreate.vue"
import FaqDetail from "../views/pages/faq/FAQDetail.vue"

/**
 * BANNER
 */
import Banner from "../views/pages/banner/Banner.vue"
import BannerCreate from "../views/pages/banner/BannerCreate.vue"
import BannerDetail from "../views/pages/banner/BannerDetail.vue"

/**
 * CONTENT HIGHLIGHT
 */
import Content from "../views/pages/content/Content.vue"

/**
 * MENU
 */
import Menu from "../views/pages/menu/Menu.vue"
import MenuCreate from "../views/pages/menu/MenuCreate.vue"
import MenuDetail from "../views/pages/menu/MenuDetail.vue"

/**
 * MENU SUB
 */
import MenuSub from "../views/pages/menu/MenuSub.vue"
import MenuSubDetail from "../views/pages/menu/MenuSubDetail.vue"
import MenuSubCreate from "../views/pages/menu/MenuSubCreate.vue"

/**
 * VDO
 */
import Vdo from "../views/pages/vdo/Vdo.vue"
import VdoCreate from "../views/pages/vdo/VdoCreate.vue"
import VdoDetail from "../views/pages/vdo/VdoDetail.vue"

/**
 * ICON
 */
import Icon from "../views/pages/icon/Icon.vue"

/**
 * PRODUCT GROUP
 */
import ProductGroup from "../views/pages/product/product_group/ProductGroup.vue"
import ProductGroupCreate from "../views/pages/product/product_group/ProductGroupCreate.vue"
import ProductGroupEdit from "../views/pages/product/product_group/ProductGroupEdit.vue"

/**
 * PRODUCT GROUP  DIMENSION
 */
import ProductGroupDimension from "../views/pages/product/product_group_dimension/ProductGroupDimension.vue"
import ProductGroupDimensionCreate from "../views/pages/product/product_group_dimension/ProductGroupDimensionCreate.vue"
import ProductGroupDimensionEdit from "../views/pages/product/product_group_dimension/ProductGroupDimensionEdit.vue"

/**
 * PRODUCT CATEGORY
 */
import ProductCategory from "../views/pages/product/product_category/ProductCategory.vue"
import ProductCategoryCreate from "../views/pages/product/product_category/ProductCategoryCreate.vue"
import ProductCategoryEdit from "../views/pages/product/product_category/ProductCategoryEdit.vue"

/**
 * PRODUCT CATEGORY DIMENSION
 */
import ProductCategoryDimension from "../views/pages/product/product_category_dimension/ProductCategoryDimension.vue"

/**
 * PRODUCT CATEGORY
 * VDO | DOCS
 */
import ProductCategoryVdoCreate from "../views/pages/product/product_category/vdo/VdoCreate.vue"
import ProductCategoryVdoEdit from "../views/pages/product/product_category/vdo/VdoDetail.vue"
import ProductCategoryDocsCreate from "../views/pages/product/product_category/docs/DocsCreate.vue"
import ProductCategoryDocsEdit from "../views/pages/product/product_category/docs/DocsDetail.vue"

/**
 * PRODUCT
 */
import Product from "../views/pages/product/product/Product.vue"
import ProductCreate from "../views/pages/product/product/ProductCreate.vue"
import ProductEdit from "../views/pages/product/product/ProductEdit.vue"

/**
 * SHADE COLOR
 */
import ShadeColor from "../views/pages/product/shade_color/ShadeColor.vue"
import ShadeColorCreate from "../views/pages/product/shade_color/ShadeColorCreate.vue"
import ShadeColorEdit from "../views/pages/product/shade_color/ShadeColorEdit.vue"

/**
 * GROUP COLOR
 */
import GroupColor from "../views/pages/product/group_color/GroupColor.vue"
import GroupColorCreate from "../views/pages/product/group_color/GroupColorCreate.vue"
import GroupColorEdit from "../views/pages/product/group_color/GroupColorEdit.vue"

/**
 * USABILITY
 */
import Usability from "../views/pages/product/usability/Usability.vue"
import UsabilityCreate from "../views/pages/product/usability/UsabilityCreate.vue"
import UsabilityEdit from "../views/pages/product/usability/UsabilityEdit.vue"

/**
 * ORDER
 */
import Order from "../views/pages/product/order/Order.vue"
import OrderCreate from "../views/pages/product/order/OrderCreate.vue"
import OrderEdit from "../views/pages/product/order/OrderEdit.vue"

/**
 * SHAPE
 */
import Shape from "../views/pages/product/shape/Shape.vue"
import ShapeCreate from "../views/pages/product/shape/ShapeCreate.vue"
import ShapeEdit from "../views/pages/product/shape/ShapeEdit.vue"

/**
 * COLOR
 */
import Color from "../views/pages/product/color/Color.vue"
import ColorCreate from "../views/pages/product/color/ColorCreate.vue"
import ColorEdit from "../views/pages/product/color/ColorEdit.vue"

/**
 * ACCESSSORY
 */
import Accessory from "../views/pages/product/accessory/Accessory.vue"
import AccessoryCreate from "../views/pages/product/accessory/AccessoryCreate.vue"
import AccessoryEdit from "../views/pages/product/accessory/AccessoryEdit.vue"

/**
 * ESTIMATION
 */
import Estimation from "../views/pages/estimation/estimation/Estimation"

import EstimationCal from "../views/pages/estimation/estimation_cal/EstimationCal.vue"

import EstimationProductGroup from "../views/pages/estimation/estimation_product_group/EstimationProductGroup.vue"
import EstimationProductGroupCreate from "../views/pages/estimation/estimation_product_group/EstimationProductGroupCreate.vue"
import EstimationProductGroupEdit from "../views/pages/estimation/estimation_product_group/EstimationProductGroupEdit.vue"

import EstimationProductCategory from "../views/pages/estimation/estimation_product_category/EstimationProductCategory.vue"
import EstimationProductCategoryCreate from "../views/pages/estimation/estimation_product_category/EstimationProductCategoryCreate.vue"
import EstimationProductCategoryEdit from "../views/pages/estimation/estimation_product_category/EstimationProductCategoryEdit.vue"

import EstimationShape from "../views/pages/estimation/estimation_shape/EstimationShape.vue"
import EstimationShapeCreate from "../views/pages/estimation/estimation_shape/EstimationShapeCreate.vue"
import EstimationShapeEdit from "../views/pages/estimation/estimation_shape/EstimationShapeEdit.vue"

import EstimationColor from "../views/pages/estimation/estimation_color/EstimationColor.vue"
import EstimationColorCreate from "../views/pages/estimation/estimation_color/EstimationColorCreate.vue"
import EstimationColorEdit from "../views/pages/estimation/estimation_color/EstimationColorEdit.vue"

import EstimationFomulaShape from "../views/pages/estimation/estimation_formula_shape/EstimationFormulaShape.vue"

import EstimationFormula from "../views/pages/estimation/estimation_formula/EstimationFormula.vue"
import EstimationFormulaRes from "../views/pages/estimation/estimation_formula/EstimationFormulaRes.vue"
import EstimationFormulaResEdit from "../views/pages/estimation/estimation_formula/EstimationFormulaResEdit.vue"

import EstimationField from "../views/pages/estimation/estimation_field/EstimationField.vue"
import EstimationFieldEdit from "../views/pages/estimation/estimation_field/EstimationFieldEdit.vue"

import EstimationFormulaResColor from "../views/pages/estimation/estimation_formular_res_color/EstimationFormulaResColor.vue"
import EstimationFormulaResColorEdit from "../views/pages/estimation/estimation_formular_res_color/EstimationFormulaResColorEdit.vue"
/**
 * SITE REF
 */
import SiteRef from "../views/pages/site_ref/site_ref/SiteRef.vue"
import SiteRefCreate from "../views/pages/site_ref/site_ref/SiteRefCreate.vue"
import SiteRefEdit from "../views/pages/site_ref/site_ref/SiteRefEdit.vue"

import SiteRefProductGroup from "../views/pages/site_ref/product_group/SiteRefProductGroup.vue"
import SiteRefProductGroupEdit from "../views/pages/site_ref/product_group/SiteRefProductGroupEdit.vue"

import SiteRefProductCategory from "../views/pages/site_ref/product_category/SiteRefProductCategory.vue"
import SiteRefProductCategoryEdit from "../views/pages/site_ref/product_category/SiteRefProductCategoryEdit.vue"

/**
 * ADDRESS
 */
import Country from "../views/pages/address/country/Country.vue"

import Province from "../views/pages/address/province/Province.vue"
import ProvinceCreate from "../views/pages/address/province/ProvinceCreate.vue"
import ProvinceEdit from "../views/pages/address/province/ProvinceEdit.vue"

import District from "../views/pages/address/district/District.vue"
import DistrictCreate from "../views/pages/address/district/DistrictCreate.vue"
import DistrictEdit from "../views/pages/address/district/DistrictEdit.vue"

import Subdistrict from "../views/pages/address/subdistrict/Subdistrict.vue"
import SubdistrictCreate from "../views/pages/address/subdistrict/SubdistrictCreate.vue"
import SubdistrictEdit from "../views/pages/address/subdistrict/SubdistrictEdit.vue"

/**
 * HOME
 */

import HomeActivity from "../views/pages/home/activity/HomeActivity.vue"
import HomeActivityCreate from "../views/pages/home/activity/HomeActivityCreate.vue"
import HomeActivityEdit from "../views/pages/home/activity/HomeActivityEdit.vue"

import HomeBanner from "../views/pages/home/banner/HomeBanner.vue"
import HomeBannerCreate from "../views/pages/home/banner/HomeBannerCreate.vue"
import HomeBannerEdit from "../views/pages/home/banner/HomeBannerEdit.vue"

import HomeContent from "../views/pages/home/content/HomeContent.vue"
import HomeContentCreate from "../views/pages/home/content/HomeContentCreate.vue"
import HomeContentEdit from "../views/pages/home/content/HomeContentEdit.vue"

import HomeProductService from "../views/pages/home/product_service/HomeProductService.vue"
import HomeProductServiceCreate from "../views/pages/home/product_service/HomeProductServiceCreate.vue"
import HomeProductServiceEdit from "../views/pages/home/product_service/HomeProductServiceEdit.vue"

import HomeCarousel from "../views/pages/home/carousel/HomeCarousel.vue"
import HomeCarouselCreate from "../views/pages/home/carousel/HomeCarouselCreate.vue"
import HomeCarouselEdit from "../views/pages/home/carousel/HomeCarouselEdit.vue"

import HomeSocialIcon from "../views/pages/home/social_icon/HomeSocialIcon.vue"
import HomeSocialIconCreate from "../views/pages/home/social_icon/HomeSocialIconCreate.vue"
import HomeSocialIconEdit from "../views/pages/home/social_icon/HomeSocialIconEdit.vue"

import HomeHighlightList from "../views/pages/home/highlight_list/HomeHighlightList.vue"
import HomeHighlightListCreate from "../views/pages/home/highlight_list/HomeHighlightListCreate.vue"
import HomeHighlightListEdit from "../views/pages/home/highlight_list/HomeHighlightListEdit.vue"

import HomeHighlight from "../views/pages/home/highlight/HomeHighlight.vue"
import HomeHighlightCreate from "../views/pages/home/highlight/HomeHighlightCreate.vue"
import HomeHighlightEdit from "../views/pages/home/highlight/HomeHighlightEdit.vue"

import HomeMarketplace from "../views/pages/home/marketplace/HomeMarketplace.vue"
// import HomeMarketplaceCreate from "../views/pages/home/marketplace/HomeMarketplaceCreate.vue"
// import HomeMarketplaceEdit from "../views/pages/home/marketplace/HomeMarketplaceEdit.vue"

/**
 * SEO
 */
import Seo from "../views/pages/seo/Seo.vue"
import SeoCreate from "../views/pages/seo/SeoCreate.vue"
import SeoDetail from "../views/pages/seo/SeoDetail.vue"

/**
 * IR LINK
 */
import Ir from "../views/pages/ir/Ir.vue"

/**
 * USER MANAGEMENT
 */
import UserManagement from "@/views/pages/user_management/UserManagement"
import UserManagementCreate from "@/views/pages/user_management/UserManagementCreate.vue"
import UserManagementEdit from "@/views/pages/user_management/UserManagementEdit.vue"

/**
 * BLOG
 */
import Blog from "@/views/pages/blog/Blog"
import BlogCreate from "@/views/pages/blog/BlogCreate"
import BlogDetail from "@/views/pages/blog/BlogDetail"

/**
 * Theme
 */
import Index from "@/views/pages/Index"

/**
 * LOGO
 */
import Logo from "@/views/pages/logo/Logo.vue"

/**
 * Router
 */
import RouterBase from "../components/core/Router.vue"

/**
 * LOG
 */
import Log from "../views/pages/log/Log.vue"

Vue.use(Router)

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: Index,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: "",
          redirect: "/index"
        },
        {
          name: "index",
          path: "index",
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN, ROLE.AGENCY, ROLE.HR, ROLE.STAFF],
            title: "index"
          }
        },
        /**
         * ABOUT US
         */

        /**
         * COMPANY
         */
        {
          name: "company",
          path: "about-us/company",
          component: Company,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "company"
          }
        },

        /**
         * VISION
         */
        {
          name: "vision_mission_corevalue",
          path: "about-us/vision",
          component: VisionMissionCoreValue,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "vision_mission_corevalue"
          }
        },

        /**
         * STRATEGY
         */
        {
          name: "strategy",
          path: "about-us/strategy",
          component: Strategy,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "strategy"
          }
        },

        /**
         * CSR
         */
        {
          name: "csr",
          path: "about-us/csr",
          component: CSR,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "csr"
          }
        },

        /**
         * POLICY & STANDARD
         */
        {
          name: "policy_standard",
          path: "about-us/policystandard",
          component: PolicyStandard,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "policy_standard"
          }
        },

        /**
         * STANDARD CATEGORY
         */
        {
          name: "standard_category_create",
          path: "about-us/standard-category-create/:standard_id",
          component: StandardCreate,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "policy_standard"
          }
        },
        {
          name: "standard_category_detail",
          path: "about-us/standard-category-detail/:standard_id/:standard_category_id",
          component: StandardDetail,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "policy_standard"
          }
        },

        /**
         * ACTIVITY
         */
        {
          name: "activity_create",
          path: "about-us/activity-create",
          component: ActivityCreate,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "policy_standard"
          }
        },
        {
          name: "activity_detail",
          path: "about-us/activity-detail/:activity_id",
          component: ActivityDetail,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "policy_standard"
          }
        },

        /**
         * END ABOUT US
         */

        /**
         * CAREER
         */
        {
          name: "career",
          path: "career",
          component: Career,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN, ROLE.HR],
            title: "career"
          }
        },
        {
          name: "career_create",
          path: "career-create",
          component: CareerCreate,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN, ROLE.HR],
            title: "career"
          }
        },
        {
          name: "career_detail",
          path: "career-detail/:career_id",
          component: CareerDetail,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN, ROLE.HR],
            title: "career"
          }
        },

        /**
         * CAREER EXECUTIVE
         */
        {
          name: "career_executive_create",
          path: "career-executive-create",
          component: CareerExecutiveCreate,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN, ROLE.HR],
            title: "career"
          }
        },
        {
          name: "career_executive",
          path: "career-executive-detail/:career_executive_id",
          component: CareerExecutive,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN, ROLE.HR],
            title: "career"
          }
        },

        /**
         * APPLY JOB
         */
        {
          name: "apply_job",
          path: "apply-job/:apply_job_id",
          component: ApplyJob,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN, ROLE.HR],
            title: "career"
          }
        },

        /**
         * CONTACT
         */
        {
          name: "contact",
          path: "contact",
          component: Contact,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "contact_us"
          }
        },
        {
          name: "contact_detail",
          path: "contact-detail/:contact_id",
          component: ContactDetail,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "contact_us"
          }
        },

        /**
         * BRANCH
         */
        {
          name: "branch",
          path: "branch",
          component: Branch,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "contact_us"
          }
        },
        {
          name: "branch_create",
          path: "branch-create",
          component: BranchCreate,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "contact_us"
          }
        },
        {
          name: "branch_detail",
          path: "branch-detail/:branch_id",
          component: BranchDetail,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "contact_us"
          }
        },

        /**
         * COOKIE
         */
        {
          name: "cookie",
          path: "cookie",
          component: Cookie,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "cookie"
          }
        },

        /**
         * NEWS & ACTIVITY
         */
        {
          name: "news",
          path: "news",
          component: News,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "news"
          }
        },
        {
          name: "news_create",
          path: "news-create",
          component: NewsCreate,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "news"
          }
        },
        {
          name: "news_detail",
          path: "news-detail/:news_id",
          component: NewsDetail,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "news"
          }
        },

        /**
         * DEALER
         */
        {
          path: "dealer",
          component: RouterBase,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN, ROLE.STAFF],
            title: "dealer"
          },
          children: [
            {
              name: "dealer",
              path: "dealers",
              component: Dealer,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN, ROLE.STAFF],
                title: "dealer"
              }
            },
            {
              name: "dealer_create",
              path: "dealer-create",
              component: DealerCreate,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN, ROLE.STAFF],
                title: "dealer"
              }
            },
            {
              name: "dealer_detail",
              path: "dealer-detail/:dealer_id",
              component: DealerDetail,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN, ROLE.STAFF],
                title: "dealer"
              }
            },
            {
              // name: "address",
              path: "address",
              component: RouterBase,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "address"
              },
              children: [
                {
                  path: "",
                  redirect: "country",
                  meta: {
                    requiresAuth: true,
                    auth: [ROLE.ADMIN],
                    title: "address"
                  }
                },
                {
                  name: "country",
                  path: "country",
                  component: Country,
                  meta: {
                    requiresAuth: true,
                    auth: [ROLE.ADMIN],
                    title: "address"
                  }
                },
                {
                  name: "province",
                  path: "country/:countryId/province",
                  component: Province,
                  meta: {
                    requiresAuth: true,
                    auth: [ROLE.ADMIN],
                    title: "address"
                  }
                },
                {
                  name: "province_create",
                  path: "country/:countryId/province-create",
                  component: ProvinceCreate,
                  meta: {
                    requiresAuth: true,
                    auth: [ROLE.ADMIN],
                    title: "address"
                  }
                },
                {
                  name: "province_detail",
                  path: "country/:countryId/province-detail/:id",
                  component: ProvinceEdit,
                  meta: {
                    requiresAuth: true,
                    auth: [ROLE.ADMIN],
                    title: "address"
                  }
                },
                {
                  name: "district",
                  path: "country/:countryId/province/:provinceId/district",
                  component: District,
                  meta: {
                    requiresAuth: true,
                    auth: [ROLE.ADMIN],
                    title: "address"
                  }
                },
                {
                  name: "district_create",
                  path: "country/:countryId/province/:provinceId/district-create",
                  component: DistrictCreate,
                  meta: {
                    requiresAuth: true,
                    auth: [ROLE.ADMIN],
                    title: "address"
                  }
                },
                {
                  name: "district_detail",
                  path: "country/:countryId/province/:provinceId/district-detail/:id",
                  component: DistrictEdit,
                  meta: {
                    requiresAuth: true,
                    auth: [ROLE.ADMIN],
                    title: "address"
                  }
                },
                {
                  name: "subdistrict",
                  path: "country/:countryId/province/:provinceId/district/:districtId/subdistrict",
                  component: Subdistrict,
                  meta: {
                    requiresAuth: true,
                    auth: [ROLE.ADMIN],
                    title: "address"
                  }
                },
                {
                  name: "subdistrict_create",
                  path: "country/:countryId/province/:provinceId/district/:districtId/subdistrict-create",
                  component: SubdistrictCreate,
                  meta: {
                    requiresAuth: true,
                    auth: [ROLE.ADMIN],
                    title: "address"
                  }
                },
                {
                  name: "subdistrict_detail",
                  path: "country/:countryId/province/:provinceId/district/:districtId/subdistrict-detail/:id",
                  component: SubdistrictEdit,
                  meta: {
                    requiresAuth: true,
                    auth: [ROLE.ADMIN],
                    title: "address"
                  }
                }
              ]
            }
          ]
        },

        /**
         * SUPPORT
         */
        {
          name: "support",
          path: "support",
          component: Support,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "support"
          }
        },
        {
          name: "support_list",
          path: "support-list/:support_header_id",
          component: SupportList,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "support"
          }
        },
        {
          name: "support_create",
          path: "support-create/:support_header_id",
          component: SupportCreate,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "support"
          }
        },
        {
          name: "support_detail",
          path: "support-detail/:support_id",
          component: SupportDetail,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "support"
          }
        },

        /**
         * SUPPORT HEADER
         */
        {
          name: "support_header_create",
          path: "support-header-create",
          component: SupportHeaderCreate,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "support"
          }
        },

        {
          name: "support_header_detail",
          path: "support-header-detail/:support_header_id",
          component: SupportHeaderDetail,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "support"
          }
        },

        /**
         * DOWNLOAD
         */
        {
          name: "download",
          path: "download",
          component: Download,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "download"
          }
        },
        {
          name: "download_list",
          path: "download-list/:download_header_id",
          component: DownloadList,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "download"
          }
        },
        {
          name: "download_create",
          path: "download-create/:download_header_id",
          component: DownloadCreate,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "download"
          }
        },
        {
          name: "download_detail",
          path: "download-detail/:download_id",
          component: DownloadDetail,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "download"
          }
        },
        {
          name: "download_header_create",
          path: "download-header-create",
          component: DownloadHeaderCreate,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "download"
          }
        },

        {
          name: "download_header_detail",
          path: "download-header-detail/:download_header_id",
          component: DownloadHeaderDetail,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "download"
          }
        },

        /**
         * FAQ
         */
        {
          name: "faq",
          path: "faq",
          component: Faq,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "faq"
          }
        },
        {
          name: "faq_create",
          path: "faq-create",
          component: FaqCreate,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "faq"
          }
        },
        {
          name: "faq_detail",
          path: "faq-detail/:faq_id",
          component: FaqDetail,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "faq"
          }
        },

        /**
         * BANNER
         */
        {
          name: "banner",
          path: "banner",
          component: Banner,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "banner"
          }
        },
        {
          name: "banner_create",
          path: "banner-create",
          component: BannerCreate,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "banner"
          }
        },
        {
          name: "banner_detail",
          path: "banner-detail/:banner_id",
          component: BannerDetail,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "banner"
          }
        },

        /**
         * CONTENT HIGHLIGHT
         */
        {
          name: "content",
          path: "content",
          component: Content,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "content"
          }
        },

        /**
         * SEO
         */
        {
          name: "seo",
          path: "seo",
          component: Seo,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "seo"
          }
        },

        /**
         * IR LINK
         */
        {
          name: "ir",
          path: "ir",
          component: Ir,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "ir"
          }
        },

        /**
         * VDO
         */
        {
          name: "vdo",
          path: "vdo",
          component: Vdo,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "vdo"
          }
        },
        {
          name: "vdo_create",
          path: "vdo-create",
          component: VdoCreate,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "vdo"
          }
        },

        {
          name: "vdo_detail",
          path: "vdo-detail/:vdo_id",
          component: VdoDetail,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "vdo"
          }
        },

        /**
         * ICON
         */
        {
          name: "icon",
          path: "icon",
          component: Icon,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "icon"
          }
        },

        /**
         * PRODUCT GROUP
         */
        {
          path: "product-group",
          component: RouterBase,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "product_group"
          },
          children: [
            {
              name: "product_group",
              path: "",
              component: ProductGroup,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "product_group"
              }
            },
            /**
             * PRODUCT CATEGORY
             */
            {
              path: ":id/product-category",
              component: RouterBase,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "product_category"
              },
              children: [
                {
                  name: "product_category",
                  path: "",
                  component: ProductCategory,
                  meta: {
                    requiresAuth: true,
                    auth: [ROLE.ADMIN],
                    title: "product_category"
                  }
                },
                {
                  name: "product",
                  path: ":productCategoryId/product",
                  component: Product,
                  meta: {
                    requiresAuth: true,
                    auth: [ROLE.ADMIN],
                    title: "product_category"
                  }
                },
                {
                  name: "product_create",
                  path: ":productCategoryId/product-create",
                  component: ProductCreate,
                  meta: {
                    requiresAuth: true,
                    auth: [ROLE.ADMIN],
                    title: "product_category"
                  }
                },
                {
                  name: "product_detail",
                  path: ":productCategoryId/product-detail/:productId",
                  component: ProductEdit,
                  meta: {
                    requiresAuth: true,
                    auth: [ROLE.ADMIN],
                    title: "product_category"
                  }
                },
                /**
                 * PRODUCT CATEGORY DIMENSION
                 */
                {
                  name: "product_category_dimension",
                  path: ":productCategoryId/dimension",
                  component: ProductCategoryDimension,
                  meta: {
                    requiresAuth: true,
                    auth: [ROLE.ADMIN],
                    title: "product_category"
                  }
                },
              ]
            },
            {
              name: "product_category_create",
              path: ":id/product-category-create",
              component: ProductCategoryCreate,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],

                title: "product_category_create"
              }
            },
            {
              name: "product_category_detail",
              path: ":id/product-category-detail/:productCategoryId",
              component: RouterBase,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "product_category_detail"
              },
              children: [
                {
                  name: "product_cate_detail_index",
                  path: "",
                  component: ProductCategoryEdit
                },
                {
                  name: "product_cate_detail_vdo_create",
                  path: "vdo",
                  component: ProductCategoryVdoCreate
                },
                {
                  name: "product_cate_detail_vdo_detail",
                  path: "vdo/:vdo_id",
                  component: ProductCategoryVdoEdit
                },
                {
                  name: "product_cate_detail_docs_create",
                  path: "docs",
                  component: ProductCategoryDocsCreate
                },
                {
                  name: "product_cate_detail_docs_detail",
                  path: "docs/:docs_id",
                  component: ProductCategoryDocsEdit
                }
              ]
            }
          ]
        },
        {
          name: "product_group_create",
          path: "product-group-create",
          component: ProductGroupCreate,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "product_group_create"
          }
        },
        {
          name: "product_group_detail",
          path: "product-group-detail/:id",
          component: ProductGroupEdit,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "product_group_detail"
          }
        },
        /**
         * PRODUCT GROUP DIMENSION
         */
        {
          // name: "product_group_dimension",
          path: "product-group/:id/dimension",
          component: RouterBase,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "product_group_dimension"
          },
          children: [
            {
              name: "product_group_dimension",
              path: "",
              component: ProductGroupDimension
            },
            {
              name: "product_group_dimension_create",
              path: "create",
              component: ProductGroupDimensionCreate
            },
            {
              name: "product_group_dimension_detail",
              path: "detail/:dimensionId",
              component: ProductGroupDimensionEdit
            }
          ]
        },

        /**
         * SHADE COLOR
         */
        {
          name: "shade_color",
          path: "shade-color",
          component: ShadeColor,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "shade_color"
          }
        },
        {
          name: "shade_color_create",
          path: "shade-color-create",
          component: ShadeColorCreate,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "shade_color_create"
          }
        },
        {
          name: "shade_color_detail",
          path: "shade-color-detail/:id",
          component: ShadeColorEdit,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "shade_color_detail"
          }
        },

        /**
         * GROUP COLOR
         */
        {
          name: "group_color",
          path: "group-color",
          component: GroupColor,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "group_color"
          }
        },
        {
          name: "group_color_create",
          path: "group-color-create",
          component: GroupColorCreate,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "group_color_create"
          }
        },
        {
          name: "group_color_detail",
          path: "group-color-detail/:id",
          component: GroupColorEdit,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "group_color_detail"
          }
        },

        /**
         * USABILITY
         */
        {
          name: "usability",
          path: "usability",
          component: Usability,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "usability"
          }
        },
        {
          name: "usability_create",
          path: "usability-create",
          component: UsabilityCreate,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "usability_create"
          }
        },
        {
          name: "usability_detail",
          path: "usability-detail/:id",
          component: UsabilityEdit,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "usability_detail"
          }
        },
        {
          name: "order",
          path: "order",
          component: Order,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "order"
          }
        },
        {
          name: "order_create",
          path: "order-create",
          component: OrderCreate,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "order_create"
          }
        },
        {
          name: "order_detail",
          path: "order-detail/:id",
          component: OrderEdit,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "order_detail"
          }
        },

        /**
         * SHAPE
         */
        {
          name: "shape",
          path: "shape",
          component: Shape,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "shape"
          }
        },
        {
          name: "shape_create",
          path: "shape-create",
          component: ShapeCreate,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "shape_create"
          }
        },
        {
          name: "shape_detail",
          path: "shape-detail/:id",
          component: ShapeEdit,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "shape_detail"
          }
        },

        /**
         * COLOR
         */
        {
          name: "color",
          path: "color",
          component: Color,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "color"
          }
        },
        {
          name: "color_create",
          path: "color-create",
          component: ColorCreate,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "color_create"
          }
        },
        {
          name: "color_detail",
          path: "color-detail/:id",
          component: ColorEdit,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "color_detail"
          }
        },

        /**
         * ACCESSORY
         */
        {
          name: "accessory",
          path: "accessory",
          component: Accessory,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "accessory"
          }
        },
        {
          name: "accessory_create",
          path: "accessory-create",
          component: AccessoryCreate,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "accessory_create"
          }
        },
        {
          name: "accessory_detail",
          path: "accessory-detail/:id",
          component: AccessoryEdit,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "accessory_detail"
          }
        },

        /**
         * ESTIMATION
         */
        {
          path: "",
          component: RouterBase,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "estimation"
          },
          children: [
            {
              path: "",
              redirect: "estimation-product-group",
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "estimation"
              }
            },
            {
              path: "estimation-product-group",
              component: RouterBase,
              children: [
                {
                  name: "estimationProductGroup",
                  path: "",
                  component: EstimationProductGroup,
                  meta: {
                    requiresAuth: true,
                    auth: [ROLE.ADMIN],
                    title: "estimation"
                  }
                },
                {
                  path: ":estimationProductGroupId/estimation-product-category",
                  component: RouterBase,
                  children: [
                    {
                      name: "estimationProductCategory",
                      path: "",
                      component: EstimationProductCategory,
                      meta: {
                        requiresAuth: true,
                        auth: [ROLE.ADMIN],
                        title: "estimation"
                      }
                    },
                    {
                      path: ":estimationProductCategoryId/estimation-formula-shape",
                      component: RouterBase,
                      children: [
                        {
                          name: "estimationFormulaShape",
                          path: "",
                          component: EstimationFomulaShape,
                          meta: {
                            requiresAuth: true,
                            auth: [ROLE.ADMIN],
                            title: "estimation"
                          }
                        },
                        {
                          name: "estimationManageForm",
                          path: ":productShapeId/estimation",
                          component: Estimation,
                          meta: {
                            requiresAuth: true,
                            auth: [ROLE.ADMIN],
                            title: "estimation"
                          }
                        }
                      ]
                    }
                  ]
                },
                {
                  name: "estimationProductCategoryCreate",
                  path: ":estimationProductGroupId/estimation-product-category-create",
                  component: EstimationProductCategoryCreate,
                  meta: {
                    requiresAuth: true,
                    auth: [ROLE.ADMIN],
                    title: "estimation"
                  }
                },
                {
                  name: "estimationProductCategoryDetail",
                  path: ":estimationProductGroupId/estimation-product-category-detail/:id",
                  component: EstimationProductCategoryEdit,
                  meta: {
                    requiresAuth: true,
                    auth: [ROLE.ADMIN],
                    title: "estimation"
                  }
                }
              ]
            },
            {
              name: "estimationCal",
              path: "estimation-calulate",
              component: EstimationCal,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "estimation"
              }
            },
            {
              name: "estimationProductGroupCreate",
              path: "estimation-product-group-create",
              component: EstimationProductGroupCreate,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "estimation"
              }
            },
            {
              name: "estimationProductGroupDetail",
              path: "estimation-product-group-detail/:id",
              component: EstimationProductGroupEdit,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "estimation"
              }
            },
            {
              name: "estimationColor",
              path: "estimation-color",
              component: EstimationColor,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "estimation"
              }
            },
            {
              name: "estimationColorCreate",
              path: "estimation-color-create",
              component: EstimationColorCreate,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "estimation"
              }
            },
            {
              name: "estimationColorDetail",
              path: "estimation-color-detail/:id",
              component: EstimationColorEdit,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "estimation"
              }
            },
            {
              name: "estimationShape",
              path: "estimation-shape",
              component: EstimationShape,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "estimation"
              }
            },
            {
              name: "estimationShapeCreate",
              path: "estimation-shape-create",
              component: EstimationShapeCreate,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "estimation"
              }
            },
            {
              name: "estimationShapeDetail",
              path: "estimation-shape-detail/:id",
              component: EstimationShapeEdit,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "estimation"
              }
            },
            {
              name: "estimationFormula",
              path: "estimation-formula",
              component: EstimationFormula,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "estimation"
              }
            },
            {
              name: "estimationFormulaRes",
              path: "estimation-formula/:estimationFormulaId/estimation-formula-res",
              component: EstimationFormulaRes,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "estimation"
              }
            },
            {
              name: "estimationFormulaResColor",
              path: "estimation-formula/:estimationFormulaId/estimation-formula-res-color/:estimationFormulaResId",
              component: EstimationFormulaResColor,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "estimation"
              }
            },
            {
              name: "EstimationFormulaResColorEdit",
              path:
                "estimation-formula/:estimationFormulaId/estimation-formula-res-color/:estimationFormulaResId/estimation-formula-res-color-detail/:estimationFormulaResColorId",
              component: EstimationFormulaResColorEdit,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "estimation"
              }
            },
            {
              name: "estimationFormulaResEdit",
              path: "estimation-formula/:estimationFormulaId/estimation-formula-res-detail/:id",
              component: EstimationFormulaResEdit,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "estimation"
              }
            },
            {
              name: "estimationField",
              path: "estimation-field",
              component: EstimationField,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "estimation"
              }
            },
            {
              name: "estimationFieldEdit",
              path: "estimation-field-detail/:id",
              component: EstimationFieldEdit,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "estimation"
              }
            }
          ]
        },
        {
          name: "Log",
          path: "log",
          component: Log,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "log"
          }
        },

        /**
         * BLOG
         */
        {
          name: "blog",
          path: "blog",
          component: Blog,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN, ROLE.AGENCY],
            title: "blog"
          }
        },
        {
          name: "blog_create",
          path: "blog-create",
          component: BlogCreate,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN, ROLE.AGENCY],
            title: "blog"
          }
        },
        {
          name: "blog_detail",
          path: "blog-detail/:blog_id",
          component: BlogDetail,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN, ROLE.AGENCY],
            title: "blog"
          }
        },

        /**
         * SITE REF
         */
        {
          path: "site-ref",
          component: RouterBase,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "site_ref"
          },
          children: [
            {
              path: "",
              redirect: "/site-ref/site-ref-product-group",
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "site_ref"
              }
            },
            {
              path: "site-ref-product-group",
              component: RouterBase,
              children: [
                {
                  path: "",
                  component: SiteRefProductGroup,
                  meta: {
                    requiresAuth: true,
                    auth: [ROLE.ADMIN],
                    title: "site_ref"
                  }
                },
                /**
                 * PRODUCT CATEGORY
                 */
                {
                  path: ":id/site-ref-product-category",
                  component: RouterBase,
                  meta: {
                    requiresAuth: true,
                    auth: [ROLE.ADMIN],

                    title: "site_ref"
                  },
                  children: [
                    {
                      name: "site_ref_product_category",
                      path: "",
                      component: SiteRefProductCategory,
                      meta: {
                        requiresAuth: true,
                        auth: [ROLE.ADMIN],
                        title: "site_ref"
                      }
                    },
                    {
                      name: "site_ref",
                      path: ":productCategoryId/site-ref",
                      component: SiteRef,
                      meta: {
                        requiresAuth: true,
                        auth: [ROLE.ADMIN],
                        title: "site_ref"
                      }
                    },
                    {
                      name: "site_ref_create",
                      path: ":productCategoryId/site-ref-create",
                      component: SiteRefCreate,
                      meta: {
                        requiresAuth: true,
                        auth: [ROLE.ADMIN],
                        title: "site_ref"
                      }
                    },
                    {
                      name: "site_ref_detail",
                      path: ":productCategoryId/site-ref-detail/:siteRefId",
                      component: SiteRefEdit,
                      meta: {
                        requiresAuth: true,
                        auth: [ROLE.ADMIN],
                        title: "site_ref"
                      }
                    }
                  ]
                },
                {
                  name: "site_ref_product_category_detail",
                  path: ":id/site-ref-product-category-detail/:productCategoryId",
                  component: SiteRefProductCategoryEdit,
                  meta: {
                    requiresAuth: true,
                    auth: [ROLE.ADMIN],
                    title: "site_ref"
                  }
                }
              ]
            },
            {
              name: "site_ref_product_group_detail",
              path: "site-ref-product-group-detail/:id",
              component: SiteRefProductGroupEdit,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "site_ref"
              }
            }
          ]
        },

        /**
         * LOGO
         */
        {
          name: "logo",
          path: "logo",
          component: Logo,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "logo"
          }
        },

        /**
         * USER
         */
        {
          name: "FAQ Management",
          path: "dev/faqmanagement",
          component: FAQ,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "FAQ"
          }
        },
        {
          name: "Diamond Site Ref Management",
          path: "dev/diamondsiterefmanagement",
          component: DiamondSiteRef,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "Diamond Site Ref Management"
          }
        },
        {
          path: "usermanagement",
          component: RouterBase,
          children: [
            {
              name: "User Management",
              path: "",
              component: UserManagement,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "user_management"
              }
            },
            {
              name: "usermanagement-create",
              path: "usermanagement-create",
              component: UserManagementCreate,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "user_management"
              }
            },
            {
              name: "usermanagement-edit",
              path: "usermanagement-edit/:id",
              component: UserManagementEdit,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "user_management"
              }
            }
          ],
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "user_management"
          }
        },
        {
          path: "home",
          component: RouterBase,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "home"
          },
          children: [
            /**
             * MENU
             */
            {
              path: "menu",
              component: RouterBase,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "menu"
              },
              children: [
                {
                  name: "menu",
                  path: "",
                  component: Menu,
                  meta: {
                    requiresAuth: true,
                    auth: [ROLE.ADMIN],
                    title: "menu"
                  }
                },
                {
                  path: ":menuId",
                  component: RouterBase,
                  children: [
                    {
                      path: "",
                      redirect: "menu-sub",
                      meta: {
                        requiresAuth: true,
                        auth: [ROLE.ADMIN],
                        title: "menu"
                      }
                    },
                    {
                      path: "menu-sub",
                      component: MenuSub,
                      meta: {
                        requiresAuth: true,
                        auth: [ROLE.ADMIN],
                        title: "menu"
                      }
                    },
                    {
                      path: "menu-sub-create",
                      component: MenuSubCreate,
                      meta: {
                        requiresAuth: true,
                        auth: [ROLE.ADMIN],
                        title: "menu"
                      }
                    },
                    {
                      path: "menu-sub-detail/:id",
                      component: MenuSubDetail,
                      meta: {
                        requiresAuth: true,
                        auth: [ROLE.ADMIN],
                        title: "menu"
                      }
                    }
                  ]
                }
              ]
            },
            {
              name: "menu_create",
              path: "menu-create",
              component: MenuCreate,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "menu"
              }
            },
            {
              name: "menu_detail",
              path: "menu-detail/:menuId",
              component: MenuDetail,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "menu"
              }
            },
            {
              name: "HomeBanner",
              path: "home-banner",
              component: HomeBanner,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "home"
              }
            },
            {
              name: "HomeBannerCreate",
              path: "home-banner-create",
              component: HomeBannerCreate,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "home"
              }
            },
            {
              name: "HomeBannerDetail",
              path: "home-banner-detail/:id",
              component: HomeBannerEdit,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "home"
              }
            },
            {
              name: "HomeContent",
              path: "home-content",
              component: HomeContent,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "home"
              }
            },
            {
              name: "HomeContentCreate",
              path: "home-content-create",
              component: HomeContentCreate,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "home"
              }
            },
            {
              name: "HomeContentDetail",
              path: "home-content-detail/:id",
              component: HomeContentEdit,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "home"
              }
            },
            {
              name: "HomeProductService",
              path: "home-product",
              component: HomeProductService,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "home"
              }
            },
            {
              name: "HomeProductServiceCreate",
              path: "home-product-create",
              component: HomeProductServiceCreate,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "home"
              }
            },
            {
              name: "HomeProductServiceDetail",
              path: "home-product-detail/:id",
              component: HomeProductServiceEdit,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "home"
              }
            },
            {
              name: "SocialMediaIcon",
              path: "home-social-icon",
              component: HomeSocialIcon,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "home"
              }
            },
            {
              name: "SocialMediaIconCreate",
              path: "home-social-icon-create",
              component: HomeSocialIconCreate,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "home"
              }
            },
            {
              name: "SocialMediaIconDetail",
              path: "home-social-icon-detail/:id",
              component: HomeSocialIconEdit,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "home"
              }
            },
            {
              name: "HomeCarousel",
              path: "home-carousel",
              component: HomeCarousel,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "home"
              }
            },
            {
              name: "HomeCarouselCreate",
              path: "home-carousel-create",
              component: HomeCarouselCreate,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "home"
              }
            },
            {
              name: "HomeCarouselDetail",
              path: "home-carousel-detail/:id",
              component: HomeCarouselEdit,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "home"
              }
            },
            {
              name: "HomeHighlight",
              path: "home-highlight",
              component: HomeHighlight,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "home"
              }
            },

            {
              name: "HomeHighlightList",
              path: "home-highlight-list",
              component: HomeHighlightList,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "home"
              }
            },
            {
              name: "HomeHighlightListCreate",
              path: "home-highlight-list-create",
              component: HomeHighlightListCreate,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "home"
              }
            },
            {
              name: "HomeHighlightListDetail",
              path: "home-highlight-list-detail/:id",
              component: HomeHighlightListEdit,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "home"
              }
            },
            {
              name: "HomeMarketPlace",
              path: "announce",
              component: HomeMarketplace,
              meta: {
                requiresAuth: true,
                auth: [ROLE.ADMIN],
                title: "home"
              }
            }
          ]
        },
        /** support and download management */
        {
          name: "SupportAndDownload",
          path: "support-downloads",
          component: InfoDownload,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "info_download"
          }
        },
        {
          name: "SupportAndDownloadCreate",
          path: "support-downloads-create",
          component: InfoDownloadCreate,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "info_download_create"
          }
        },
        {
          name: "SupportAndDownloadDetail",
          path: "support-downloads-detail/:id",
          component: InfoDownloadDetail,
          meta: {
            requiresAuth: true,
            auth: [ROLE.ADMIN],
            title: "info_download_detail"
          }
        }
      ]
    },
    {
      name: "login",
      path: "/login",
      component: Login,
      meta: {
        guest: true
      }
    },
    {
      path: "*",
      redirect: "/"
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(({ meta }) => meta.requiresAuth)) {
    if (!(await getAuthToken())) {
      next({ path: "/login" })
    } else {
      const data = await getUserBytoken()
      if (!data || !data.position) {
        next({ path: "/login" })
      } else {
        next()
      }
    }
    next()
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (await getAuthToken()) {
      next({ path: "/" })
    } else {
      next()
    }
    next()
  }
})

export default router
