<template>
  <base-material-card icon="mdi-home" color="primary">
    <template v-slot:after-heading>
      <div class="font-weight-light card-title mt-2">{{ $t("menu_sub") }}</div>
    </template>

    <v-dialog v-model="dialogDelete" max-width="300">
      <v-card>
        <v-card-title>
          <p>
            ยืนยันการลบ
          </p>
          <v-spacer />
          <v-icon aria-label="Close" @click="closeDelete">
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="pb-6 text-center">
          <v-btn class="mr-1" color="error" dark @click="closeDelete"> {{ $t("cancel") }} </v-btn>
          <v-btn class="mr-1" color="success" dark @click="deleteItemConfirm"> {{ $t("delete") }} </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-container fluid>
      <v-row class="text-no-wrap" align="center">
        <v-col>
          <v-data-table :headers="headers" :items="menuSubs" class="elevation-1">
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>{{ $t("menu_sub") }}</v-toolbar-title>

                <v-spacer></v-spacer>
                <v-btn color="primary" dark class="mb-2 mx-1" @click="sortItem">
                  {{ $t("menu_sub_sort") }}
                </v-btn>
                <v-btn color="primary" dark class="mb-2" @click="createItem">
                  {{ $t("menu_sub_create") }}
                </v-btn>

                <v-dialog v-model="dialogSort" max-width="900px">
                  <v-card>
                    <v-card-title class="headline">
                      {{ $t("menu_sub_sort") }}
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <base-dnd-table :headers="header_list" :items.sync="menuSubs" :dragOptions="dragOptions" order>
                          <tr v-for="item in menuSubs" :key="`menu-sub-${item.menu_sub_id}`">
                            <td scope="row" style="cursor: all-scroll"><v-icon>mdi-format-align-justify</v-icon></td>
                            <td>{{ item.title }}</td>
                            <td>{{ item.link }}</td>
                            <td>{{ item.sort + 1 }}</td>
                          </tr>
                        </base-dnd-table>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" dark @click="closeSort">{{ $t("cancel") }}</v-btn>
                      <v-btn color="primary" dark @click="saveSort">{{ $t("save") }}</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:[`item.sort`]="{ item }">
              {{ item.sort + 1 }}
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <base-switch
                v-model="item.status"
                :height="26"
                :width="99"
                :font-size="5"
                :color="{ checked: '#4CAF50', unchecked: '#FF5252' }"
                :labels="{ checked: 'ACTIVE', unchecked: 'INACTIVE' }"
                @change="setLoading(true), onChangeEventHandler(item, $event)"
              />
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-btn class="px-2 ml-1" min-width="0" color="success" @click="editItem(item)" small>
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn class="px-2 ml-1" min-width="0" color="error" @click="deleteItem(item)" small>
                <v-icon small> mdi-delete </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </base-material-card>
</template>

<script>
/**
 * CONSTANTS
 */
import { STATUS } from "@/constants/status"
import { LANGUAGES } from "@/constants/language"

/**
 * CONTROLLER
 */
import {
  getMenuSubByMenu as getMenuSub,
  putMenuSubSort,
  putStatus,
  deleteMenuSub
} from "@/controllers/menu/menu_sub.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "MenuSub",
  data: () => ({
    status: STATUS,
    languages: LANGUAGES,
    menuSubs: [],
    headers: [
      {
        text: "ID",
        align: "start",
        
        value: "menu_sub_id"
      },
      { text: "title", value: "title" },
      { text: "status", value: "status" },
      { text: "sort", value: "sort" },
      { text: "Actions", value: "actions", align: "center", width: "15%", sortable: false }
    ],

    header_list: [
      { text: "", value: "menu_id" },
      { text: "title", value: "title" },
      { text: "link", value: "link" },
      { text: "sort", value: "sort" }
    ],
    dialogSort: false,
    dialogDelete: false,
    delete_item: null,
    dragOptions: {
      animation: 200,
      group: "description",
      disabled: false,
      ghostClass: "ghost"
    }
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.menuSubs = await getMenuSub({ id: this.$route.params.menuId })
        this.setLoading(false)
      } catch (e) {
        this.errorHandle(e)
      }
    },
    editItem(item) {
      this.$router.push({ path: `menu-sub-detail/${item.menu_sub_id}` })
    },
    createItem() {
      this.$router.push({ path: "menu-sub-create" })
    },
    deleteItem(item) {
      this.delete_item = item
      this.dialogDelete = true
    },
    closeDelete() {
      this.delete_item = null
      this.dialogDelete = false
    },
    async deleteItemConfirm() {
      await deleteMenuSub({ menu_sub: this.delete_item }, this.resultHandle, this.errorHandle, this.deleteResultHandle)
      this.dialogDelete = false
    },
    deleteResultHandle() {
      this.menuSubs = this.menuSubs.filter((menu_sub) => menu_sub.menu_sub_id !== this.delete_item.menu_sub_id)
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    },
    async onChangeEventHandler(item, { value }) {
      if (value) await putStatus({ menu_sub: item, status: "ACTIVE" }, this.resultHandle, this.errorHandle)
      else await putStatus({ menu_sub: item, status: "INACTIVE" }, this.resultHandle, this.errorHandle)
    },
    sortItem() {
      this.dialogSort = true
    },
    saveSort() {
      putMenuSubSort({ menuSubs: this.menuSubs }, this.resultHandle, this.errorHandle)
      this.closeSort()
      this.setLoading(false)
    },
    closeSort() {
      this.dialogSort = false
    }
  }
}
</script>

<style></style>
