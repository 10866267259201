<template>
  <v-container>
    <base-material-card icon="mdi-face-agent" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ support_header.title }}</div>
      </template>

      <v-dialog v-model="dialogDelete" max-width="300">
        <v-card>
          <v-card-title>
            <p>
              ยืนยันการลบ
            </p>
            <v-spacer />
            <v-icon aria-label="Close" @click="closeDelete">
              mdi-close
            </v-icon>
          </v-card-title>

          <v-card-text class="pb-6 text-center">
            <v-btn class="mr-1" color="error" dark @click="closeDelete"> {{ $t("cancel") }} </v-btn>
            <v-btn class="mr-1" color="success" dark @click="deleteItemConfirm"> {{ $t("delete") }} </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-container fluid>
        <v-row class="text-no-wrap" align="center">
          <v-col>
            <v-data-table :headers="filterHeaders" :items="supports" class="elevation-1">
              <template v-slot:top>
                <v-toolbar flat>
                  <!-- <v-toolbar-title>{{ $t("support") }}</v-toolbar-title> -->

                  <v-spacer></v-spacer>
                  <v-btn color="primary" dark class="mb-2 mx-1" @click="sortItem">
                    {{ $t("support_sort") }}
                  </v-btn>
                  <v-btn color="primary" dark class="mb-2 mx-1" @click="createItem">
                    {{ $t("support_create") }}
                  </v-btn>

                  <v-dialog v-model="dialogSort" max-width="900px">
                    <v-card>
                      <v-card-title class="headline">
                        {{ $t("support_sort") }}
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <base-dnd-table
                            :headers="header_list"
                            :items.sync="supports"
                            :dragOptions="dragOptions"
                            order
                          >
                            <tr v-for="item in supports" :key="item.support_id">
                              <td scope="row" style="cursor: all-scroll"><v-icon>mdi-format-align-justify</v-icon></td>
                              <td scope="row">{{ item.title }}</td>
                              <td>{{ item.created_date | formatDate }}</td>
                              <td>{{ item.status }}</td>
                              <td>{{ item.sort + 1 }}</td>
                            </tr>
                          </base-dnd-table>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" dark @click="closeSort">{{ $t("cancel") }}</v-btn>
                        <v-btn color="primary" dark @click="saveSort">{{ $t("save") }}</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>

              <template v-slot:[`item.file`]="{ item }">
                <a v-if="Object.keys(item.file).length" :href="`${path_file}${item.file.file_path}`" target="_blank">
                  <v-icon small class="mr-2" color="info"> mdi-file </v-icon>
                </a>
              </template>

              <template v-slot:[`item.cover`]="{ item }">
                <v-list-item-avatar tile class="align-self-center" color="hsl(255, 3%, 18%, 0)">
                  <v-img :src="`${path_file}${item.cover.file_path}`" />
                </v-list-item-avatar>
              </template>

              <template v-slot:[`item.sort`]="{ item }">
                {{ item.sort + 1 }}
              </template>

              <template v-slot:[`item.status`]="{ item }">
                <base-switch
                  v-model="item.status"
                  :height="26"
                  :width="99"
                  :font-size="5"
                  :color="{ checked: '#4CAF50', unchecked: '#FF5252' }"
                  :labels="{ checked: 'ACTIVE', unchecked: 'INACTIVE' }"
                  @change="setLoading(true), onChangeEventHandler(item, $event)"
                />
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-btn class="px-2 ml-1" min-width="0" color="success" @click="editItem(item)" small>
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
                <v-btn class="px-2 ml-1" min-width="0" color="error" @click="deleteItem(item)" small>
                  <v-icon small> mdi-delete </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" min-width="100" class="pl-0" :loading="$store.state.isloading" @click="cancel">
            {{ $t("back") }}
          </v-btn>
        </v-card-actions>
      </v-container>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANTS
 */
import { PATHFILE } from "@/constants/config"

/**
 * CONTROLLER
 */
import {
  putSupportSort,
  getSupportBySupportHeaderID,
  putStatus,
  deleteSupport
} from "@/controllers/support/support.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "SupportList",
  data: () => ({
    supports: [],
    support_header: {},
    path_file: PATHFILE,
    headers: [
      {
        text: "ID",
        align: "start",
        
        value: "support_id"
      },
      { text: "title", value: "title" },
      // { text: "support_header", value: "support_header" },
      { text: "file", value: "file" },
      { text: "cover", value: "cover" },
      { text: "sort", value: "sort" },
      { text: "status", value: "status" },
      // { text: "lang", value: "lang" },
      { text: "Actions", value: "actions", align: "center", width: "15%", sortable: false }
    ],

    /**
     * SORT
     */
    dialogSort: false,
    header_list: [
      { text: "", value: "support_id" },
      { text: "title", value: "title" },
      { text: "created_date", value: "created_date" },
      { text: "status", value: "status" },
      { text: "sort", value: "sort" }
    ],
    dialogDelete: false,
    delete_item: null,
    dragOptions: {
      animation: 200,
      group: "description",
      disabled: false,
      ghostClass: "ghost"
    }
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  computed: {
    filterHeaders() {
      if (this.support_header.type == "VDO") {
        this.headers.splice(
          this.headers.findIndex((header) => header.value == "file"),
          1
        )
      }
      return this.headers
    }
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        // console.log(result);
        const { supports, support_header } = await getSupportBySupportHeaderID({
          support_header_id: this.$route.params.support_header_id
        })
        this.supports = supports
        this.support_header = support_header
        this.setLoading(false)
      } catch (e) {
        this.errorHandle(e)
      }
    },
    createItem() {
      this.$router.push({ path: `/support-create/${this.support_header.support_header_id}` })
    },
    editItem(item) {
      this.$router.push({ path: `/support-detail/${item.support_id}` })
    },

    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    },
    cancel() {
      this.$router.back()
    },

    /**
     * SORT
     */
    sortItem() {
      this.dialogSort = true
    },
    closeSort() {
      this.dialogSort = false
    },
    async saveSort() {
      await putSupportSort({ supports: this.supports }, this.resultHandle, this.errorHandle)
      this.closeSort()
      this.setLoading(false)
    },

    async onChangeEventHandler(item, { value }) {
      if (value) await putStatus({ support: item, status: "ACTIVE" }, this.resultHandle, this.errorHandle)
      else await putStatus({ support: item, status: "INACTIVE" }, this.resultHandle, this.errorHandle)
    },
    deleteItem(item) {
      this.delete_item = item
      this.dialogDelete = true
    },
    closeDelete() {
      this.delete_item = null
      this.dialogDelete = false
    },
    async deleteItemConfirm() {
      await deleteSupport({ support: this.delete_item }, this.resultHandle, this.errorHandle, this.deleteResultHandle)
      this.dialogDelete = false
    },
    deleteResultHandle() {
      this.supports = this.supports.filter((support) => support.support_id !== this.delete_item.support_id)
    }
  }
}
</script>

<style></style>
