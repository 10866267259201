import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    barColor: localStorage.getItem("barColor") || "#EC6921",
    drawer: localStorage.getItem("drawer") || null,
    scrimColor: localStorage.getItem("scrimColor") || "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
    barImage: localStorage.getItem("barImage") || null,
    darkMode: localStorage.getItem("darkMode") || false,
    isloading: false,
    color: "success",
    snackbar: false,
    message: "",
    token: ""
  },
  mutations: {
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload
      localStorage.setItem("barImage", payload)
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload
      localStorage.setItem("drawer", payload)
    },
    SET_DARK_MODE(state, payload) {
      state.darkMode = payload
      localStorage.setItem("darkMode", payload)
    },
    SET_BAR_COLOR(state, payload) {
      state.barColor = payload
      localStorage.setItem("barColor", payload)
    },
    SET_SCRIM(state, payload) {
      state.scrimColor = payload
      localStorage.setItem("scrimColor", payload)
    },
    SET_IS_LOADING(state, payload) {
      state.isloading = payload
    },
    SET_IS_NOTIFICATION(state, payload) {
      state.snackbar = payload.snackbar
      state.color = payload.color
      state.message = payload.message
    },
    SET_TOKEN(state, payload) {
      state.token = payload
    },
    CLEAR_TOKEN(state) {
      state.token = ""
    }
  },
  actions: {}
})
