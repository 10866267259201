const DrawerList = [
  {
    icon: "mdi-home",
    title: "home",
    group: "/home",
    children: [
      {
        // icon: "mdi-dots-horizontal",
        title: "menu",
        to: "menu"
      },
      {
        // icon: "mdi-format-color-fill",
        title: "home_banner",
        to: "home-banner"
      },
      {
        // icon: "mdi-format-color-fill",
        title: "home_product_title",
        to: "home-product"
      },
      {
        // icon: "mdi-format-color-fill",
        title: "home_content",
        to: "home-content"
      },
      // {
      //   // icon: "mdi-brush",
      //   title: "home_highlight",
      //   to: "home-highlight"
      // },
      // {
      //   // icon: "mdi-tools",
      //   title: "home_highlight_list",
      //   to: "home-highlight-list"
      // },
      {
        // icon: "mdi-palette",
        title: "social_media_icon",
        to: "home-social-icon"
      },
      // {
      //   // icon: "mdi-palette",
      //   title: "home_carousel",
      //   to: "home-carousel"
      // },
      {
        // icon: "mdi-tools",
        title: "home_market_place",
        to: "announce"
      }
    ]
  },
  {
    icon: " mdi-domain",
    title: "about_us",
    group: "/about-us",
    children: [
      {
        title: "company",
        to: "company"
      },
      {
        title: "vision_mission_corevalue",
        to: "vision"
      },
      {
        title: "strategy",
        to: "strategy"
      },
      {
        title: "policy_standard",
        to: "policystandard"
      },
      {
        title: "csr",
        to: "csr"
      }
    ]
  },
  {
    icon: "mdi-newspaper",
    title: "news",
    to: "/news"
  },
  {
    icon: "mdi-wall",
    title: "product",
    group: "",
    children: [
      {
        // icon: "mdi-wall",
        title: "product_group",
        to: "product-group"
      },
      {
        // icon: "mdi-tools",
        title: "accessory",
        to: "accessory"
      },
      {
        // icon: "mdi-palette",
        title: "group_color",
        to: "group-color"
      },
      {
        // icon: "mdi-format-color-fill",
        title: "shade_color",
        to: "shade-color"
      },
      {
        // icon: "mdi-brush",
        title: "color",
        to: "color"
      },
      {
        // icon: "mdi-tools",
        title: "usability",
        to: "usability"
      },
      {
        // icon: "mdi-tools",
        title: "order",
        to: "order"
      }
    ]
  },
  {
    icon: "mdi-download",
    title: "support_download",
    group: "",
    children: [
      {
        // icon: "mdi-frequently-asked-questions",
        title: "info_download",
        to: "support-downloads"
      },
      {
        // icon: "mdi-frequently-asked-questions",
        title: "faq",
        to: "faq"
      },
      {
        title: "site_ref",
        to: "site-ref"
      },
      {
        title: "blog",
        to: "blog"
      },
      {
        // icon: "mdi-face-agent",
        title: "support",
        to: "support"
      },
      {
        // icon: "mdi-download",
        title: "download",
        to: "download"
      }
    ]
  },
  {
    icon: "mdi-calculator-variant",
    title: "estimation",
    group: "",
    children: [
      {
        title: "estimation_product_group",
        to: "estimation-product-group"
      },
      {
        title: "estimation_cal",
        to: "estimation-calulate"
      },
      {
        title: "estimation_formula",
        to: "estimation-formula"
      },
      {
        title: "estimation_field",
        to: "estimation-field"
      },
      {
        title: "estimation_shape",
        to: "estimation-shape"
      }
    ]
  },
  {
    icon: "mdi-handshake",
    title: "dealer",
    group: "/dealer",
    children: [
      {
        // icon: "mdi-wall",
        title: "dealer",
        to: "dealers"
      },
      {
        // icon: "mdi-format-color-fill",
        title: "address",
        to: "address"
      }
    ]
  },
  {
    icon: "mdi-account-hard-hat",
    title: "career",
    to: "/career"
  },
  {
    icon: "mdi-cookie",
    title: "cookie",
    to: "/cookie"
  },
  {
    icon: "mdi-contacts",
    title: "contact_us",
    group: "",
    children: [
      {
        title: "branch",
        to: "branch"
      },
      {
        title: "contact",
        to: "contact"
      }
    ]
  },
  {
    icon: "mdi-earth",
    title: "seo",
    to: "/seo"
  },
  {
    icon: "mdi-link",
    title: "ir",
    to: "/ir"
  },
  {
    icon: "mdi-page-layout-header",
    title: "banner",
    to: "/banner"
  },
  {
    icon: "mdi-account-search",
    title: "user_management",
    to: "/usermanagement"
  },
  {
    icon: "mdi-dock-top",
    title: "logo",
    to: "/logo"
  },
  {
    icon: "mdi-file-chart",
    title: "log",
    to: "/log"
  }
]

const DrawerListAgency = [
  {
    icon: "mdi-post",
    title: "blog",
    to: "blog"
  }
]

const DrawerListHR = [
  {
    icon: "mdi-account-hard-hat",
    title: "career",
    to: "/career"
  }
]

const DrawerListStaff = [
  {
    icon: "mdi-handshake",
    title: "dealer",
    to: "/dealer/dealers"
  }
]

const TYPE_SUPPORT_HEADER = [
  { text: "FILE", value: "FILE" },
  { text: "VDO", value: "VDO" }
]

export { DrawerList, DrawerListAgency, DrawerListHR, DrawerListStaff, TYPE_SUPPORT_HEADER }
