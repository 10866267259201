<template>
  <v-container>
    <base-material-card icon="mdi-home" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("home_product_detail") }}</div>
      </template>
      <div class="text-center">
        <base-upload-image v-model="file" :defaultValue="content.file" width="512px" height="555px" class="mb-2" />
        <span class="red--text">*แนะนำขนาดรูปภาพ 512 x 555 px</span>
      </div>
      <v-form @submit.prevent="setLoading(true), save()" ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="text-no-wrap" align="center">
            <v-col cols="12" md="12">
              <v-text-field
                v-model="content.title"
                :label="$t('title')"
                :rules="[rules.required]"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-textarea
                v-model="content.content"
                :label="$t('content')"
                :rules="[rules.required]"
                outlined
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field v-model="content.link" :label="$t('link')" outlined></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-select
                v-model="content.lang"
                :items="languages"
                item-text="language"
                item-value="lang_code"
                :label="$t('language')"
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" min-width="100" class="pl-0" :loading="$store.state.isloading" @click="cancel">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"
import { PATHFILE } from "@/constants/config"

/**
 * FUNCTIONS
 */
import { putHomeProductService, getHomeProductServiceById } from "@/controllers/home/home_product_service.controller"

/**
 * LIBs
 */
import { mapMutations } from "vuex"

/**
 * UTILS
 */
import { rules } from "@/utils/validates"

export default {
  data: () => ({
    rules: rules,
    content: {
      title: "",
      content: "",
      link: "",
      lang: "TH"
    },
    file: null,
    pathFile: PATHFILE,
    languages: LANGUAGES,
    status: STATUS
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.content = await getHomeProductServiceById({ id: this.$route.params.id })
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async save() {
      await putHomeProductService(
        {
          content: this.content,
          file: this.file
        },
        this.resultHandle,
        this.errorHandle
      )
    },
    cancel() {
      this.setLoading(true)
      this.$router.back()
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
      this.$router.back()
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    },
    onTabChange(e) {
      this.initialize(e)
    }
  }
}
</script>

<style scoped>
.carousel-icon-close {
  margin: auto !important;
  left: 0 !important;
  right: 0 !important;
  /* bottom: 0 !important; */
  top: 0 !important;
}
</style>
