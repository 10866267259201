/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
; ("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_DOWNLOAD_HEADER } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut, axiosPost, axiosDelete } from "@/utils/axios"

export async function getDownloadHeaderAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_DOWNLOAD_HEADER.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    return error
  }
}

export async function getDownloadHeaderByID({ download_header_id }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_DOWNLOAD_HEADER.FINDBYID}/${download_header_id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getDownloadHeaderByLang({ lang }, errorHandle) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_DOWNLOAD_HEADER.FINDBYLANG}/${lang}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function postDownloadHeader({ download_header }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = {
      title: download_header.title,
      type: download_header.type,
      filter: download_header.filter,
      lang: download_header.lang
    }
    const url = `${API}${EP_DOWNLOAD_HEADER.CREATE}`

    const { code, data, message } = await axiosPost(url, payload, token)

    if (code == 201) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(error)
  }
}

export async function putDownloadHeader({ download_header }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = {
      title: download_header.title,
      lang: download_header.lang,
      type: download_header.type,
      filter: download_header.filter,
      status: download_header.status
    }
    const url = `${API}${EP_DOWNLOAD_HEADER.UPDATE}/${download_header.download_header_id}`

    const { code, data, message } = await axiosPut(url, payload, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putDownloadHeaderSort({ download_headers }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = download_headers
    const url = `${API}${EP_DOWNLOAD_HEADER.UPDATE_SORT}`

    const { code, message, data } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putStatus({ download_header, status }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    const payload = { status: status }
    const url = `${API}${EP_DOWNLOAD_HEADER.UPDATE_STATUS}/${download_header.download_header_id}`

    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function deleteDownloadHeader({ download_header }, resultHandle, errorHandle, deleteResultHandle) {
  try {
    const token = await getAuthToken()

    const url = `${API}${EP_DOWNLOAD_HEADER.DELETE}/${download_header.download_header_id}`

    const { code, data, message } = await axiosDelete(url, token)

    if (code == 200) {
      deleteResultHandle()
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}
