/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_PRODUCT_CATEGORY_VDO } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPost, axiosPatch } from "@/utils/axios"

export async function getProductCategoryVdoAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_PRODUCT_CATEGORY_VDO.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getProductCategoryVdoById({ cate_id, id }) {
  try {
    const token = await getAuthToken()
    const url = API + EP_PRODUCT_CATEGORY_VDO.FINDBYID.replace(":id", cate_id).replace(":vdo_id", id)
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function postProductCategoryVdo({ cate_id, video }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const url = API + EP_PRODUCT_CATEGORY_VDO.CREATE.replace(":id", cate_id)
    const { code, data, message } = await axiosPost(url, video, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    errorHandle(message)
    return message
  }
}

export async function putProductCategoryVdo({ cate_id, id, video }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const url = API + EP_PRODUCT_CATEGORY_VDO.UPDATE.replace(":id", cate_id).replace(":vdo_id", id)
    const params = {
      title: video.title,
      link: video.link,
      status: video.status
    }
    const { code, data, message } = await axiosPatch(url, params, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    errorHandle(message)
    return message
  }
}

export async function putProductCategoryVdoStatus({ cate_id, id, video }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const url = API + EP_PRODUCT_CATEGORY_VDO.UPDATE_STATUS.replace(":id", cate_id).replace(":vdo_id", id)
    const params = {
      status: video.status
    }
    const { code, data, message } = await axiosPatch(url, params, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    errorHandle(message)
    return message
  }
}

export async function putProductCategoryVdoSort({ cate_id, vdo }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = vdo.map((item) => {
      return {
        product_category_vdo_id: item.product_category_vdo_id,
        sort: item.sort
      }
    })
    const url = `${API}${EP_PRODUCT_CATEGORY_VDO.UPDATE_SORT.replace(":id", cate_id)}`
    const { code, message, data } = await axiosPatch(url, payload, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function deleteProductCategoryVdo({ cate_id, id }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const url = API + EP_PRODUCT_CATEGORY_VDO.UPDATE_STATUS.replace(":id", cate_id).replace(":vdo_id", id)
    const params = {
      status: "DELETED"
    }
    const { code, data, message } = await axiosPatch(url, params, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    errorHandle(message)
    return message
  }
}
