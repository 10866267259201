/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
"use strict"

/**
 * Module dependencies.
 */

/**
 * CONSTANTS
 */
import { API } from "@/constants/config"
import { EP_ACTIVITY } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut, axiosCreateFile, axiosUpdateFile, axiosDelete } from "@/utils/axios"

export async function getActivityAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_ACTIVITY.FINDALL
    const { code, data } = await axiosGet(url, token)
    if (code == 200) return data
  } catch (error) {
    const { code, message } = error.response.data
    return errorHandle(message)
  }
}

export async function getActivityByID({ activity_id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_ACTIVITY.FINDBYID}/${activity_id}`
    const { code, data } = await axiosGet(url, token)
    if (code == 200) return data
  } catch (error) {
    const { code, message } = error.response.data
    return errorHandle(message)
  }
}

export async function getActivityByLang({ lang }, errorHandle) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_ACTIVITY.FINDBYLANG}/${lang}`
    const { code, data } = await axiosGet(url, token)
    if (code == 200) return data
  } catch (error) {
    return errorHandle(error)
  }
}

export async function postActivity({ activity, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    let formData = new FormData()
    formData.append("activity", activity.activity)
    formData.append("status", activity.status)
    formData.append("lang", activity.lang)
    if (file) formData.append("file", file, file.name)

    const url = `${API}${EP_ACTIVITY.CREATE}`
    const { code, data, message } = await axiosCreateFile(url, formData, token)
    if (code == 201) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    return errorHandle(error)
  }
}

export async function putActivity({ activity, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    let formData = new FormData()
    formData.append("activity", activity.activity)
    formData.append("status", activity.status)
    formData.append("lang", activity.lang)
    if (file) formData.append("file", file, file.name)

    const url = `${API}${EP_ACTIVITY.UPDATE}/${activity.activity_id}`
    const { code, data, message } = await axiosUpdateFile(url, formData, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    return errorHandle(error)
  }
}

export async function putActivitySort({ activity }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = activity
    const url = `${API}${EP_ACTIVITY.UPDATE_SORT}`
    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    return errorHandle(error)
  }
}

export async function putActivityStatus({ activity, status }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    const payload = { status: status }
    const url = `${API}${EP_ACTIVITY.UPDATE_STATUS}/${activity.activity_id}`

    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    return errorHandle(error)
  }
}

export async function deleteActivity({ activity, delete_index }, resultHandle, errorHandle, deleteResultHandle) {
  try {
    const token = await getAuthToken()

    const url = `${API}${EP_ACTIVITY.DELETE}/${activity.activity_id}`

    const { code, data, message } = await axiosDelete(url, token)

    if (code == 200) {
      deleteResultHandle({ activity, delete_index })
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    return errorHandle(error)
  }
}
