/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API } from "@/constants/config"
import { EP_SITE_REF } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut, axiosCreateFile, axiosUpdateFile, axiosDelete } from "../../utils/axios"

export async function getSiteRefAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_SITE_REF.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getSiteRefByID({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_SITE_REF.FINDBYID}/${id}`
    const { code, data, message } = await axiosGet(url, token)
    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getSiteRefByLang({ lang }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_SITE_REF.FINDBYLANG}/${lang}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function getBySiteRefProductCategory({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_SITE_REF.FINDBY_PD_CATEGORY}/${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function postSiteRef({ siteRef, files }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_SITE_REF.CREATE}`
    let formData = new FormData()
    formData.append("product_group_id", siteRef.product_group_id)
    formData.append("product_category_id", siteRef.product_category_id)
    formData.append("shade_color_id", siteRef.shade_color_id)
    formData.append("color_id", siteRef.color_id)
    // formData.append("lang", siteRef.lang)

    if (files && files.length) {
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i], files[i].name)
      }
    }

    const { code, data, message } = await axiosCreateFile(url, formData, token)

    if (code == 201) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putSiteRef({ siteRef, files, delete_files }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_SITE_REF.UPDATE}/${siteRef.site_ref_id}`
    let formData = new FormData()
    formData.append("site_ref_id", siteRef.site_ref_id)
    formData.append("product_group_id", siteRef.product_group_id)
    formData.append("product_category_id", siteRef.product_category_id)
    formData.append("shade_color_id", siteRef.shade_color_id)
    formData.append("color_id", siteRef.color_id)
    formData.append("lang", siteRef.lang)
    formData.append("status", siteRef.status)

    if (delete_files && delete_files.length) formData.append("delete_files", JSON.stringify(delete_files))

    if (files && files.length) {
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i], files[i].name)
      }
    }

    const { code, data, message } = await axiosUpdateFile(url, formData, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putSiteRefSort({ siteRefs }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = siteRefs
    const url = `${API}${EP_SITE_REF.UPDATE_SORT}`

    const { code, message, data } = await axiosPut(url, payload, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putStatus({ site_ref, status }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    const payload = { status: status }
    const url = `${API}${EP_SITE_REF.UPDATE_STATUS}/${site_ref.site_ref_id}`

    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function deleteSiteRef({ site_ref }, resultHandle, errorHandle, deleteResultHandle) {
  try {
    const token = await getAuthToken()

    const url = `${API}${EP_SITE_REF.DELETE}/${site_ref.site_ref_id}`

    const { code, data, message } = await axiosDelete(url, token)

    if (code == 200) {
      deleteResultHandle()
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}
