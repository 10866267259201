/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_FAQ } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPost, axiosPut, axiosDelete } from "@/utils/axios"

export async function getFaqAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_FAQ.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getFaqByID({ faq_id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_FAQ.FINDBYID}/${faq_id}`
    const { code, data, message } = await axiosGet(url, token)
    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getFaqByLang({ lang }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_FAQ.FINDBYLANG}/${lang}`
    const { code, data, message } = await axiosGet(url, token)
    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function postFaq({ faq }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = {
      answer: faq.answer,
      product_category_id: faq.product_category_id,
      question: faq.question,
      lang: faq.lang
    }
    console.log(payload)
    const url = `${API}${EP_FAQ.CREATE}`

    const { code, data, message } = await axiosPost(url, payload, token)
    if (code == 201) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putFaq({ faq }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = {
      answer: faq.answer,
      lang: faq.lang,
      product_category_id: faq.product_category_id,
      question: faq.question,
      status: faq.status
    }

    const url = `${API}${EP_FAQ.UPDATE}/${faq.faq_id}`

    const { code, data, message } = await axiosPut(url, payload, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putFaqSort({ faq }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = faq
    const url = `${API}${EP_FAQ.UPDATE_SORT}`

    const { code, message, data } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putStatus({ faq, status }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    const payload = { status: status }
    const url = `${API}${EP_FAQ.UPDATE_STATUS}/${faq.faq_id}`

    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function deleteFaq({ faq }, resultHandle, errorHandle, deleteResultHandle) {
  try {
    const token = await getAuthToken()

    const url = `${API}${EP_FAQ.DELETE}/${faq.faq_id}`

    const { code, data, message } = await axiosDelete(url, token)

    if (code == 200) {
      deleteResultHandle()
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}
