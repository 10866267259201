<template>
  <v-container>
    <base-material-card icon="mdi-youtube" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("vdo") }}</div>
      </template>

      <v-container fluid>
        <v-row class="text-no-wrap" align="center">
          <v-col>
            <v-data-table :headers="headers" :items="vdos" class="elevation-1">
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>{{ $t("vdo") }}</v-toolbar-title>

                  <v-spacer></v-spacer>
                  <v-btn color="primary" dark class="mx-2" @click="sortItem">
                    {{ $t("vdo_sort") }}
                  </v-btn>
                  <v-btn color="primary" dark class="mx-2" @click="createItem">
                    {{ $t("vdo_create") }}
                  </v-btn>
                  <v-dialog v-model="dialogSort" max-width="900px">
                    <v-form @submit.prevent="saveSort">
                      <v-card>
                        <v-card-title class="headline">{{ $t("vdo_sort") }}</v-card-title>
                        <v-card-text>
                          <v-container>
                            <base-dnd-table :headers="header_sort" :items.sync="vdos" :dragOptions="dragOptions" order>
                              <tr v-for="item in vdos" :key="item.vdo_id">
                                <td scope="row" style="cursor: all-scroll">
                                  <v-icon>mdi-format-align-justify</v-icon>
                                </td>
                                <td scope="row">{{ item.link }}</td>
                                <td>{{ item.sort + 1 }}</td>
                              </tr>
                            </base-dnd-table>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" dark @click="closeSort">{{ $t("cancel") }}</v-btn>
                          <v-btn type="submit" color="primary" dark>{{ $t("save") }}</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-form>
                  </v-dialog>
                </v-toolbar>
              </template>

              <template v-slot:[`item.link`]="{ item }">
                <a v-if="item.link" target="_blank" :href="item.link">{{ item.link }}</a>
              </template>

              <template v-slot:[`item.sort`]="{ item }">
                {{ item.sort + 1 }}
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-btn class="px-2 ml-1" min-width="0" color="success" @click="editItem(item)" small>
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANTS
 */
import { STATUS } from "@/constants/status"
import { LANGUAGES } from "@/constants/language"

/**
 * CONTROLLER
 */
import { getVdoAll, putVdoSort } from "@/controllers/vdo/vdo.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "Vdo",
  data: () => ({
    vdos: [],
    dialogSort: false,
    status: STATUS,
    languages: LANGUAGES,
    headers: [
      {
        text: "ID",
        align: "start",
        
        value: "vdo_id"
      },
      { text: "link", value: "link" },
      { text: "sort", value: "sort" },
      { text: "status", value: "status" },
      { text: "lang", value: "lang" },
      { text: "Actions", value: "actions", align: "center", width: "15%", sortable: false }
    ],
    header_sort: [
      { text: "ID", value: "vdo_id" },
      { text: "link", value: "link" },
      { text: "sort", value: "sort" }
    ],
    dragOptions: {
      animation: 200,
      group: "description",
      disabled: false,
      ghostClass: "ghost"
    }
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.vdos = await getVdoAll()
        this.setLoading(false)
      } catch (e) {
        this.errorHandle(e)
      }
    },
    sortItem() {
      this.dialogSort = true
    },
    closeSort() {
      this.dialogSort = false
    },
    editItem(item) {
      this.$router.push({ path: `vdo-detail/${item.vdo_id}` })
    },
    createItem() {
      this.$router.push({ path: `vdo-create` })
    },
    async saveSort() {
      await putVdoSort({ vdos: this.vdos }, this.resultHandle, this.errorHandle)
      this.dialogSort = false
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
