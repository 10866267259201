/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
; ("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_ESTIMATION_PRODUCT_CATEGORY } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut, axiosCreateFile, axiosPost, axiosUpdateFile } from "@/utils/axios"

export async function getEstimationProductCategoryAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_ESTIMATION_PRODUCT_CATEGORY.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getEstimationProductCategoryById({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_ESTIMATION_PRODUCT_CATEGORY.FINDBYID}/${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getByIdFilterLang({ id, lang = "th" }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_ESTIMATION_PRODUCT_CATEGORY.FINDBYID_FILTER}?lang=${lang}&product_category_id=${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getByEstimationProductGroupFilterLang({ id, lang = "th" }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_ESTIMATION_PRODUCT_CATEGORY.FINDBY_ESTIMATION_PRODUCT_GROUP_FILTER}?lang=${lang}&product_category_id=${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getEstimationProductCategoryByLang({ lang }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_ESTIMATION_PRODUCT_CATEGORY.FINDBYLANG}/${lang}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function getByShadeEstimationProductCategory({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_ESTIMATION_PRODUCT_CATEGORY.FINDBY_SHADE}/${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function getByEstimationProductGroup({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_ESTIMATION_PRODUCT_CATEGORY.FINDBY_ESTIMATION_PRODUCT_GROUP}/${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function getByEstimationProductGroupFilter({ id, lang = "th" }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_ESTIMATION_PRODUCT_CATEGORY.FINDBY_ESTIMATION_PRODUCT_GROUP_FILTER}?lang=${lang}&product_group_id=${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function getEstimationProductCategoryDetail({ id, lang = "th" }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_ESTIMATION_PRODUCT_CATEGORY.FINDDETAIL}?lang=${lang}&product_category_id=${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function postEstimationProductCategory({ estimationProductCategory, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_ESTIMATION_PRODUCT_CATEGORY.CREATE}`
    let formData = new FormData()
    formData.append("estimation_product_group_id", estimationProductCategory.estimation_product_group_id)
    formData.append("title", estimationProductCategory.title)
    formData.append("title_en", estimationProductCategory.title_en)
    formData.append("color_id", JSON.stringify(estimationProductCategory.color_id))
    formData.append("shape_id", JSON.stringify(estimationProductCategory.shape_id))
    formData.append("lang", estimationProductCategory.lang)

    if (file) formData.append("file", file, file.name)

    const { code, data, message } = await axiosCreateFile(url, formData, token)

    if (code == 201) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putEstimationProductCategory({ estimationProductCategory, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_ESTIMATION_PRODUCT_CATEGORY.UPDATE}/${estimationProductCategory.estimation_product_category_id}`
    let formData = new FormData()
    formData.append("estimation_product_group_id", estimationProductCategory.estimation_product_group_id)
    formData.append("title", estimationProductCategory.title)
    formData.append("title_en", estimationProductCategory.title_en)
    formData.append("color_id", JSON.stringify(estimationProductCategory.color_id))
    formData.append("shape_id", JSON.stringify(estimationProductCategory.shape_id))
    formData.append("lang", estimationProductCategory.lang)
    formData.append("status", estimationProductCategory.status)

    if (file) formData.append("file", file, file.name)

    const { code, data, message } = await axiosUpdateFile(url, formData, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putEstimationProductCategorySort({ estimationProductCategorys }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = estimationProductCategorys
    const url = `${API}${EP_ESTIMATION_PRODUCT_CATEGORY.UPDATE_SORT}`

    const { code, message, data } = await axiosPut(url, payload, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}
