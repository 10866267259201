/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
; ("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_ESTIMATION_FORMULA, EP_ESTIMATION_FORMULA_RES } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut } from "@/utils/axios"

export async function getEstimationFormulaAll() {
    try {
        const token = await getAuthToken()
        const url = `${API}${EP_ESTIMATION_FORMULA.FINDALL}`

        const { code, data, message } = await axiosGet(url, token)

        if (code == 200) {
            return data
        }
        return data
    } catch (error) {
        console.log(error)
    }
}

export async function getFormulaResByFormulaId({ id }) {
    try {
        const token = await getAuthToken()
        const url = `${API}${EP_ESTIMATION_FORMULA_RES.FINDBY_FORMULA_ID}/${id}`
        const { code, data, message } = await axiosGet(url, token)

        if (code == 200) {
            return data
        }
        return data
    } catch (error) {
        console.log(error)
    }
}

export async function getFormulaResById({ id }) {
    try {
        const token = await getAuthToken()
        const url = `${API}${EP_ESTIMATION_FORMULA_RES.FINDBYID}/${id}`
        const { code, data, message } = await axiosGet(url, token)

        if (code == 200) {
            return data
        }
        return data
    } catch (error) {
        console.log(error)
    }
}

export async function putEstimationFormula({ formula }, resultHandle, errorHandle) {
    try {
        const token = await getAuthToken()
        const payload = formula
        const url = `${API}${EP_ESTIMATION_FORMULA_RES.UPDATE}/${formula.estimation_formula_res_id}`

        const { code, data, message } = await axiosPut(url, payload, token)

        if (code == 200) {
            resultHandle(message)
            return data
        }
        return data
    } catch (error) {
        console.log(error)
        errorHandle(message)
    }
}
