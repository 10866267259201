/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_SUPPORT_HEADER } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPost, axiosPut, axiosDelete } from "@/utils/axios"

export async function getSupportHeaderAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_SUPPORT_HEADER.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getSupportHeaderByID({ support_header_id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_SUPPORT_HEADER.FINDBYID}/${support_header_id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getSupportHeaderByLang({ lang }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_SUPPORT_HEADER.FINDBYLANG}/${lang}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function postSupportHeader({ support_header }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = {
      title: support_header.title,
      type: support_header.type,
      filter: support_header.filter,
      lang: support_header.lang
    }
    const url = `${API}${EP_SUPPORT_HEADER.CREATE}`

    const { code, data, message } = await axiosPost(url, payload, token)

    if (code == 201) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putSupportHeader({ support_header }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = {
      title: support_header.title,
      lang: support_header.lang,
      type: support_header.type,
      filter: support_header.filter,
      status: support_header.status
    }
    const url = `${API}${EP_SUPPORT_HEADER.UPDATE}/${support_header.support_header_id}`

    const { code, data, message } = await axiosPut(url, payload, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putSupportHeaderSort({ support_headers }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = support_headers
    const url = `${API}${EP_SUPPORT_HEADER.UPDATE_SORT}`

    const { code, message, data } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putStatus({ support_header, status }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    const payload = { status: status }
    const url = `${API}${EP_SUPPORT_HEADER.UPDATE_STATUS}/${support_header.support_header_id}`

    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function deleteSupportHeader({ support_header }, resultHandle, errorHandle, deleteResultHandle) {
  try {
    const token = await getAuthToken()

    const url = `${API}${EP_SUPPORT_HEADER.DELETE}/${support_header.support_header_id}`

    const { code, data, message } = await axiosDelete(url, token)

    if (code == 200) {
      deleteResultHandle()
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}
