/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_BANNER } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosCreateFile, axiosUpdateFile } from "@/utils/axios"

export async function getBannerAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_BANNER.FINDALL

    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getBannerByPage({ page, lang }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_BANNER.FINDBYPAGE}/${page}/${lang}`
    const { code, data, message } = await axiosGet(url, token)
    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getBannerByID({ banner_id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_BANNER.FINDBYID}/${banner_id}`
    const { code, data, message } = await axiosGet(url, token)
    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function postBanner({ banner, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    let formData = new FormData()
    formData.append("page", banner.page)
    if (file) formData.append("file", file, file.name)

    const url = `${API}${EP_BANNER.CREATE}`
    const { code, data, message } = await axiosCreateFile(url, formData, token)
    if (code == 201) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(error)
  }
}

export async function putBanner({ banner, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    let formData = new FormData()
    formData.append("page", banner.page)
    formData.append("status", banner.status)
    if (file) formData.append("file", file, file.name)

    const url = `${API}${EP_BANNER.UPDATE}/${banner.banner_id}`
    const { code, data, message } = await axiosUpdateFile(url, formData, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(error)
  }
}
