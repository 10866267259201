;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_BLOG } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut, axiosPost, axiosUpdateFile, axiosCreateFile, axiosDelete } from "@/utils/axios"

export async function getBlogAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_BLOG.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getBlogByID({ blog_id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_BLOG.FINDBYID}/${blog_id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getBlogByLang({ lang, status, search }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_BLOG.FINDBYLANG}/${lang}${status ? `?status=${status}` : ""} ${search ? `${status ? '&search=' + search : '?search=' + search}` : ''}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function postBlog({ blog, cover }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    let formData = new FormData()
    formData.append("title", blog.title)
    formData.append("blog", blog.blog)
    formData.append("created_date", blog.created_date)
    formData.append("lang", blog.lang)
    if (cover) formData.append("cover_blog", cover, cover.name)

    const url = `${API}${EP_BLOG.CREATE}`

    const { code, data, message } = await axiosCreateFile(url, formData, token)

    if (code == 201) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    // const { message } = error.response.data
    console.log(error)
    return errorHandle(error)
  }
}

export async function putBlogSort({ blogs }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = blogs
    const url = `${API}${EP_BLOG.UPDATE_SORT}`

    const { code, message, data } = await axiosPut(url, payload, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putBlog({ blog, cover }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    let formData = new FormData()
    formData.append("title", blog.title)
    formData.append("blog", blog.blog)
    formData.append("created_date", blog.created_date)
    formData.append("lang", blog.lang)
    formData.append("status", blog.status)
    if (cover) formData.append("cover_blog", cover, cover.name)

    const url = `${API}${EP_BLOG.UPDATE}/${blog.blog_id}`

    const { code, data, message } = await axiosUpdateFile(url, formData, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    // const { message } = error.response.data
    console.log(error)
    return errorHandle(error)
  }
}

export async function putStatus({ blog, status }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    const payload = { status: status }
    const url = `${API}${EP_BLOG.UPDATE_STATUS}/${blog.blog_id}`

    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function deleteBlog({ blog }, resultHandle, errorHandle, deleteResultHandle) {
  try {
    const token = await getAuthToken()

    const url = `${API}${EP_BLOG.DELETE}/${blog.blog_id}`

    const { code, data, message } = await axiosDelete(url, token)

    if (code == 200) {
      deleteResultHandle()
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}
