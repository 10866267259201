var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('base-material-card',{attrs:{"icon":"mdi-calculator-variant","color":"primary"},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"font-weight-light card-title mt-2"},[_vm._v(_vm._s(_vm.$t("estimation_formula_res")))])]},proxy:true}])},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"text-no-wrap",attrs:{"align":"center"}},[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.formulas},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer')],1)]},proxy:true},{key:"item.created_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_date))+" ")]}},{key:"item.file",fn:function(ref){
var item = ref.item;
return [(Object.keys(item.file).length)?_c('a',{attrs:{"href":("" + _vm.path_file + (item.file.file_path)),"target":"_blank"}},[_c('v-icon',{attrs:{"color":"info"}},[_vm._v("mdi-file-download")])],1):_vm._e()]}},{key:"item.cover",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"align-self-center",attrs:{"tile":"","color":"hsl(255, 3%, 18%,0)"}},[(Object.keys(item.cover).length)?_c('v-img',{attrs:{"src":("" + _vm.path_file + (item.cover.file_path))}}):_vm._e()],1)]}},{key:"item.sort",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.sort + 1)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"px-2 ml-1",attrs:{"min-width":"0","color":"success","small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),(!item.value)?_c('v-btn',{staticClass:"px-2 ml-1",attrs:{"min-width":"0","color":"warning","small":""},on:{"click":function($event){return _vm.infoItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1):_vm._e()]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"pl-0",attrs:{"color":"primary","min-width":"100","loading":_vm.$store.state.isloading},on:{"click":function($event){_vm.setLoading(true), _vm.cancel()}}},[_vm._v(" "+_vm._s(_vm.$t("back"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }