const EP_USERS = {
  LOGIN: `/login`,
  REGISTER: `/register`,
  CREATE: `/user`,
  FINDALL: `/user`,
  FINDBYID: `/user`,
  UPDATE: `/user`,
  UPDATE_STATUS: `/user-status/admin`,
  FINDBYTOKEN: `/user`,
  FINDBYID: `/user-by-id/admin`,
  FINDROLE: `/role/admin`,
  DELETE: `/user`
}

const EP_COMPANY = {
  FINDALL: `/company/admin`,
  UPDATE: `/company/admin`
}

const EP_HISTORY = {
  FINDALL: `/history/admin`,
  UPDATE: `/history/admin`
}

const EP_LOCATION = {
  FINDALL: `/location/admin`,
  UPDATE: `/location/admin`
}

const EP_VISION = {
  FINDALL: `/vision/admin`,
  UPDATE: `/vision/admin`
}

const EP_MISSION = {
  FINDALL: `/mission/admin`,
  UPDATE: `/mission/admin`
}

const EP_CORE_VALUE = {
  FINDALL: `/core-value/admin`,
  UPDATE: `/core-value/admin`
}

const EP_STRATEGY = {
  FINDALL: `/strategy/admin`,
  UPDATE: `/strategy/admin`
}

const EP_CSR = {
  FINDALL: `/csr/admin`,
  UPDATE: `/csr/admin`
}

const EP_POLICY = {
  FINDALL: `/policy/admin`,
  UPDATE: `/policy/admin`,
  UPDATE_SORT: `/policy/sort/admin`
}

const EP_STANDARD = {
  FINDALL: `/standard/admin`,
  FINDBYID: `/standard/admin`,
  UPDATE: `/standard/admin`
}

const EP_STANDARD_CATEGORY = {
  FINDALL: `/standard-category/admin`,
  FINDBYID: `/standard-category/admin`,
  CREATE: `/standard-category/admin`,
  UPDATE: `/standard-category/admin`,
  UPDATE_STATUS: `/standard-category-status/admin`,
  UPDATE_SORT: `/standard-category/sort/admin`,
  DELETE: `/standard-category/admin`
}

const EP_CAREER_BANNER = {
  FINDALL: `/career-banner/admin`,
  UPDATE: `/career-banner/admin`
}

const EP_CAREER_DESCRIPTION = {
  FINDALL: `/career-description/admin`,
  UPDATE: `/career-description/admin`
}

const EP_CAREER_EXECUTIVE = {
  FINDALL: `/career-executive/admin`,
  FINDBYID: `/career-executive/admin`,
  FINDBYLANG: `/career-executive-by-lang/admin`,
  CREATE: `/career-executive/admin`,
  UPDATE: `/career-executive/admin`,
  UPDATE_SORT: `/career-executive/admin/sort`,
  UPDATE_STATUS: `/career-executive-status/admin`,
  DELETE: `/career-executive/admin`
}

const EP_CAREER_FOOTER = {
  FINDALL: `/career-footer/admin`,
  UPDATE: `/career-footer/admin`
}

const EP_CAREER = {
  FINDALL: `/career/admin`,
  FINDBYID: `/career/admin`,
  FINDBYLANG: `/career-by-lang/admin`,
  CREATE: `/career/admin`,
  UPDATE: `/career/admin`,
  UPDATE_STATUS: `/career-status/admin`,
  UPDATE_SORT: `/career/admin/sort`,
  DELETE: `/career/admin`
}

const EP_APPLY_JOB = {
  FINDALL: `/apply-job/admin`,
  DELETE: `/apply-job/admin`
}

const EP_CONTACT = {
  FINDALL: `/contact/admin`,
  FINDBYID: `/contact/admin`,
  FINDBYLANG: `/contact-by-lang/admin`,
  UPDATE: `/contact/admin`,
  DELETE: `/contact/admin`
}

const EP_BRANCH = {
  FINDALL: `/branch/admin`,
  FINDBYLANG: `/branch-by-lang/admin`,
  FINDBYID: `/branch/admin`,
  CREATE: `/branch/admin`,
  UPDATE: `/branch/admin`,
  UPDATE_STATUS: `/branch-status/admin`,
  DELETE: `/branch/admin`
}

const EP_COOKIE = {
  FINDALL: `/cookie-policy/admin`,
  UPDATE: `/cookie-policy/admin`
}

const EP_NEWS = {
  FINDALL: `/news/admin`,
  FINDBYID: `/news/admin`,
  FINDBYLANG: `/news-by-lang/admin`,
  CREATE: `/news/admin`,
  UPDATE: `/news/admin`,
  UPDATE_SORT: `/news/sort/admin`,
  UPDATE_STATUS: `/news-status/admin`,
  DELETE: `/news/admin`
}

const EP_DEALER = {
  FINDALL: `/dealer/admin`,
  FINDBYID: `/dealer/admin`,
  FINDBYLANG: `/dealer-by-lang/admin`,
  FINDCOUNTRY: `/dealer-country/admin`,
  FINDPROVINCE: `/dealer-province/admin`,
  FINDDISTRICT: `/dealer-district/admin`,
  FINDSUBDISTRICT: `/dealer-sub-district/admin`,
  CREATE: `/dealer/admin`,
  UPDATE_STATUS: `/dealer-status/admin`,
  UPDATE: `/dealer/admin`,
  DELETE: `/dealer/admin`
}

const EP_SUPPORT = {
  FINDALL: `/support/admin`,
  FINDBYID: `/support/admin`,
  FINDBYSUPPORTHEADER: `/support/admin/support_header`,
  CREATE: `/support/admin`,
  UPDATE: `/support/admin`,
  UPDATE_STATUS: `/support-status/admin`,
  UPDATE_SORT: `/support/sort/admin`,
  DELETE: `/support/admin`
}

const EP_SUPPORT_HEADER = {
  FINDALL: `/support-header/admin`,
  FINDBYID: `/support-header/admin`,
  FINDBYLANG: `/support-header-by-lang/admin`,
  CREATE: `/support-header/admin`,
  UPDATE: `/support-header/admin`,
  UPDATE_STATUS: `/support-header-status/admin`,
  UPDATE_SORT: `/support-header/sort/admin`,
  DELETE: `/support-header/admin`
}

const EP_DOWNLOAD = {
  FINDALL: `/download/admin`,
  FINDBYID: `/download/admin`,
  FINDBYDOWNLOADHEADER: `/download/admin/download-header`,
  CREATE: `/download/admin`,
  UPDATE: `/download/admin`,
  UPDATE_STATUS: `/download-status/admin`,
  UPDATE_SORT: `/download/sort/admin`,
  DELETE: `/download/admin`
}

const EP_DOWNLOAD_HEADER = {
  FINDALL: `/download-header/admin`,
  FINDBYID: `/download-header/admin`,
  FINDBYLANG: `/download-header-by-lang/admin`,
  CREATE: `/download-header/admin`,
  UPDATE: `/download-header/admin`,
  UPDATE_STATUS: `/download-header-status/admin`,
  UPDATE_SORT: `/download-header/sort/admin`,
  DELETE: `/download-header/admin`
}

const EP_FAQ = {
  FINDALL: `/faq/admin`,
  FINDBYID: `/faq/admin`,
  FINDBYLANG: `/faq-by-lang/admin`,
  CREATE: `/faq/admin`,
  UPDATE: `/faq/admin`,
  UPDATE_STATUS: `/faq-status/admin`,
  UPDATE_SORT: `/faq/sort/admin`,
  DELETE: `/faq/admin`
}

const EP_INFO_DOWNLOAD = {
  FINDALL: `/info-downloads/admin`,
  FINDBYID: `/info-downloads/admin`,
  FINDBYLANG: `/info-downloads/admin`,
  CREATE: `/info-downloads/admin`,
  UPDATE: `/info-downloads/admin`,
  UPDATE_SORT: `/info-downloads/admin/sort`,
  UPDATE_STATUS: `/info-downloads/admin/:id/status`,
  DELETE: `/info-downloads/admin`
}

const EP_PRODUCT_GROUP = {
  FINDALL: `/product-group/admin`,
  FINDBYID: `/product-group/admin`,
  FINDBYLANG: `/product-group-by-lang/admin`,
  CREATE: `/product-group/admin`,
  UPDATE: `/product-group/admin`,
  DELETE: `/product-group/admin`,
  UPDATE_STATUS: `/product-group-status/admin`,
  UPDATE_SORT: `/product-group/sort/admin`,
  FOR_SELECT: `/product-group-for-select/admin`,
  DELETE: `/product-group/admin`
}

const EP_PRODUCT_GROUP_DIMENSION = {
  FINDALL: `/product-group-dimensions/admin/product-group/:product_group_id?sort=YES`,
  FINDBYID: `/product-group-dimensions/admin/:product_group_dimension_id`,
  CREATE: `/product-group-dimensions/admin`,
  UPDATE: `/product-group-dimensions/admin/:product_group_dimension_id`,
  UPDATE_SORT: `/product-group-dimensions/admin/product-group/:product_group_id/sort`,
  UPDATE_STATUS: `/product-group-dimensions/admin/:product_group_dimension_id/status`
}

const EP_PRODUCT_CATEGORY = {
  FINDALL: `/product-category/admin`,
  FINDBYID: `/product-category/admin`,
  FINDBYLANG: `/product-category-by-lang/admin`,
  FIND_BY_PRODUCT_GROUP: `/product-category-by-product-group/admin`,
  FIND_FOR_SELECT: `/product-category-for-select/admin`,
  CREATE: `/product-category/admin`,
  UPDATE: `/product-category/admin`,
  UPDATE_STATUS: `/product-category-status/admin`,
  DELETE: `/product-category/admin`,
  UPDATE_SORT: `/product-category/sort/admin`,
  DELETE: `/product-category/admin`
}

const EP_PRODUCT_CATEGORY_DIMENSION = {
  FINDALL: `/product-category-dimensions/admin/:product_category_id`,
  CREATE: `/product-category-dimensions/admin`,
  UPDATE: `/product-category-dimensions/admin`
}

const EP_PRODUCT_CATEGORY_VDO = {
  FINDALL: `/product-category/admin`,
  FINDBYID: `/product-category/admin/:id/vdo/:vdo_id`,
  CREATE: `/product-category/admin/:id/vdo`,
  UPDATE: `/product-category/admin/:id/vdo/:vdo_id`,
  UPDATE_SORT: `/product-category/admin/:id/vdo/sort`,
  UPDATE_STATUS: `/product-category/admin/:id/vdo/:vdo_id/status`
}

const EP_PRODUCT_CATEGORY_DOCS = {
  FINDALL: `/product-category/admin`,
  FINDBYID: `/product-category/admin/:id/doc/:doc_id`,
  CREATE: `/product-category/admin/:id/doc`,
  UPDATE: `/product-category/admin/:id/doc/:doc_id`,
  UPDATE_SORT: `/product-category/admin/:id/doc/sort`,
  UPDATE_STATUS: `/product-category/admin/:id/doc/:doc_id/status`
}

const EP_PRODUCT = {
  FINDALL: `/products/admin`,
  FINDBYID: `/products/admin`,
  FINDBYLANG: `/products-by-lang/admin`,
  FIND_BY_PRODUCT_CATEGORY: `/product-by-product-category/admin`,
  CREATE: `/products/admin`,
  UPDATE: `/products/admin`,
  UPDATE_STATUS: `/products-status/admin`,
  DELETE: `/products/admin`,
  UPDATE_SORT: `/products/sort/admin`,
  DELETE: `/products/admin`
}

const EP_SHADE_COLOR = {
  FINDALL: `/shade-colors/admin`,
  FINDBYID: `/shade-colors/admin`,
  FINDBYLANG: `/shade-colors-by-lang/admin`,
  FINDBY_CATEGORY: `/shade-colors-by-proudct-category/admin`,
  CREATE: `/shade-colors/admin`,
  UPDATE: `/shade-colors/admin`,
  DELETE: `/shade-colors/admin`,
  UPDATE_STATUS: `/shade-colors-status/admin`,
  UPDATE_SORT: `/shade-colors/sort/admin`,
  FOR_SELECT: `/shade-colors-for-select/admin`,
  DELETE: `/shade-colors/admin`
}

const EP_GROUP_COLOR = {
  FINDALL: `/group-colors/admin`,
  FINDBYID: `/group-colors/admin`,
  FINDBYLANG: `/group-colors-by-lang/admin`,
  CREATE: `/group-colors/admin`,
  UPDATE: `/group-colors/admin`,
  DELETE: `/group-colors/admin`,
  UPDATE_STATUS: `/group-colors-status/admin`,
  UPDATE_SORT: `/group-colors/sort/admin`,
  FOR_SELECT: `/group-colors-for-select/admin`,
  DELETE: `/group-colors/admin`
}

const EP_USABILITY = {
  FINDALL: `/usability/admin`,
  FINDBYID: `/usability/admin`,
  FINDBYLANG: `/usability-by-lang/admin`,
  CREATE: `/usability/admin`,
  UPDATE: `/usability/admin`,
  DELETE: `/usability/admin`,
  UPDATE_STATUS: `/usability-status/admin`,
  UPDATE_SORT: `/usability/sort/admin`,
  FOR_SELECT: `/usability-for-select/admin`,
  DELETE: `/usability/admin`
}

const EP_ORDER = {
  FINDALL: `/order/admin`,
  FINDBYID: `/order/admin`,
  FINDBYLANG: `/order-by-lang/admin`,
  CREATE: `/order/admin`,
  UPDATE: `/order/admin`,
  DELETE: `/order/admin`,
  UPDATE_STATUS: `/order-status/admin`,
  UPDATE_SORT: `/order/sort/admin`,
  FOR_SELECT: `/order-for-select/admin`,
  DELETE: `/order/admin`
}

const EP_SHAPE = {
  FINDALL: `/shape/admin`,
  FINDBYID: `/shape/admin`,
  FINDBYLANG: `/shape-by-lang/admin`,
  CREATE: `/shape/admin`,
  UPDATE: `/shape/admin`,
  DELETE: `/shape/admin`,
  UPDATE_SORT: `/shape/sort/admin`,
  FOR_SELECT: `/shape-for-select/admin`
}

const EP_COLOR = {
  FINDALL: `/color/admin`,
  FINDBYID: `/color/admin`,
  FINDBYLANG: `/color-by-lang/admin`,
  FINDBY_SHADE: `/colors-by-shade-color/admin`,
  FINDBY_PRODUCT_CATEGORY: `/colors-by-product-category/admin`,
  CREATE: `/color/admin`,
  UPDATE: `/color/admin`,
  DELETE: `/color/admin`,
  UPDATE_STATUS: `/color-status/admin`,
  UPDATE_SORT: `/color/sort/admin`,
  DELETE: `/color/admin`
}

const EP_ACCESSORY = {
  FINDALL: `/accessory/admin`,
  FINDBYID: `/accessory/admin`,
  FINDBYLANG: `/accessory-by-lang/admin`,
  CREATE: `/accessory/admin`,
  UPDATE: `/accessory/admin`,
  DELETE: `/accessory/admin`,
  UPDATE_SORT: `/accessory/sort/admin`,
  UPDATE_STATUS: `/accessory-status/admin`,
  FOR_SELECT: `/accessory-for-select/admin`,
  DELETE: `/accessory/admin`
}

const EP_BANNER = {
  FINDALL: `/banner/admin`,
  FINDBYID: `/banner/admin`,
  FINDBYPAGE: `/banner-by-page/admin`,
  CREATE: `/banner/admin`,
  UPDATE: `/banner/admin`
}

const EP_MENU = {
  FINDALL: `/menu/admin`,
  FINDBYID: `/menu/admin`,
  FINDBYLANG: `/menu-by-lang/admin`,
  CREATE: `/menu/admin`,
  UPDATE: `/menu/admin`,
  UPDATE_SORT: `/menu/sort/admin`,
  UPDATE_STATUS: `/menu-status/admin`,
  DELETE: `/menu/admin`
}

const EP_MENU_SUB = {
  FINDALL: `/menu-sub/admin`,
  FINDBYID: `/menu-sub/admin`,
  FINDBYLANG: `/menu-sub/admin`,
  FINDBYMENU: `/menu-sub-by-product-group/admin`,
  FINDPRODUCTGROUPBYMENU: `/menu-sub-product-group-by-menu/admin`,
  CREATE: `/menu-sub/admin`,
  UPDATE: `/menu-sub/admin`,
  UPDATE_STATUS: `/menu-sub-status/admin`,
  UPDATE_SORT: `/menu-sub/sort/admin`,
  DELETE: `/menu-sub/admin`
}

const EP_CONTENT = {
  FINDALL: `/content/admin`,
  FINDBYID: `/content/admin`,
  UPDATE: `/content/admin`
}
const EP_VDO = {
  FINDALL: `/vdo/admin`,
  FINDBYID: `/vdo/admin`,
  CREATE: `/vdo/admin`,
  UPDATE: `/vdo/admin`,
  UPDATE_SORT: `/vdo/sort/admin`
}

const EP_ICON = {
  FINDALL: `/icon/admin`,
  FINDBYID: `/icon/admin`,
  CREATE: `/icon/admin`,
  UPDATE: `/icon/admin`,
  UPDATE_SORT: `/icon/sort/admin`
}

const EP_ACTIVITY = {
  FINDALL: `/activity/admin`,
  FINDBYID: `/activity/admin`,
  FINDBYLANG: `/activity-by-lang/admin`,
  CREATE: `/activity/admin`,
  UPDATE: `/activity/admin`,
  UPDATE_STATUS: `/activity-status/admin`,
  UPDATE_SORT: `/activity/sort/admin`,
  DELETE: `/activity/admin`
}

const EP_COUNTRY = {
  FINDALL: `/country/admin`,
  FINDBYID: `/country/admin`,
  CREATE: `/country/admin`,
  UPDATE: `/country/admin`
}

const EP_PROVINCE = {
  FINDALL: `/province/admin`,
  FINDBYID: `/province/admin`,
  FINDBY_COUNTRY: `/province-by-country/admin`,
  CREATE: `/province/admin`,
  UPDATE: `/province/admin`,
  DELETE: `/province/admin`
}

const EP_DISTRICT = {
  FINDALL: `/district/admin`,
  FINDBYID: `/district/admin`,
  FINDBY_PROVINCE: `/district-by-province/admin`,
  CREATE: `/district/admin`,
  UPDATE: `/district/admin`
}

const EP_SUBDISTRICT = {
  FINDALL: `/sub-district/admin`,
  FINDBYID: `/sub-district/admin`,
  FINDBY_DISTRICT: `/sub-district-by-district/admin`,
  CREATE: `/sub-district/admin`,
  UPDATE: `/sub-district/admin`
}

const EP_SITE_REF = {
  FINDALL: `/site-ref/admin`,
  FINDBYID: `/site-ref/admin`,
  FINDBYLANG: `/site-ref-by-lang/admin`,
  FINDBY_PD_CATEGORY: `/site-ref-by-lang/admin`,
  CREATE: `/site-ref/admin`,
  UPDATE: `/site-ref/admin`,
  UPDATE_STATUS: `/site-ref-status/admin`,
  UPDATE_SORT: `/site-ref/sort/admin`,
  DELETE: `/site-ref/admin`
}

const EP_SITE_REF_PRODUCT_GROUP = {
  FINDALL: `/site-ref-product-group/admin`,
  FINDBYID: `/site-ref-product-group-by-id/admin`,
  FINDBYLANG: `/site-ref-product-group/admin`,
  UPDATE: `/site-ref-product-group/admin`,
  UPDATE_STATUS: `/site-ref-product-group-status/admin`,
  UPDATE_SORT: `/site-ref-product-group/sort/admin`,
  DELETE: `/site-ref-product-group/admin`
}

const EP_SITE_REF_PRODUCT_CATEGORY = {
  FINDALL: `/site-ref-product-category/admin`,
  FINDBYID: `/site-ref-product-category-by-id/admin`,
  FINDBYLANG: `/site-ref-product-category/admin`,
  FINDBY_PD_GROUP: `/site-ref-product-category/admin`,
  CREATE: `/site-ref-product-category/admin`,
  UPDATE: `/site-ref-product-category/admin`,
  UPDATE_STATUS: `/site-ref-product-category-status/admin`,
  UPDATE_SORT: `/site-ref-product-category/sort/admin`,
  DELETE: `/site-ref-product-category/admin`
}

const EP_ESTIMATION = {
  FINDALL: `/estimation/admin`
}

const EP_ESTIMATION_PRODUCT_GROUP = {
  FINDALL: `/estimation-product-group/admin`,
  FINDBYID: `/estimation-product-group/admin`,
  FINDBYLANG: `/estimation-product-group-by-lang/admin`,
  CREATE: `/estimation-product-group/admin`,
  UPDATE: `/estimation-product-group/admin`,
  UPDATE_SORT: `/estimation-product-group/sort/admin`
}

const EP_ESTIMATION_PRODUCT_CATEGORY = {
  FINDALL: `/estimation-product-category/admin`,
  FINDBYID: `/estimation-product-category/admin`,
  FINDBYLANG: `/estimation-product-category-by-lang/admin`,
  FINDBYID_FILTER: `/estimation-product-category/detail`,
  FINDDETAIL: `/estimation-product-category/detail`,
  FINDBY_ESTIMATION_PRODUCT_GROUP_FILTER: `/estimation-product-category`,
  FINDBY_ESTIMATION_PRODUCT_GROUP: `/estimation-product-category-by-product-group/admin`,
  CREATE: `/estimation-product-category/admin`,
  UPDATE: `/estimation-product-category/admin`,
  UPDATE_SORT: `/estimation-product-category/sort/admin`
}

const EP_ESTIMATION_COLOR = {
  FINDALL: `/estimation-color/admin`,
  FINDBYID: `/estimation-color/admin`,
  FINDBYLANG: `/estimation-color-by-lang/admin`,
  FINDBY_ESTIMATION_PRODUCT_CATEGORY_FILTER: `/estimation-color`,
  CREATE: `/estimation-color/admin`,
  UPDATE: `/estimation-color/admin`,
  UPDATE_SORT: `/estimation-color/sort/admin`
}

const EP_ESTIMATION_SHAPE = {
  FINDALL: `/estimation-shape/admin`,
  FINDBYID: `/estimation-shape/admin`,
  FINDBYLANG: `/estimation-shape-by-lang/admin`,
  FINDBY_ESTIMATION_PRODUCT_CATEGORY_FILTER: `/estimation-shape`,
  FINDBY_ESTIMATION_PRODUCT_CATEGORY_ID: `/estimation-shape-by-product-category/admin`,
  CREATE: `/estimation-shape/admin`,
  UPDATE: `/estimation-shape/admin`,
  UPDATE_SORT: `/estimation-shape/sort/admin`
}

const EP_ESTIMATION_CALCULATE = {
  FINDFIELD: `/estimation-field`,
  CREATE: `/estimation-calculate`,
  FINDPRODUCT: `/estimation-product/`
}

const EP_ESTIMATION_FORMULA = {
  FINDALL: `/estimation-formula/admin`
}

const EP_ESTIMATION_FORMULA_RES = {
  FINDALL: `/estimation-formula-res/admin`,
  FINDBY_FORMULA_ID: `/estimation-formula-res-by-formula/admin`,
  FINDBYID: `/estimation-formula-res/admin`,
  UPDATE: `/estimation-formula-res/admin`
}

const EP_ESTIMATION_FIELD = {
  FINDALL: `/estimation-field/admin`,
  FINDBYID: `/estimation-field/admin`,
  UPDATE: `/estimation-field/admin`
}

const EP_TEST = {
  UPLOADFILE: `/test_upload`
}

const EP_HOME_BANNER = {
  FINDALL: `/home-banner/admin`,
  FINDBYID: `/home-banner/admin`,
  FINDBYLANG: `/home-banner-by-lang/admin`,
  CREATE: `/home-banner/admin`,
  UPDATE: `/home-banner/admin`,
  UPDATE_SORT: `/home-banner/sort/admin`,
  UPDATE_STATUS: `/home-banner-status/admin`,
  DELETE: `/home-banner/admin`
}

const EP_HOME_HEADER_CONTACT_US = {
  FINDALL: `/contact-us/admin`,
  FINDBYID: `/contact-us/admin`,
  CREATE: `/contact-us/admin`,
  UPDATE: `/contact-us/admin`
}

const EP_HOME_PRODUCT_SERVICE_HEADER = {
  FINDALL: `/product-service-header/admin`,
  FINDBYID: `/product-service-header/admin`,
  CREATE: `/product-service-header/admin`,
  UPDATE: `/product-service-header/admin`
}

const EP_HOME_PRODUCT_SERVICE = {
  FINDALL: `/product-services/admin`,
  FINDBYID: `/product-services/admin`,
  CREATE: `/product-services/admin`,
  UPDATE: `/product-services/admin`,
  UPDATE_SORT: `/product-services/admin/sort`,
  UPDATE_STATUS: `/product-services/admin/:id/status`
}

const EP_HOME_CONTENT = {
  FINDALL: `/home-articles/admin`,
  FINDBYID: `/home-articles/admin`,
  FINDBYLANG: `/home-articles-by-lang/admin`,
  CREATE: `/home-articles/admin`,
  UPDATE: `/home-articles/admin`,
  UPDATE_SORT: `/home-articles/admin/sort`,
  UPDATE_STATUS: `/home-articles/admin/:id/status`,
  DELETE: `/home-articles/admin`
}

const EP_HOME_PRODUCT = {
  FINDALL: `/home-product/admin`,
  FINDBYID: `/home-product/admin`,
  FINDBYLANG: `/home-product-by-lang/admin`,
  CREATE: `/home-product/admin`,
  UPDATE: `/home-product/admin`,
  UPDATE_SORT: `/home-product/sort/admin`,
  UPDATE_STATUS: `/home-product-status/admin`,
  DELETE: `/home-product/admin`
}

const EP_HOME_CAROUSEL = {
  FINDALL: `/home-carousel/admin`,
  FINDBYID: `/home-carousel/admin`,
  FINDBYLANG: `/home-carousel-by-lang/admin`,
  CREATE: `/home-carousel/admin`,
  UPDATE: `/home-carousel/admin`,
  UPDATE_STATUS: `/home-carousel-status/admin`,
  UPDATE_SORT: `/home-carousel/sort/admin`,
  DELETE: `/home-carousel/admin`
}

const EP_HOME_SOCIAL_ICON = {
  FINDALL: `/social-menus/admin`,
  FINDBYID: `/social-menus/admin`,
  CREATE: `/social-menus/admin`,
  UPDATE: `/social-menus/admin`,
  UPDATE_STATUS: `/social-menus/admin/:id/status`,
  UPDATE_SORT: `/social-menus/admin/sort`,
  DELETE: `/social-menus/admin`
}

const EP_HOME_HIGHLIGHT_LIST = {
  FINDALL: `/home-product-highlight-list/admin`,
  FINDBYID: `/home-product-highlight-list/admin`,
  FINDBYLANG: `/home-product-highlight-list-by-lang/admin`,
  CREATE: `/home-product-highlight-list/admin`,
  UPDATE: `/home-product-highlight-list/admin`,
  UPDATE_STATUS: `/home-product-highlight-list-status/admin`,
  UPDATE_SORT: `/home-product-highlight-list/sort/admin`,
  DELETE: `/home-product-highlight-list/admin`
}

const EP_HOME_HIGHLIGHTS = {
  FINDALL: `/home-product-highlight/admin`,
  FINDBYID: `/home-product-highlight/admin`,
  FINDBYLANG: `/home-product-highlight-by-lang/admin`,
  CREATE: `/home-product-highlight/admin`,
  UPDATE: `/home-product-highlight/admin`,
  UPDATE_SORT: `/home-product-highlight/sort/admin`,
  DELETE: `/home-product-highlight/admin`
}

const EP_HOME_ACTIVITY = {
  FINDALL: `/home-activity/admin`,
  FINDBYID: `/home-activity/admin`,
  FINDBYLANG: `/home-activity-by-lang/admin`,
  CREATE: `/home-activity/admin`,
  UPDATE: `/home-activity/admin`,
  UPDATE_SORT: `/home-activity/sort/admin`,
  DELETE: `/home-activity/admin`
}

const EP_HOME_MARKETPLACE = {
  FINDALL: `/home-market-place/admin`,
  FINDBYID: `/home-market-place/admin`,
  FINDBYLANG: `/home-market-place-by-lang/admin`,
  CREATE: `/home-market-place/admin`,
  UPDATE: `/home-market-place/admin`,
  UPDATE_SORT: `/home-market-place/sort/admin`,
  DELETE: `/home-market-place/admin`
}

const EP_SEO = {
  FINDALL: `/seo/admin`,
  FINDBYID: `/seo/admin`,
  FINDBYLANG: `/seo-by-lang/admin`,
  CREATE: `/seo/admin`,
  UPDATE: `/seo/admin`
}

const EP_IR = {
  FINDALL: `/ir/admin`,
  FINDBYID: `/ir/admin`,
  FINDBYLANG: `/ir-by-lang/admin`,
  CREATE: `/ir/admin`,
  UPDATE: `/ir/admin`
}

const EP_LOGO = {
  FINDALL: `/logo/admin`,
  FINDBYID: `/logo/admin`,
  FINDBYLANG: `/logo-by-lang/admin`,
  CREATE: `/logo/admin`,
  UPDATE: `/logo/admin`
}

const EP_LOG = {
  FINDALL: `/logs/admin`
}

const EP_BLOG = {
  FINDALL: `/blog/admin`,
  FINDBYID: `/blog/admin`,
  FINDBYLANG: `/blog-by-lang/admin`,
  CREATE: `/blog/admin`,
  UPDATE: `/blog/admin`,
  UPDATE_SORT: `/blog/sort/admin`,
  UPDATE_STATUS: `/blog-status/admin`,
  DELETE: `/blog/admin`
}

const EP_ESTIMATION_FORMULA_RES_COLOR = {
  FINDBY_FORMULA_RES_ID: `/estimation-formula-res-color-by-formula/admin`,
  FINDBY_FORMULA_BY_ID: `/estimation-formula-res-color-by-formula-color/admin`,
  UPDATE: `/estimation-formula-res-color-by-formula-color/admin`
}

const EP_MENU_STATUS = {
  FINDALL: `/all-menus/admin`,
  FIND_BY_ID: `/all-menus/admin/:id`,
  UPDATE_STATUS: `/all-menus/admin/:id/status`
}

export {
  EP_USERS,
  EP_COMPANY,
  EP_TEST,
  EP_VISION,
  EP_MISSION,
  EP_CORE_VALUE,
  EP_STRATEGY,
  EP_CSR,
  EP_POLICY,
  EP_STANDARD,
  EP_STANDARD_CATEGORY,
  EP_CAREER_BANNER,
  EP_CAREER_DESCRIPTION,
  EP_CAREER_EXECUTIVE,
  EP_CAREER_FOOTER,
  EP_CAREER,
  EP_APPLY_JOB,
  EP_HISTORY,
  EP_LOCATION,
  EP_CONTACT,
  EP_BRANCH,
  EP_COOKIE,
  EP_NEWS,
  EP_DEALER,
  EP_SUPPORT,
  EP_SUPPORT_HEADER,
  EP_DOWNLOAD,
  EP_DOWNLOAD_HEADER,
  EP_FAQ,
  EP_INFO_DOWNLOAD,
  EP_PRODUCT_GROUP,
  EP_PRODUCT_GROUP_DIMENSION,
  EP_SHADE_COLOR,
  EP_GROUP_COLOR,
  EP_USABILITY,
  EP_ORDER,
  EP_SHAPE,
  EP_COLOR,
  EP_ACCESSORY,
  EP_PRODUCT_CATEGORY,
  EP_PRODUCT_CATEGORY_DIMENSION,
  EP_PRODUCT_CATEGORY_VDO,
  EP_PRODUCT_CATEGORY_DOCS,
  EP_PRODUCT,
  EP_BANNER,
  EP_MENU,
  EP_MENU_SUB,
  EP_CONTENT,
  EP_VDO,
  EP_ICON,
  EP_ACTIVITY,
  EP_COUNTRY,
  EP_PROVINCE,
  EP_DISTRICT,
  EP_SUBDISTRICT,
  EP_SITE_REF,
  EP_SITE_REF_PRODUCT_GROUP,
  EP_SITE_REF_PRODUCT_CATEGORY,
  EP_ESTIMATION,
  EP_ESTIMATION_PRODUCT_GROUP,
  EP_ESTIMATION_PRODUCT_CATEGORY,
  EP_ESTIMATION_COLOR,
  EP_ESTIMATION_SHAPE,
  EP_ESTIMATION_CALCULATE,
  EP_ESTIMATION_FORMULA,
  EP_ESTIMATION_FORMULA_RES,
  EP_ESTIMATION_FIELD,
  EP_HOME_BANNER,
  EP_HOME_HEADER_CONTACT_US,
  EP_HOME_CONTENT,
  EP_HOME_PRODUCT,
  EP_HOME_PRODUCT_SERVICE_HEADER,
  EP_HOME_PRODUCT_SERVICE,
  EP_HOME_CAROUSEL,
  EP_HOME_SOCIAL_ICON,
  EP_HOME_HIGHLIGHT_LIST,
  EP_HOME_HIGHLIGHTS,
  EP_HOME_ACTIVITY,
  EP_HOME_MARKETPLACE,
  EP_SEO,
  EP_IR,
  EP_LOGO,
  EP_LOG,
  EP_BLOG,
  EP_ESTIMATION_FORMULA_RES_COLOR,
  EP_MENU_STATUS
}
