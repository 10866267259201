/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
"use strict"

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_CAREER_EXECUTIVE } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut, axiosUpdateFile, axiosCreateFile, axiosDelete } from "@/utils/axios"

export async function getCareerExecutiveAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_CAREER_EXECUTIVE.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return errorHandle(message)
  }
}

export async function getCareerExecutiveByID({ career_executive_id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_CAREER_EXECUTIVE.FINDBYID}/${career_executive_id}`
    const { code, data, message } = await axiosGet(url, token)
    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getCareerExecutiveByLang({ lang }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_CAREER_EXECUTIVE.FINDBYLANG}/${lang}?sort=YES`
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    return error
  }
}

export async function postCareerExecutive({ career_executive, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    let formData = new FormData()
    formData.append("bio", career_executive.bio)
    formData.append("name", career_executive.name)
    formData.append("posion_en", career_executive.posion_en)
    formData.append("posion_th", career_executive.posion_th)
    formData.append("lang", career_executive.lang)
    if (file) formData.append("file", file, file.name)

    const url = `${API}${EP_CAREER_EXECUTIVE.CREATE}`

    const { code, data, message } = await axiosCreateFile(url, formData, token)

    if (code == 201) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putCareerExecutive({ career_executive, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    let formData = new FormData()
    formData.append("bio", career_executive.bio)
    formData.append("name", career_executive.name)
    formData.append("posion_en", career_executive.posion_en)
    formData.append("posion_th", career_executive.posion_th)
    formData.append("status", career_executive.status)
    formData.append("sort", career_executive.sort)
    formData.append("lang", career_executive.lang)
    if (file) formData.append("file", file, file.name)

    const url = `${API}${EP_CAREER_EXECUTIVE.UPDATE}/${career_executive.career_executive_id}`

    const { code, data, message } = await axiosUpdateFile(url, formData, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putCareerExecutiveSort({ career_executives }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = career_executives.map((item) => {
      return {
        id: item.career_executive_id,
        sort: item.sort
      }
    })
    const url = `${API}${EP_CAREER_EXECUTIVE.UPDATE_SORT}`

    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putStatus({ career_executive, status }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    const payload = { status: status }
    const url = `${API}${EP_CAREER_EXECUTIVE.UPDATE_STATUS}/${career_executive.career_executive_id}`

    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function deleteCareerExecutive({ career_executive }, resultHandle, errorHandle, deleteResultHandle) {
  try {
    const token = await getAuthToken()

    const url = `${API}${EP_CAREER_EXECUTIVE.DELETE}/${career_executive.career_executive_id}`

    const { code, data, message } = await axiosDelete(url, token)

    if (code == 200) {
      deleteResultHandle()
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}
