<template>
  <v-container>
    <base-material-card icon="mdi-domain" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">
          {{ $t("strategy") }}
        </div>
      </template>

      <v-tabs vertical>
        <v-tab v-for="(strategy, i) in strategies" :key="`strategy-${i}`">
          {{ strategy.lang }}
        </v-tab>

        <v-tab-item touchless v-for="(strategy, i) in strategies" :key="`strategy-${i}`">
          <form @submit.prevent="setLoading(true), save(i)">
            <v-container fluid>
              <v-row class="text-no-wrap" align="center">
                <v-col>
                  <base-ck-editor v-model="strategy.strategy" :text.sync="strategy.strategy" placeholder="วิสัยทัศน์" />
                </v-col>
              </v-row>

              <v-row class="text-no-wrap" align="center">
                <v-col>
                  <v-select
                    v-model="strategy.status"
                    :items="status"
                    item-text="text"
                    item-value="value"
                    label="สถานะ"
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
              <!-- <v-row class="text-no-wrap" align="center">
                <v-col>
                  <v-select
                    v-model="strategy.lang"
                    :items="languages"
                    item-text="language"
                    item-value="lang_code"
                    label="ภาษา"
                    outlined
                  ></v-select>
                </v-col>
              </v-row> -->

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
                  {{ $t("save") }}
                </v-btn>
              </v-card-actions>
            </v-container>
          </form>
        </v-tab-item>
      </v-tabs>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANTS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"

/**
 * CONTROLLER
 */
import { getStrategyAll, putStrategy } from "@/controllers/about_us/strategy.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "Strategy",
  data: () => ({
    strategies: [],
    languages: LANGUAGES,
    status: STATUS
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.strategies = await getStrategyAll()
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async save(i) {
      try {
        await putStrategy({ strategy: this.strategies[i] }, this.resultHandle, this.errorHandle)
      } catch (e) {
        console.log(e)
      }
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
