<template>
  <v-container>
    <base-material-card icon="mdi-domain" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("company") }}</div>
      </template>

      <v-tabs vertical>
        <v-tab v-for="(company, i) in companies" :key="`company-${i}`">
          {{ company.lang }}
        </v-tab>

        <v-tab-item v-for="(company, i) in companies" :key="`company-${i}`">
          <form @submit.prevent="setLoading(true), saveCompanyHandle(i)">
            <v-container fluid>
              <v-row class="text-no-wrap" align="center">
                <v-col>
                  <v-text-field :label="$t('company_name')" v-model="company.company_name" outlined />
                </v-col>
              </v-row>

              <v-row class="text-no-wrap" align="center">
                <v-col>
                  <v-text-field :label="$t('company_short_name')" v-model="company.company_shortname" outlined />
                </v-col>
              </v-row>

              <v-row class="text-no-wrap" align="center">
                <v-col>
                  <v-text-field :label="$t('company_no')" v-model="company.company_no" outlined />
                </v-col>
              </v-row>

              <v-row class="text-no-wrap" align="center">
                <v-col>
                  <v-textarea label="ประเภทธุรกิจ" v-model="company.type_business" outlined />
                </v-col>
              </v-row>

              <v-row class="text-no-wrap" align="center">
                <v-col>
                  <v-textarea label="ทุนจดทะเบียน" v-model="company.registered_capital" outlined />
                </v-col>
              </v-row>

              <v-row class="text-no-wrap" align="center">
                <v-col>
                  <v-select
                    v-model="company.status"
                    :items="status"
                    item-text="text"
                    item-value="value"
                    label="สถานะ"
                    outlined
                  ></v-select>
                </v-col>
              </v-row>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" min-width="100" class="pl-0" type="submit">
                  {{ $t("save") }}
                </v-btn>
              </v-card-actions>
            </v-container>
          </form>
        </v-tab-item>
      </v-tabs>
    </base-material-card>

    <base-material-card icon="mdi-domain" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">
          {{ $t("history") }}
        </div>
      </template>

      <v-tabs vertical>
        <v-tab v-for="(history, i) in histories" :key="`company-${i}`">
          {{ history.lang }}
        </v-tab>

        <v-tab-item v-for="(history, i) in histories" :key="`history-${i}`">
          <form @submit.prevent="setLoading(true), saveHistoryHandle(i)">
            <v-container fluid>
              <v-row class="text-no-wrap" align="center">
                <v-col>
                  <v-text-field label="Link" v-model="history.vdo_link" outlined />
                </v-col>
              </v-row>

              <v-row class="text-no-wrap" align="center">
                <v-col>
                  <v-select
                    v-model="history.status"
                    :items="status"
                    item-text="text"
                    item-value="value"
                    label="สถานะ"
                    outlined
                  ></v-select>
                </v-col>
              </v-row>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" min-width="100" class="pl-0" type="submit">
                  {{ $t("save") }}
                </v-btn>
              </v-card-actions>
            </v-container>
          </form>
        </v-tab-item>
      </v-tabs>
    </base-material-card>

    <base-material-card icon="mdi-domain" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">
          {{ $t("location") }}
        </div>
      </template>

      <v-tabs vertical>
        <v-tab v-for="(location, i) in locations" :key="`company-${i}`">
          {{ location.lang }}
        </v-tab>

        <v-tab-item v-for="(location, i) in locations" :key="`history-${i}`">
          <form @submit.prevent="setLoading(true), saveLocationHandle(i)">
            <v-container fluid>
              <v-row class="text-no-wrap" align="center">
                <v-col>
                  <v-text-field label="สำนักงานใหญ่" v-model="location.headquarters" outlined />
                </v-col>
              </v-row>

              <v-row class="text-no-wrap" align="center">
                <v-col>
                  <v-text-field label="สำนักงานสาขาที่ 1" v-model="location.first_branch" outlined />
                </v-col>
              </v-row>

              <v-row class="text-no-wrap" align="center">
                <v-col>
                  <v-text-field label="สำนักงานสาขาที่ 2" v-model="location.second_branch" outlined />
                </v-col>
              </v-row>

              <v-row class="text-no-wrap" align="center">
                <v-col>
                  <v-text-field label="สำนักงานสาขาที่ 3" v-model="location.third_branch" outlined />
                </v-col>
              </v-row>

              <v-row class="text-no-wrap" align="center">
                <v-col>
                  <v-text-field label="Call Center" v-model="location.call_center" outlined />
                </v-col>
              </v-row>

              <v-row class="text-no-wrap" align="center">
                <v-col>
                  <v-text-field label="Website" v-model="location.website" outlined />
                </v-col>
              </v-row>

              <v-row class="text-no-wrap" align="center">
                <v-col>
                  <v-text-field label="E-mail Address" v-model="location.email" outlined />
                </v-col>
              </v-row>

              <v-row class="text-no-wrap" align="center">
                <v-col>
                  <v-select
                    v-model="location.status"
                    :items="status"
                    item-text="text"
                    item-value="value"
                    label="สถานะ"
                    outlined
                  ></v-select>
                </v-col>
              </v-row>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" min-width="100" class="pl-0" type="submit">
                  {{ $t("save") }}
                </v-btn>
              </v-card-actions>
            </v-container>
          </form>
        </v-tab-item>
      </v-tabs>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONTROLLER
 */
import { getCompanyAll, putCompany } from "@/controllers/about_us/company.controller"
import { getHistoryAll, putHistory } from "@/controllers/about_us/history.controller"
import { getLocationAll, putLocation } from "@/controllers/about_us/location.controller"

/**
 * CONSTANTS
 */
import { STATUS } from "@/constants/status"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "Company",
  data: () => ({
    companies: [],
    histories: [],
    locations: [],
    status: STATUS
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.companies = await getCompanyAll()
        this.histories = await getHistoryAll()
        this.locations = await getLocationAll()
        this.setLoading(false)
      } catch (e) {
        this.errorHandle(e)
      }
    },
    async saveCompanyHandle(i) {
      try {
        await putCompany({ company: this.companies[i] }, this.resultHandle, this.errorHandle)
      } catch (e) {
        this.errorHandle(e)
      }
    },
    async saveHistoryHandle(i) {
      try {
        await putHistory({ history: this.histories[i] }, this.resultHandle, this.errorHandle)
      } catch (e) {
        console.log(e)
      }
    },
    async saveLocationHandle(i) {
      try {
        await putLocation({ location: this.locations[i] }, this.resultHandle, this.errorHandle)
      } catch (e) {
        console.log(e)
      }
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>
