const SUPPORT_DOWNLOAD_HEADERS = [
  {
    ref_id: -2,
    title: "คำถามที่พบบ่อย",
    type: "FAQ",
    view: "support"
  },
  {
    ref_id: -1,
    title: "Dimaond Site Ref.",
    type: "DAIMON_SITE_REF",
    view: "support"
  },
  {
    ref_id: 0,
    title: "บทความ",
    type: "BLOG",
    view: "support"
  },
  {
    ref_id: 1,
    title: "สื่อประชาสัมพันธ์",
    type: "SUPPORT_HEADER",
    view: "support"
  },
  {
    ref_id: 2,
    title: "ดาวน์โหลด",
    type: "DOWNLOAD_HEADER",
    view: "download"
  }
]

export { SUPPORT_DOWNLOAD_HEADERS }
