/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_HOME_SOCIAL_ICON } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut, axiosPatch, axiosCreateFile, axiosUpdateFile, axiosPatchFile } from "@/utils/axios"

export async function getSocialIconAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_HOME_SOCIAL_ICON.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getSocialIconById({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_HOME_SOCIAL_ICON.FINDBYID}/${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getSocialIconByLang({ lang, sort = "YES" }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_HOME_SOCIAL_ICON.FINDALL}?lang=${lang}&sort=${sort}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function postSocialIcon({ socialIcon, social_normal, social_hover }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_HOME_SOCIAL_ICON.CREATE}`
    let formData = new FormData()
    formData.append("link", socialIcon.link)
    formData.append("lang", socialIcon.lang)
    if (social_normal) formData.append("social_normal", social_normal, social_normal.name)
    if (social_hover) formData.append("social_hover", social_hover, social_hover.name)

    const { code, data, message } = await axiosCreateFile(url, formData, token)

    if (code == 201) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putSocialIcon({ socialIcon, social_normal, social_hover }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const url = `${API + EP_HOME_SOCIAL_ICON.UPDATE}/${socialIcon.social_menu_id}`
    let formData = new FormData()
    formData.append("link", socialIcon.link)
    formData.append("lang", socialIcon.lang)
    formData.append("status", socialIcon.status)
    if (social_normal) formData.append("social_normal", social_normal, social_normal.name)
    if (social_hover) formData.append("social_hover", social_hover, social_hover.name)

    const { code, data, message } = await axiosPatchFile(url, formData, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    // const { message } = error.response.data
    console.log(error)
    return errorHandle(error)
  }
}

export async function putStatus({ socialIcon, status }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const url = `${API + EP_HOME_SOCIAL_ICON.UPDATE_STATUS.replace(":id", socialIcon.social_menu_id)}`
    const payload = {
      status: status
    }
    const { code, data, message } = await axiosPatch(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    // const { message } = error.response.data
    console.log(error)
    return errorHandle(error)
  }
}

export async function putSocialIconSort({ socialIcons }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = socialIcons.map((item) => {
      return {
        id: item.social_menu_id,
        sort: item.sort
      }
    })
    const url = `${API}${EP_HOME_SOCIAL_ICON.UPDATE_SORT}`

    const { code, message, data } = await axiosPatch(url, payload, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function deleteSocialIcon({ socialIcon }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = {
      status: "DELETED"
    }
    const url = `${API}${EP_HOME_SOCIAL_ICON.UPDATE_STATUS.replace(":id", socialIcon.social_menu_id)}`

    const { code, message, data } = await axiosPatch(url, payload, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}
