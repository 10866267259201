<template>
  <v-container>
    <base-material-card icon="mdi-handshake" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("dealer_create") }}</div>
      </template>
      <base-upload-image v-model="file" class="mb-2" width="160px" height="100px" />
      <p class="text-center red--text">*แนะนำขนาดรูปภาพ 160 x 100 px</p>

      <!-- <GoogleMap
        v-model="dealer.search"
        :location="{ lat: dealer.lat, lng: dealer.lng }"
        @getPosition="getPosition"
        @getPlaceName="getPlaceName"
        @getPlaceId="getPlaceId"
      /> -->
      <form @submit.prevent="setLoading(true), save()">
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field v-model="dealer.i_frame" :label="$t('map')" outlined></v-text-field>
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field v-model="dealer.lat" :label="$t('lat')" outlined></v-text-field>
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field v-model="dealer.lng" :label="$t('lng')" outlined></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field v-model="dealer.shop_name" :label="$t('shop_name')" outlined></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-textarea v-model="dealer.address" :label="$t('address')" outlined />
            </v-col>

            <!-- <v-col cols="12" md="12">
              <v-text-field v-model="dealer.tel" label="tel" outlined></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field v-model="dealer.no_address" label="no_address" outlined></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field v-model="dealer.building" label="building" outlined></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field v-model="dealer.floor" label="floor" outlined></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field v-model="dealer.unit" label="unit" outlined></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field v-model="dealer.moo" label="moo" outlined></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field v-model="dealer.alley" label="alley" outlined></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field v-model="dealer.soi" label="soi" outlined></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field v-model="dealer.road" label="road" outlined></v-text-field>
            </v-col> -->

            <v-col cols="6" md="6">
              <v-autocomplete
                v-model="dealer.country"
                :items="countries"
                item-text="country"
                item-value="country_id"
                :label="$t('country')"
                outlined
                @change="onCountry"
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="6" md="6">
              <v-autocomplete
                v-model="dealer.province"
                :items="provinces"
                item-text="province"
                item-value="province_id"
                :label="$t('province')"
                outlined
                @change="onProvince"
              ></v-autocomplete>
            </v-col>

            <v-col cols="6" md="6">
              <v-autocomplete
                v-model="dealer.district"
                :items="districts"
                item-text="district"
                item-value="district_id"
                :label="$t('district')"
                outlined
                @change="onDistrict"
              ></v-autocomplete>
            </v-col>

            <v-col cols="6" md="6">
              <v-autocomplete
                v-model="dealer.sub_district"
                :items="sub_districts"
                item-text="sub_district"
                item-value="sub_district_id"
                :label="$t('sub_district')"
                outlined
                @change="onSubDistrict"
                return-object
              ></v-autocomplete>
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field v-model="dealer.postal_code" :label="$t('postal_code')" outlined></v-text-field>
            </v-col>

            <v-col cols="6" md="6">
              <!-- <v-switch :false-value="0" :true-value="1" v-model="dealer.lead" inset :label="dealer.lead == 1 ? $t('lead') : $t('modern_trade')"></v-switch> -->
              <v-radio-group class="mt-0 mt-sm-auto" v-model="dealer.lead" row>
                <v-radio :label="$t('lead')" :value="1"></v-radio>
                <v-radio :label="$t('modern_trade')" :value="0"></v-radio>
                <v-radio :label="$t('none')" :value="2"></v-radio>
              </v-radio-group>
            </v-col>

            <!-- <v-col cols="6" md="6">
              <v-switch
                :false-value="0"
                :true-value="1"
                v-model="dealer.modern_trade"
                inset
                :label="$t('modern_trade')"
              ></v-switch>
            </v-col> -->

            <v-col cols="12" md="12">
              <v-text-field v-model="dealer.link" :label="$t('link')" outlined></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field v-model="dealer.tel" :label="$t('telphone')" outlined></v-text-field>
            </v-col>

            <!-- <v-col cols="12" md="12">
              <v-select
                v-model="dealer.status"
                :items="status"
                item-text="text"
                item-value="value"
                :label="$t('status')"
                outlined
              ></v-select>
            </v-col> -->

            <v-col>
              <v-select
                v-model="dealer.lang"
                :items="languages"
                item-text="language"
                item-value="lang_code"
                :label="$t('language')"
                outlined
                @change="onLang"
              ></v-select>
            </v-col>
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="primary"
              min-width="100"
              class="pl-0"
              :loading="$store.state.isloading"
              @click="setLoading(true), cancel()"
            >
              {{ $t("cancel") }}
            </v-btn>

            <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </form>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"

/**
 * CONTROLLER
 */
import { postDealer } from "@/controllers/dealer/dealer.controller"
import { getCountry, getProvince, getDistrict, getSubDistrict } from "@/controllers/dealer/dealer.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

/**
 * COMPONENTS
 */
import GoogleMap from "../../../components/GoogleMap"

export default {
  name: "DealerCreate",
  data: () => ({
    languages: LANGUAGES,
    status: STATUS,
    file: null,
    countries: [],
    provinces: [],
    districts: [],
    sub_districts: [],
    dealer: {
      lat: null,
      lng: null
    },
    center: { lat: 13.764187851076384, lng: 100.5108642578125 }
  }),
  components: {
    GoogleMap
  },
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.dealer.lang = this.$route.query.lang
        this.countries = await getCountry({ lang: this.$route.query.lang })
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async onLang(e) {
      this.countries = await getCountry({ lang: e })
      this.provinces = await getProvince({ country: this.dealer.country, lang: e })
      this.districts = await getDistrict({ province_id: this.dealer.province, lang: e })
      this.sub_districts = await getSubDistrict({ district_id: this.dealer.district, lang: e })
    },
    async save() {
      await postDealer({ dealer: this.dealer, file: this.file }, this.resultHandle, this.errorHandle)
    },
    async onCountry(e) {
      this.provinces = await getProvince({ country: e, lang: this.dealer.lang })
      this.districts = []
      this.sub_districts = []
      this.dealer.postal_code = null
    },
    async onProvince(e) {
      this.districts = await getDistrict({ province_id: e, lang: this.dealer.lang })
      this.sub_districts = []
      this.dealer.postal_code = null
    },
    async onDistrict(e) {
      this.sub_districts = await getSubDistrict({ district_id: e, lang: this.dealer.lang })
      this.dealer.postal_code = null
    },
    onSubDistrict(e) {
      this.dealer.sub_district = e.sub_district_id
      this.dealer.postal_code = e.zip_code
    },
    cancel() {
      this.$router.back()
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
      this.$router.back()
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    },
    // getPosition(location) {
    //   this.dealer.lat = location.lat
    //   this.dealer.lng = location.lng
    // },
    getPlaceName(placeName) {
      this.dealer.place_name = placeName
    },
    getPlaceId(place_id) {
      this.dealer.place_id = place_id
    }
  }
}
</script>

<style></style>
