<template>
  <div>
    <!-- defaultValue: {{ defaultValue }}<br /> -->
    <!-- imageUrl: {{ imageUrl }} <br /> -->
    <!-- imageFile:{{ imageFile }}<br /> -->
    <!-- imageFile:{{ typeof imageFile }}<br /> -->
    <!-- imageFile:{{ Object.keys(defaultValue).length }}<br /> -->
    <!-- {{ Object.keys(defaultValue).length ? `${path_file}${defaultValue.file_path}` : null }} -->

    <!-- <v-hover v-slot="{ hover }">
      <div
        v-if="imageUrl"
        class="base-image-input"
        :style="{ 'background-image': `url(${imageUrl})` }"
        @click="chooseImage"
      >
        <v-fade-transition>
          <div justify="center" align="center" v-if="hover" class="text-center d-flex darken-2 white--text placeholder">
            <v-row class="text-no-wrap" align="center">
              <v-col class="text-center">
                <v-icon large color="black">mdi-pencil</v-icon>
              </v-col>
            </v-row>
          </div>
        </v-fade-transition>
      </div>
      <div
        v-else-if="!Object.keys(defaultValue).length"
        class="base-image-input"
        :style="{ 'background-color': '#e0e0e0' }"
        @click="chooseImage"
      >
        <v-fade-transition>
          <div justify="center" align="center" v-if="hover" class="text-center d-flex darken-2 white--text placeholder">
            <v-row class="text-no-wrap" align="center">
              <v-col class="text-center">
                <v-icon large color="black">mdi-pencil</v-icon>
              </v-col>
            </v-row>
          </div>
        </v-fade-transition>
      </div>
      <v-img v-else class="base-image" :src="`${path_file}${defaultValue.file_path}`" @click="chooseImage">
        <v-fade-transition>
          <div justify="center" align="center" v-if="hover" class="text-center d-flex darken-2 white--text placeholder">
            <v-row class="text-no-wrap" align="center">
              <v-col class="text-center">
                <v-icon large color="black">mdi-pencil</v-icon>
              </v-col>
            </v-row>
          </div>
        </v-fade-transition>
      </v-img>
    </v-hover> -->
    <v-expand-transition>
      <div class="text-center" v-if="file && fileUrl">
        <v-card class="preview-img mx-auto justify-center align-center" :href="fileUrl" target="_blank">
          <v-layout class="justify-center align-center" style="height: 100%">
            <v-icon size="40">mdi-file-download </v-icon>
          </v-layout>
        </v-card>
        {{ file && file.name }}
      </div>
      <div class="text-center" v-else-if="Object.keys(defaultValue).length">
        <v-card
          class="preview-img mx-auto justify-center align-center"
          :href="`${path_file}${defaultValue.file_path}`"
          target="_blank"
        >
          <v-layout class="justify-center align-center" style="height: 100%">
            <v-icon size="40">mdi-file-download</v-icon>
          </v-layout>
        </v-card>
        {{ `${defaultValue.file_name}.${defaultValue.file_extension}` }}
      </div>
    </v-expand-transition>

    <v-file-input
      v-model="file"
      prepend-icon="mdi-camera"
      :label="label"
      :rules="Object.keys(defaultValue).length ? [] : rules"
      @change="onSelectFile"
      outlined
    />
  </div>
</template>

<script>
import { PATHFILE } from "@/constants/config"

export default {
  name: "UploadFile",
  data() {
    return {
      fileUrl: null,
      path_file: PATHFILE
    }
  },
  props: {
    value: {
      type: File,
      default: null
    },
    label: {
      type: String,
      default: "file"
    },
    defaultValue: {
      type: Object,
      default: () => ({})
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    file: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    }
  },
  methods: {
    chooseImage() {
      this.$refs.fileInput.$refs.input.click()
    },
    onSelectFile(e) {
      if (e) this.fileUrl = URL.createObjectURL(e)
      else this.fileUrl = null
    },
    clear() {
      this.file = null
      this.fileUrl = null
    }
  }
}
</script>

<style scoped>
.base-image-input {
  text-align: center;
  margin: auto;
  display: block;
  width: 200px;
  height: 200px;
  cursor: pointer;
  background-size: contain;
  background-position: center center;
}
.base-image {
  text-align: center;
  margin: auto;
  /* display: block; */
  width: 200px;
  height: 200px;
  cursor: pointer;
  background-size: contain;
  background-position: center center;
}
.placeholder {
  height: 100%;
  width: 100%;
  background-color: HSL(0, 0%, 74%, 0.5);
}
.file-input {
  display: none;
}
</style>

<style lang="scss" scoped>
$gray--light: #e8e8e8;
$box-shadow-none: 0 0 0 0 !important;
$border-radius-md: 20px !important;
.v-card {
  position: relative;
  box-shadow: $box-shadow-none;
  border-radius: $border-radius-md;
  padding: 4px;
  &.preview-img {
    max-width: 100px;
    height: 100px;
    background-color: $gray--light !important;

    .icon-close {
      position: absolute;
      display: none;
      top: 0;
      right: 0;
    }
    &:hover {
      .icon-close {
        display: block;
      }
    }
  }
}
</style>
