var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('base-material-card',{attrs:{"icon":"mdi-map-marker","color":"primary"},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"font-weight-light card-title mt-2"},[_vm._v(_vm._s(_vm.$t("branch")))])]},proxy:true}])},[_c('v-dialog',{attrs:{"max-width":"300"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',[_c('p',[_vm._v(" ยืนยันการลบ ")]),_c('v-spacer'),_c('v-icon',{attrs:{"aria-label":"Close"},on:{"click":_vm.closeDelete}},[_vm._v(" mdi-close ")])],1),_c('v-card-text',{staticClass:"pb-6 text-center"},[_c('v-btn',{staticClass:"mr-1",attrs:{"color":"error","dark":""},on:{"click":_vm.closeDelete}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('v-btn',{staticClass:"mr-1",attrs:{"color":"success","dark":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")])],1)],1)],1),_c('v-tabs',{attrs:{"vertical":""}},[_vm._l((_vm.languages),function(language,i){return _c('v-tab',{key:("branch-" + i),on:{"change":function($event){return _vm.onTabChange(language.lang_code)}}},[_vm._v(" "+_vm._s(language.lang_code)+" ")])}),_vm._l((_vm.languages),function(language,i){return _c('v-tab-item',{key:("branch-" + i),attrs:{"touchless":""}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1 table",attrs:{"headers":_vm.headers,"items":_vm.branchs,"calculate-widths":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.createBranch(language.lang_code)}}},[_vm._v(" "+_vm._s(_vm.$t("branch_create"))+" ")])],1)]},proxy:true},{key:"item.branch",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-overflow"},[_vm._v(" "+_vm._s(item.branch)+" ")])]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-overflow"},[_vm._v(" "+_vm._s(item.address)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('base-switch',{attrs:{"height":26,"width":99,"font-size":5,"color":{ checked: '#4CAF50', unchecked: '#FF5252' },"labels":{ checked: 'ACTIVE', unchecked: 'INACTIVE' }},on:{"change":function($event){_vm.setLoading(true), _vm.onChangeEventHandler(item, $event)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"px-2 ml-1",attrs:{"min-width":"0","color":"success","small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"px-2 ml-1",attrs:{"min-width":"0","color":"error","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)})],1)],1)],1)],1)})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }