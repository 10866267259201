<template>
  <v-container>
    <base-material-card icon="mdi-calculator-variant" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("estimation_field") }}</div>
      </template>
      <!-- <v-tabs vertical>
        <v-tab v-for="(language, i) in languages" :key="`field-${i}`" @change="onTabChange(language.lang_code)">
          {{ language.lang_code }}
        </v-tab>

        <v-tab-item touchless v-for="(language, i) in languages" :key="`accessory-${i}`"> -->
      <v-container fluid>
        <v-row class="text-no-wrap" align="center">
          <v-col>
            <v-data-table :headers="headers" :items="fields" class="elevation-1">
              <template v-slot:top>
                <v-toolbar flat>
                  <!-- <v-toolbar-title>{{ $t("estimation_formula_shape") }}</v-toolbar-title> -->

                  <v-spacer></v-spacer>
                </v-toolbar>
              </template>

              <template v-slot:[`item.created_date`]="{ item }">
                {{ item.created_date | formatDate }}
              </template>

              <template v-slot:[`item.status`]="{ item }">
                <v-chip :color="getColor(item.status)" dark>
                  {{ item.status }}
                </v-chip>
              </template>

              <template v-slot:[`item.file`]="{ item }">
                <a v-if="Object.keys(item.file).length" :href="`${path_file}${item.file.file_path}`" target="_blank">
                  <v-icon color="info">mdi-file-download</v-icon>
                </a>
              </template>

              <template v-slot:[`item.cover`]="{ item }">
                <v-list-item-avatar tile class="align-self-center" color="hsl(255, 3%, 18%,0)">
                  <v-img v-if="Object.keys(item.cover).length" :src="`${path_file}${item.cover.file_path}`" />
                </v-list-item-avatar>
              </template>

              <template v-slot:[`item.sort`]="{ item }">
                {{ item.sort + 1 }}
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-btn class="px-2 ml-1" min-width="0" color="success" @click="editItem(item)" small>
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
      <!-- </v-tab-item>
      </v-tabs> -->
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANTS
 */
import { STATUS } from "@/constants/status"
import { LANGUAGES } from "@/constants/language"
import { PATHFILE } from "@/constants/config"

/**
 * CONTROLLER
 */
import { getEstimationFieldAll } from "@/controllers/estimation/estimation_field.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "News",
  data: () => ({
    fields: [],
    status: STATUS,
    languages: LANGUAGES,
    headers: [
      {
        text: "Id",
        align: "start",
        
        value: "estimation_field_id"
      },
      { text: "title", value: "title" },
      { text: "unit", value: "unit" },
      { text: "title_en", value: "title_en" },
      { text: "unit_en", value: "unit_en" },
      // { text: "lang", value: "lang" },
      { text: "Actions", value: "actions", align: "center", width: "15%", sortable: false }
    ],
    path_file: PATHFILE
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.fields = await getEstimationFieldAll()
        this.setLoading(false)
      } catch (e) {
        this.errorHandle(e)
      }
    },
    getColor(status) {
      if (status === "ACTIVE") return `success`
      else if (status === "INACTIVE") return `error`
    },
    onTabChange(e) {
      this.initialize(e)
    },
    editItem(item) {
      this.$router.push({ path: `estimation-field-detail/${item.estimation_field_id}` })
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
