<template>
  <v-container>
    <base-material-card icon="mdi-book-information-variant" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">
          {{ $t("product_category_detail") }} {{ productCategory.title }}
        </div>
      </template>

      <base-upload-image
        v-model="file"
        :defaultValue="productCategory.file"
        width="320px"
        height="275px"
        class="mb-2"
      />
      <p class="text-center red--text">*แนะนำขนาดรูปภาพ 320 x 275 px</p>

      <v-form @submit.prevent="setLoading(true), save()" ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="text-no-wrap" align="center">
            <v-col cols="12">
              <v-select
                v-model="productCategory.status"
                :items="status"
                item-text="text"
                item-value="value"
                :label="$t('status')"
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" min-width="100" class="pl-0" :loading="$store.state.isloading" @click="cancel">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"
import { PATHFILE } from "@/constants/config"

/**
 * FUNCTIONS
 */
import {
  putSiteRefProductCategory,
  getSiteRefProductCategoryByID
} from "@/controllers/site_ref/site_ref_product_category.controller"

/**
 * LIBs
 */
import { mapMutations } from "vuex"

/**
 * UTILS
 */
import { rules } from "@/utils/validates"

import axios from "axios"
export default {
  data: () => ({
    rules: rules,
    productCategory: {},
    pathFile: PATHFILE,
    languages: LANGUAGES,
    status: STATUS,
    file: null
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.productCategory = await getSiteRefProductCategoryByID({ id: this.$route.params.productCategoryId })
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },

    async save() {
      const isValid = this.$refs.form.validate()
      if (isValid) {
        await putSiteRefProductCategory(
          {
            productCategory: this.productCategory,
            file: this.file
          },
          this.resultHandle,
          this.errorHandle
        )
        this.setLoading(true)
        this.$router.back()
      }
      this.setLoading(false)
    },
    cancel() {
      this.setLoading(true)
      this.$router.back()
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style scoped>
.carousel-icon-close {
  margin: auto !important;
  left: 0 !important;
  right: 0 !important;
  /* bottom: 0 !important; */
  top: 30% !important;
}
</style>
