/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
; ("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API } from "@/constants/config"
import { EP_COUNTRY } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet } from "@/utils/axios"

export async function getCountryAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_COUNTRY.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

// export async function postCountry({ country }, resultHandle, errorHandle) {
//   try {
//     const token = await getAuthToken()
//     const payload = country
//     const url = `${API}${EP_COUNTRY.CREATE}`
//     const { code, data, message } = await axiosPost(url, payload, token)

//     if (code == 201) {
//       resultHandle(message)
//       return data
//     }
//     errorHandle(message)
//     return data
//   } catch (error) {
//     return errorHandle(error)
//   }
// }

// export async function putCountry({ country }, resultHandle, errorHandle) {
//   try {
//     const token = await getAuthToken()
//     const payload = country
//     const url = `${API + EP_COUNTRY.UPDATE}/${country.country_id}`
//     const { code, data, message } = await axiosPut(url, payload, token)

//     if (code == 200) {
//       resultHandle(message)
//       return data
//     }
//   } catch (error) {
//     return errorHandle(error)
//   }
// }
