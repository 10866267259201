<template>
  <v-container>
    <base-material-card icon="mdi-cookie" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("cookie") }}</div>
      </template>

      <v-tabs vertical>
        <v-tab v-for="(cookie, i) in cookies" :key="`company-${i}`">
          {{ cookie.lang }}
        </v-tab>

        <v-tab-item v-for="(cookie, i) in cookies" :key="`company-${i}`">
          <form @submit.prevent="setLoading(true), saveHandle(i)">
            <v-container fluid>
              <v-row class="text-no-wrap" align="center">
                <v-col cols="12" md="12">
                  <base-ck-editor
                    v-model="cookie.cookie_policy"
                    :text.sync="cookie.cookie_policy"
                    placeholder="วิสัยทัศน์"
                  />
                </v-col>

                <v-col cols="12" md="12">
                  <v-select
                    v-model="cookie.status"
                    :items="status"
                    item-text="text"
                    item-value="value"
                    label="สถานะ"
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
                  {{ $t("save") }}
                </v-btn>
              </v-card-actions>
            </v-container>
          </form>
        </v-tab-item>
      </v-tabs>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANTS
 */
import { STATUS } from "@/constants/status"
import { LANGUAGES } from "@/constants/language"

/**
 * CONTROLLER
 */
import { getCookieAll, putCookie } from "@/controllers/cookie/cookie.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "Cookie",
  data: () => ({
    cookies: [],
    status: STATUS,
    languages: LANGUAGES
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.cookies = await getCookieAll()
        this.setLoading(false)
      } catch (e) {
        this.errorHandle(e)
      }
    },
    async saveHandle(i) {
      try {
        await putCookie({ cookie: this.cookies[i] }, this.resultHandle, this.errorHandle)
      } catch (e) {
        console.log(e)
      }
    },

    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
