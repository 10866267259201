<template>
  <base-material-card icon="mdi-domain" color="primary">
    <template v-slot:after-heading>
      <div class="font-weight-light card-title mt-2">
        {{ $t("standard") }}
      </div>
    </template>

    <v-dialog v-model="dialogDelete" max-width="300">
      <v-card>
        <v-card-title>
          <p>
            ยืนยันการลบ
          </p>
          <v-spacer />
          <v-icon aria-label="Close" @click="closeDelete">
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="pb-6 text-center">
          <v-btn class="mr-1" color="error" dark @click="closeDelete"> {{ $t("cancel") }} </v-btn>
          <v-btn class="mr-1" color="success" dark @click="deleteItemConfirm"> {{ $t("delete") }} </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-tabs vertical>
      <v-tab v-for="(standard, i) in standards" :key="`standard-${i}`">
        {{ standard.lang }}
      </v-tab>
      <v-tab-item touchless v-for="(standard, i) in standards" :key="`standard-${i}`">
        <form @submit.prevent="save(i)">
          <v-container fluid>
            <v-row class="text-no-wrap" align="center">
              <v-col>
                <base-ck-editor v-model="standard.standard" placeholder="วิสัยทัศน์" />
              </v-col>
            </v-row>
            <v-row class="text-no-wrap" align="center">
              <v-col>
                <v-data-table
                  :headers="headers"
                  :items="standard.standard_category"
                  sort-by="calories"
                  class="elevation-1"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title> {{ $t("standard_category") }} </v-toolbar-title>

                      <v-spacer></v-spacer>
                      <v-btn color="primary" dark class="mb-2 mx-1" @click="sortItem()">
                        {{ $t("standard_category_sort") }}
                      </v-btn>
                      <v-btn
                        color="primary"
                        dark
                        class="mb-2 mx-1"
                        @click="createItem(standard.standard_id, standard.lang)"
                      >
                        {{ $t("standard_category_create") }}
                      </v-btn>

                      <v-dialog v-model="dialogSort" max-width="900px">
                        <v-card>
                          <v-card-title class="headline"> {{ $t("standard_category_sort") }} </v-card-title>
                          <v-card-text>
                            <v-container>
                              <base-dnd-table
                                :headers="header_list"
                                :items.sync="standard.standard_category"
                                :dragOptions="dragOptions"
                                order
                              >
                                <tr v-for="item in standard.standard_category" :key="item.standard_category_id">
                                  <td scope="row" style="cursor: all-scroll">
                                    <v-icon>mdi-format-align-justify</v-icon>
                                  </td>
                                  <td>{{ item.title }}</td>
                                  <td>
                                    <v-list-item-avatar tile class="align-self-center" color="hsl(255, 3%, 18%, 0)">
                                      <v-img
                                        v-if="Object.keys(item.file).length"
                                        :src="`${path_file}${item.file.file_path}`"
                                      />
                                    </v-list-item-avatar>
                                  </td>
                                  <td>{{ item.sort + 1 }}</td>
                                </tr>
                              </base-dnd-table>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" dark @click="closeSort">{{ $t("cancel") }}</v-btn>
                            <v-btn color="primary" dark @click="saveSort(i)">{{ $t("save") }}</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn
                      class="px-2 ml-1"
                      min-width="0"
                      color="success"
                      @click="editItem(item, standard.standard_id)"
                      small
                    >
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn class="px-2 ml-1" min-width="0" color="error" @click="deleteItem(item, i)" small>
                      <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                  </template>

                  <template v-slot:[`item.status`]="{ item }">
                    <base-switch
                      v-model="item.status"
                      :height="26"
                      :width="99"
                      :font-size="5"
                      :color="{ checked: '#4CAF50', unchecked: '#FF5252' }"
                      :labels="{ checked: 'ACTIVE', unchecked: 'INACTIVE' }"
                      @change="onChangeEventHandler(item, $event)"
                    />
                  </template>

                  <template v-slot:[`item.sort`]="{ item }">
                    {{ item.sort + 1 }}
                  </template>

                  <template v-slot:[`item.file`]="{ item }">
                    <v-list-item-avatar tile class="align-self-center" color="hsl(255, 3%, 18%, 0)">
                      <v-img :src="item.file.file_path ? `${path_file}${item.file.file_path}` : item.file" />
                    </v-list-item-avatar>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>

            <v-row class="text-no-wrap" align="center">
              <v-col>
                <v-select
                  v-model="standard.status"
                  :items="status"
                  item-text="text"
                  item-value="value"
                  label="สถานะ"
                  outlined
                ></v-select>
              </v-col>
            </v-row>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-container>
        </form>
      </v-tab-item>
    </v-tabs>
  </base-material-card>
</template>

<script>
/**
 * CONSTANT
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"
import { PATHFILE } from "@/constants/config"

export default {
  name: "Standard",
  data: () => ({
    languages: LANGUAGES,
    status: STATUS,
    path_file: PATHFILE,
    headers: [
      {
        text: "ID",
        align: "start",
        
        value: "standard_category_id"
      },
      { text: "title", value: "title" },
      { text: "file", value: "file" },
      { text: "sort", value: "sort" },
      { text: "status", value: "status" },
      { text: "Actions", value: "actions", align: "center", width: "15%", sortable: false }
    ],

    /**
     * SORT
     */
    header_list: [
      { text: "", value: "menu_id" },
      { text: "title", value: "title" },
      { text: "file", value: "file" },
      { text: "sort", value: "sort" }
    ],
    dialogDelete: false,
    delete_item: null,
    delete_index: null,
    dialogSort: false,
    dragOptions: {
      animation: 200,
      group: "description",
      disabled: false,
      ghostClass: "ghost"
    }
  }),
  props: {
    value: {
      type: Array,
      default: null
    }
  },
  computed: {
    standards: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    }
  },
  methods: {
    createItem(id, lang) {
      this.$router.push({ path: `standard-category-create/${id}?lang=${lang}` })
    },
    editItem(item, id) {
      this.$router.push({ path: `standard-category-detail/${id}/${item.standard_category_id}` })
    },
    save(i) {
      try {
        this.$emit("submit", i)
      } catch (e) {
        console.log(e)
      }
    },
    sortItem() {
      this.dialogSort = true
    },
    closeSort() {
      this.dialogSort = false
    },
    saveSort(i) {
      this.$emit("submitSort", i)
      this.dialogSort = false
    },
    onChangeEventHandler(item, e) {
      this.$emit("onStatusChange", { item, value: e })
    },
    deleteItem(item, i) {
      this.delete_item = item
      this.dialogDelete = true
      this.delete_index = i
    },
    closeDelete() {
      this.delete_item = null
      this.dialogDelete = false
    },
    deleteItemConfirm() {
      this.$emit("onDelete", { delete_item: this.delete_item, delete_index: this.delete_index })
      this.closeDelete()
    }
  }
}
</script>

<style></style>
