<template>
  <v-container>
    <base-material-card icon="mdi-map-marker" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("branch") }}</div>
      </template>

      <v-dialog v-model="dialogDelete" max-width="300">
        <v-card>
          <v-card-title>
            <p>
              ยืนยันการลบ
            </p>
            <v-spacer />
            <v-icon aria-label="Close" @click="closeDelete">
              mdi-close
            </v-icon>
          </v-card-title>

          <v-card-text class="pb-6 text-center">
            <v-btn class="mr-1" color="error" dark @click="closeDelete"> {{ $t("cancel") }} </v-btn>
            <v-btn class="mr-1" color="success" dark @click="deleteItemConfirm"> {{ $t("delete") }} </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-tabs vertical>
        <v-tab v-for="(language, i) in languages" :key="`branch-${i}`" @change="onTabChange(language.lang_code)">
          {{ language.lang_code }}
        </v-tab>

        <v-tab-item touchless v-for="(language, i) in languages" :key="`branch-${i}`">
          <v-container fluid>
            <v-row align="center">
              <v-col>
                <v-data-table :headers="headers" :items="branchs" class="elevation-1 table" :calculate-widths="true">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" dark class="mb-2" @click="createBranch(language.lang_code)">
                        {{ $t("branch_create") }}
                      </v-btn>
                    </v-toolbar>
                  </template>

                  <template v-slot:[`item.branch`]="{ item }">
                    <div class="text-overflow">
                      {{ item.branch }}
                    </div>
                  </template>

                  <template v-slot:[`item.address`]="{ item }">
                    <div class="text-overflow">
                      {{ item.address }}
                    </div>
                  </template>

                  <template v-slot:[`item.status`]="{ item }">
                    <base-switch
                      v-model="item.status"
                      :height="26"
                      :width="99"
                      :font-size="5"
                      :color="{ checked: '#4CAF50', unchecked: '#FF5252' }"
                      :labels="{ checked: 'ACTIVE', unchecked: 'INACTIVE' }"
                      @change="setLoading(true), onChangeEventHandler(item, $event)"
                    />
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn class="px-2 ml-1" min-width="0" color="success" @click="editItem(item)" small>
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn class="px-2 ml-1" min-width="0" color="error" @click="deleteItem(item)" small>
                      <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANTS
 */
import { STATUS } from "@/constants/status"
import { LANGUAGES } from "@/constants/language"
import { PATHFILE } from "@/constants/config"

/**
 * CONTROLLER
 */
import { getBranchByLang, putStatus, deleteBranch } from "@/controllers/branch/branch.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "Branch",
  data: () => ({
    branchs: [],
    status: STATUS,
    languages: LANGUAGES,
    path_file: PATHFILE,
    dialogDelete: false,
    delete_item: null,
    headers: [
      {
        text: "ID",
        align: "start",
        
        value: "branch_id",
        width: "5%"
      },
      { text: "branch", value: "branch" },
      { text: "address", value: "address" },
      { text: "status", value: "status" },
      { text: "Actions", value: "actions", align: "center", width: "15%", sortable: false }
    ]
  }),
  created() {
    this.setLoading(true)
    this.initialize("TH")
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize(lang) {
      try {
        this.branchs = await getBranchByLang({ lang })
        this.setLoading(false)
      } catch (e) {
        this.errorHandle(e)
      }
    },
    onTabChange(e) {
      this.initialize(e)
    },
    editItem(item) {
      this.$router.push({ path: `branch-detail/${item.branch_id}` })
    },
    createBranch(lang) {
      this.$router.push({ path: `branch-create?lang=${lang}` })
    },
    async onChangeEventHandler(item, { value }) {
      if (value) await putStatus({ branch: item, status: "ACTIVE" }, this.resultHandle, this.errorHandle)
      else await putStatus({ branch: item, status: "INACTIVE" }, this.resultHandle, this.errorHandle)
    },
    deleteItem(item) {
      this.delete_item = item
      this.dialogDelete = true
    },
    closeDelete() {
      this.delete_item = null
      this.dialogDelete = false
    },
    async deleteItemConfirm() {
      await deleteBranch({ branch: this.delete_item }, this.resultHandle, this.errorHandle, this.deleteResultHandle)
      this.dialogDelete = false
    },
    deleteResultHandle() {
      this.branchs = this.branchs.filter((branch) => branch.branch_id !== this.delete_item.branch_id)
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
