/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API } from "@/constants/config"
import { EP_PRODUCT_CATEGORY_DOCS } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosCreateFile, axiosPatch, axiosPatchFile } from "@/utils/axios"

export async function getProductCategoryDocsAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_PRODUCT_CATEGORY_DOCS.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getProductCategoryDocsById({ cate_id, id }) {
  try {
    const token = await getAuthToken()
    const url = API + EP_PRODUCT_CATEGORY_DOCS.FINDBYID.replace(":id", cate_id).replace(":doc_id", id)
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function postProductCategoryDocs({ cate_id, docs, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const url = API + EP_PRODUCT_CATEGORY_DOCS.CREATE.replace(":id", cate_id)
    let formData = new FormData()
    formData.append("title", docs.title)
    if (file) {
      formData.append("docs", file)
    }
    const { code, data, message } = await axiosCreateFile(url, formData, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    errorHandle(message)
    return message
  }
}

export async function putProductCategoryDocs({ cate_id, id, docs, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const url = API + EP_PRODUCT_CATEGORY_DOCS.UPDATE.replace(":id", cate_id).replace(":doc_id", id)
    let formData = new FormData()
    formData.append("title", docs.title)
    formData.append("status", docs.status)
    if (file) {
      formData.append("docs", file)
    }
    const { code, data, message } = await axiosPatchFile(url, formData, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    errorHandle(message)
    return message
  }
}

export async function putProductCategoryDocsStatus({ cate_id, id, docs }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const url = API + EP_PRODUCT_CATEGORY_DOCS.UPDATE_STATUS.replace(":id", cate_id).replace(":doc_id", id)
    const params = {
      status: docs.status
    }
    const { code, data, message } = await axiosPatch(url, params, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    errorHandle(message)
    return message
  }
}

export async function putProductCategoryDocsSort({ cate_id, docs }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = docs.map((item) => {
      return {
        product_category_doc_id: item.product_category_doc_id,
        sort: item.sort
      }
    })
    const url = `${API}${EP_PRODUCT_CATEGORY_DOCS.UPDATE_SORT.replace(":id", cate_id)}`

    const { code, message, data } = await axiosPatch(url, payload, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function deleteProductCategoryDocs({ cate_id, id }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const url = API + EP_PRODUCT_CATEGORY_DOCS.UPDATE_STATUS.replace(":id", cate_id).replace(":doc_id", id)
    const params = {
      status: "DELETED"
    }
    const { code, data, message } = await axiosPatch(url, params, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    errorHandle(message)
    return message
  }
}
