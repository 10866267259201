/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
; ("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_SHAPE } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut } from "@/utils/axios"
import { axiosPost } from "../../utils/axios"

export async function getShapeAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_SHAPE.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getShapeByID({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_SHAPE.FINDBYID}/${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getShapeByLang({ lang }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_SHAPE.FINDBYLANG}/${lang}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function getShapeForSelect({ lang }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_SHAPE.FOR_SELECT}/${lang}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function postShape({ shape }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = shape
    const url = `${API}${EP_SHAPE.CREATE}`
    const { code, data, message } = await axiosPost(url, payload, token)

    if (code == 201) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putShape({ shape }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = shape
    const url = `${API + EP_SHAPE.UPDATE}/${shape.shape_id}`
    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putShapeSort({ shapes }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = shapes
    const url = `${API}${EP_SHAPE.UPDATE_SORT}`

    const { code, message, data } = await axiosPut(url, payload, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}
