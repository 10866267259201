/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_HOME_PRODUCT_SERVICE_HEADER } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPost, axiosPut, axiosPatch, axiosDelete } from "@/utils/axios"

export async function getHomeProductServiceHeaderAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_HOME_PRODUCT_SERVICE_HEADER.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getHomeProductServiceHeaderById({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_HOME_PRODUCT_SERVICE_HEADER.FINDBYID}/${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getHomeProductServiceHeaderByLang({ lang, sort = "YES" }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_HOME_PRODUCT_SERVICE_HEADER.FINDBYLANG}/${lang}?sort=${sort}`
    const { code, data, message } = await axiosGet(url, token)
    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function postHomeProductServiceHeader({ title }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = title
    const url = `${API}${EP_HOME_PRODUCT_SERVICE_HEADER.CREATE}`

    const { code, data, message } = await axiosPost(url, payload, token)

    if (code == 201) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putHomeProductServiceHeader({ title }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = title
    const url = `${API + EP_HOME_PRODUCT_SERVICE_HEADER.UPDATE}/${title.product_service_header_id}`

    const { code, data, message } = await axiosPatch(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    // const { message } = error.response.data
    console.log(error)
    return errorHandle(error)
  }
}
