<template>
  <v-container>
    <base-material-card icon="mdi-calculator-variant" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("estimation_cal") }}</div>
      </template>
      <v-form @submit.prevent="setLoading(true), calculate()" ref="form">
        <v-container fluid>
          <v-row class="text-no-wrap" align="center">
            <v-flex xs12></v-flex>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="productGroupId"
                :items="productGroups"
                item-text="title"
                item-value="estimation_product_group_id"
                :label="$t('product_group')"
                :rules="[rules.required]"
                @change="getEstimationProductCategory"
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="productCategory"
                :items="productCategories"
                item-text="title"
                :label="$t('product_category')"
                :rules="[rules.required]"
                return-object
                @change="selectCategory($event)"
                outlined
              ></v-autocomplete>
            </v-col>
            <!-- <v-col cols="auto" v-for="(shape, index) in shapes" :key="`shape-${index + 1}`">
              <v-img
                :key="`img-${index + 1}`"
                width="150px"
                height="150px"
                contain
                :src="`${filePath}${shape.file.file_path}`"
                @click="selectShape(shape)"
              ></v-img>
            </v-col> -->
            <v-flex xs12></v-flex>
            <v-col cols="12" md="6" class="d-flex justify-center align-center flex-column">
              <v-img
                class="mb-4"
                contain
                width="75%"
                height="75%"
                max-height="400px"
                max-width="400px"
                :src="imagePreview"
              >
              </v-img>
            </v-col>
            <v-col cols="12" md="6" class="d-flex flex-column justify-center">
              <v-row class="mb-0" v-if="shapes && shapes.value && shapes.value.length">
                <v-col class="d-flex align-center py-2" cols="12" md="3">
                  <div class="text-right">{{ shapes.title }}:</div>
                </v-col>
                <v-col class="d-flex align-center py-2" cols="12" md="7">
                  <v-autocomplete
                    v-model="shapeId"
                    :items="shapes.value"
                    item-text="title"
                    item-value="estimation_shape_id"
                    :label="shapes.title"
                    @change="selectShape()"
                    outlined
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col class="d-flex align-center py-2" cols="12" md="2">
                  <div class="d-table-cell ml-2 w-25 text-left">{{ shape.unit }}</div>
                </v-col>
              </v-row>
              <v-row class="mb-0" v-if="thickness && thickness.value && thickness.value.length">
                <v-col class="d-flex align-center py-2" cols="12" md="3">
                  <div class="text-right">{{ thickness.title }}:</div>
                </v-col>
                <v-col class="d-flex align-center py-2" cols="12" md="7">
                  <v-autocomplete
                    v-model="colorId"
                    :items="thickness.value"
                    item-text="title"
                    item-value="estimation_color_id"
                    :label="thickness.title"
                    :rules="[rules.required]"
                    hide-details="auto"
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col class="d-flex align-center py-2" cols="12" md="2">
                  <div class="d-table-cell ml-2 w-25 text-left">{{ thickness.unit }}</div>
                </v-col>
              </v-row>
              <v-row v-for="(field, index) in fields" :key="`field-${index}`" no-gutters>
                <v-col class="d-flex align-center py-2" cols="12" md="3">
                  <div class="text-right">{{ field.title }}:</div>
                </v-col>
                <v-col class="d-flex align-center py-2" cols="12" md="7">
                  <v-text-field
                    v-model="estimation[`${field.key}`]"
                    type="number"
                    class="elevation-0 px-2"
                    :label="field.label"
                    :rules="[rules.required]"
                    solo
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex align-center py-2" cols="12" md="2">
                  <div class="d-table-cell ml-2 w-25 text-left">{{ field.unit }}</div>
                </v-col>
              </v-row>
              <v-row class="mb-0" v-if="colors && colors.value && colors.value.length">
                <v-col class="d-flex align-center py-2" cols="12" md="3">
                  <div class="text-right">{{ colors.title }}:</div>
                </v-col>
                <v-col class="d-flex align-center py-2" cols="12" md="7">
                  <v-autocomplete
                    v-model="colorId"
                    :items="colors.value"
                    item-text="title"
                    item-value="estimation_color_id"
                    :label="colors.title"
                    :rules="[rules.required]"
                    hide-details="auto"
                    @change="selectColor()"
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col class="d-flex align-center py-2" cols="12" md="2">
                  <div class="d-table-cell ml-2 w-25 text-left">{{ colors.unit }}</div>
                </v-col>
              </v-row>
              <v-col cols="12" md="7" class="ma-auto">
                <v-btn
                  class="pl-0 my-4 mx-auto py-2"
                  color="primary"
                  type="submit"
                  min-width="200"
                  max-width="300"
                  :loading="$store.state.isloading"
                  v-if="fields && fields.length"
                >
                  คำนวน
                </v-btn>
              </v-col>
            </v-col>
          </v-row>
        </v-container>
      </v-form>

      <div class="separator mt-4 mb-6" v-if="hasResult">{{ $t("result") }}</div>
      <v-container class="text-center" v-if="hasResult">
        <v-row>
          <v-col cols="12" md="6" v-for="(result, index) in results" :key="`result${index}`">
            <v-row>
              <v-col class="d-flex align-center py-4" cols="12" md="3">
                <p class="ml-md-auto mb-0">{{ result.title }}</p>
              </v-col>
              <v-col class="d-flex align-center py-4" cols="8" md="5">
                <v-text-field
                  v-model="result.value"
                  class="input-custom"
                  outlined
                  solo
                  readonly
                  disabled
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col class="d-flex align-center py-4" cols="4" md="1">
                <!-- <p >{{ result.unit }}</p> -->
                <p>{{ result.unit }}</p>
              </v-col>
              <v-col v-if="result.price" class="d-flex align-center py-4" cols="12" md="3">
                <!-- <p class="primary--text">{{ result.unit }}</p> -->
                <p class="primary--text">{{ `${result.price} บาท` }}</p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-btn class="mt-6" color="primary" min-width="300" @click="reset()">เริ่มใหม่</v-btn>
      </v-container>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { PATHFILE } from "@/constants/config"

/**
 * FUNCTIONS
 */
import { getField, calculateEstimation, getProduct } from "@/controllers/estimation/estimation_cal.controller"
import { getEstimationProductGroupByLang } from "@/controllers/estimation/estimation_product_group.controller"
import {
  getByEstimationProductGroupFilter as getEstimationProductCategory,
  getEstimationProductCategoryDetail
} from "@/controllers/estimation/estimation_product_category.controller"

/**
 * LIBs
 */
import { mapMutations } from "vuex"

/**
 * UTILS
 */
import { rules } from "@/utils/validates"

export default {
  data: () => ({
    rules: rules,
    filePath: PATHFILE,
    languages: LANGUAGES,
    lang: "th",
    isValid: false,
    isActive: false,
    hasResult: false,
    productGroups: [],
    productCategories: [],
    shapes: [],
    colors: [],
    thickness: [],
    productGroupId: null,
    productCategory: null,
    categoryId: null,
    shape: {},
    shapeId: null,
    colorId: null,
    fields: [],
    imagePreview: "",
    estimation: {},
    results: [],
    title: ""
  }),
  created() {
    // this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.setLoading(false)
        this.getEstimationProductGroup()
      } catch (e) {
        console.log(e)
      }
    },
    async getEstimationProductGroup() {
      this.productGroups = await getEstimationProductGroupByLang({ lang: this.lang })

      this.productCategories = []
      this.shapes = []
      this.colors = []
      this.thickness = []
      this.fields = null
      this.categoryId = null
      this.shape = {}
      this.shapeId = null
      this.colorId = null
      this.imagePreview = null
    },
    async getEstimationProductCategory(id) {
      this.productCategories = []
      this.productCategories = await getEstimationProductCategory({ id })
      this.shapes = []
      this.colors = []
      this.thickness = []
      this.fields = null
      this.shape = {}
      this.shapeId = null
      this.colorId = null
      this.imagePreview = null
    },
    async selectCategory(category) {
      this.thickness = []
      const {
        estimation_product_category_id: id,
        file: { file_path }
      } = category
      this.categoryId = id
      this.imagePreview = `${PATHFILE}${file_path}`

      const { field, file, shape, color, title } = await getEstimationProductCategoryDetail({ id })

      if (field && field.length) this.fields = field
      else {
        if (shape) this.shapeId = shape.value[0].estimation_shape_id
        this.selectShape()
      }
      if (file) this.imagePreview = `${PATHFILE}${file.file_path}`
      if (shape) this.shapes = shape
      if (color) this.colors = color
      if (title) this.title = title
    },
    async selectShape() {
      this.fields = []
      this.estimation = {}
      const { field, thickness, file } = await getField({
        categoryId: this.categoryId,
        shapeId: this.shapeId
      })
      console.log(field)
      this.fields = field
      if (thickness) this.thickness = thickness
      if (file) this.imagePreview = `${PATHFILE}${file.file_path}`
    },
    async selectColor() {
      const { file_path } = await getProduct({
        color_id: this.colorId,
        product_category_id: this.productCategory
      })
      this.imagePreview = `${PATHFILE}${file_path}`
    },
    active() {
      this.setLoading(true)
      setTimeout(() => {
        this.isActive = !this.isActive
        this.setLoading(false)
      }, 500)
    },
    reset() {
      this.setLoading(true)
      this.results = []
      this.hasResult = false
      setTimeout(() => {
        this.isActive = !this.isActive
        this.setLoading(false)
      }, 500)
    },
    async calculate() {
      const isValid = this.$refs.form.validate()
      if (isValid) {
        this.setLoading(true)
        this.results = await calculateEstimation(
          {
            fields: this.estimation,
            categoryId: this.categoryId,
            shapeId: this.shapeId,
            colorId: this.colorId
          },
          this.resultHandle,
          this.errorHandle
        )
        if (this.results.length) {
          this.hasResult = true
        }
        // this.$router.back()
      }
      this.setLoading(false)
    },
    cancel() {
      this.setLoading(true)
      this.$router.back()
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>
