/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
; ("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_DOWNLOAD } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut, axiosUpdateFile, axiosCreateFile, axiosDelete } from "@/utils/axios"

export async function getDownloadAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_DOWNLOAD.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getDownloadByDownloadHeaderID({ download_header_id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_DOWNLOAD.FINDBYDOWNLOADHEADER}/${download_header_id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
    return error
  }
}

export async function getDownloadByID({ download_id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_DOWNLOAD.FINDBYID}/${download_id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    console.log(error)
    return error
  }
}

export async function postDownload({ download, cover_download, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    let formData = new FormData()
    formData.append("download_header_id", download.download_header_id)
    formData.append("product_group_id", download.product_group_id)
    formData.append("title", download.title)
    formData.append("created_date", download.created_date)
    formData.append("lang", download.lang)
    formData.append("link", download.link)
    formData.append("file", file)
    formData.append("cover_download", cover_download)

    const url = `${API}${EP_DOWNLOAD.CREATE}`
    const { code, data, message } = await axiosCreateFile(url, formData, token)
    if (code == 201) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putDownload({ download, cover_download, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    let formData = new FormData()
    formData.append("download_header_id", download.download_header_id)
    formData.append("product_group_id", download.product_group_id)
    formData.append("title", download.title)
    formData.append("created_date", download.created_date)
    formData.append("status", download.status)
    formData.append("lang", download.lang)
    formData.append("link", download.link)
    formData.append("file", file)
    formData.append("cover_download", cover_download)

    const url = `${API}${EP_DOWNLOAD.UPDATE}/${download.download_id}`
    const { code, data, message } = await axiosUpdateFile(url, formData, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putDownloadSort({ downloads }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = downloads
    const url = `${API}${EP_DOWNLOAD.UPDATE_SORT}`

    const { code, message, data } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putStatus({ download, status }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    const payload = { status: status }
    const url = `${API}${EP_DOWNLOAD.UPDATE_STATUS}/${download.download_id}`

    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function deleteDownload({ download }, resultHandle, errorHandle, deleteResultHandle) {
  try {
    const token = await getAuthToken()

    const url = `${API}${EP_DOWNLOAD.DELETE}/${download.download_id}`

    const { code, data, message } = await axiosDelete(url, token)

    if (code == 200) {
      deleteResultHandle()
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}
