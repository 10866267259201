<template>
  <v-container id="diamond-site-ref" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="12">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Edit Profile{{ $route.query.item }}</div>

            <div class="subtitle-1 font-weight-light">
              Complete your profile
            </div>
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" md="12">
                  <h1>Diamond Site Ref</h1>
                  <base-ck-editor v-model="textEditor" :text.sync="textEditor" placeholder="Placeholder" />
                  <!-- {{ textEditor }} -->
                </v-col>
                {{ textArea }}
                <v-col cols="12" md="12">
                  <v-textarea name="input-7-1" label="Default style" v-model="textArea" hint="Hint text"></v-textarea>
                  <!-- <v-file-input
                    accept="application/pdf"
                    show-size
                    counter
                    ref="files"
                    multiple
                    label="File input"
                    @change="handleFileUpload"
                  ></v-file-input> -->
                </v-col>
              </v-row>
            </v-container>
            <v-btn color="primary" @click="submit">Submit</v-btn>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex"
import { uploadFile } from "@/controllers/auth/login.controller"
import { getCompanyAll } from "@/controllers/about_us/company.controller"

export default {
  name: "Diamond",
  data() {
    return {
      textEditor: "",
      textArea: null,
      files: null
    }
  },
  created() {
    this.initialize()
  },

  methods: {
    async initialize() {
      console.log("COMPANY VUE")
      const company = await getCompanyAll()
      this.textArea = company[0].headquarters
    },
    modifyUrl(url) {
      let endpoint = url
      endpoint = endpoint.replace("oembed", "iframe")
      endpoint = endpoint.replace("url", "src")
      endpoint = endpoint.replace("watch?v=", "embed/")
      endpoint = endpoint.replace("oembed", "iframe")
      return endpoint
    },
    handleFileUpload() {
      this.files = this.$refs.files.files
    },
    async submit() {
      try {
        // this.loading = true
        console.log(this.textArea)
        // await uploadFile(this.files)
      } catch (error) {
        console.log(error)
      }
    },
    update(text) {
      this.textEditor = text
    }
  },
  computed: {
    ...mapState(["darkMode"])
  }
  // created: function() {
  //   // const darkMode = this.darkMode
  //   // if (darkMode)
  //   //   if (theme == "true")
  //   //   else this.$vuetify.theme.dark = false
  // }
}
</script>
