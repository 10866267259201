<template>
  <v-container>
    <base-material-card icon="mdi-home" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("menu_sub_create") }}</div>
      </template>

      <v-form @submit.prevent="setLoading(true), save()">
        <v-container fluid>
          <v-row class="text-no-wrap" align="center">
            <v-col cols="12">
              <v-autocomplete
                v-model="menuSub.product_category_id"
                :items="productCategories"
                item-text="title"
                item-value="product_category_id"
                :label="$t('product_category')"
                outlined
              ></v-autocomplete>
            </v-col>

            <!-- <v-col cols="12" md="12">
              <v-text-field v-model="menuSub.link" label="link" outlined></v-text-field>
            </v-col> -->

            <!-- <v-col cols="12" md="12">
              <v-select
                v-model="menuSub.lang"
                :items="languages"
                item-text="language"
                item-value="lang_code"
                :label="$t('language')"
                outlined
              ></v-select>
            </v-col> -->
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" min-width="100" class="pl-0" :loading="$store.state.isloading" @click="cancel">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"

/**
 * CONTROLLERS
 */
import { getForSelect as getProductCategory } from "@/controllers/product/product_category.controller"
import { postMenuSub, getProductGroupByMenu } from "@/controllers/menu/menu_sub.controller"
/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "MenuSubCreate",
  data: () => ({
    languages: LANGUAGES,
    status: STATUS,
    menuSub: {},
    menus: [],
    productCategories: []
  }),

  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.menuSub.menu_id = this.$route.params.menuId
        const { product_group_id } = await getProductGroupByMenu({ id: this.$route.params.menuId })
        this.productCategories = await getProductCategory({ id: product_group_id })
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async save() {
      await postMenuSub({ menuSub: this.menuSub }, this.resultHandle, this.errorHandle)
      this.setLoading(true)
      this.$router.back()
    },
    cancel() {
      this.setLoading(true)
      this.$router.back()
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
