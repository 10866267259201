<template>
  <v-container>
    <base-material-card v-for="(icon, i) in icons" :key="`icon-${i}`" icon="mdi-numeric-1-box" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("icon") }}</div>
      </template>

      <form @submit.prevent="setLoading(true), save(i)">
        <v-container fluid>
          <v-row class="text-no-wrap" align="center">
            <v-col cols="12" md="12">
              <base-upload-image v-model="file" :defaultValue="icon.file" />
            </v-col>
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </form>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONTROLLER
 */
import { getIconAll, putIcon } from "@/controllers/icon/icon.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "Icon",
  data: () => ({
    icons: {},
    file: null
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),

    async initialize() {
      try {
        this.icons = await getIconAll()
        this.setLoading(false)
      } catch (e) {
        this.errorHandle(e)
      }
    },
    async save(i) {
      await putIcon({ icon: this.icons[i], file: this.file }, this.resultHandle, this.errorHandle)
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
