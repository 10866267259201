/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
; ("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_ESTIMATION_PRODUCT_GROUP } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut, axiosCreateFile, axiosPost, axiosUpdateFile} from "@/utils/axios"

export async function getEstimationProductGroupAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_ESTIMATION_PRODUCT_GROUP.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getEstimationProductGroupById({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_ESTIMATION_PRODUCT_GROUP.FINDBYID}/${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getEstimationProductGroupByLang({ lang }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_ESTIMATION_PRODUCT_GROUP.FINDBYLANG}/${lang}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function postEstimationProductGroup({ estimationProductGroup, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_ESTIMATION_PRODUCT_GROUP.CREATE}`
    let formData = new FormData()
    formData.append("title", estimationProductGroup.title)
    formData.append("title_en", estimationProductGroup.title_en)
    formData.append("lang", estimationProductGroup.lang)

    if (file) formData.append("file", file, file.name)

    const { code, data, message } = await axiosCreateFile(url, formData, token)

    if (code == 201) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putEstimationProductGroup({ estimationProductGroup, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_ESTIMATION_PRODUCT_GROUP.CREATE}/${estimationProductGroup.estimation_product_group_id}`
    let formData = new FormData()
    formData.append("title", estimationProductGroup.title)
    formData.append("title_en", estimationProductGroup.title_en)
    formData.append("lang", estimationProductGroup.lang)
    formData.append("status", estimationProductGroup.status)

    if (file) formData.append("file", file, file.name)

    const { code, data, message } = await axiosUpdateFile(url, formData, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putEstimationProductGroupSort({ estimationProductGroups }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = estimationProductGroups
    const url = `${API}${EP_ESTIMATION_PRODUCT_GROUP.UPDATE_SORT}`

    const { code, message, data } = await axiosPut(url, payload, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}
