<template>
  <v-container>
    <base-material-card icon="mdi-wall" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("product_create") }}</div>
      </template>
      <v-container>
        <v-row justify="center">
          <v-col class="text-center" cols="12" sm="6">
            <p>รูปสินค้า</p>
            <base-upload-image v-model="file" width="320px" height="165px" class="mb-2" />
            <p class="text-center red--text">*แนะนำขนาดรูปภาพ 320 x 165 px</p>
          </v-col>
          <!-- <v-col class="text-center" cols="12" sm="6" v-if="product.color_id">
            <p>รูปสี</p>
            <base-upload-image v-model="product_color" width="50px" height="50px" class="mb-2" />
            <p class="text-center red--text">*แนะนำขนาดรูปภาพ 50 x 50 px</p>
          </v-col> -->
        </v-row>
      </v-container>
      <v-form @submit.prevent="setLoading(true), save()" ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="text-no-wrap" align="center">
            <v-col cols="12">
              <v-autocomplete
                v-model="product.product_category_id"
                :items="productCategories"
                item-text="title"
                item-value="product_category_id"
                :label="$t('product_category')"
                :rules="[rules.required]"
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="product.color_id"
                :items="colors"
                item-text="title"
                item-value="color_id"
                :label="$t('color')"
                :rules="[rules.required]"
                outlined
                v-if="colors.length"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <base-file-input
                v-model="files"
                @change="onChangeHandler"
                chip
                label="รูปภาพเพิ่มเติม"
                multiple
                outlined
              />
              <p class="red--text">*แนะนำขนาดรูปภาพ 1270 x 636 px</p>
              <v-row justify="center">
                <v-col cols="12" md="5">
                  <v-carousel
                    cycle
                    height="300"
                    hide-delimiter-background
                    show-arrows-on-hover
                    v-if="previewImages.length"
                  >
                    <v-carousel-item v-for="(slide, i) in previewImages" :key="`image-${i}`">
                      <v-sheet height="100%">
                        <v-row class="fill-height" align="center" justify="center">
                          <v-img :src="slide" />
                        </v-row>
                      </v-sheet>
                    </v-carousel-item>
                  </v-carousel>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" min-width="100" class="pl-0" :loading="$store.state.isloading" @click="cancel">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"

/**
 * FUNCTIONS
 */
import { getForSelect as getProductCategory } from "@/controllers/product/product_category.controller"
import { getByProductCategory as getColor } from "@/controllers/product/color.controller"
import { postProduct } from "@/controllers/product/product.controller"

/**
 * LIBs
 */
import { mapMutations } from "vuex"

/**
 * UTILS
 */
import { rules } from "@/utils/validates"

export default {
  data: () => ({
    rules: rules,
    languages: LANGUAGES,
    product: {
      product_category_id: "",
      color_id: ""
    },
    file: null,
    files: [],
    product_color: null,
    productCategories: [],
    colors: [],
    previewImages: []
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.product.product_category_id = this.$route.params.productCategoryId
        this.getProductCategory()
        this.getColor()
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async getProductCategory() {
      this.productCategories = await getProductCategory({ id: this.$route.params.id })
    },
    async getColor() {
      this.colors = await getColor({ id: this.$route.params.productCategoryId })
    },
    onChangeHandler(e) {
      this.previewImages = []
      if (e) {
        e.forEach((file) => {
          this.previewImages.push(URL.createObjectURL(file))
        })
      }
    },
    async save() {
      const isValid = this.$refs.form.validate()
      if (isValid) {
        await postProduct(
          { product: this.product, file: this.file, product_color: this.product_color, product_additional: this.files },
          this.resultHandle,
          this.errorHandle
        )
        this.setLoading(true)
        this.$router.back()
      }
      this.setLoading(false)
    },
    cancel() {
      this.setLoading(true)
      this.$router.back()
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>
