const IP = "localhost"
const DOMAIN = "revamp-api.knacx.dev"
const PORT = ":3401"
const PATH = "api"

/**
 * DEV
 */
// const API = `http://${IP}${PORT}/${PATH}`
// const PATHFILE = `http://${IP}${PORT}/`

/**
 * PRODUCTION
 */
const API = process.env.VUE_APP_APIURL
const PATHFILE = process.env.VUE_APP_FILEPATH

const APITOKEN = process.env.VUE_APP_APITOKEN
const GOOGLE_API_KEY = process.env.VUE_APP_GOOGLE_API_KEY

export { API, APITOKEN, PATHFILE, GOOGLE_API_KEY }
