<template>
  <base-material-card icon="mdi-domain" color="primary">
    <template v-slot:after-heading>
      <div class="font-weight-light card-title mt-2">
        {{ $t("policy") }}
      </div>
    </template>

    <v-tabs vertical>
      <v-tab v-for="(policy, i) in policies" :key="`policy-${i}`">
        {{ policy.lang }}
      </v-tab>

      <v-tab-item touchless v-for="(policy, i) in policies" :key="`policy-${i}`">
        <form @submit.prevent="save(i)">
          <v-container fluid>
            <v-row class="text-no-wrap" align="center">
              <v-col>
                <base-ck-editor v-model="policy.policy" :text.sync="policy.policy" placeholder="วิสัยทัศน์" />
              </v-col>
            </v-row>

            <v-row class="text-no-wrap" align="center">
              <v-col>
                <v-select
                  v-model="policy.status"
                  :items="status"
                  item-text="text"
                  item-value="value"
                  label="สถานะ"
                  outlined
                ></v-select>
              </v-col>
            </v-row>
            <!-- <v-row class="text-no-wrap" align="center">
              <v-col>
                <v-select
                  v-model="policy.lang"
                  :items="languages"
                  item-text="language"
                  item-value="lang_code"
                  label="ภาษา"
                  outlined
                ></v-select>
              </v-col>
            </v-row> -->

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-container>
        </form>
      </v-tab-item>
    </v-tabs>
  </base-material-card>
</template>

<script>
/**
 * CONSTANTS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"

export default {
  name: "Policy",
  data: () => ({
    headers: [
      {
        text: "ID",
        align: "start",
        
        value: "standard_category_id"
      },
      { text: "title", value: "title" },
      { text: "สถานะ", value: "status" },
      { text: "file", value: "file" },
      { text: "Actions", value: "actions", align: "center", width: "15%", sortable: false }
    ],
    languages: LANGUAGES,
    status: STATUS
  }),
  props: {
    value: {
      type: Array,
      default: null
    }
  },
  computed: {
    policies: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    }
  },
  methods: {
    save(i) {
      try {
        this.$emit("submit", i)
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style></style>
