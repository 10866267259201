<template>
  <base-material-card icon="mdi-account-hard-hat" color="primary">
    <template v-slot:after-heading>
      <div class="font-weight-light card-title mt-2">
        {{ $t("career_executives") }}
      </div>
    </template>

    <v-dialog v-model="dialogDelete" max-width="300">
      <v-card>
        <v-card-title>
          <p>
            ยืนยันการลบ
          </p>
          <v-spacer />
          <v-icon aria-label="Close" @click="closeDelete">
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="pb-6 text-center">
          <v-btn class="mr-1" color="error" dark @click="closeDelete"> {{ $t("cancel") }} </v-btn>
          <v-btn class="mr-1" color="success" dark @click="deleteItemConfirm"> {{ $t("delete") }} </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-tabs v-model="lang" vertical>
      <v-tab v-for="(language, i) in languages" :key="`activity-${i}`" @change="onTabChange(language.lang_code)">
        {{ language.lang_code }}
      </v-tab>

      <v-tab-item touchless v-for="(language, i) in languages" :key="`career-executive-${i}`">
        <v-container fluid>
          <v-row align="center">
            <v-col>
              <v-data-table :headers="headers" :items="career_executives ? career_executives : []" class="elevation-1">
                <template v-slot:top>
                  <v-toolbar flat>
                    <!-- <v-toolbar-title>{{ $t("career_executives") }}</v-toolbar-title> -->

                    <v-spacer></v-spacer>
                    <v-btn color="primary" dark class="mx-2" @click="sortItem">
                      {{ $t("career_executive_sort") }}
                    </v-btn>
                    <v-btn color="primary" dark class="mb-2" @click="createItem(language.lang_code)">
                      {{ $t("career_executive_create") }}
                    </v-btn>

                    <v-dialog v-model="dialogSort" max-width="900px">
                      <v-card>
                        <v-card-title class="headline">{{ $t("career_executive_sort") }}</v-card-title>
                        <v-form>
                          <v-card-text>
                            <v-container>
                              <base-dnd-table :headers="header_list" :items.sync="itemsSort" order>
                                <tr v-for="item in itemsSort" :key="item.career_executive_id">
                                  <td scope="row" style="cursor: all-scroll">
                                    <v-icon>mdi-format-align-justify</v-icon>
                                  </td>
                                  <td>{{ item.name }}</td>
                                  <td>{{ item.status }}</td>
                                  <td>{{ item.sort }}</td>
                                </tr>
                              </base-dnd-table>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" dark @click="closeSort">Cancel</v-btn>
                            <v-btn color="primary" dark @click="saveSort">OK</v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>

                <template v-slot:[`item.name`]="{ item }">
                  <div class="text-no-wrap" v-html="item.name"></div>
                </template>

                <template v-slot:[`item.bio`]="{ item }">
                  <div class="text-overflow" v-html="item.bio"></div>
                </template>

                <template v-slot:[`item.posion_th`]="{ item }">
                  <div class="text-overflow" v-html="item.posion_th"></div>
                </template>

                <template v-slot:[`item.status`]="{ item }">
                  <base-switch
                    v-model="item.status"
                    :height="26"
                    :width="99"
                    :font-size="5"
                    :color="{ checked: '#4CAF50', unchecked: '#FF5252' }"
                    :labels="{ checked: 'ACTIVE', unchecked: 'INACTIVE' }"
                    @change="onChangeEventHandler(item, $event)"
                  />
                </template>

                <template v-slot:[`item.file`]="{ item }">
                  <v-list-item-avatar tile class="align-self-center" color="hsl(255, 3%, 18%,0)">
                    <v-img :src="item.url ? item.url : `${path_file}${item.file.file_path}`" />
                  </v-list-item-avatar>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn class="px-2 ma-1" min-width="0" color="success" @click="editItem(item)" small>
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn class="px-2 ma-1" min-width="0" color="error" @click="deleteItem(item)" small>
                    <v-icon small> mdi-delete </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs>
  </base-material-card>
</template>

<script>
import { mapMutations } from "vuex"

/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"
import { PATHFILE } from "@/constants/config"

/**
 * CONTROLLERS
 */
import { putCareerExecutiveSort } from "@/controllers/career/career_executive.controller"

export default {
  name: "CareerExecutive",
  props: {
    value: {
      type: Array,
      default: []
    },
    image: {
      type: Object
    }
  },
  data: () => ({
    path_file: PATHFILE,
    languages: LANGUAGES,
    status: STATUS,
    dialogSort: false,
    dialogDelete: false,
    delete_item: null,
    lang: 0
  }),

  computed: {
    career_executives: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    },
    itemsSort: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("update:sort", value)
      }
    },
    headers() {
      return [
        {
          text: "ID",
          align: "start",
          value: "career_executive_id"
        },
        { text: "name", value: "name" },
        { text: "sort", value: "sort", align: "center" },
        { text: "bio", value: "bio" },
        { text: this.lang === 0 ? "posion_th" : "posion_en", value: this.lang === 0 ? "posion_th" : "posion_en" },
        { text: "file", value: "file" },
        { text: "status", value: "status" },
        { text: "Actions", value: "actions", align: "center", width: "15%", sortable: false }
      ]
    },
    header_list() {
      return [
        {
          text: "",
          value: "career_executive_id"
        },
        { text: "name", value: "name" },
        { text: "status", value: "status" },
        { text: "sort", value: "sort", align: "center", width: "15%", sortable: false }
      ]
    },
    textLang() {
      return this.lang === 1 ? "EN" : "TH"
    }
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    editItem(item) {
      this.$router.push({ path: `career-executive-detail/${item.career_executive_id}` })
    },

    createItem(lang) {
      this.$router.push({ path: `career-executive-create?lang=${lang}` })
    },

    onChangeEventHandler(item, { value }) {
      this.$emit("onChangeStatus", { item, value })
    },
    onTabChange(e) {
      this.$emit("onTabChange", e)
    },
    sortItem() {
      this.dialogSort = true
    },
    async saveSort() {
      await putCareerExecutiveSort({ career_executives: this.value }, this.resultHandle, this.errorHandle)
      this.$emit("sort:success", this.textLang)
      this.dialogSort = false
    },
    closeSort() {
      this.dialogSort = false
    },
    deleteItem(item) {
      this.delete_item = item
      this.dialogDelete = true
    },
    closeDelete() {
      this.delete_item = null
      this.dialogDelete = false
    },
    async deleteItemConfirm() {
      this.$emit("onDelete", { delete_item: this.delete_item, deleteResultHandle: this.deleteResultHandle })
      this.dialogDelete = false
    },
    deleteResultHandle() {
      this.career_executives = this.career_executives.filter(
        (career_executive) => career_executive.career_executive_id !== this.delete_item.career_executive_id
      )
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style scope>
.text-mb-0 p {
  margin-bottom: 0px !important;
}
</style>
