<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="75">
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="$vuetify.breakpoint.smAndDown ? setDrawer(!drawer) : $emit('input', !value)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title class="hidden-sm-and-down font-weight-light" v-text="$t($route.meta.title)" />

    <v-spacer />

    <!-- <v-text-field :label="$t('search')" color="secondary" hide-details style="max-width: 165px;">
      <template v-if="$vuetify.breakpoint.mdAndUp" v-slot:append-outer>
        <v-btn class="mt-n2" elevation="1" fab small>
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
    </v-text-field> -->

    <!-- <div class="mx-3" />

    <v-btn class="ml-2" min-width="0" text>
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn> -->

    <!-- <v-menu bottom left offset-y origin="top right" transition="scale-transition">
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-badge color="red" overlap bordered>
            <template v-slot:badge>
              <span>5</span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <div>
          <template v-for="(n, i) in notifications">
            <v-hover v-slot="{ hover }" :key="`item-${i}`">
              <v-list-item :class="hover ? 'white--text primary ' : 'white--text'" dense>
                <v-list-item-title v-text="n" />
              </v-list-item>
            </v-hover>
          </template>
        </div>
      </v-list>
    </v-menu> -->

    <v-btn class="ml-2" min-width="0" @click="handleLogout" text>
      <v-icon>mdi-logout</v-icon>
    </v-btn>

    <!-- <v-menu bottom left min-width="200" offset-y origin="top right" transition="scale-transition">
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-list :tile="false" flat nav>
        <template v-for="(p, i) in profile">
          <v-divider v-if="p.divider" :key="`divider-${i}`" class="mb-2 mt-2" />
          <v-hover v-slot="{ hover }" :key="`item-${i}`" v-else>
            <v-list-item
              :active-class="`white--text primary`"
              :class="hover ? 'white--text elevation-6' : 'white--text'"
              :key="`item-${i}`"
              dense
              :to="p.to"
            >
              <v-list-item-title v-text="p.title" />
            </v-list-item>
          </v-hover>
        </template>
        <v-hover v-slot="{ hover }">
          <v-list-item
            :active-class="`white--text primary`"
            :class="hover ? 'white--text elevation-6' : 'white--text'"
            dense
            @click="handleLogout"
          >
            <v-list-item-title v-text="`Log Out`" />
          </v-list-item>
        </v-hover>
      </v-list>
    </v-menu> -->
  </v-app-bar>
</template>

<script>
// Components
import { VHover, VListItem } from "vuetify/lib"

// Utilities
import { mapState, mapMutations } from "vuex"

import { logout } from "@/controllers/auth/login.controller"

export default {
  name: "DashboardCoreAppBar",
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    notifications: [
      "Mike John Responded to your email",
      "You have 5 new tasks",
      "You're now friends with Andrew",
      "Another Notification",
      "Another one"
    ],
    profile: [
      { title: "Profile", to: "/editprofile" },
      { title: "Settings", to: "#" },
      { divider: true }
      // { title: "Log Out", to: "#" }
    ]
  }),

  computed: {
    ...mapState(["drawer"])
  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER"
    }),
    async handleLogout() {
      this.$store.commit("SET_IS_LOADING", false)
      await logout(this.resultHandle)
    },
    async resultHandle() {
      await this.$router.push("/login")
    }
  }
}
</script>
