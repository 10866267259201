<template>
  <v-container>
    <base-material-card icon="mdi-face-agent" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("support_detail") }}</div>
      </template>

      <v-form @submit.prevent="setLoading(true), save()" ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="text-no-wrap" align="center">
            <v-col v-if="type == 'VDO'" cols="12" md="12">
              <base-upload-image v-model="image" :defaultValue="support.cover" width="400px" height="200px" />
              <p class="text-center red--text">*แนะนำขนาดรูปภาพ 400 x 200 px</p>
            </v-col>

            <v-col v-if="type == 'FILE'" cols="12" md="12">
              <base-upload-image v-model="image" :defaultValue="support.cover" width="250px" height="250px" />
              <p class="text-center red--text">*แนะนำขนาดรูปภาพ 250 x 250 px</p>
            </v-col>

            <v-col cols="12" md="12">
              <v-select
                v-model="support.support_header_id"
                :items="support_headers"
                item-text="title"
                item-value="support_header_id"
                :label="$t('support_header')"
                outlined
                return-object
                @change="onSupportdHeader"
              />
            </v-col>

            <v-expand-transition>
              <v-col v-show="filter" cols="12" md="12">
                <v-select
                  v-model="support.product_group_id"
                  :items="products_groups"
                  item-text="title"
                  item-value="product_group_id"
                  :label="$t('product_group')"
                  outlined
                ></v-select>
              </v-col>
            </v-expand-transition>

            <v-col cols="12" md="12">
              <v-text-field v-model="support.title" :label="$t('title')" outlined></v-text-field>
            </v-col>

            <v-expand-transition>
              <v-col v-show="type == 'VDO'" cols="12" md="12">
                <v-text-field v-model="support.link" :label="$t('link')" outlined></v-text-field>
              </v-col>
            </v-expand-transition>

            <v-expand-transition>
              <v-col v-show="type == 'FILE'" cols="12" md="12">
                <base-upload-file v-model="file" :label="$t('file')" :defaultValue="support.file" />
              </v-col>
            </v-expand-transition>

            <v-col cols="12" md="12">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="support.created_date"
                    :label="$t('created_date')"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="[rules.required]"
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker v-model="support.created_date" @input="menu = false" no-title scrollable locale="th" />
              </v-menu>
            </v-col>

            <v-col>
              <v-select
                v-model="support.status"
                :items="status"
                item-text="text"
                item-value="value"
                label="สถานะ"
                outlined
              ></v-select>
            </v-col>

            <!-- <v-col>
              <v-select
                v-model="support.lang"
                :items="languages"
                item-text="language"
                item-value="lang_code"
                label="ภาษา"
                outlined
              ></v-select>
            </v-col> -->
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" min-width="100" class="pl-0" :loading="$store.state.isloading" @click="cancel">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"

/**
 * CONTROLLER
 */
import { getSupportByID, putSupport } from "@/controllers/support/support.controller"
import { getSupportHeaderAll } from "@/controllers/support/support_header.controller"
import { getProductGroupByLang } from "@/controllers/product/product_group.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

/**
 * UTILS
 */
import { rules } from "@/utils/validates"

export default {
  name: "SupportDetail",
  data: () => ({
    rules: rules,
    languages: LANGUAGES,
    status: STATUS,
    support: {},
    support_headers: [],
    products_groups: [],
    file: null,
    image: null,
    menu: false,
    type: null,
    filter: null
  }),

  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.support = await getSupportByID({
          support_id: this.$route.params.support_id
        })
        this.support_headers = await getSupportHeaderAll()
        this.filter = this.support_headers.find((o) => o.support_header_id == this.support.support_header_id).filter
        this.support.created_date = this.support.created_date.substr(0, 10)

        const support_header = this.support_headers.find(
          (support_header) => support_header.support_header_id == this.support.support_header_id
        )
        this.getProductGroup(support_header.lang)
        this.type = support_header.type
        this.filter = support_header.filter
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async getProductGroup(lang) {
      this.products_groups = await getProductGroupByLang({ lang })
    },
    async save() {
      const isValid = this.$refs.form.validate()
      if (isValid) {
        await putSupport(
          { support: this.support, cover_support: this.image, file: this.file },
          this.resultHandle,
          this.errorHandle
        )
        this.$router.back()
      }
      this.setLoading(false)
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    },
    cancel() {
      this.$router.back()
    },
    clearProductGroup(e) {
      if (!e) {
        this.support.product_group_id = null
      }
    },
    onSupportdHeader(e) {
      this.support.support_header_id = e.support_header_id
      this.support.product_group_id = null
      this.support.link = null
      this.filter = e.filter
      this.file = null
      this.type = e.type
      this.getProductGroup(e.lang)
    }
  }
}
</script>

<style></style>
