<template>
  <v-container>
    <base-material-card icon="mdi-swap-horizontal" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("compare_header") }} - {{ title }}</div>
      </template>

      <v-dialog v-model="dialogDelete" max-width="300">
        <v-card>
          <v-card-title>
            <p>ยืนยันการลบ</p>
            <v-spacer />
            <v-icon aria-label="Close" @click="closeDelete"> mdi-close </v-icon>
          </v-card-title>

          <v-card-text class="pb-6 text-center">
            <v-btn class="mr-1" color="error" dark @click="closeDelete"> {{ $t("cancel") }} </v-btn>
            <v-btn class="mr-1" color="success" dark @click="deleteItemConfirm"> {{ $t("delete") }} </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-container fluid>
        <v-row class="text-no-wrap" align="center">
          <v-col>
            <v-data-table :headers="headers" :items="dimensions" class="elevation-1">
              <template v-slot:top>
                <v-toolbar flat>
                  <!-- <v-toolbar-title>{{ $t("dimension") }}</v-toolbar-title> -->

                  <v-spacer></v-spacer>
                  <v-btn color="primary" dark class="mx-2" @click="sortItem">
                    {{ $t("sort") }}
                  </v-btn>

                  <v-btn color="primary" dark class="mx-2" @click="createItem()">
                    {{ $t("create") }}
                  </v-btn>

                  <v-dialog v-model="dialogSort" max-width="900px">
                    <v-card>
                      <v-card-title class="headline">{{ $t("dimension_sort") }}</v-card-title>
                      <v-form>
                        <v-card-text>
                          <v-container>
                            <base-dnd-table
                              :headers="header_list"
                              :items.sync="itemsSort"
                              @update:items="updateSortItems"
                              order
                            >
                              <tr v-for="item in itemsSort" :key="item.product_group_dimension_id">
                                <td scope="row" style="cursor: all-scroll">
                                  <v-icon>mdi-format-align-justify</v-icon>
                                </td>
                                <td scope="row">{{ item.title }}</td>
                                <td>{{ item.status }}</td>
                                <td>{{ item.lang }}</td>
                                <td>{{ item.sort }}</td>
                              </tr>
                            </base-dnd-table>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" dark @click="closeSort">Cancel</v-btn>
                          <v-btn color="primary" dark @click="saveSort">OK</v-btn>
                        </v-card-actions>
                      </v-form>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>

              <template v-slot:[`item.created_date`]="{ item }">
                {{ item.created_date | formatDate }}
              </template>

              <template v-slot:[`item.status`]="{ item }">
                <base-switch
                  v-model="item.status"
                  :height="26"
                  :width="99"
                  :font-size="5"
                  :color="{ checked: '#4CAF50', unchecked: '#FF5252' }"
                  :labels="{ checked: 'ACTIVE', unchecked: 'INACTIVE' }"
                  @change="setLoading(true), onChangeEventHandler(item, $event)"
                />
              </template>

              <template v-slot:[`item.file`]="{ item }">
                <a v-if="Object.keys(item.file).length" :href="`${path_file}${item.file.file_path}`" target="_blank">
                  <v-icon color="info">mdi-file-download</v-icon>
                </a>
              </template>

              <template v-slot:[`item.cover`]="{ item }">
                <v-list-item-avatar tile class="align-self-center" color="hsl(255, 3%, 18%,0)">
                  <v-img v-if="Object.keys(item.cover).length" :src="`${path_file}${item.cover.file_path}`" />
                </v-list-item-avatar>
              </template>

              <template v-slot:[`item.sort`]="{ item }">
                {{ item.sort }}
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-btn class="px-2 ml-1" min-width="0" color="success" @click="editItem(item)" small>
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
                <v-btn class="px-2 ml-1" min-width="0" color="error" @click="deleteItem(item)" small>
                  <v-icon small> mdi-delete </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
          <!-- <v-col cols="12">
          </v-col> -->
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            min-width="100"
            class="pl-0 ma-0"
            :loading="$store.state.isloading"
            @click="setLoading(true), cancel()"
          >
            {{ $t("back") }}
          </v-btn>
        </v-card-actions>
      </v-container>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANTS
 */
import { STATUS } from "@/constants/status"
import { LANGUAGES } from "@/constants/language"
import { PATHFILE } from "@/constants/config"

/**
 * CONTROLLER
 */
import { getProductGroupByID } from "@/controllers/product/product_group.controller"
import {
  getProductGroupDimensionAll,
  putProductGroupDimensionSort,
  putStatus,
  deleteProductGroupDimension
} from "@/controllers/product/product_group_dimension.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "News",
  data: () => ({
    dimensions: [],
    status: STATUS,
    languages: LANGUAGES,
    title: "",
    headers: [
      {
        text: "Id",
        align: "start",

        value: "product_group_dimension_id"
      },
      { text: "title", value: "title" },
      { text: "Sort", value: "sort" },
      { text: "Status", value: "status" },
      { text: "Actions", value: "actions", align: "center", width: "15%" }
    ],
    path_file: PATHFILE,
    dialogSort: false,

    /**
     * SORT
     */
    header_list: [
      { text: "", value: "product_group_dimension_id" },
      { text: "title", value: "title" },
      { text: "status", value: "status" },
      { text: "sort", value: "sort" }
    ],
    dialogDelete: false,
    delete_item: null
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  computed: {
    itemsSort: {
      get() {
        return this.dimensions
      },
      set(items) {
        this.dimensions = items
      }
    }
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.dimensions = await getProductGroupDimensionAll({ id: this.$route.params.id })
        const { title } = await getProductGroupByID({ id: this.$route.params.id })
        this.title = title
        this.setLoading(false)
      } catch (e) {
        this.errorHandle(e)
      }
    },
    createItem() {
      this.$router.push({ path: `dimension/create` })
    },
    editItem(item) {
      this.$router.push({ path: `dimension/detail/${item.product_group_dimension_id}` })
    },
    sortItem() {
      this.dialogSort = true
    },
    updateSortItems(items) {
      this.itemsSort = items.map((item) => {
        const {
          product_group_dimension_id,
          product_group_id,
          sort,
          status,
          title,
          created_at,
          created_by,
          updated_at,
          updated_by
        } = item

        return {
          product_group_dimension_id,
          product_group_id,
          status,
          title,
          sort: sort + 1,
          created_at,
          created_by,
          updated_at,
          updated_by
        }
      })
    },
    async saveSort() {
      this.setLoading(true)
      await putProductGroupDimensionSort(
        { dimensions: this.dimensions, groupId: this.$route.params.id },
        this.resultHandle,
        this.errorHandle
      )
      this.setLoading(false)
      this.closeSort()
    },
    closeSort() {
      this.dialogSort = false
    },
    cancel() {
      this.$router.back()
    },
    async onChangeEventHandler(item, { value }) {
      console.log(item)
      if (value) await putStatus({ dimension: item, status: "ACTIVE" }, this.resultHandle, this.errorHandle)
      else await putStatus({ dimension: item, status: "INACTIVE" }, this.resultHandle, this.errorHandle)
    },
    onTabChange(e) {
      this.initialize(e)
    },
    deleteItem(item) {
      this.delete_item = item
      this.dialogDelete = true
    },
    closeDelete() {
      this.delete_item = null
      this.dialogDelete = false
    },
    async deleteItemConfirm() {
      await deleteProductGroupDimension(
        { dimension: this.delete_item },
        this.resultHandle,
        this.errorHandle,
        this.deleteResultHandle
      )
      this.dialogDelete = false
    },
    deleteResultHandle() {
      this.initialize()
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
