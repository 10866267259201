/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_HOME_HIGHLIGHTS } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPost, axiosPut, axiosCreateFile, axiosUpdateFile } from "@/utils/axios"

export async function getHighlightAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_HOME_HIGHLIGHTS.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getHighlightById({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_HOME_HIGHLIGHTS.FINDBYID}/${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getHighlightByLang({ lang }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_HOME_HIGHLIGHTS.FINDBYLANG}/${lang}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function putHighlight({ highlight, file_front, file_back }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    // const payload = highlight
    const url = `${API + EP_HOME_HIGHLIGHTS.UPDATE}/${highlight.home_product_highlight_id}`
    let formData = new FormData()
    formData.append("title", highlight.title)
    formData.append("content", highlight.content)
    formData.append("link", highlight.link)
    formData.append("lang", highlight.lang)
    formData.append("status", highlight.status)

    if (file_front) formData.append("file_front", file_front, file_front.name)
    if (file_back) formData.append("file_back", file_back, file_back.name)

    const { code, data, message } = await axiosUpdateFile(url, formData, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    // const { message } = error.response.data
    console.log(error)
    return errorHandle(error)
  }
}

export async function putHighlightSort({ highlights }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = highlights
    const url = `${API}${EP_HOME_HIGHLIGHTS.UPDATE_SORT}`

    const { code, message, data } = await axiosPut(url, payload, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}
