<template>
  <v-container>
    <base-material-card icon="mdi-book-information-variant" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">
          {{ $t("site_ref_detail") }}
        </div>
      </template>

      <v-form @submit.prevent="setLoading(true), save()">
        <v-container fluid>
          <v-row class="text-no-wrap" align="center">
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="siteRef.product_group_id"
                :items="productGroups"
                item-text="title"
                item-value="product_group_id"
                :label="$t('product_group')"
                outlined
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="siteRef.product_category_id"
                :items="productCategories"
                item-text="title"
                item-value="product_category_id"
                :label="$t('product_category')"
                @change="selectProductCategory"
                outlined
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="siteRef.shade_color_id"
                :items="shadeColors"
                item-text="title"
                item-value="shade_color_id"
                :label="$t('shade_color')"
                @change="selectShadeColor"
                outlined
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="siteRef.color_id"
                :items="colors"
                item-text="title"
                item-value="color_id"
                :label="$t('color')"
                outlined
              >
              </v-autocomplete>
            </v-col>

            <v-flex xs12></v-flex>
            <v-col class="d-flex flex-wrap flex-column" cols="12" md="12">
              <base-file-input
                v-model="files"
                @change="onChangeHandler"
                chip
                label="images"
                multiple
                outlined
              ></base-file-input>
            </v-col>
            <v-flex xs12></v-flex>
            <v-col cols="12" sm="4" v-for="(file, i) in siteRef.files" :key="`file-${i}`">
              <v-chip :href="pathFile + file.file_path" target="_blank" close @click:close="removeImage(file, i)">
                <v-icon color="primary">mdi-file</v-icon> {{ file.file_name }}.{{ file.file_extension }}
              </v-chip>
            </v-col>
            <v-flex xs12></v-flex>
            <v-col cols="6">
              <v-carousel
                cycle
                height="275px"
                hide-delimiter-background
                show-arrows-on-hover
                v-if="previewImages.length || defaultImages.length"
              >
                <v-carousel-item v-for="(slide, i) in [...previewImages, ...defaultImages]" :key="`image-${i}`">
                  <v-sheet height="100%">
                    <v-row class="fill-height" align="center" justify="center">
                      <v-img :src="slide" contain width="480px" height="275px"> </v-img>
                    </v-row>
                  </v-sheet>
                </v-carousel-item>
              </v-carousel>
            </v-col>
            <v-flex xs12></v-flex>
            <v-col cols="12" md="12">
              <v-select
                v-model="siteRef.status"
                :items="status"
                item-text="text"
                item-value="value"
                :label="$t('status')"
                outlined
              ></v-select>
            </v-col>
            <!-- <v-col cols="12" md="6">
              <v-autocomplete
                v-model="siteRef.lang"
                :items="languages"
                item-text="language"
                item-value="lang_code"
                :label="$t('language')"
                outlined
              >
              </v-autocomplete>
            </v-col>

            <v-flex xs12></v-flex> -->
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" min-width="100" class="pl-0" :loading="$store.state.isloading" @click="cancel">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"
import { PATHFILE } from "@/constants/config"

/**
 * FUNCTIONS
 */
import { getProductGroupByLang } from "@/controllers/product/product_group.controller"
import { getByProductGroup as getProductCategory } from "@/controllers/product/product_category.controller"
import { getByProductCategory as getShadeColor } from "@/controllers/product/shade_color.controller"
import { getByShadeColor as getColor } from "@/controllers/product/color.controller"

import { getSiteRefByID, putSiteRef } from "@/controllers/site_ref/site_ref.controller"

/**
 * LIBS
 */
import { mapMutations } from "vuex"
import UploadImage from "@/components/base/UploadImage.vue"

export default {
  components: { UploadImage },
  data: () => ({
    siteRef: {
      title: null,
      product_group_id: null,
      product_category_id: null,
      shade_color_id: null,
      color_id: null,
      lang: null,
      status: "ACTIVE"
    },
    files: null,
    pathFile: PATHFILE,
    languages: LANGUAGES,
    status: STATUS,
    productGroups: [],
    productCategories: [],
    shadeColors: [],
    colors: [],
    previewImages: [],
    delete_files: [],
    defaultImages: []
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        await this.getSiteRef()
        await this.fetchProductGroup()
        await this.fetchProductCategory(this.siteRef.product_group_id)
        await this.fetchShadeColor(this.siteRef.product_category_id)
        await this.fetchColor(this.siteRef.shade_color_id)
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async getSiteRef() {
      this.siteRef = await getSiteRefByID({ id: this.$route.params.siteRefId })
      if (this.siteRef.files && this.siteRef.files.length) {
        this.defaultImages = this.siteRef.files.map((file) => {
          return PATHFILE + file.file_path
        })
      }
    },
    async fetchProductGroup() {
      try {
        this.productGroups = await getProductGroupByLang({ lang: this.siteRef.lang })
      } catch (e) {
        this.errorHandle(e)
      }
    },
    async fetchProductCategory(id) {
      try {
        this.productCategories = await getProductCategory({ id })
      } catch (e) {
        this.errorHandle(e)
      }
    },
    async fetchShadeColor(id) {
      try {
        this.shadeColors = await getShadeColor({ id })
      } catch (e) {
        this.errorHandle(e)
      }
    },
    async fetchColor(id) {
      try {
        this.colors = await getColor({ id })
      } catch (e) {
        this.errorHandle(e)
      }
    },
    selectProductGroup(e) {
      this.fetchProductCategory(e)
    },
    selectProductCategory(e) {
      this.fetchShadeColor(e)
    },
    selectShadeColor(e) {
      this.fetchColor(e)
    },
    onChangeHandler(e) {
      this.previewImages = []
      if (e) {
        e.forEach((file) => {
          this.previewImages.push(URL.createObjectURL(file))
        })
      }
    },
    removeImage({ file_id, file_path }, index) {
      this.delete_files.push(file_id)
      this.siteRef.files.splice(index, 1)
      this.defaultImages.splice(
        this.defaultImages.findIndex((item) => item.search(file_path) > -1),
        1
      )
    },
    async save() {
      await putSiteRef(
        { siteRef: this.siteRef, files: this.files, delete_files: this.delete_files },
        this.resultHandle,
        this.errorHandle
      )
      this.setLoading(true)
      this.$router.back()
    },
    cancel() {
      this.setLoading(true)
      this.$router.back()
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>
