/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_PRODUCT } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut, axiosCreateFile, axiosUpdateFile, axiosDelete } from "../../utils/axios"

export async function getProductAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_PRODUCT.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getProductById({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_PRODUCT.FINDBYID}/${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getProductByLang({ lang }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_PRODUCT.FINDBYLANG}/${lang}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function getProductForSelect({ lang }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_PRODUCT.FOR_SELECT}/${lang}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function getByProductCategory({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_PRODUCT.FIND_BY_PRODUCT_CATEGORY}/${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function postProduct({ product, file, product_color, product_additional }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_PRODUCT.CREATE}`
    let formData = new FormData()
    formData.append("product_category_id", product.product_category_id)

    if (product.color_id) formData.append("color_id", product.color_id)
    if (file) formData.append("file", file, file.name)
    if (product_color) formData.append("product_color", product_color, product_color.name)
    if (product_additional && product_additional.length > 0) {
      for (let i = 0; i < product_additional.length; i++) {
        formData.append("product_additional", product_additional[i], product_additional[i].name)
      }
    }

    const { code, data, message } = await axiosCreateFile(url, formData, token)

    if (code == 201) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putProduct(
  { product, file, product_color, product_additional, delete_files },
  resultHandle,
  errorHandle
) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_PRODUCT.UPDATE}/${product.product_id}`
    let formData = new FormData()
    formData.append("product_category_id", product.product_category_id)
    formData.append("status", product.status)

    if (product.color_id) formData.append("color_id", product.color_id)
    if (file) formData.append("file", file, file.name)
    if (product_color) formData.append("product_color", product_color, product_color.name)

    if (product_additional && product_additional.length > 0) {
      for (let i = 0; i < product_additional.length; i++) {
        formData.append("product_additional", product_additional[i], product_additional[i].name)
      }
    }

    if (delete_files && delete_files.length > 0) {
      for (let i = 0; i < delete_files.length; i++) {
        formData.append("product_additional_destroy", delete_files[i])
      }
    }

    const { code, data, message } = await axiosUpdateFile(url, formData, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putStatus({ product, status }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    const payload = { status: status }
    const url = `${API}${EP_PRODUCT.UPDATE_STATUS}/${product.product_id}`

    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putProductSort({ products }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = products
    const url = `${API}${EP_PRODUCT.UPDATE_SORT}`
    const { code, message, data } = await axiosPut(url, payload, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function deleteProduct({ product }, resultHandle, errorHandle, deleteResultHandle) {
  try {
    const token = await getAuthToken()

    const url = `${API}${EP_PRODUCT.DELETE}/${product.product_id}`

    const { code, data, message } = await axiosDelete(url, token)

    if (code == 200) {
      deleteResultHandle()
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}
