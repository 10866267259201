<template>
  <v-container>
    <base-material-card icon="mdi-wall" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">
          {{ $t("product_category_detail") }} - {{ productCategory.title || "" }}
        </div>
      </template>

      <div class="text-center">
        <base-upload-image
          v-model="banner"
          :defaultValue="productCategory.banner"
          width="760px"
          height="175px"
          class="mb-2"
        />
        <p class="text-center red--text">*แนะนำขนาดรูปภาพ 1520 x 350 px</p>
      </div>

      <div class="text-center">
        <base-upload-image
          v-model="cover_product_category"
          :defaultValue="productCategory.cover_product_category"
          width="320px"
          height="165px"
          class="mb-2"
        />
        <p class="text-center red--text">*แนะนำขนาดรูปภาพ 320 x 165 px</p>
      </div>

      <v-form @submit.prevent="setLoading(true), save()" ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="text-no-wrap" align="center">
            <v-col cols="12" md="6">
              <v-text-field
                v-model="productCategory.title"
                :label="$t('title')"
                :rules="[rules.required]"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="productCategory.product_group_id"
                :items="productGroups"
                item-text="title"
                item-value="product_group_id"
                :label="$t('product_group')"
                :rules="[rules.required]"
                outlined
              ></v-autocomplete>
            </v-col>
            <v-flex xs12></v-flex>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="productCategory.bio_short"
                label="bio short"
                :rules="[rules.required]"
                outlined
              ></v-text-field>
            </v-col>
            <v-flex xs12></v-flex>
            <v-col cols="12" md="6">
              <v-textarea
                v-model="productCategory.bio_long"
                label="bio long"
                rows="13"
                :rules="[rules.required]"
                outlined
              ></v-textarea>
            </v-col>
            <v-flex xs12></v-flex>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="productCategory.group_color_id"
                :items="groupColors"
                item-text="title"
                return-object
                :label="$t('group_color')"
                multiple
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="productCategory.shade_color_id"
                :items="shadeColors"
                item-text="title"
                return-object
                :label="`filter ${$t('shade_color')}`"
                multiple
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="productCategory.usability_id"
                :items="usabilities"
                item-text="title"
                return-object
                :label="`filter ${$t('usability')}`"
                :rules="[rules.atleastOne]"
                multiple
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <!-- :label="$t('accessory')" -->
              <v-autocomplete
                v-model="productCategory.accessory_id"
                :items="accessories"
                item-text="title"
                return-object
                label="วัสดุและอุปกรณ์ประกอบ"
                :rules="[rules.atleastOne]"
                multiple
                outlined
              ></v-autocomplete>
            </v-col>
            <v-flex xs12></v-flex>
            <v-col class="mb-auto" cols="12" md="6">
              <v-autocomplete
                v-model="productCategory.order_id"
                :items="orders"
                item-text="title"
                :label="`filter ${$t('order')}`"
                return-object
                multiple
                outlined
              ></v-autocomplete>
            </v-col>

            <v-flex xs12></v-flex>
            <v-col cols="12">
              <h4 class="mb-2">รายละเอียด</h4>
              <base-ck-editor
                v-model="productCategory.product_ref"
                :text.sync="productCategory.product_ref"
                placeholder="product ref"
              />
            </v-col>

            <v-col>
              <h4>{{ $t("usage_image") }}</h4>
            </v-col>

            <v-col class="ml-md-auto" cols="12">
              <v-select
                v-model="productCategory.usage_type"
                :items="usageTypes"
                item-text="text"
                item-value="value"
                :label="$t('province_type_menu')"
                outlined
              ></v-select>
            </v-col>
            <v-col class="d-flex flex-wrap flex-column" cols="12" v-if="productCategory.usage_type === 'URL'">
              <v-text-field v-model="productCategory.link" :label="$t('link')" outlined></v-text-field>
            </v-col>
            <v-col class="d-flex flex-wrap flex-column" cols="12" v-else>
              <base-upload-file v-model="usage_file" :label="$t('file')" :defaultValue="productCategory.usage_file" />
            </v-col>
            <v-flex xs12></v-flex>
            <v-col class="ml-md-auto" cols="12" md="12">
              <v-select
                v-model="productCategory.status"
                :items="status"
                item-text="text"
                item-value="value"
                :label="$t('status')"
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" min-width="100" class="pl-0" :loading="$store.state.isloading" @click="cancel">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </base-material-card>

    <!-- Video -->
    <base-material-card icon="mdi-wall" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("video") }}</div>
      </template>

      <v-container fluid>
        <v-row class="text-no-wrap" align="center">
          <v-col>
            <v-data-table :headers="videoHeaders" :items="productCategory.vdo" class="elevation-1">
              <template v-slot:top>
                <v-toolbar flat>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" dark class="mx-2" @click="sortVdoItem">
                    {{ $t("sort") }}
                  </v-btn>
                  <v-btn color="primary" dark class="mx-2" @click="addVideo()">
                    {{ $t("create") }}
                  </v-btn>
                  <v-dialog v-model="dialogSortVdo" max-width="900px">
                    <v-card>
                      <v-card-title class="headline">{{ $t("sort") }}</v-card-title>
                      <v-form>
                        <v-card-text>
                          <v-container>
                            <base-dnd-table
                              :headers="videoSortHeaders"
                              :items.sync="productCategory.vdo"
                              :dragOptions="dragOptions"
                              order
                            >
                              <tr v-for="item in productCategory.vdo" :key="item.product_category_vdo_id">
                                <td scope="row" style="cursor: all-scroll">
                                  <v-icon>mdi-format-align-justify</v-icon>
                                </td>
                                <td scope="row">{{ item.title }}</td>
                                <td>{{ item.status }}</td>
                                <td>{{ item.sort + 1 }}</td>
                              </tr>
                            </base-dnd-table>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" dark @click="closeSortVdo">Cancel</v-btn>
                          <v-btn color="primary" dark @click="saveSortVdo">OK</v-btn>
                        </v-card-actions>
                      </v-form>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>

              <template v-slot:[`item.sort`]="{ item }">
                {{ item.sort + 1 }}
              </template>

              <template v-slot:[`item.status`]="{ item }">
                <base-switch
                  v-model="item.status"
                  :height="26"
                  :width="99"
                  :font-size="5"
                  :color="{ checked: '#4CAF50', unchecked: '#FF5252' }"
                  :labels="{ checked: 'ACTIVE', unchecked: 'INACTIVE' }"
                  @change="onChangeEventHandler(item, $event, 'VDO')"
                />
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-btn class="px-2 ml-1" min-width="0" color="success" @click="editVdo(item)" small>
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
                <v-btn class="px-2 ml-1" min-width="0" color="error" @click="deleteVdo(item)" small>
                  <v-icon small> mdi-delete </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
          <!-- <v-col cols="12">
          </v-col> -->
        </v-row>
      </v-container>
    </base-material-card>

    <!-- Docs -->
    <base-material-card icon="mdi-wall" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("docs") }}</div>
      </template>

      <v-container fluid>
        <v-row class="text-no-wrap" align="center">
          <v-col>
            <v-data-table :headers="docHeaders" :items="productCategory.docs" class="elevation-1" calculate-widths>
              <template v-slot:top>
                <v-toolbar flat>
                  <v-spacer></v-spacer>

                  <v-btn color="primary" dark class="mx-2" @click="sortDocsItem">
                    {{ $t("sort") }}
                  </v-btn>

                  <v-btn color="primary" dark class="mx-2" @click="addDocs()">
                    {{ $t("create") }}
                  </v-btn>
                </v-toolbar>

                <v-dialog v-model="dialogSortDocs" max-width="900px">
                  <v-card>
                    <v-card-title class="headline">{{ $t("sort") }}</v-card-title>
                    <v-form>
                      <v-card-text>
                        <v-container>
                          <base-dnd-table
                            :headers="docSortHeaders"
                            :items.sync="productCategory.docs"
                            :dragOptions="dragOptions"
                            order
                          >
                            <tr v-for="item in productCategory.docs" :key="item.product_category_doc_id">
                              <td scope="row" style="cursor: all-scroll">
                                <v-icon>mdi-format-align-justify</v-icon>
                              </td>
                              <td scope="row">{{ item.title }}</td>
                              <td>{{ item.status }}</td>
                              <td>{{ item.sort + 1 }}</td>
                            </tr>
                          </base-dnd-table>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" dark @click="closeSortDocs">Cancel</v-btn>
                        <v-btn color="primary" dark @click="saveSortDocs">OK</v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
                </v-dialog>
              </template>

              <template v-slot:[`item.title`]="{ item }">
                {{ item.title }}
              </template>

              <template v-slot:[`item.sort`]="{ item }">
                {{ item.sort + 1 }}
              </template>

              <template v-slot:[`item.status`]="{ item }">
                <base-switch
                  v-model="item.status"
                  :height="26"
                  :width="99"
                  :font-size="5"
                  :color="{ checked: '#4CAF50', unchecked: '#FF5252' }"
                  :labels="{ checked: 'ACTIVE', unchecked: 'INACTIVE' }"
                  @change="onChangeEventHandler(item, $event, 'DOCS')"
                />
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-btn class="px-2 ml-1" min-width="0" color="success" @click="editDocs(item)" small>
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
                <v-btn class="px-2 ml-1" min-width="0" color="error" @click="deleteDocs(item)" small>
                  <v-icon small> mdi-delete </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </base-material-card>

    <v-dialog v-model="dialogDelete" max-width="300">
      <v-card>
        <v-card-title>
          <p>ยืนยันการลบ</p>
          <v-spacer />
          <v-icon aria-label="Close" @click="closeDelete"> mdi-close </v-icon>
        </v-card-title>

        <v-card-text class="pb-6 text-center">
          <v-btn class="mr-1" color="error" dark @click="closeDelete"> {{ $t("cancel") }} </v-btn>
          <v-btn class="mr-1" color="success" dark @click="deleteItemConfirm"> {{ $t("delete") }} </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"
import { PATHFILE } from "@/constants/config"

/**
 * FUNCTIONS
 */
import { putProductCategory, getProductCategoryByID } from "@/controllers/product/product_category.controller"
import { getProductGroupForSelect } from "@/controllers/product/product_group.controller"
import { getGroupColorForSelect } from "@/controllers/product/group_color.controller"
import { getAccessoryForSelect } from "@/controllers/product/accessory.controller"
import { getShadeColorForSelect } from "@/controllers/product/shade_color.controller"
import { getUsabillityForSelect } from "@/controllers/product/usability.controller"
import { getOrderForSelect } from "@/controllers/product/order.controller"
import {
  putProductCategoryVdoStatus,
  putProductCategoryVdoSort,
  deleteProductCategoryVdo
} from "@/controllers/product/product_category_vdo.controller"
import {
  putProductCategoryDocsStatus,
  putProductCategoryDocsSort,
  deleteProductCategoryDocs
} from "@/controllers/product/product_category_docs.controller"

/**
 * LIBs
 */
import { mapMutations } from "vuex"

/**
 * UTILS
 */
import { rules } from "@/utils/validates"

export default {
  data: () => ({
    rules: rules,
    productCategory: {
      product_group_id: 1,
      shade_color_id: [],
      group_color_id: [],
      usability_id: [],
      accessory_id: [],
      order_id: [],
      title: "",
      bio_short: "",
      bio_long: "",
      product_detail: "",
      product_ref: ""
    },
    pathFile: PATHFILE,
    languages: LANGUAGES,
    status: STATUS,
    productGroups: [],
    shadeColors: [],
    groupColors: [],
    accessories: [],
    usabilities: [],
    orders: [],
    files: [],
    usageTypes: [
      { text: "URL", value: "URL" },
      { text: "File", value: "FILE" }
    ],
    banner: null,
    cover_product_category: null,
    usage_file: null,
    previewImages: [],
    defaultImages: [],
    videos: [],
    dialogDelete: false,
    type: "",
    id: 0,
    type: "",
    dialogSortVdo: false,
    dialogSortDocs: false,
    dragOptions: {
      animation: 200,
      group: "description",
      disabled: false,
      ghostClass: "ghost"
    }
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  computed: {
    videoHeaders() {
      return [
        { text: "Id", value: "product_category_vdo_id", width: "10%" },
        { text: "title", value: "title" },
        { text: "sort", value: "sort" },
        { text: "status", value: "status", width: "20%" },
        { text: "Actions", value: "actions", width: "20%" }
      ]
    },
    videoSortHeaders() {
      return [
        { text: "", value: "product_category_vdo_id" },
        { text: "title", value: "title" },
        { text: "status", value: "status" },
        { text: "sort", value: "sort" }
      ]
    },
    docHeaders() {
      return [
        { text: "Id", value: "product_category_doc_id", width: "10%" },
        { text: "title", value: "title" },
        { text: "sort", value: "sort" },
        { text: "status", value: "status", width: "20%" },
        { text: "Actions", value: "actions", width: "20%" }
      ]
    },
    docSortHeaders() {
      return [
        { text: "", value: "product_category_doc_id" },
        { text: "title", value: "title" },
        { text: "status", value: "status" },
        { text: "sort", value: "sort" }
      ]
    }
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        if (!this.$route.params.id || !this.$route.params.productCategoryId) this.$route.back()
        this.setSelections(parseInt(this.$route.params.id))
        this.getCategory()
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async getCategory() {
      this.productCategory = await getProductCategoryByID({ id: this.$route.params.productCategoryId })
      if (this.productCategory.files && this.productCategory.files.length) {
        this.defaultImages = this.productCategory.files.map((file) => {
          return PATHFILE + file.file_path
        })
      }
    },
    async getGroupColor(lang) {
      this.groupColors = await getGroupColorForSelect({ lang })
    },
    async getAccessory(lang) {
      this.accessories = await getAccessoryForSelect({ lang })
    },
    async getUsabillity(lang) {
      this.usabilities = await getUsabillityForSelect({ lang })
    },
    async getShadeColor(lang) {
      this.shadeColors = await getShadeColorForSelect({ lang })
    },
    async getOrderForSelect(lang) {
      this.orders = await getOrderForSelect({ lang })
    },
    async setSelections(id) {
      this.productCategory.product_group_id = id
      this.productGroups = await getProductGroupForSelect()
      const index = this.productGroups.findIndex(
        (item) => item.product_group_id == this.productCategory.product_group_id
      )
      if (index == -1) this.$router.back()
      const lang = this.productGroups[index].lang
      await this.getGroupColor(lang)
      await this.getAccessory(lang)
      await this.getUsabillity(lang)
      await this.getShadeColor(lang)
      await this.getOrderForSelect(lang)
    },
    onChangeHandler() {
      this.previewImages = []
      if (this.files && this.files.length) {
        this.files.forEach((image) => {
          this.previewImages.push(URL.createObjectURL(image))
        })
      }
    },
    addVideo() {
      this.$router.push(`${this.$route.params.productCategoryId}/vdo`)
    },
    addDocs() {
      this.$router.push(`${this.$route.params.productCategoryId}/docs`)
    },
    closeDelete() {
      this.dialogDelete = false
      this.getCategory()
    },
    async deleteItemConfirm() {
      if (this.type === "VDO") {
        await deleteProductCategoryVdo(
          {
            cate_id: this.$route.params.productCategoryId,
            id: this.id
          },
          this.resultHandle,
          this.errorHandle
        )
      } else if (this.type === "DOCS") {
        await deleteProductCategoryDocs(
          {
            cate_id: this.$route.params.productCategoryId,
            id: this.id
          },
          this.resultHandle,
          this.errorHandle
        )
      }
      this.closeDelete()
    },
    /** Vdo */
    editVdo(vdo) {
      this.$router.push(`${this.$route.params.productCategoryId}/vdo/${vdo.product_category_vdo_id}`)
    },
    deleteVdo(vdo) {
      this.type = "VDO"
      this.id = vdo.product_category_vdo_id
      this.dialogDelete = true
    },
    sortVdoItem() {
      this.dialogSortVdo = true
    },
    async saveSortVdo() {
      await putProductCategoryVdoSort(
        { cate_id: this.$route.params.productCategoryId, vdo: this.productCategory.vdo },
        this.resultHandle,
        this.errorHandle
      )
      this.closeSortVdo()
    },
    closeSortVdo() {
      this.dialogSortVdo = false
    },

    /** Docs */
    editDocs(doc) {
      this.$router.push(`${this.$route.params.productCategoryId}/docs/${doc.product_category_doc_id}`)
    },
    deleteDocs(doc) {
      this.type = "DOCS"
      this.id = doc.product_category_doc_id
      this.dialogDelete = true
    },
    sortDocsItem() {
      this.dialogSortDocs = true
    },
    async saveSortDocs() {
      await putProductCategoryDocsSort(
        { cate_id: this.$route.params.productCategoryId, docs: this.productCategory.docs },
        this.resultHandle,
        this.errorHandle
      )
      this.closeSortDocs()
    },
    closeSortDocs() {
      this.dialogSortDocs = false
    },

    async onChangeEventHandler(item, e, type) {
      if (type === "VDO") {
        await putProductCategoryVdoStatus(
          {
            cate_id: this.$route.params.productCategoryId,
            id: item.product_category_vdo_id,
            video: item
          },
          this.resultHandle,
          this.errorHandle
        )
      } else if (type === "DOCS") {
        await putProductCategoryDocsStatus(
          {
            cate_id: this.$route.params.productCategoryId,
            id: item.product_category_doc_id,
            docs: item
          },
          this.resultHandle,
          this.errorHandle
        )
      }
      this.getCategory()
    },
    async save() {
      const isValid = this.$refs.form.validate()
      if (isValid) {
        await putProductCategory(
          {
            productCategory: this.productCategory,
            files: this.files,
            banner: this.banner,
            cover_product_category: this.cover_product_category,
            usage_file: this.usage_file
          },
          this.resultHandle,
          this.errorHandle
        )
        this.setLoading(true)
        this.$router.back()
      }
      this.setLoading(false)
    },
    cancel() {
      this.setLoading(true)
      this.$router.back()
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
      this.getCategory()
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style scoped>
.carousel-icon-close {
  margin: auto !important;
  left: 0 !important;
  right: 0 !important;
  /* bottom: 0 !important; */
  top: 30% !important;
}
</style>
