/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_IR } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPost, axiosPut } from "@/utils/axios"

export async function getIrAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_IR.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getIrByLang({ lang }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_IR.FINDBYLANG}/${lang}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getIrByID({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_IR.FINDBYID}/${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

// export async function postIr({ ir }, resultHandle, errorHandle) {
//     try {
//         const token = await getAuthToken()
//         const payload = ir
//         const url = `${API}${EP_IR.CREATE}`

//         const { code, data, message } = await axiosPost(url, payload, token)

//         if (code == 201) {
//             resultHandle(message)
//             return data
//         }
//         errorHandle(message)
//         return data
//     } catch (error) {
//         console.log(error)
//         return errorHandle(error)
//     }
// }

export async function putIr({ ir }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = ir
    const url = `${API}${EP_IR.UPDATE}/${ir.ir_id}`

    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}
