/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
"use strict"

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_USERS } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut, axiosUpdateFile, axiosCreateFile, axiosDelete } from "@/utils/axios"

export async function findAllUser() {
  try {
    const token = await getAuthToken()

    const url = API + EP_USERS.FINDALL
    const { code, data } = await axiosGet(url, token)
    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return errorHandle(message)
  }
}

export async function getUserBytoken() {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_USERS.FINDBYTOKEN}/${token}`
    const { code, message, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    } else {
      location.reload()
      return ""
    }
  } catch (error) {
    console.log(error)
    return ""
  }
}

export async function getUserByID({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_USERS.FINDBYID}/${id}`
    const { code, message, data } = await axiosGet(url, token)
    if (code == 200) {
      return data
    }
  } catch (error) {
    console.log(error)
  }
}

export async function getRole() {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_USERS.FINDROLE}`
    const { code, message, data } = await axiosGet(url, token)
    if (code == 200) {
      return data
    }
  } catch (error) {
    console.log(error)
  }
}

// export async function updateUser(req) {
//   try {
//     const token = await getAuthToken()
//     const url = `${API}${EP_USERS.FINDBYTOKEN}/${token}`
//     const { code, message, data } = await axiosPut(url, token)
//     if (code == 200) {
//       return data
//     }
//   } catch (error) {
//     const { code, message } = error.response.data
//     return errorHandle(message)
//   }
// }

export async function putUser({ user, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    let formData = new FormData()
    formData.append("email", user.email)
    formData.append("firstname", user.firstname)
    formData.append("lastname", user.lastname)
    formData.append("position", user.position)
    formData.append("status", user.status)

    if (user.password) formData.append("password", user.password)

    if (user.confirm_password) formData.append("confirm_password", user.confirm_password)

    if (file) formData.append("file", file, file.name)
    const url = `${API}${EP_USERS.UPDATE}/${user.user_id}`
    const { code, data, message } = await axiosUpdateFile(url, formData, token)

    if (code == 200) return resultHandle(message)

    return errorHandle(message)
  } catch (error) {
    return errorHandle(error)
  }
}

export async function postUser({ user, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    let formData = new FormData()
    formData.append("email", user.email)
    formData.append("password", user.password)
    formData.append("confirm_password", user.confirm_password)
    formData.append("firstname", user.firstname)
    formData.append("lastname", user.lastname)
    formData.append("position", user.position)
    if (file) formData.append("file", file, file.name)

    const url = API + EP_USERS.CREATE
    const { code, data, message } = await axiosCreateFile(url, formData, token)

    if (code == 201) {
      return resultHandle()
    }

    return errorHandle(message)
  } catch (error) {
    return errorHandle(error)
  }
}

export async function putStatus({ user, status }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    const payload = { status: status }
    const url = `${API}${EP_USERS.UPDATE_STATUS}/${user.user_id}`

    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function deleteUser({ user }, resultHandle, errorHandle, deleteResultHandle) {
  try {
    const token = await getAuthToken()

    const url = `${API}${EP_USERS.DELETE}/${user.user_id}`

    const { code, data, message } = await axiosDelete(url, token)

    if (code == 200) {
      deleteResultHandle()
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}
