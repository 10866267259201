<template>
  <v-container>
    <base-material-card icon="mdi-download" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("info_download") }}</div>
      </template>

      <v-dialog v-model="dialogDelete" max-width="300">
        <v-card>
          <v-card-title>
            <p>
              ยืนยันการลบ
            </p>
            <v-spacer />
            <v-icon aria-label="Close" @click="closeDelete">
              mdi-close
            </v-icon>
          </v-card-title>

          <v-card-text class="pb-6 text-center">
            <v-btn class="mr-1" color="error" dark @click="closeDelete"> {{ $t("cancel") }} </v-btn>
            <v-btn class="mr-1" color="success" dark @click="deleteItemConfirm"> {{ $t("delete") }} </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-tabs vertical>
        <v-tab v-for="(language, i) in languages" :key="`branch-${i}`" @change="onTabChange(language.lang_code)">
          {{ language.lang_code }}
        </v-tab>

        <v-tab-item touchless v-for="(language, i) in languages" :key="`lang-home-infoDownload-${i}`">
          <v-container fluid>
            <v-row class="text-no-wrap" align="center">
              <v-col>
                <v-data-table :headers="headers" :items="infoDownloads" class="elevation-1">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" dark class="mx-2" @click="sortItem">
                        {{ $t("info_download_sort") }}
                      </v-btn>

                      <v-btn color="primary" dark class="mx-2" @click="createItem(language.lang_code)">
                        {{ $t("info_download_create") }}
                      </v-btn>

                      <v-dialog v-model="dialogSort" max-width="900px">
                        <v-card>
                          <v-card-title class="headline">{{ $t("info_download_sort") }}</v-card-title>
                          <v-form>
                            <v-card-text>
                              <v-container>
                                <base-dnd-table
                                  :headers="header_list"
                                  :items.sync="itemsSort"
                                  @update:items="updateSortItems"
                                  order
                                >
                                  <tr v-for="item in infoDownloads" :key="item.info_download_id">
                                    <td scope="row" style="cursor: all-scroll">
                                      <v-icon>mdi-format-align-justify </v-icon>
                                    </td>
                                    <td>{{ item.title }}</td>
                                    <td>{{ item.status }}</td>
                                    <td>{{ item.sort }}</td>
                                  </tr>
                                </base-dnd-table>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="primary" dark @click="closeSort">Cancel</v-btn>
                              <v-btn color="primary" dark @click="saveSort">OK</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>

                  <template v-slot:[`item.status`]="{ item }">
                    <base-switch
                      v-model="item.status"
                      :height="26"
                      :width="99"
                      :font-size="5"
                      :color="{ checked: '#4CAF50', unchecked: '#FF5252' }"
                      :labels="{ checked: 'ACTIVE', unchecked: 'INACTIVE' }"
                      @change="setLoading(true), onChangeEventHandler(item, $event)"
                    />
                  </template>

                  <template v-slot:[`item.file`]="{ item }">
                    <v-list-item
                      :href="`${path_file}${item.file.file_path}`"
                      target="_blank"
                      :ripple="false"
                      v-if="Object.keys(item.file).length"
                    >
                      <v-list-item-avatar tile class="align-self-centermr-auto px-0" color="hsl(255, 3%, 18%,0)">
                        <v-img aspect-ratio="1" contain :src="`${path_file}${item.file.file_path}`" />
                      </v-list-item-avatar>
                    </v-list-item>
                  </template>

                  <template v-slot:[`item.sort`]="{ item }">
                    {{ item.sort }}
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn class="px-2 ml-1" min-width="0" color="success" @click="editItem(item)" small>
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn class="px-2 ml-1" min-width="0" color="error" @click="deleteItem(item)" small>
                      <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"
import { PATHFILE } from "@/constants/config"

/**
 * FUNCTIONS
 */
import {
  getInfoDownloadByLang,
  putInfoDownloadSort,
  putStatus,
  deleteInfoDownload
} from "@/controllers/info_download/info_download.controller"

/**
 * LIBs
 */
import { mapMutations } from "vuex"

/**
 * UTILS
 */
import { rules } from "@/utils/validates"

export default {
  data: () => ({
    rules: rules,
    infoDownloads: [],
    path_file: PATHFILE,
    languages: LANGUAGES,
    status: STATUS,
    file: null,
    fileHeaderSubs: {
      TH: null,
      EN: null
    },
    headers: [
      {
        text: "Id",
        align: "start",
        value: "info_download_id"
      },
      {
        text: "Title",
        align: "start",
        value: "title"
      },
      { text: "Cover", value: "file" },
      { text: "sort", value: "sort" },
      { text: "Status", value: "status" },
      { text: "Actions", value: "actions", align: "center", width: "15%", sortable: false }
    ],
    tab: 0,
    dialogSort: false,
    dialogDelete: false,
    delete_item: null,

    /**
     * SORT
     */
    header_list: [
      { text: "", value: "info_download_id " },
      { text: "title", value: "title", align: "center" },
      { text: "status", value: "status" },
      { text: "sort", value: "sort" }
    ]
  }),
  computed: {
    itemsSort: {
      get() {
        return this.infoDownloads
      },
      set(items) {
        this.infoDownloads = items
      }
    },
    currentLocale() {
      return this.tab === 0 ? "TH" : "EN"
    }
  },
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize(lang = "TH") {
      try {
        this.infoDownloads = await getInfoDownloadByLang({ lang })
        this.setLoading(false)
      } catch (e) {
        this.errorHandle(e)
      }
    },
    createItem(lang) {
      this.$router.push({ path: `support-downloads-create?lang=${lang}` })
    },
    editItem(item) {
      this.$router.push({ path: `support-downloads-detail/${item.info_download_id}` })
    },
    sortItem() {
      this.dialogSort = true
    },
    updateSortItems(items) {
      this.itemsSort = items.map((item) => {
        const {
          info_download_id,
          ref_id,
          sub_ref_id,
          menu_type,
          title,
          file,
          link,
          lang,
          sort,
          status,
          created_at,
          updated_at
        } = item

        return {
          info_download_id,
          ref_id,
          sub_ref_id,
          menu_type,
          title,
          file,
          link,
          lang,
          sort: sort + 1,
          status,
          created_at,
          updated_at
        }
      })
    },
    async saveSort() {
      await putInfoDownloadSort({ infoDownloads: this.infoDownloads }, this.resultHandle, this.errorHandle)
      this.closeSort()
    },
    closeSort() {
      this.dialogSort = false
    },
    async onChangeEventHandler(item, { value }) {
      if (value) await putStatus({ infoDownload: item, status: "ACTIVE" }, this.resultHandle, this.errorHandle)
      else await putStatus({ infoDownload: item, status: "INACTIVE" }, this.resultHandle, this.errorHandle)
    },
    onTabChange(e) {
      this.initialize(e)
    },
    deleteItem(item) {
      this.delete_item = item
      this.dialogDelete = true
    },
    closeDelete() {
      this.delete_item = null
      this.dialogDelete = false
    },
    async deleteItemConfirm() {
      await deleteInfoDownload(
        { infoDownload: this.delete_item },
        this.resultHandle,
        this.errorHandle,
        this.deleteResultHandle
      )
      this.dialogDelete = false
    },
    deleteResultHandle() {
      this.infoDownloads = this.infoDownloads.filter(
        (infoDownloads) => infoDownloads.info_download_id !== this.delete_item.info_download_id
      )
    },

    /** Callback Function */
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
