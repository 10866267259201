<template>
  <base-material-card icon="mdi-account-hard-hat" color="primary">
    <template v-slot:after-heading>
      <div class="font-weight-light card-title mt-2">
        {{ $t("career_banner") }}
      </div>
    </template>

    <v-tabs vertical>
      <v-tab v-for="(career_banner, i) in career_banners" :key="`career_banner-tab-${i}`">
        {{ career_banner.lang }}
      </v-tab>

      <v-tab-item v-for="(career_banner, i) in career_banners" :key="`career_banner-tab-item-${i}`">
        <form @submit.prevent="save(i)">
          <v-container fluid>
            <v-row class="text-no-wrap" align="center">
              <v-col cols="12" class="text-center grey--text">
                <base-upload-image
                  v-model="image_career_banners[i]"
                  :defaultValue="career_banner.file"
                  width="420px"
                  height="260px"
                />
                <p class="text-center red--text">*แนะนำขนาดรูปภาพ 420 x 260 px</p>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field :label="$t('title_main')" v-model="career_banner.title" outlined />
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field :label="$t('title_sub')" v-model="career_banner.title_sub" outlined />
              </v-col>
              <v-col cols="12" md="12">
                <base-ck-editor :placeholder="$t('description')" v-model="career_banner.description" outlined />
              </v-col>
              <!-- <v-col cols="12" md="12">
                <v-text-field :label="$t('link')" v-model="career_banner.link" outlined />
              </v-col> -->
              <v-col>
                <v-select
                  v-model="career_banner.status"
                  :items="status"
                  item-text="text"
                  item-value="value"
                  :label="$t('status')"
                  outlined
                />
              </v-col>

              <!-- <v-col>
                <v-select
                  v-model="career_banner.lang"
                  :items="languages"
                  item-text="language"
                  item-value="lang_code"
                  :label="$t('languages')"
                  outlined
                />
              </v-col> -->
            </v-row>
          </v-container>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </form>
      </v-tab-item>
    </v-tabs>
  </base-material-card>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"

export default {
  name: "CareerBanner",
  data: () => ({
    languages: LANGUAGES,
    status: STATUS
  }),
  props: {
    value: {
      type: Array
    },
    image: {
      type: Array
    }
  },
  computed: {
    career_banners: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    },
    image_career_banners: {
      get() {
        return this.image
      },
      set(value) {
        this.$emit("image", value)
      }
    }
  },
  methods: {
    save(i) {
      this.$emit("submit", i)
    }
  }
}
</script>

<style></style>
