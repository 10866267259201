<template>
  <v-container>
    <base-material-card icon="mdi-face-agent" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("support_header_detail") }}</div>
      </template>

      <v-form @submit.prevent="setLoading(true), save()">
        <v-container fluid>
          <v-row class="text-no-wrap" align="center">
            <v-col cols="12" md="12">
              <v-text-field v-model="support_header.title" :label="$t('title')" outlined></v-text-field>
            </v-col>

            <v-col>
              <v-select
                v-model="support_header.type"
                :items="type_support_header"
                item-text="text"
                item-value="value"
                :label="$t('menu_type')"
                outlined
              ></v-select>
            </v-col>

            <v-col cols="12" md="12">
              <v-switch
                :false-value="0"
                :true-value="1"
                v-model="support_header.filter"
                inset
                :label="$t('filter')"
              ></v-switch>
            </v-col>

            <v-col>
              <v-select
                v-model="support_header.status"
                :items="status"
                item-text="text"
                item-value="value"
                :label="$t('status')"
                outlined
              ></v-select>
            </v-col>

            <v-col
              ><v-select
                v-model="support_header.lang"
                :items="languages"
                item-text="language"
                item-value="lang_code"
                :label="$t('language')"
                outlined
              ></v-select
            ></v-col>
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" min-width="100" class="pl-0" :loading="$store.state.isloading" @click="cancel">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"
import { TYPE_SUPPORT_HEADER } from "@/constants/default"

/**
 * CONTROLLER
 */
import { getSupportHeaderByID, putSupportHeader } from "@/controllers/support/support_header.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "SupportHeaderDetail",
  data: () => ({
    languages: LANGUAGES,
    status: STATUS,
    type_support_header: TYPE_SUPPORT_HEADER,
    support_header: [],
    file: null
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.support_header = await getSupportHeaderByID({ support_header_id: this.$route.params.support_header_id })
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async save() {
      await putSupportHeader({ support_header: this.support_header }, this.resultHandle, this.errorHandle)
      this.$router.back()
    },
    cancel() {
      this.$router.back()
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    },
    clearProductGroup(e) {
      if (!e) {
        this.support.product_group_id = null
      }
    }
  }
}
</script>

<style></style>
