<template>
  <v-container>
    <base-material-card icon="mdi-map-marker" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("branch_detail") }}</div>
      </template>

      <!-- <GoogleMap
        :location="{ lat: branch.lat, lng: branch.lng }"
        v-model="branch.search"
        @getPosition="getPosition"
        @getPlaceName="getPlaceName"
        @getPlaceId="getPlaceId"
      /> -->
      <form @submit.prevent="setLoading(true), save()">
        <v-container>
          <v-row>
            <!-- <v-col class="text-center align-center" cols="12" md="12">
              <GmapMap class="mx-auto map-responsive" :center="center" :zoom="10" map-type-id="terrain">
                <GmapMarker
                  :position="coordinates"
                  :clickable="true"
                  :draggable="true"
                  @click="center = coordinates"
                  @drag="updateCoordinates"
                />
              </GmapMap>
            </v-col> -->

            <v-col cols="12" md="12">
              <v-text-field v-model="branch.i_frame" :label="$t('map')" outlined></v-text-field>
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field v-model="branch.lat" :label="$t('lat')" outlined></v-text-field>
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field v-model="branch.lng" :label="$t('lng')" outlined></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field v-model="branch.branch" :label="$t('branch_name')" outlined></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-textarea v-model="branch.address" :label="$t('address')" outlined></v-textarea>
            </v-col>

            <v-col cols="6" md="6">
              <v-autocomplete
                v-model="branch.country"
                :items="countries"
                item-text="country"
                item-value="country_id"
                :label="$t('country')"
                outlined
                @change="onCountry"
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="6" md="6">
              <v-autocomplete
                v-model="branch.province"
                :items="provinces"
                item-text="province"
                item-value="province_id"
                :label="$t('province')"
                outlined
                @change="onProvince"
              ></v-autocomplete>
            </v-col>

            <v-col cols="6" md="6">
              <v-autocomplete
                v-model="branch.district"
                :items="districts"
                item-text="district"
                item-value="district_id"
                :label="$t('district')"
                outlined
                @change="onDistrict"
              ></v-autocomplete>
            </v-col>

            <v-col cols="6" md="6">
              <v-autocomplete
                v-model="branch.sub_district"
                :items="sub_districts"
                item-text="sub_district"
                item-value="sub_district_id"
                :label="$t('sub_district')"
                outlined
                @change="onSubDistrict"
                return-object
              ></v-autocomplete>
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field v-model="branch.postal_code" :label="$t('postal_code')" outlined></v-text-field>
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field v-model="branch.tel" :label="$t('telphone')" outlined></v-text-field>
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field v-model="branch.fax" :label="$t('fax')" outlined></v-text-field>
            </v-col>

            <v-col cols="6" md="6"> </v-col>

            <v-col>
              <v-select
                v-model="branch.status"
                :items="status"
                item-text="text"
                item-value="value"
                :label="$t('status')"
                outlined
              ></v-select>
            </v-col>
            <v-col
              ><v-select
                v-model="branch.lang"
                :items="languages"
                item-text="language"
                item-value="lang_code"
                :label="$t('language')"
                outlined
                @change="onLang"
              ></v-select
            ></v-col>
            <v-col cols="12" md="12">
              <!-- <base-upload-image v-model="file" :defaultValue="branch.file" /> -->
              <v-file-input v-model="file" outlined :label="$t('file')" />
            </v-col>
            <v-col v-if="!file" cols="auto" :key="`file-${i}`">
              <v-chip :href="pathFile + branch.file.file_path" target="_blank">
                <v-icon color="primary">mdi-file</v-icon> {{ branch.file.file_name }}{{ branch.file.file_extension }}
              </v-chip>
            </v-col>
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              min-width="100"
              class="pl-0"
              :loading="$store.state.isloading"
              @click="setLoading(true), cancel()"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </form>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"
import { PATHFILE } from "@/constants/config"

/**
 * CONTROLLER
 */
import { getBranchByID, putBranch } from "@/controllers/branch/branch.controller"
import { getCountry, getProvince, getDistrict, getSubDistrict } from "@/controllers/dealer/dealer.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

/**
 * COMPONENTS
 */
import GoogleMap from "@/components/GoogleMap"
export default {
  name: "BranchDetail",
  data: () => ({
    path_file: PATHFILE,
    languages: LANGUAGES,
    status: STATUS,
    branch: {},
    file: null,
    countries: [],
    provinces: [],
    districts: [],
    sub_districts: [],
    center: { lat: 13.764187851076384, lng: 100.5108642578125 }
  }),
  components: {
    GoogleMap
  },
  computed: {
    coordinates() {
      return {
        lat: this.branch.lat,
        lng: this.branch.lng
      }
    }
  },
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.branch = await getBranchByID({
          branch_id: this.$route.params.branch_id
        })
        this.countries = await getCountry({ lang: this.branch.lang })
        this.provinces = await getProvince({ country: this.branch.country, lang: this.branch.lang })
        this.districts = await getDistrict({ province_id: this.branch.province, lang: this.branch.lang })
        this.sub_districts = await getSubDistrict({ district_id: this.branch.district, lang: this.branch.lang })
        this.center = { lat: this.branch.lat, lng: this.branch.lng }
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    updateCoordinates(location) {
      this.branch.lat = location.latLng.lat()
      this.branch.lng = location.latLng.lng()
    },
    async save() {
      await putBranch({ branch: this.branch, file: this.file }, this.resultHandle, this.errorHandle)
    },
    async onLang(lang) {
      this.countries = await getCountry({ lang })
      this.provinces = await getProvince({ country: this.branch.country, lang: this.branch.lang })
      this.districts = await getDistrict({ province_id: this.branch.province, lang: this.branch.lang })
      this.sub_districts = await getSubDistrict({ district_id: this.branch.district, lang: this.branch.lang })
    },
    async onCountry(e) {
      this.provinces = await getProvince({ country: e, lang: this.branch.lang })
      this.districts = []
      this.sub_districts = []
      this.branch.postal_code = null
    },
    async onProvince(e) {
      this.districts = await getDistrict({ province_id: e, lang: this.branch.lang })
      this.sub_districts = []
      this.branch.postal_code = null
    },
    async onDistrict(e) {
      this.sub_districts = await getSubDistrict({ district_id: e, lang: this.branch.lang })
      this.branch.postal_code = null
    },
    onSubDistrict(e) {
      this.branch.sub_district = e.sub_district_id
      this.branch.postal_code = e.zip_code
    },
    cancel() {
      this.$router.back()
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
      this.$router.back()
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    },
    getPosition(location) {
      this.branch.lat = location.lat
      this.branch.lng = location.lng
    },
    getPlaceName(placeName) {
      this.branch.place_name = placeName
    },
    getPlaceId(place_id) {
      this.branch.place_id = place_id
    }
  }
}
</script>

<style></style>
