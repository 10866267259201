<template>
  <v-container>
    <base-material-card icon="mdi-post-outline" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("blog_create") }}</div>
      </template>

      <v-form @submit.prevent="setLoading(true), save()" ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="text-no-wrap" align="center">
            <v-col class="text-center" cols="12" md="12">
              <span>ภาพ Cover </span>
              <base-upload-image v-model="cover" :defaultValue="blog.cover" width="400px" height="200px" />
              <p class="text-center red--text">*แนะนำขนาดรูปภาพ 400 x 200 px</p>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field v-model="blog.title" :label="$t('title')" :rules="[rules.required]" outlined></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <base-ck-editor :placeholder="$t('description')" v-model="blog.blog" :rules="[rules.required]" outlined />
            </v-col>

            <v-col cols="12" md="12">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="blog.created_date"
                    :label="$t('created_date')"
                    readonly
                    v-bind="attrs"
                    :rules="[rules.required]"
                    v-on="on"
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker v-model="blog.created_date" @input="menu = false" no-title scrollable locale="th" />
              </v-menu>
            </v-col>

            <!-- <v-col>
              <v-select
                v-model="blog.status"
                :items="status"
                item-text="text"
                item-value="value"
                :label="$t('status')"
                outlined
              ></v-select>
            </v-col> -->

            <v-col
              ><v-select
                v-model="blog.lang"
                :items="languages"
                item-text="language"
                item-value="lang_code"
                :label="$t('language')"
                outlined
              ></v-select
            ></v-col>
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" min-width="100" class="pl-0" :loading="$store.state.isloading" @click="cancel">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"

/**
 * CONTROLLER
 */
import { postBlog } from "@/controllers/blog/blog.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

/**
 * UTILS
 */
import { rules } from "@/utils/validates"

export default {
  name: "BlogCreate",
  data: () => ({
    languages: LANGUAGES,
    status: STATUS,
    rules: rules,
    blog: {},
    cover: null,
    menu: false
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.blog.lang = this.$route.query.lang
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async save() {
      const isValid = this.$refs.form.validate()
      if (isValid) {
        await postBlog({ blog: this.blog, cover: this.cover }, this.resultHandle, this.errorHandle)
      }
      this.setLoading(false)
    },
    cancel() {
      this.setLoading(true)
      this.$router.back()
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
      this.$router.back()
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>
