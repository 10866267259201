<template>
  <v-app>
    <base-load />
    <v-main class="transparent" style="display: flex; justify-content: center; height:100%;">
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <!-- LOGIN -->
          <v-flex xs12 sm8 md6 v-if="options.isLoggingIn">
            <v-form @submit.prevent="loginHandle">
              <div class="d-flex flex-column justify-space-between align-center">
                <v-img
                  style="height: 140px; width: 234px;"
                  :height="'80%'"
                  :src="require('../../../assets/logo_dbp.png')"
                ></v-img>
              </div>
              <!-- <v-card>
                <v-toolbar dark color="primary">
                  <v-toolbar-title>Login</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-text-field
                    v-model="user.email"
                    prepend-icon="mdi-account"
                    id="email"
                    name="email"
                    label="E-mail"
                    type="text"
                  ></v-text-field>
                  <v-text-field
                    v-model="user.password"
                    prepend-icon="mdi-lock"
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" :loading="loading" @click="loginHandle">Login</v-btn>
                </v-card-actions>
              </v-card> -->
              <base-material-card color="primary" icon="mdi-lock-outline" title="Login" class="px-5 py-3">
                <v-container fluid>
                  <v-row class="text-no-wrap" align="center">
                    <v-col cols="3" class="text-right grey--text">
                      Email
                    </v-col>

                    <v-col>
                      <v-text-field v-model="user.email" />
                    </v-col>
                  </v-row>

                  <v-row class="text-no-wrap" align="center">
                    <v-col class="text-right grey--text" cols="3">
                      Password
                    </v-col>

                    <v-col>
                      <v-text-field
                        v-model="user.password"
                        :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append.prevent="showPass = !showPass"
                        :type="showPass ? 'text' : 'password'"
                        class="pt-0"
                      />
                    </v-col>
                  </v-row>
                </v-container>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn type="submit" color="primary" min-width="100" class="pl-0" :loading="$store.state.isloading">
                    Login
                  </v-btn>
                  <!-- <v-spacer></v-spacer>
                  <div class="text-center text-white">
                    <span>
                      Don't have an account?
                      <v-btn x-small color="primary" @click="options.isLoggingIn = false" outlined>
                        Register
                      </v-btn>
                    </span>
                  </div> -->
                </v-card-actions>
              </base-material-card>
            </v-form>
          </v-flex>

          <!-- REGISTER -->
          <v-flex xs12 sm8 md6 v-else>
            <div class="d-flex flex-column justify-space-between align-center">
              <v-img :height="'80%'" src="https://www.dbp.co.th/uploads/news/thumbnail/1517370218.png"></v-img>
            </div>

            <base-material-card color="primary" icon="mdi-lock-outline" title="Register" class="px-5 py-3">
              <v-form>
                <v-container fluid>
                  <v-row class="text-no-wrap" align="center">
                    <v-col class="text-center grey--text">
                      <v-hover v-slot="{ hover }">
                        <v-list-item-avatar size="100" class="align-self-center" @click="inputFile">
                          <v-img v-if="url" style="background-color: HSL(255, 3%, 18%);" :src="url">
                            <v-fade-transition>
                              <div
                                justify="center"
                                align="center"
                                v-if="hover"
                                class="text-center d-flex darken-2 white--text"
                                style="height: 100%; width:100%; background-color: HSL(0, 0%, 74%,0.5);"
                              >
                                <v-row class="text-no-wrap" align="center">
                                  <v-col class="text-center">
                                    <v-icon large>mdi-pencil</v-icon>
                                  </v-col>
                                </v-row>
                              </div>
                            </v-fade-transition>
                          </v-img>
                          <v-img v-else style="background-color: HSL(255, 3%, 18%);" :src="profileDefault">
                            <v-fade-transition>
                              <div
                                justify="center"
                                align="center"
                                v-if="hover"
                                class="text-center d-flex darken-2 white--text"
                                style="height: 100%; width:100%; background-color: HSL(0, 0%, 74%,0.5);"
                              >
                                <v-row class="text-no-wrap" align="center">
                                  <v-col class="text-center">
                                    <v-icon large>mdi-pencil</v-icon>
                                  </v-col>
                                </v-row>
                              </div>
                            </v-fade-transition>
                          </v-img>
                          <!-- <v-img src="https://cdn.vuetifyjs.com/images/parallax/material.jpg" /> -->
                          <!-- <v-row class="text-no-wrap" align="center">
                                <v-col class="text-center">
                                  <v-icon large>mdi-pencil</v-icon>
                                </v-col>
                              </v-row> -->
                        </v-list-item-avatar>
                      </v-hover>
                    </v-col>
                  </v-row>
                  <v-row class="text-no-wrap d-none" align="center">
                    <v-col cols="3" class="text-right grey--text">
                      Image Profile
                    </v-col>
                    <v-col class="text-right grey--text">
                      <v-file-input label="Image Profile" ref="fileInput" @change="Preview_image" v-model="image">
                      </v-file-input>
                    </v-col>
                  </v-row>
                  <v-row class="text-no-wrap" align="center">
                    <!-- <v-col cols="3" class="text-right grey--text">
                        E-mail
                      </v-col> -->
                    <v-col>
                      <v-text-field label="E-mail" v-model="register.email" />
                    </v-col>
                  </v-row>
                  <v-row class="text-no-wrap" align="center">
                    <!-- <v-col cols="3" class="text-right grey--text">
                        First name
                      </v-col> -->

                    <v-col>
                      <v-text-field label="First name" v-model="register.firstname" />
                    </v-col>
                  </v-row>

                  <v-row class="text-no-wrap" align="center">
                    <!-- <v-col cols="3" class="text-right grey--text">
                        Last name
                      </v-col> -->

                    <v-col>
                      <v-text-field label="Last name" v-model="register.lastname" />
                    </v-col>
                  </v-row>

                  <v-row class="text-no-wrap" align="center">
                    <!-- <v-col class="text-right grey--text" cols="3">
                        Password
                      </v-col> -->

                    <v-col>
                      <v-text-field
                        label="Password"
                        v-model="register.password"
                        :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append.prevent="showPass = !showPass"
                        :type="showPass ? 'text' : 'password'"
                        class="pt-0"
                      />
                    </v-col>
                  </v-row>

                  <v-row class="text-no-wrap" align="center">
                    <!-- <v-col class="text-right grey--text" cols="3">
                        Confirm password
                      </v-col> -->

                    <v-col>
                      <v-text-field
                        label="Confirm password"
                        v-model="register.confirm_password"
                        :append-icon="showConfirmPass ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append.prevent="showConfirmPass = !showConfirmPass"
                        :type="showConfirmPass ? 'text' : 'password'"
                        class="pt-0"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>

              <v-card-actions>
                <v-btn
                  color="primary"
                  min-width="100"
                  :loading="$store.state.isloading"
                  @click="registerHandle"
                  class="pl-0"
                >
                  Register
                </v-btn>
                <v-spacer></v-spacer>
                <div class="text-center text-white">
                  <span>
                    Have an account!
                    <v-btn x-small color="primary" class="mx-2" @click="options.isLoggingIn = true" outlined>
                      LOGIN
                    </v-btn>
                  </span>
                </div>
              </v-card-actions>
            </base-material-card>

            <!-- <v-card>
                <v-toolbar dark color="primary">
                  <v-toolbar-title>Register</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-form>
                    <v-img :src="url"></v-img>
                    <v-file-input ref="input_profile" @change="Preview_image" v-model="image"> </v-file-input>
                    <v-text-field
                      v-model="register.firstname"
                      prepend-icon="mdi-account"
                      id="firstname"
                      name="firstname"
                      label="First Name"
                      type="text"
                      :rules="rules.ruleReq"
                    ></v-text-field>
                    <v-text-field
                      v-model="register.lastname"
                      prepend-icon="mdi-account"
                      name="lastname"
                      label="Last Name"
                      type="text"
                      :rules="rules.ruleReq"
                    ></v-text-field>
                    <v-text-field
                      v-model="register.email"
                      prepend-icon="mdi-email"
                      id="email"
                      name="email"
                      label="E-mail"
                      type="email"
                      :rules="rules.ruleEmail"
                    ></v-text-field>
                    <v-text-field
                      v-model="register.password"
                      id="password"
                      prepend-icon="mdi-lock"
                      name="password"
                      label="Password"
                      type="password"
                      :rules="rules.ruleReq"
                    ></v-text-field>
                    <v-text-field
                      v-model="register.confirm_password"
                      prepend-icon="mdi-lock"
                      id="confirm_password"
                      name="confirm_password"
                      label="Confirm Password"
                      type="password"
                      :rules="rules.ruleReq"
                    ></v-text-field>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" :loading="loading" @click="remove">Register</v-btn>
                </v-card-actions>
              </v-card> -->
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { login, register } from "@/controllers/auth/login.controller"

export default {
  name: "Login",
  data: () => ({
    profileDefault: require("@/assets/profile.png"),
    user: {
      email: "",
      password: ""
      // email: "bro.miss.rice@gmail.com",
      // password: "123456789"
    },
    register: {
      email: "",
      firstname: "",
      lastname: "",
      password: "",
      confirm_password: ""
      // email: "white8989za@gmail.com",
      // firstname: "Warat",
      // lastname: "Chumin",
      // password: "123456789",
      // confirm_password: "123456789"
    },
    url: null,
    image: null,
    options: {
      isLoggingIn: true,
      shouldStayLoggedIn: true
    },
    rules: {
      ruleReq: [(value) => !!value || "Required.", (value) => (value || "").length <= 20 || "Max 20 characters"],
      ruleEmail: [
        (value) => !!value || "Required.",
        (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || "Invalid e-mail."
        }
      ]
    },
    showPass: false,
    showConfirmPass: false
  }),
  mounted: function() {
    return this.$store.commit("SET_IS_LOADING", false)
  },
  methods: {
    remove() {
      this.$store.commit("SET_IS_LOADING", true)
    },
    Preview_image() {
      if (this.image) this.url = URL.createObjectURL(this.image)
      else this.url = null
    },
    inputFile() {
      this.$refs.fileInput.$refs.input.click()
    },
    async loginHandle() {
      try {
        this.$store.commit("SET_IS_LOADING", true)
        await login({ email: this.user.email, password: this.user.password }, this.successHandle, this.errorHandle)
      } catch (error) {
        console.log(error)
        this.$store.commit("SET_IS_LOADING", false)
      }
    },
    async registerHandle() {
      this.$store.commit("SET_IS_LOADING", true)
      await register(
        {
          email: this.register.email,
          password: this.register.password,
          confirm_password: this.register.confirm_password,
          firstname: this.register.firstname,
          lastname: this.register.lastname
        },
        this.successHandle,
        this.errorHandle
      )
    },
    async successHandle() {
      this.$store.commit("SET_IS_LOADING", false)
      this.$router.push("/")
    },
    async errorHandle(error) {
      alert(error)
      this.$store.commit("SET_IS_LOADING", false)
    }
  }
}
</script>

<style scoped>
.transparent {
  background-color: transparent !important;
  border-color: transparent !important;
}
</style>
