<template>
  <v-container>
    <base-material-card icon="mdi-home" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("home_content") }}</div>
      </template>
      <v-form @submit.prevent="setLoading(true), save()" ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="text-no-wrap" align="center">
            <v-col cols="12">
              <v-autocomplete
                v-model="content.blog_id"
                :items="blogs"
                item-text="title"
                item-value="blog_id"
                :search-input.sync="searchQuery"
                :label="$t('home_content_title')"
                :rules="[rules.required]"
                @keyup="search(content.lang)"
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="content.status"
                :items="status"
                item-text="text"
                item-value="value"
                :label="$t('status')"
                :rules="[rules.required]"
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="content.lang"
                :items="languages"
                item-text="language"
                item-value="lang_code"
                :label="$t('language')"
                :rules="[rules.required]"
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" min-width="100" class="pl-0" :loading="$store.state.isloading" @click="cancel">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"
import { PATHFILE } from "@/constants/config"

/**
 * FUNCTIONS
 */
import { putHomeContent, getHomeContentById } from "@/controllers/home/home_content.controller"
import { getBlogByLang } from "@/controllers/blog/blog.controller"

/**
 * LIBs
 */
import { mapMutations } from "vuex"

/**
 * UTILS
 */
import { rules } from "@/utils/validates"

export default {
  data: () => ({
    rules: rules,
    content: {},
    pathFile: PATHFILE,
    languages: LANGUAGES,
    status: STATUS,
    blogs: [],
    searchQuery: ""
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        await this.getHomeContent()
        this.blogs = await getBlogByLang({ lang: this.content.lang, status: "ACTIVE" })
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async getHomeContent() {
      this.content = await getHomeContentById({ id: this.$route.params.id })
    },
     async getBlogs(lang) {
      this.content.blog_id = null
      this.blogs = await getBlogByLang({ lang, status: "ACTIVE", search: this.searchQuery })
    },
    search(lang) {
      this.getBlogs(lang)
    },
    async save() {
      await putHomeContent(
        {
          content: this.content
        },
        this.resultHandle,
        this.errorHandle
      )
    },
    cancel() {
      this.setLoading(true)
      this.$router.back()
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
      this.$router.back()
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    },
    onTabChange(e) {
      this.initialize(e)
    }
  }
}
</script>

<style></style>
