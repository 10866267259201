<template>
  <v-container>
    <base-material-card icon="mdi-page-layout-header" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("banner_detail") }}</div>
      </template>

      <form @submit.prevent="setLoading(true), save()">
        <v-container>
          <v-row>
            <v-col cols="12" md="12" class="text-center grey--text">
              <base-upload-image v-model="file" :defaultValue="banner.file" width="1520px" height="350px" />
              <p class="text-center red--text">*แนะนำขนาดรูปภาพ 1520 x 350 px</p>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field v-model="banner.page" label="page" outlined></v-text-field>
            </v-col>

            <v-col>
              <v-select
                v-model="banner.status"
                :items="status"
                item-text="text"
                item-value="value"
                label="สถานะ"
                outlined
              ></v-select>
            </v-col>
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              min-width="100"
              class="pl-0"
              :loading="$store.state.isloading"
              @click="setLoading(true), cancel()"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </form>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"
import { PATHFILE } from "@/constants/config"

/**
 * CONTROLLER
 */
import { getBannerByID, putBanner } from "@/controllers/banner/banner.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "BannerDetail",
  data: () => ({
    banner: {},
    path_file: PATHFILE,
    languages: LANGUAGES,
    status: STATUS,
    file: null
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.banner = await getBannerByID({
          banner_id: this.$route.params.banner_id
        })
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async save() {
      await putBanner({ banner: this.banner, file: this.file }, this.resultHandle, this.errorHandle)
      this.setLoading(true)
      this.$router.back()
    },
    cancel() {
      this.$router.back()
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
