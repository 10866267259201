import axios from "axios"
import { Unauthorized } from "@/controllers/auth/login.controller"

export async function axiosGet(url, token) {
  try {
    const axiosInstance = axios.create({
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    })

    // axiosInstance.interceptors.response.use(
    //   (response) => response,
    //   (error) => {
    //     if (error.response.status === 401) {
    //       Unauthorized()
    //       return error
    //     }
    //     return error
    //   }
    // )

    const { data } = await axiosInstance.get(url)

    if (data.code === 401) {
      Unauthorized()
      return data
    }

    return data
  } catch (error) {
    return error
  }
}

export async function axiosPost(url, params, token) {
  try {
    const axiosInstance = axios.create({
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    })

    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          Unauthorized()
          return error
        }
        return error
      }
    )

    const { data, status } = await axiosInstance.post(url, params)

    return data
  } catch (error) {
    return error
  }
}

export async function axiosPut(url, params, token) {
  try {
    const axiosInstance = axios.create({
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    })

    const { data } = await axiosInstance.put(url, params)

    if (data.code === 401) {
      Unauthorized()
      return data
    }

    return data
  } catch (error) {
    return error
  }
}

export async function axiosPatch(url, params, token) {
  try {
    const axiosInstance = axios.create({
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    })

    const { data } = await axiosInstance.patch(url, params)

    if (data.code === 401) {
      Unauthorized()
      return data
    }

    return data
  } catch (error) {
    return error
  }
}

export async function axiosDelete(url, token) {
  try {
    const axiosInstance = axios.create({
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    })

    const { data } = await axiosInstance.delete(url)

    if (data.code === 401) {
      Unauthorized()
      return data
    }

    return data
  } catch (error) {
    return error
  }
}

export async function axiosUploadFile(url, params, token) {
  try {
    const axiosInstance = axios.create({
      headers: {
        "Content-Type": `multipart/form-data`,
        Authorization: `Bearer ${token}`
      }
    })

    const result = await axiosInstance.post(url, params)
    return result.data
  } catch (error) {
    return error
  }
}

export async function axiosCreateFile(url, params, token) {
  try {
    const axiosInstance = axios.create({
      headers: {
        "Content-Type": `multipart/form-data`,
        Authorization: `Bearer ${token}`
      }
    })

    const { data } = await axiosInstance.post(url, params)

    if (data.code === 401) {
      Unauthorized()
      return data
    }
    return data
  } catch (error) {
    console.log(error)
    return error
  }
}

export async function axiosUpdateFile(url, params, token) {
  try {
    const axiosInstance = axios.create({
      headers: {
        "Content-Type": `multipart/form-data`,
        Authorization: `Bearer ${token}`
      }
    })

    const { data } = await axiosInstance.put(url, params)

    if (data.code === 401) {
      Unauthorized()
      return data
    }

    return data
  } catch (error) {
    console.log(error)
    return error
  }
}

export async function axiosPatchFile(url, params, token) {
  try {
    const axiosInstance = axios.create({
      headers: {
        "Content-Type": `multipart/form-data`,
        Authorization: `Bearer ${token}`
      }
    })

    const { data } = await axiosInstance.patch(url, params)

    if (data.code === 401) {
      Unauthorized()
      return data
    }

    return data
  } catch (error) {
    console.log(error)
    return error
  }
}
