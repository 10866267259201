/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_HOME_CONTENT } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPost, axiosPut, axiosPatch, axiosDelete } from "@/utils/axios"

export async function getHomeContentAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_HOME_CONTENT.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getHomeContentById({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_HOME_CONTENT.FINDBYID}/${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getHomeContentByLang({ lang, sort = "YES" }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_HOME_CONTENT.FINDALL}?lang=${lang}&sort=${sort}`
    const { code, data, message } = await axiosGet(url, token)
    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function postHomeContent({ content }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = content
    const url = `${API}${EP_HOME_CONTENT.CREATE}`

    const { code, data, message } = await axiosPost(url, payload, token)

    if (code == 201) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putHomeContent({ content }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = {
      blog_id: content.blog_id,
      lang: content.lang,
      status: content.status
    }
    const url = `${API + EP_HOME_CONTENT.UPDATE}/${content.home_article_id}`

    const { code, data, message } = await axiosPatch(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    // const { message } = error.response.data
    console.log(error)
    return errorHandle(error)
  }
}

export async function putHomeContentSort({ contents }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = contents.map((item) => {
      return {
        id: item.home_article_id,
        sort: item.sort
      }
    })
    const url = `${API}${EP_HOME_CONTENT.UPDATE_SORT}`

    const { code, message, data } = await axiosPatch(url, payload, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putStatus({ content, status }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = { status: status }
    const url = `${API}${EP_HOME_CONTENT.UPDATE_STATUS.replace(":id", content.home_article_id)}`

    const { code, data, message } = await axiosPatch(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function deleteHomeContent({ content }, resultHandle, errorHandle, deleteResultHandle) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_HOME_CONTENT.UPDATE_STATUS.replace(":id", content.home_article_id)}`
    const payload = { status: "DELETED" }

    const { code, data, message } = await axiosPatch(url, payload, token)

    if (code == 200) {
      deleteResultHandle()
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}
