<template>
  <v-container>
    <base-material-card icon="mdi-home-map-marker" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("district") }}</div>
      </template>

      <v-container fluid>
        <v-row class="text-no-wrap" align="center">
          <v-col>
            <v-data-table :headers="headers" :items="districts" class="elevation-1" :search="search">
              <template v-slot:top>
                <v-toolbar flat>
                  <!-- <v-toolbar-title>{{ $t("country") }}</v-toolbar-title> -->
                  <v-text-field
                    class="mt-2"
                    v-model="search"
                    :label="$t('search')"
                    single-line
                    append-icon="mdi-magnify"
                  ></v-text-field>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" dark class="ml-auto mx-2" @click="createItem()">
                    {{ $t("district_create") }}
                  </v-btn>
                </v-toolbar>
              </template>

              <template v-slot:[`item.status`]="{ item }">
                <v-chip :color="getColor(item.status)" dark>
                  {{ item.status }}
                </v-chip>
              </template>
              <template v-slot:[`item.sort`]="{ item }">
                {{ item.sort + 1 }}
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-btn class="px-2 ml-1" min-width="0" color="success" @click="editItem(item)" small>
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
                <v-btn class="px-2 ml-1" min-width="0" color="warning" @click="infoItem(item)" small>
                  <v-icon small>mdi-eye</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANTS
 */
import { STATUS } from "@/constants/status"
import { LANGUAGES } from "@/constants/language"
import { PATHFILE } from "@/constants/config"

/**
 * CONTROLLER
 */
import { getByProvinceId as getDistrict } from "@/controllers/address/district.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "News",
  data: () => ({
    districts: [],
    status: STATUS,
    languages: LANGUAGES,
    headers: [
      {
        text: "Id",
        align: "start",
        
        value: "district_id"
      },
      { text: "title", value: "district" },
      { text: "title_en", value: "district_en" },
      // { text: "lang", value: "lang" },
      { text: "Actions", value: "actions", align: "center", width: "15%", sortable: false }
    ],
    path_file: PATHFILE,
    search: ""
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.districts = await getDistrict({ id: this.$route.params.provinceId })
        this.setLoading(false)
      } catch (e) {
        this.errorHandle(e)
      }
    },
    createItem() {
      this.$router.push({ path: `district-create` })
    },
    editItem(item) {
      this.$router.push({ path: `district-detail/${item.district_id}` })
    },
    infoItem(item) {
      this.$router.push({ path: `district/${item.district_id}/subdistrict` })
    },
    getColor(status) {
      if (status === "ACTIVE") return `success`
      else if (status === "INACTIVE") return `error`
    },
    onTabChange(e) {
      this.initialize(e)
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
