<template>
  <v-container>
    <vision v-model="visions" @submit="setLoading(false), saveVision($event)"></vision>
    <mission v-model="missions" @submit="setLoading(false), saveMission($event)"></mission>
    <core-value v-model="core_values" @submit="setLoading(false), saveCoreValue($event)"></core-value>
  </v-container>
</template>

<script>
/**
 * COMPONENT
 */
import CoreValue from "./CoreValue.vue"
import Mission from "./Mission"
import Vision from "./Vision"

/**
 * CONTROLLER
 */
import { getVisionAll, putVision } from "@/controllers/about_us/vision.controller"
import { getMissionAll, putMission } from "@/controllers/about_us/mission.controller"
import { getCoreValueAll, putCoreValue } from "@/controllers/about_us/core_value.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "VisionMissionCoreValue",
  components: {
    Vision,
    Mission,
    CoreValue
  },
  data: () => ({
    visions: [],
    missions: [],
    core_values: []
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.visions = await getVisionAll()
        this.missions = await getMissionAll()
        this.core_values = await getCoreValueAll()
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },

    async saveVision(i) {
      try {
        await putVision({ vision: this.visions[i] }, this.resultHandle, this.errorHandle)
      } catch (e) {
        console.log(e)
      }
    },
    async saveMission(i) {
      try {
        await putMission({ mission: this.missions[i] }, this.resultHandle, this.errorHandle)
      } catch (e) {
        console.log(e)
      }
    },
    async saveCoreValue(i) {
      try {
        await putCoreValue({ core_value: this.core_values[i] }, this.resultHandle, this.errorHandle)
      } catch (e) {
        console.log(e)
      }
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
