<template>
  <v-container>
    <base-material-card icon="mdi-frequently-asked-questions" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("faq") }}</div>
      </template>

      <v-dialog v-model="dialogDelete" max-width="300">
        <v-card>
          <v-card-title>
            <p>
              ยืนยันการลบ
            </p>
            <v-spacer />
            <v-icon aria-label="Close" @click="closeDelete">
              mdi-close
            </v-icon>
          </v-card-title>

          <v-card-text class="pb-6 text-center">
            <v-btn class="mr-1" color="error" dark @click="closeDelete"> {{ $t("cancel") }} </v-btn>
            <v-btn class="mr-1" color="success" dark @click="deleteItemConfirm"> {{ $t("delete") }} </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-tabs vertical>
        <v-tab v-for="(language, i) in languages" :key="`news-${i}`" @change="onTabChange(language.lang_code)">
          {{ language.lang_code }}
        </v-tab>

        <v-tab-item touchless v-for="(language, i) in languages" :key="`news-${i}`">
          <v-container fluid>
            <v-row align="center">
              <v-col>
                <v-data-table :headers="headers" :items="faq" class="elevation-1">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <!-- <v-toolbar-title>{{ $t("faq") }}</v-toolbar-title> -->

                      <v-spacer></v-spacer>
                      <v-btn color="primary" dark class="mb-2 mx-1" @click="sortItem">
                        {{ $t("faq_sort") }}
                      </v-btn>
                      <v-btn color="primary" dark class="mb-2 mx-1" @click="createItem(language.lang_code)">
                        {{ $t("faq_create") }}
                      </v-btn>

                      <v-dialog v-model="dialogSort" max-width="900px">
                        <v-card>
                          <v-card-title class="headline">{{ $t("faq_sort") }}</v-card-title>
                          <v-card-text>
                            <v-container>
                              <base-dnd-table :headers="header_list" :items.sync="faq" :dragOptions="dragOptions" order>
                                <tr v-for="item in faq" :key="item.support_header_id">
                                  <td scope="row" style="cursor: all-scroll">
                                    <v-icon>mdi-format-align-justify</v-icon>
                                  </td>
                                  <td scope="row">{{ item.product_category }}</td>
                                  <td scope="row">{{ item.question }}</td>
                                  <td scope="row">{{ item.answer }}</td>
                                  <td>{{ item.sort + 1 }}</td>
                                </tr>
                              </base-dnd-table>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" dark @click="closeSort">{{ $t("cancel") }}</v-btn>
                            <v-btn color="primary" dark @click="saveSort">{{ $t("save") }}</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>

                  <template v-slot:[`item.product_category`]="{ item }">
                    <div class="text-overflow">
                      {{ item.product_category }}
                    </div>
                  </template>

                  <template v-slot:[`item.question`]="{ item }">
                    <div class="text-overflow">
                      {{ item.question }}
                    </div>
                  </template>

                  <template v-slot:[`item.answer`]="{ item }">
                    <div class="text-overflow">
                      {{ item.answer }}
                    </div>
                  </template>

                  <template v-slot:[`item.status`]="{ item }">
                    <base-switch
                      v-model="item.status"
                      :height="26"
                      :width="99"
                      :font-size="5"
                      :color="{ checked: '#4CAF50', unchecked: '#FF5252' }"
                      :labels="{ checked: 'ACTIVE', unchecked: 'INACTIVE' }"
                      @change="setLoading(true), onChangeEventHandler(item, $event)"
                    />
                  </template>

                  <template v-slot:[`item.file`]="{ item }">
                    <v-btn
                      v-if="Object.keys(item.file).length"
                      icon
                      outlined
                      color="info"
                      :href="`${path_file}${item.file.file_path}`"
                      target="_blank"
                    >
                      <v-icon>mdi-file-download</v-icon>
                    </v-btn>
                  </template>

                  <template v-slot:[`item.sort`]="{ item }">
                    {{ item.sort + 1 }}
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn class="px-2 ml-1" min-width="0" color="success" @click="editItem(item)" small>
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn class="px-2 ml-1" min-width="0" color="error" @click="deleteItem(item)" small>
                      <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANTS
 */
import { LANGUAGES } from "@/constants/language"

/**
 * CONTROLLER
 */
import { getFaqByLang, putFaqSort, putStatus, deleteFaq } from "@/controllers/faq/faq.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "FAQ",
  data: () => ({
    faq: [],
    languages: LANGUAGES,
    headers: [
      {
        text: "ID",
        align: "start",
        value: "faq_id"
      },
      { text: "product_category", value: "product_category" },
      { text: "question", value: "question" },
      { text: "answer", value: "answer" },
      { text: "sort", value: "sort" },
      { text: "status", value: "status" },
      // { text: "lang", value: "lang" },
      { text: "Actions", value: "actions", align: "center", width: "15%", sortable: false }
    ],
    /**
     * SORT
     */
    dialogDelete: false,
    delete_item: null,
    dialogSort: false,
    dragOptions: {
      animation: 200,
      group: "description",
      disabled: false,
      ghostClass: "ghost"
    }
  }),
  created() {
    this.setLoading(true)
    this.initialize("TH")
  },
  computed: {
    header_list() {
      return [
        { text: "", value: "support_id" },
        { text: this.$t("product_category"), value: "product_category" },
        { text: this.$t("question"), value: "question" },
        { text: this.$t("answer"), value: "answer" },
        { text: this.$t("sort"), value: "sort" }
      ]
    }
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),

    async initialize(lang) {
      try {
        this.faq = await getFaqByLang({ lang })
        this.setLoading(false)
      } catch (e) {
        this.errorHandle(e)
      }
    },

    onTabChange(e) {
      this.initialize(e)
    },

    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    },
    editItem(item) {
      this.$router.push({ path: `faq-detail/${item.faq_id}` })
    },
    createItem(lang) {
      this.$router.push({ path: `faq-create?lang=${lang}` })
    },
    async onChangeEventHandler(item, { value }) {
      if (value) await putStatus({ faq: item, status: "ACTIVE" }, this.resultHandle, this.errorHandle)
      else await putStatus({ faq: item, status: "INACTIVE" }, this.resultHandle, this.errorHandle)
    },
    deleteItem(item) {
      this.delete_item = item
      this.dialogDelete = true
    },
    closeDelete() {
      this.delete_item = null
      this.dialogDelete = false
    },
    async deleteItemConfirm() {
      await deleteFaq({ faq: this.delete_item }, this.resultHandle, this.errorHandle, this.deleteResultHandle)
      this.dialogDelete = false
    },
    deleteResultHandle() {
      this.faq = this.faq.filter((faq) => faq.faq_id !== this.delete_item.faq_id)
    },
    /**
     * SORT
     */
    sortItem() {
      this.dialogSort = true
    },
    saveSort() {
      putFaqSort({ faq: this.faq }, this.resultHandle, this.errorHandle)
      this.setLoading(false)
      this.dialogSort = false
    },
    closeSort() {
      this.dialogSort = false
    }
  }
}
</script>

<style></style>
