<template>
  <div>
    <v-file-input
      v-model="file"
      :multiple="multiple"
      :label="label"
      :prepend-icon="prependIcon"
      :accept="accept"
      :chips="chip"
      :outlined="outlined"
      :rules="rules"
      @change="onchangeHandler"
      @click:clear="clearFile"
      ref="inputFile"
    ></v-file-input>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [File, Array],
      default: () => null
    },
    label: {
      type: String,
      default: "file"
    },
    multiple: {
      type: Boolean,
      default: () => false
    },
    "prepend-icon": {
      type: String,
      default: () => "mdi-attachment mdi-rotate-270"
    },
    accept: {
      type: String,
      default: () => ""
    },
    maxSize: {
      type: Number,
      default: () => 5242880
    },
    chip: {
      type: Boolean,
      default: () => false
    },
    outlined: {
      type: Boolean,
      default: () => false
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    file: {
      get() {
        return this.value
      },
      set(value) {
        this.setFile(value)
      }
    }
  },
  methods: {
    onchangeHandler(e) {
      this.$emit("change", e)
    },
    clearFile() {
      if (this.multiple) {
        this.$emit("input", [])
      } else {
        this.$emit("input", null)
      }
    },
    async setFile(files) {
      let valid = true
      if (files && files.length) {
        for (let i = 0; i < files.length; i++) {
          if (files[i].size > this.maxSize) {
            this.errorMaximum()
            valid = false
            break
          }
        }
        if (valid) {
          if (this.multiple) {
            this.$emit("input", files)
          } else {
            this.$emit("input", files[0])
          }
        } else {
          this.clearFile()
          this.$refs.inputFile.clearableCallback()
          this.$refs.inputFile.lazyValue = null
        }
      }
    },
    errorMaximum() {
      alert(`Maximum is ${this.maxSize / 1025 / 1025} MB`)
    }
  }
}
</script>
