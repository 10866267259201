<template>
  <div :class="{ darkMode: 'theme--dark' }">
    <ckeditor v-model="textEditor" :editor="editor" :config="editorConfig" :rules="rules"></ckeditor>
  </div>
</template>

<script>
import UploadAdapter from "@/components/uploadadapter"
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor"
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment"
import AutoImage from "@ckeditor/ckeditor5-image/src/autoimage"
import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat"
import Autolink from "@ckeditor/ckeditor5-link/src/autolink"
import Autosave from "@ckeditor/ckeditor5-autosave/src/autosave"
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote"
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold"
import CKFinder from "@ckeditor/ckeditor5-ckfinder/src/ckfinder"
import CKFinderUploadAdapter from "@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter"
import Code from "@ckeditor/ckeditor5-basic-styles/src/code"
import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock"
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials"
// import ExportToPDF from "@ckeditor/ckeditor5-export-pdf/src/exportpdf"
// import ExportToWord from "@ckeditor/ckeditor5-export-word/src/exportword"
import FontBackgroundColor from "@ckeditor/ckeditor5-font/src/fontbackgroundcolor"
import FontColor from "@ckeditor/ckeditor5-font/src/fontcolor"
import FontFamily from "@ckeditor/ckeditor5-font/src/fontfamily"
import FontSize from "@ckeditor/ckeditor5-font/src/fontsize"
import Heading from "@ckeditor/ckeditor5-heading/src/heading"
import Highlight from "@ckeditor/ckeditor5-highlight/src/highlight"
import HorizontalLine from "@ckeditor/ckeditor5-horizontal-line/src/horizontalline"
// import HtmlEmbed from "@ckeditor/ckeditor5-html-embed/src/htmlembed"
import Image from "@ckeditor/ckeditor5-image/src/image"
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption"
import ImageInsert from "@ckeditor/ckeditor5-image/src/imageinsert"
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize"
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle"
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar"
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload"
import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter"
import Indent from "@ckeditor/ckeditor5-indent/src/indent"
import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock"
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic"
import Link from "@ckeditor/ckeditor5-link/src/link"
import LinkImage from "@ckeditor/ckeditor5-link/src/linkimage"
import List from "@ckeditor/ckeditor5-list/src/list"
import ListStyle from "@ckeditor/ckeditor5-list/src/liststyle"
// import Markdown from "@ckeditor/ckeditor5-markdown-gfm/src/markdown"
import MathType from "@wiris/mathtype-ckeditor5"
// import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed"
// import MediaEmbedToolbar from "@ckeditor/ckeditor5-media-embed/src/mediaembedtoolbar"
import Mention from "@ckeditor/ckeditor5-mention/src/mention"
import PageBreak from "@ckeditor/ckeditor5-page-break/src/pagebreak"
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph"
import PasteFromOffice from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice"
import RemoveFormat from "@ckeditor/ckeditor5-remove-format/src/removeformat"
import SpecialCharacters from "@ckeditor/ckeditor5-special-characters/src/specialcharacters"
import SpecialCharactersArrows from "@ckeditor/ckeditor5-special-characters/src/specialcharactersarrows"
import SpecialCharactersCurrency from "@ckeditor/ckeditor5-special-characters/src/specialcharacterscurrency"
import SpecialCharactersEssentials from "@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials"
import SpecialCharactersLatin from "@ckeditor/ckeditor5-special-characters/src/specialcharacterslatin"
import SpecialCharactersMathematical from "@ckeditor/ckeditor5-special-characters/src/specialcharactersmathematical"
import SpecialCharactersText from "@ckeditor/ckeditor5-special-characters/src/specialcharacterstext"
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough"
import Subscript from "@ckeditor/ckeditor5-basic-styles/src/subscript"
import Superscript from "@ckeditor/ckeditor5-basic-styles/src/superscript"
import Table from "@ckeditor/ckeditor5-table/src/table"
import TableCellProperties from "@ckeditor/ckeditor5-table/src/tablecellproperties"
import TableProperties from "@ckeditor/ckeditor5-table/src/tableproperties"
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar"
import TextTransformation from "@ckeditor/ckeditor5-typing/src/texttransformation"
import TodoList from "@ckeditor/ckeditor5-list/src/todolist"
// import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline"
import WordCount from "@ckeditor/ckeditor5-word-count/src/wordcount"

export default {
  name: "CkEditor",
  props: {
    value: {
      type: String
    },
    placeholder: {
      type: String
    },
    counter: {
      type: Number
    },
    rules: {
      type: Array
    },
    textable: {
      type: Boolean
    }
  },
  computed: {
    textEditor: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    },
    characters: {
      get() {
        return this.counter
      },
      set(counter) {
        this.$emit("onCounter", counter)
      }
    }
  },
  methods: {
    uploader(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new UploadAdapter(loader)
      }
    }
  },

  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        plugins: [
          Alignment,
          AutoImage,
          Autolink,
          Autosave,
          Autoformat,
          Base64UploadAdapter,
          BlockQuote,
          Bold,
          CKFinder,
          CKFinderUploadAdapter,
          Code,
          CodeBlock,
          Essentials,
          // ExportToPDF,
          // ExportToWord,
          FontBackgroundColor,
          FontColor,
          FontFamily,
          FontSize,
          Heading,
          Highlight,
          HorizontalLine,
          // HtmlEmbed,
          Image,
          ImageCaption,
          ImageInsert,
          ImageResize,
          ImageStyle,
          ImageToolbar,
          ImageUpload,
          Indent,
          IndentBlock,
          Italic,
          Link,
          LinkImage,
          List,
          ListStyle,
          // Markdown, //HTML TO TEXT
          MathType,
          // MediaEmbed,
          // MediaEmbedToolbar,
          Mention,
          PageBreak,
          Paragraph,
          PasteFromOffice,
          RemoveFormat,
          SpecialCharacters,
          SpecialCharactersArrows,
          SpecialCharactersCurrency,
          SpecialCharactersEssentials,
          SpecialCharactersLatin,
          SpecialCharactersMathematical,
          SpecialCharactersText,
          Strikethrough,
          Subscript,
          Superscript,
          Table,
          TableCellProperties,
          TableProperties,
          TableToolbar,
          TextTransformation,
          TodoList,
          // Underline,
          WordCount
        ],
        fontColor: {
          columns: 10,
          documentColors: 200,
          colors: [
            { color: "hsl(6, 54%, 95%)", label: " " },
            { color: "hsl(6, 54%, 89%)", label: " " },
            { color: "hsl(6, 54%, 78%)", label: " " },
            { color: "hsl(6, 54%, 68%)", label: " " },
            { color: "hsl(6, 54%, 57%)", label: " " },
            { color: "hsl(6, 63%, 46%)", label: " " },
            { color: "hsl(6, 63%, 41%)", label: " " },
            { color: "hsl(6, 63%, 35%)", label: " " },
            { color: "hsl(6, 63%, 29%)", label: " " },
            { color: "hsl(6, 63%, 24%)", label: " " },
            { color: "hsl(6, 78%, 96%)", label: " " },
            { color: "hsl(6, 78%, 91%)", label: " " },
            { color: "hsl(6, 78%, 83%)", label: " " },
            { color: "hsl(6, 78%, 74%)", label: " " },
            { color: "hsl(6, 78%, 66%)", label: " " },
            { color: "hsl(6, 78%, 57%)", label: " " },
            { color: "hsl(6, 59%, 50%)", label: " " },
            { color: "hsl(6, 59%, 43%)", label: " " },
            { color: "hsl(6, 59%, 37%)", label: " " },
            { color: "hsl(6, 59%, 30%)", label: " " },
            { color: "hsl(283, 39%, 95%)", label: " " },
            { color: "hsl(283, 39%, 91%)", label: " " },
            { color: "hsl(283, 39%, 81%)", label: " " },
            { color: "hsl(283, 39%, 72%)", label: " " },
            { color: "hsl(283, 39%, 63%)", label: " " },
            { color: "hsl(283, 39%, 53%)", label: " " },
            { color: "hsl(283, 34%, 47%)", label: " " },
            { color: "hsl(283, 34%, 40%)", label: " " },
            { color: "hsl(283, 34%, 34%)", label: " " },
            { color: "hsl(283, 34%, 28%)", label: " " },
            { color: "hsl(282, 39%, 95%)", label: " " },
            { color: "hsl(282, 39%, 89%)", label: " " },
            { color: "hsl(282, 39%, 79%)", label: " " },
            { color: "hsl(282, 39%, 68%)", label: " " },
            { color: "hsl(282, 39%, 58%)", label: " " },
            { color: "hsl(282, 44%, 47%)", label: " " },
            { color: "hsl(282, 44%, 42%)", label: " " },
            { color: "hsl(282, 44%, 36%)", label: " " },
            { color: "hsl(282, 44%, 30%)", label: " " },
            { color: "hsl(282, 44%, 25%)", label: " " },
            { color: "hsl(204, 51%, 94%)", label: " " },
            { color: "hsl(204, 51%, 89%)", label: " " },
            { color: "hsl(204, 51%, 78%)", label: " " },
            { color: "hsl(204, 51%, 67%)", label: " " },
            { color: "hsl(204, 51%, 55%)", label: " " },
            { color: "hsl(204, 64%, 44%)", label: " " },
            { color: "hsl(204, 64%, 39%)", label: " " },
            { color: "hsl(204, 64%, 34%)", label: " " },
            { color: "hsl(204, 64%, 28%)", label: " " },
            { color: "hsl(204, 64%, 23%)", label: " " },
            { color: "hsl(204, 70%, 95%)", label: " " },
            { color: "hsl(204, 70%, 91%)", label: " " },
            { color: "hsl(204, 70%, 81%)", label: " " },
            { color: "hsl(204, 70%, 72%)", label: " " },
            { color: "hsl(204, 70%, 63%)", label: " " },
            { color: "hsl(204, 70%, 53%)", label: " " },
            { color: "hsl(204, 62%, 47%)", label: " " },
            { color: "hsl(204, 62%, 40%)", label: " " },
            { color: "hsl(204, 62%, 34%)", label: " " },
            { color: "hsl(204, 62%, 28%)", label: " " },
            { color: "hsl(168, 55%, 94%)", label: " " },
            { color: "hsl(168, 55%, 88%)", label: " " },
            { color: "hsl(168, 55%, 77%)", label: " " },
            { color: "hsl(168, 55%, 65%)", label: " " },
            { color: "hsl(168, 55%, 54%)", label: " " },
            { color: "hsl(168, 76%, 42%)", label: " " },
            { color: "hsl(168, 76%, 37%)", label: " " },
            { color: "hsl(168, 76%, 32%)", label: " " },
            { color: "hsl(168, 76%, 27%)", label: " " },
            { color: "hsl(168, 76%, 22%)", label: " " },
            { color: "hsl(168, 42%, 94%)", label: " " },
            { color: "hsl(168, 42%, 87%)", label: " " },
            { color: "hsl(168, 42%, 74%)", label: " " },
            { color: "hsl(168, 42%, 61%)", label: " " },
            { color: "hsl(168, 45%, 49%)", label: " " },
            { color: "hsl(168, 76%, 36%)", label: " " },
            { color: "hsl(168, 76%, 31%)", label: " " },
            { color: "hsl(168, 76%, 27%)", label: " " },
            { color: "hsl(168, 76%, 23%)", label: " " },
            { color: "hsl(168, 76%, 19%)", label: " " },
            { color: "hsl(145, 45%, 94%)", label: " " },
            { color: "hsl(145, 45%, 88%)", label: " " },
            { color: "hsl(145, 45%, 77%)", label: " " },
            { color: "hsl(145, 45%, 65%)", label: " " },
            { color: "hsl(145, 45%, 53%)", label: " " },
            { color: "hsl(145, 63%, 42%)", label: " " },
            { color: "hsl(145, 63%, 37%)", label: " " },
            { color: "hsl(145, 63%, 32%)", label: " " },
            { color: "hsl(145, 63%, 27%)", label: " " },
            { color: "hsl(145, 63%, 22%)", label: " " },
            { color: "hsl(145, 61%, 95%)", label: " " },
            { color: "hsl(145, 61%, 90%)", label: " " },
            { color: "hsl(145, 61%, 80%)", label: " " },
            { color: "hsl(145, 61%, 69%)", label: " " },
            { color: "hsl(145, 61%, 59%)", label: " " },
            { color: "hsl(145, 63%, 49%)", label: " " },
            { color: "hsl(145, 63%, 43%)", label: " " },
            { color: "hsl(145, 63%, 37%)", label: " " },
            { color: "hsl(145, 63%, 31%)", label: " " },
            { color: "hsl(145, 63%, 25%)", label: " " },
            { color: "hsl(48, 89%, 95%)", label: " " },
            { color: "hsl(48, 89%, 90%)", label: " " },
            { color: "hsl(48, 89%, 80%)", label: " " },
            { color: "hsl(48, 89%, 70%)", label: " " },
            { color: "hsl(48, 89%, 60%)", label: " " },
            { color: "hsl(48, 89%, 50%)", label: " " },
            { color: "hsl(48, 88%, 44%)", label: " " },
            { color: "hsl(48, 88%, 38%)", label: " " },
            { color: "hsl(48, 88%, 32%)", label: " " },
            { color: "hsl(48, 88%, 26%)", label: " " },
            { color: "hsl(37, 90%, 95%)", label: " " },
            { color: "hsl(37, 90%, 90%)", label: " " },
            { color: "hsl(37, 90%, 80%)", label: " " },
            { color: "hsl(37, 90%, 71%)", label: " " },
            { color: "hsl(37, 90%, 61%)", label: " " },
            { color: "hsl(37, 90%, 51%)", label: " " },
            { color: "hsl(37, 86%, 45%)", label: " " },
            { color: "hsl(37, 86%, 39%)", label: " " },
            { color: "hsl(37, 86%, 33%)", label: " " },
            { color: "hsl(37, 86%, 27%)", label: " " },
            { color: "hsl(28, 80%, 95%)", label: " " },
            { color: "hsl(28, 80%, 90%)", label: " " },
            { color: "hsl(28, 80%, 81%)", label: " " },
            { color: "hsl(28, 80%, 71%)", label: " " },
            { color: "hsl(28, 80%, 61%)", label: " " },
            { color: "hsl(28, 80%, 52%)", label: " " },
            { color: "hsl(28, 74%, 46%)", label: " " },
            { color: "hsl(28, 74%, 39%)", label: " " },
            { color: "hsl(28, 74%, 33%)", label: " " },
            { color: "hsl(28, 74%, 27%)", label: " " },
            { color: "hsl(24, 71%, 94%)", label: " " },
            { color: "hsl(24, 71%, 88%)", label: " " },
            { color: "hsl(24, 71%, 77%)", label: " " },
            { color: "hsl(24, 71%, 65%)", label: " " },
            { color: "hsl(24, 71%, 53%)", label: " " },
            { color: "hsl(24, 100%, 41%)", label: " " },
            { color: "hsl(24, 100%, 36%)", label: " " },
            { color: "hsl(24, 100%, 31%)", label: " " },
            { color: "hsl(24, 100%, 26%)", label: " " },
            { color: "hsl(24, 100%, 22%)", label: " " },
            { color: "hsl(192, 15%, 99%)", label: " " },
            { color: "hsl(192, 15%, 99%)", label: " " },
            { color: "hsl(192, 15%, 97%)", label: " " },
            { color: "hsl(192, 15%, 96%)", label: " " },
            { color: "hsl(192, 15%, 95%)", label: " " },
            { color: "hsl(192, 15%, 94%)", label: " " },
            { color: "hsl(192, 5%, 82%)", label: " " },
            { color: "hsl(192, 3%, 71%)", label: " " },
            { color: "hsl(192, 2%, 60%)", label: " " },
            { color: "hsl(192, 1%, 49%)", label: " " },
            { color: "hsl(204, 8%, 98%)", label: " " },
            { color: "hsl(204, 8%, 95%)", label: " " },
            { color: "hsl(204, 8%, 90%)", label: " " },
            { color: "hsl(204, 8%, 86%)", label: " " },
            { color: "hsl(204, 8%, 81%)", label: " " },
            { color: "hsl(204, 8%, 76%)", label: " " },
            { color: "hsl(204, 5%, 67%)", label: " " },
            { color: "hsl(204, 4%, 58%)", label: " " },
            { color: "hsl(204, 3%, 49%)", label: " " },
            { color: "hsl(204, 3%, 40%)", label: " " },
            { color: "hsl(184, 9%, 96%)", label: " " },
            { color: "hsl(184, 9%, 92%)", label: " " },
            { color: "hsl(184, 9%, 85%)", label: " " },
            { color: "hsl(184, 9%, 77%)", label: " " },
            { color: "hsl(184, 9%, 69%)", label: " " },
            { color: "hsl(184, 9%, 62%)", label: " " },
            { color: "hsl(184, 6%, 54%)", label: " " },
            { color: "hsl(184, 5%, 47%)", label: " " },
            { color: "hsl(184, 5%, 40%)", label: " " },
            { color: "hsl(184, 5%, 32%)", label: " " },
            { color: "hsl(184, 6%, 95%)", label: " " },
            { color: "hsl(184, 6%, 91%)", label: " " },
            { color: "hsl(184, 6%, 81%)", label: " " },
            { color: "hsl(184, 6%, 72%)", label: " " },
            { color: "hsl(184, 6%, 62%)", label: " " },
            { color: "hsl(184, 6%, 53%)", label: " " },
            { color: "hsl(184, 5%, 46%)", label: " " },
            { color: "hsl(184, 5%, 40%)", label: " " },
            { color: "hsl(184, 5%, 34%)", label: " " },
            { color: "hsl(184, 5%, 27%)", label: " " },
            { color: "hsl(210, 12%, 93%)", label: " " },
            { color: "hsl(210, 12%, 86%)", label: " " },
            { color: "hsl(210, 12%, 71%)", label: " " },
            { color: "hsl(210, 12%, 57%)", label: " " },
            { color: "hsl(210, 15%, 43%)", label: " " },
            { color: "hsl(210, 29%, 29%)", label: " " },
            { color: "hsl(210, 29%, 25%)", label: " " },
            { color: "hsl(210, 29%, 22%)", label: " " },
            { color: "hsl(210, 29%, 18%)", label: " " },
            { color: "hsl(210, 29%, 15%)", label: " " },
            { color: "hsl(210, 9%, 92%)", label: " " },
            { color: "hsl(210, 9%, 85%)", label: " " },
            { color: "hsl(210, 9%, 70%)", label: " " },
            { color: "hsl(210, 9%, 55%)", label: " " },
            { color: "hsl(210, 14%, 39%)", label: " " },
            { color: "hsl(210, 29%, 24%)", label: " " },
            { color: "hsl(210, 29%, 21%)", label: " " },
            { color: "hsl(210, 29%, 18%)", label: " " },
            { color: "hsl(210, 29%, 16%)", label: " " },
            { color: "hsl(210, 29%, 13%)", label: " " }
          ]
        },
        image: {
          styles: [
            "full",
            "alignLeft",
            "alignCenter",
            "alignRight",
            { name: "contact", icon: "right", title: "My contact style", className: "my-contact-side-image" }
          ],
          resizeOptions: [
            {
              name: "imageResize:original",
              label: "Original",
              value: null
            },
            {
              name: "imageResize:25",
              label: "25%",
              value: "25"
            },
            {
              name: "imageResize:50",
              label: "50%",
              value: "50"
            },
            {
              name: "imageResize:75",
              label: "75%",
              value: "75"
            },
            {
              name: "imageResize:100",
              label: "100%",
              value: "100"
            }
          ],
          toolbar: [
            "imageStyle:full",
            "|",
            "imageStyle:alignLeft",
            "imageStyle:alignCenter",
            "imageStyle:alignRight",
            "|",
            "imageResize",
            "|",
            "imageTextAlternative"
          ]
        },
        toolbar: {
          items: [
            "heading",
            "|",
            // "fontFamily",
            "fontColor",
            "removeFormat",
            "|",
            "bold",
            "italic",
            "|",
            "alignment",
            "bulletedList",
            "numberedList",
            "todoList",
            "undo",
            "redo",
            "|",
            "indent",
            "outdent",
            "horizontalLine",
            "|",
            this.textable ? null : "insertTable",
            this.textable ? null : "tableCellProperties",
            this.textable ? null : "tableProperties",
            this.textable ? null : "|",
            this.textable ? null : "imageUpload",
            this.textable ? null : "ImageInsert",
            // "mediaEmbed",
            "link"
            // "exportPdf",
            // "htmlEmbed"
          ]
        },
        placeholder: this.placeholder,
        wordCount: {
          onUpdate: (stats) => {
            // Prints the current content statistics.
            this.characters = stats.characters
          }
        }
        // extraPlugins: [this.uploader]
        // The configuration of the editor.
      }
    }
  }
}
</script>

<style>
.ck-editor__editable {
  min-height: 300px;
  white-space: pre-wrap !important;
}
.theme--dark .ck > .ck.ck-editor__main > .ck-editor__editable {
  background: #272727;
  color: #ffffff;
}

.image-style-side {
  max-width: 50%;
  float: right;
}

.image-style-align-left {
  float: left;
  margin: 5px;
}

.image-style-align-right {
  float: right;
  margin: 5px;
}

.image-style-align-center {
  margin: 0px auto;
  display: block;
}

.image.image_resized {
  max-width: 100%;
}

.image.image_resized {
  display: block;
  box-sizing: border-box;
}
.image.image_resized img {
  width: 100%;
}

.image img {
  margin: 0px auto;
  display: block;
}

.image.image_resized > figcaption {
  display: block;
}
</style>

<style>
:root .theme--dark {
  /* Overrides the border radius setting in the theme. */
  --ck-border-radius: 4px;

  /* Overrides the default font size in the theme. */
  --ck-font-size-base: 14px;

  /* Helper variables to avoid duplication in the colors. */
  --ck-custom-background: #272727;
  --ck-custom-foreground: hsl(255, 3%, 18%);
  --ck-custom-border: hsl(300, 1%, 22%);
  --ck-custom-white: hsl(0, 0%, 100%);

  /* -- Overrides generic colors. ------------------------------------------------------------- */

  --ck-color-base-foreground: var(--ck-custom-background);
  --ck-color-focus-border: var(--v-primary-base);
  --ck-color-text: hsl(0, 0%, 98%);
  --ck-color-shadow-drop: hsla(0, 0%, 0%, 0.2);
  --ck-color-shadow-inner: hsla(0, 0%, 0%, 0.1);

  /* -- Overrides the default .ck-button class colors. ---------------------------------------- */

  --ck-color-button-default-background: var(--ck-custom-background);
  --ck-color-button-default-hover-background: hsl(270, 1%, 22%);
  --ck-color-button-default-active-background: hsl(270, 2%, 20%);
  --ck-color-button-default-active-shadow: hsl(270, 2%, 23%);
  --ck-color-button-default-disabled-background: var(--ck-custom-background);

  --ck-color-button-on-background: var(--ck-custom-foreground);
  --ck-color-button-on-hover-background: hsl(255, 4%, 16%);
  --ck-color-button-on-active-background: hsl(255, 4%, 14%);
  --ck-color-button-on-active-shadow: hsl(240, 3%, 19%);
  --ck-color-button-on-disabled-background: var(--ck-custom-foreground);

  --ck-color-button-action-background: hsl(168, 76%, 42%);
  --ck-color-button-action-hover-background: hsl(168, 76%, 38%);
  --ck-color-button-action-active-background: hsl(168, 76%, 36%);
  --ck-color-button-action-active-shadow: hsl(168, 75%, 34%);
  --ck-color-button-action-disabled-background: hsl(168, 76%, 42%);
  --ck-color-button-action-text: var(--ck-custom-white);

  --ck-color-button-save: hsl(120, 100%, 46%);
  --ck-color-button-cancel: hsl(15, 100%, 56%);

  /* -- Overrides the default .ck-dropdown class colors. -------------------------------------- */

  --ck-color-dropdown-panel-background: var(--ck-custom-background);
  --ck-color-dropdown-panel-border: var(--ck-custom-foreground);

  /* -- Overrides the default .ck-splitbutton class colors. ----------------------------------- */

  --ck-color-split-button-hover-background: var(--ck-color-button-default-hover-background);
  --ck-color-split-button-hover-border: var(--ck-custom-foreground);

  /* -- Overrides the default .ck-input class colors. ----------------------------------------- */

  --ck-color-input-background: var(--ck-custom-background);
  --ck-color-input-border: hsl(257, 3%, 43%);
  --ck-color-input-text: hsl(0, 0%, 98%);
  --ck-color-input-disabled-background: hsl(255, 4%, 21%);
  --ck-color-input-disabled-border: hsl(250, 3%, 38%);
  --ck-color-input-disabled-text: hsl(0, 0%, 78%);

  /* -- Overrides the default .ck-labeled-field-view class colors. ---------------------------- */

  --ck-color-labeled-field-label-background: var(--ck-custom-background);

  /* -- Overrides the default .ck-list class colors. ------------------------------------------ */

  --ck-color-list-background: var(--ck-custom-background);
  --ck-color-list-button-hover-background: var(--ck-color-base-foreground);
  --ck-color-list-button-on-background: var(--ck-color-base-active);
  --ck-color-list-button-on-background-focus: var(--ck-color-base-active-focus);
  --ck-color-list-button-on-text: var(--ck-color-base-background);

  /* -- Overrides the default .ck-balloon-panel class colors. --------------------------------- */

  --ck-color-panel-background: var(--ck-custom-background);
  --ck-color-panel-border: var(--ck-custom-border);

  /* -- Overrides the default .ck-toolbar class colors. --------------------------------------- */

  --ck-color-toolbar-background: var(--ck-custom-background);
  --ck-color-toolbar-border: var(--ck-custom-border);

  /* -- Overrides the default .ck-tooltip class colors. --------------------------------------- */

  --ck-color-tooltip-background: hsl(252, 7%, 14%);
  --ck-color-tooltip-text: hsl(0, 0%, 93%);

  /* -- Overrides the default colors used by the ckeditor5-image package. --------------------- */

  --ck-color-image-caption-background: hsl(0, 0%, 97%);
  --ck-color-image-caption-text: hsl(0, 0%, 20%);

  /* -- Overrides the default colors used by the ckeditor5-widget package. -------------------- */

  --ck-color-widget-blurred-border: hsl(0, 0%, 87%);
  --ck-color-widget-hover-border: hsl(43, 100%, 68%);
  --ck-color-widget-editable-focus-background: var(--ck-custom-white);

  /* -- Overrides the default colors used by the ckeditor5-link package. ---------------------- */

  --ck-color-link-default: hsl(190, 100%, 75%);
}
</style>
