<template>
  <!-- <div>1231</div> -->
  <router-view />
</template>

<script>
export default {
  name: "App"
}
</script>
