<template>
  <div>
    <!-- defaultValue: {{ defaultValue }}<br /> -->
    <!-- imageUrl: {{ imageUrl }} <br /> -->
    <!-- imageFile:{{ imageFile }}<br /> -->
    <!-- imageFile:{{ typeof imageFile }}<br /> -->
    <!-- imageFile:{{ Object.keys(defaultValue).length }}<br /> -->
    <!-- {{ Object.keys(defaultValue).length ? `${path_file}${defaultValue.file_path}` : null }} -->
    <v-hover v-slot="{ hover }">
      <v-img contain v-if="imageUrl" class="base-image-input" :style="style" :src="`${imageUrl}`" @click="chooseImage">
        <!-- <div
        v-if="imageUrl"
        class="base-image-input"
        :style="style + `background-image: url(${imageUrl})`"
        @click="chooseImage"
      > -->
        <v-fade-transition>
          <div justify="center" align="center" v-if="hover" class="text-center d-flex darken-2 white--text placeholder">
            <v-row class="text-no-wrap" align="center">
              <v-col class="text-center">
                <v-icon large color="black">mdi-camera</v-icon>
              </v-col>
            </v-row>
          </div>
        </v-fade-transition>
      </v-img>
      <!-- </div> -->
      <div
        v-else-if="!Object.keys(defaultValue).length"
        class="base-image-input"
        :style="style + `background-color: #e0e0e0`"
        @click="chooseImage"
      >
        <v-fade-transition>
          <div justify="center" align="center" v-if="hover" class="text-center d-flex darken-2 white--text placeholder">
            <v-row class="text-no-wrap" align="center">
              <v-col class="text-center">
                <v-icon large color="black">mdi-camera</v-icon>
              </v-col>
            </v-row>
          </div>
        </v-fade-transition>
      </div>
      <v-img
        v-else
        contain
        class="base-image"
        :style="style"
        :src="`${path_file}${defaultValue.file_path}`"
        @click="chooseImage"
      >
        <v-fade-transition>
          <div justify="center" align="center" v-if="hover" class="text-center d-flex darken-2 white--text placeholder">
            <v-row class="text-no-wrap" align="center">
              <v-col class="text-center">
                <v-icon large color="black">mdi-camera</v-icon>
              </v-col>
            </v-row>
          </div>
        </v-fade-transition>
      </v-img>
    </v-hover>

    <v-file-input v-model="imageFile" class="file-input" ref="fileInput" @change="onSelectFile" />
  </div>
</template>

<script>
import { PATHFILE } from "@/constants/config"

export default {
  name: "UploadImage",
  data() {
    return {
      imageUrl: null,
      imageData: null,
      path_file: PATHFILE
    }
  },
  props: {
    value: {
      type: File,
      default: null
    },
    defaultValue: {
      type: Object,
      default: () => ({})
    },
    maxSize: {
      type: Number,
      default: () => 5 * 1025 * 1025
    },
    width: {
      type: String,
      default: () => "200px"
    },
    height: {
      type: String,
      default: () => "200px"
    }
  },
  computed: {
    imageFile: {
      get() {
        return this.value
      },
      set(value) {
        if (value.size < this.maxSize) {
          this.$emit("input", value)
        } else {
          alert(`Maximum size is ${this.maxSize / 1025 / 1025}MB`)
        }
      }
    },
    style() {
      return `max-width: ${this.width}; width: ${this.width}; max-height: ${this.height}; height: ${this.height};`
    }
    // defaultValue: {
    //   get() {
    //     return this.defaultValue
    //   },
    //   set(value) {
    //     this.$emit("defaultValue", value)
    //   }
    // }
  },
  methods: {
    chooseImage() {
      this.$refs.fileInput.$refs.input.click()
    },
    onSelectFile(e) {
      if (e && e.size < 5000000) this.imageUrl = URL.createObjectURL(e)
      // else this.imageUrl = null
    }
  }
}
</script>

<style scoped>
.base-image-input {
  text-align: center;
  margin: auto;
  cursor: pointer;
  width: 250px;
  height: 250px;
  background-size: contain;
  background-position: center center;
}
.base-image {
  text-align: center;
  margin: auto;
  /* display: block; */
  cursor: pointer;
  width: 250px;
  height: 250px;
  background-size: contain;
  background-position: center center;
}
.placeholder {
  height: 100%;
  width: 100%;
  background-color: HSL(0, 0%, 74%, 0.5);
}
.file-input {
  display: none;
}
</style>
