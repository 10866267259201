<template>
  <div class="map-wrapper">
    <GmapAutocomplete
      v-model="search"
      @change="getSearch"
      @place_changed="setPlace"
      class="input-search"
      ref="googleSearch"
    ></GmapAutocomplete>
    <GmapMap
      class="mx-auto map-responsive"
      :center="coordinates"
      :zoom="zoom"
      map-type-id="terrain"
      :options="{ mapTypeControl: false }"
    >
      <GmapMarker
        :position="coordinates"
        :clickable="true"
        :draggable="false"
        @click="center = coordinates"
        @drag="updateCoordinates"
      />
    </GmapMap>
  </div>
</template>

<script>
import { getCountry } from "@/controllers/dealer/dealer.controller"
import { mapMutations } from "vuex"

export default {
  props: {
    value: {
      type: String,
      default: () => ""
    },
    location: {
      type: Object,
      default: () => ({
        lat: null,
        lng: null
      })
    }
  },
  mounted() {
    this.initialize()
  },
  data() {
    return {
      zoom: 13
    }
  },
  computed: {
    coordinates() {
      if (!this.location.lat || !this.location.lng) {
        return {
          lat: 13.764187851076384,
          lng: 100.5108642578125
        }
      }
      this.zoom = 18
      return {
        lat: this.location.lat,
        lng: this.location.lng
      }
    },
    search: {
      get() {
        return this.value
      },    
      set(val) {
        if (val) {
          this.$emit("input", val.target.value)
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.countries = await getCountry()
        this.setLoading(false)
      } catch (e) {
      }
    },
    getSearch(val) {
      this.$emit("input", val.target.value)
    },
    setPlace(place) {
      this.zoom = 18
      this.$emit("input", this.$refs.googleSearch.$el.value)
      this.$emit("getPosition", {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      })

      this.$emit("getPlaceName", place.name)
      this.$emit("getPlaceId", place.place_id)
    },
    updateCoordinates(location) {
      this.$emit("getPosition", {
        lat: location.latLng.lat(),
        lng: location.latLng.lng()
      })
    }
  }
}
</script>

<style lang="scss">
.map-wrapper {
  position: relative;
  width: 100%;
  margin: 0 auto;
}
.input-search {
  position: absolute;
  display: flex;
  z-index: 1;
  top: 5px;
  left: 5px;
  width: 100%;
  max-width: 700px;
  min-height: 50px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  padding: 0 8px;
  outline: none;
  border-radius: 5px;
  transition: all 0.5s;

  &:focus {
    border: 1px solid rgba(0, 0, 0, 0.45);
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.14);
    -webkit-box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.14);
  }
}
</style>
