/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
"use strict"

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_LOCATION } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut } from "@/utils/axios"

export async function getLocationAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_LOCATION.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return errorHandle(message)
  }
}

export async function putLocation({ location }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = {
      headquarters: location.headquarters,
      first_branch: location.first_branch,
      second_branch: location.second_branch,
      third_branch: location.third_branch,
      call_center: location.call_center,
      website: location.website,
      email: location.email,
      status: location.status,
      lang: location.lang
    }

    const url = `${API}${EP_LOCATION.UPDATE}/${location.location_id}`
    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    return errorHandle(message)
  }
}
