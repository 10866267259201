<template>
  <base-material-card icon="mdi-face-agent" color="primary">
    <template v-slot:after-heading>
      <div class="font-weight-light card-title mt-2">{{ $t("support_header") }}</div>
    </template>

    <v-dialog v-model="dialogDelete" max-width="300">
      <v-card>
        <v-card-title>
          <p>
            ยืนยันการลบ
          </p>
          <v-spacer />
          <v-icon aria-label="Close" @click="closeDelete">
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="pb-6 text-center">
          <v-btn class="mr-1" color="error" dark @click="closeDelete"> {{ $t("cancel") }} </v-btn>
          <v-btn class="mr-1" color="success" dark @click="deleteItemConfirm"> {{ $t("delete") }} </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-tabs vertical>
      <v-tab v-for="(language, i) in languages" :key="`support-header-${i}`" @change="onTabChange(language.lang_code)">
        {{ language.lang_code }}
      </v-tab>

      <v-tab-item touchless v-for="(language, i) in languages" :key="`support-header-${i}`">
        <v-container fluid>
          <v-row class="text-no-wrap" align="center">
            <v-col>
              <v-data-table :headers="headers" :items="support_headers" class="elevation-1">
                <template v-slot:top>
                  <v-toolbar flat>
                    <!-- <v-toolbar-title>{{ $t("support_header") }}</v-toolbar-title> -->

                    <v-spacer></v-spacer>
                    <v-btn color="primary" dark class="mb-2 mx-1" @click="sortItem">
                      {{ $t("support_header_sort") }}
                    </v-btn>
                    <v-btn color="primary" dark class="mb-2 mx-1" @click="createItem(language.lang_code)">
                      {{ $t("support_header_create") }}
                    </v-btn>

                    <v-dialog v-model="dialogSort" max-width="900px">
                      <v-card>
                        <v-card-title class="headline">{{ $t("support_header_sort") }}</v-card-title>
                        <v-card-text>
                          <v-container>
                            <base-dnd-table
                              :headers="header_list"
                              :items.sync="support_headers"
                              :dragOptions="dragOptions"
                              order
                            >
                              <tr v-for="item in support_headers" :key="item.support_header_id">
                                <td scope="row" style="cursor: all-scroll">
                                  <v-icon>mdi-format-align-justify</v-icon>
                                </td>
                                <td scope="row">{{ item.title }}</td>
                                <td>{{ item.status }}</td>
                                <td>{{ item.sort + 1 }}</td>
                              </tr>
                            </base-dnd-table>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" dark @click="closeSort">{{ $t("cancel") }}</v-btn>
                          <v-btn color="primary" dark @click="saveSort">{{ $t("save") }}</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>

                <template v-slot:[`item.status`]="{ item }">
                  <base-switch
                    v-model="item.status"
                    :height="26"
                    :width="99"
                    :font-size="5"
                    :color="{ checked: '#4CAF50', unchecked: '#FF5252' }"
                    :labels="{ checked: 'ACTIVE', unchecked: 'INACTIVE' }"
                    @change="onChangeEventHandler(item, $event)"
                  />
                </template>

                <template v-slot:[`item.filter`]="{ item }">
                  <v-icon v-if="item.filter == 1" color="success">mdi-check </v-icon>
                  <v-icon v-else color="error">mdi-close </v-icon>
                </template>

                <template v-slot:[`item.sort`]="{ item }">
                  {{ item.sort + 1 }}
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn class="px-2 ml-1" min-width="0" color="success" @click="editItem(item)" small>
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn class="px-2 ml-1" min-width="0" color="warning" @click="infoItem(item)" small>
                    <v-icon small> mdi-eye </v-icon>
                  </v-btn>
                  <v-btn class="px-2 ml-1" min-width="0" color="error" @click="deleteItem(item)" small>
                    <v-icon small> mdi-delete </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs>
  </base-material-card>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"

export default {
  name: "SupportHeader",
  data: () => ({
    languages: LANGUAGES,
    headers: [
      {
        text: "ID",
        align: "start",
        
        value: "support_header_id"
      },
      { text: "title", value: "title" },
      { text: "sort", value: "sort" },
      { text: "status", value: "status" },
      { text: "filter", value: "filter" },
      { text: "type", value: "type" },
      // { text: "lang", value: "lang" },
      { text: "Actions", value: "actions", align: "center", width: "15%", sortable: false }
    ],

    /**
     * SORT
     */
    dialogSort: false,
    header_list: [
      { text: "", value: "support_id" },
      { text: "title", value: "title" },
      { text: "status", value: "status" },
      // { text: "lang", value: "lang" },
      { text: "sort", value: "sort" }
    ],
    dialogDelete: false,
    delete_item: null,
    dragOptions: {
      animation: 200,
      group: "description",
      disabled: false,
      ghostClass: "ghost"
    }
  }),
  props: {
    value: {
      type: Array
    }
  },
  computed: {
    support_headers: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    }
  },
  methods: {
    editItem(item) {
      this.$router.push({ path: `support-header-detail/${item.support_header_id}` })
    },
    createItem(lang) {
      this.$router.push({ path: `support-header-create?lang=${lang}` })
    },
    infoItem(item) {
      this.$router.push({ path: `support-list/${item.support_header_id}` })
    },
    sortItem() {
      this.dialogSort = true
    },
    saveSort() {
      this.$emit("submitSort")
      this.dialogSort = false
    },
    closeSort() {
      this.dialogSort = false
    },
    onChangeEventHandler(item, { value }) {
      this.$emit("onChangeStatus", { item, value })
    },
    onTabChange(e) {
      this.$emit("onTabChange", e)
    },
    deleteItem(item) {
      this.delete_item = item
      this.dialogDelete = true
    },
    closeDelete() {
      this.delete_item = null
      this.dialogDelete = false
    },
    async deleteItemConfirm() {
      this.$emit("onDelete", { delete_item: this.delete_item, deleteResultHandle: this.deleteResultHandle })
      this.dialogDelete = false
    },
    deleteResultHandle() {
      this.support_headers = this.support_headers.filter(
        (support_header) => support_header.support_header_id !== this.delete_item.support_header_id
      )
    }
  }
}
</script>

<style></style>
