/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_HOME_HIGHLIGHT_LIST } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPost, axiosPut, axiosCreateFile, axiosUpdateFile, axiosDelete } from "@/utils/axios"

export async function getHighlightListAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_HOME_HIGHLIGHT_LIST.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getHighlightListById({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_HOME_HIGHLIGHT_LIST.FINDBYID}/${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getHighlightListByLang({ lang }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_HOME_HIGHLIGHT_LIST.FINDBYLANG}/${lang}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function postHighlightList({ highlightList }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = highlightList
    const url = `${API}${EP_HOME_HIGHLIGHT_LIST.CREATE}`
    const { code, data, message } = await axiosPost(url, payload, token)

    if (code == 201) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putHighlightList({ highlightList }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = highlightList
    const url = `${API + EP_HOME_HIGHLIGHT_LIST.UPDATE}/${highlightList.home_product_highlight_list_id}`
    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    // const { message } = error.response.data
    console.log(error)
    return errorHandle(error)
  }
}

export async function putHighlightListSort({ highlightList }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = highlightList
    const url = `${API}${EP_HOME_HIGHLIGHT_LIST.UPDATE_SORT}`

    const { code, message, data } = await axiosPut(url, payload, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putStatus({ highlightList, status }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    const payload = { status: status }
    const url = `${API}${EP_HOME_HIGHLIGHT_LIST.UPDATE_STATUS}/${highlightList.home_product_highlight_list_id}`

    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function deleteHighlightList({ highlightList }, resultHandle, errorHandle, deleteResultHandle) {
  try {
    const token = await getAuthToken()

    const url = `${API}${EP_HOME_HIGHLIGHT_LIST.DELETE}/${highlightList.home_product_highlight_list_id}`

    const { code, data, message } = await axiosDelete(url, token)

    if (code == 200) {
      deleteResultHandle()
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}
