<template>
  <v-container>
    <base-material-card icon="mdi-youtube" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("vdo_create") }}</div>
      </template>

      <v-form @submit.prevent="setLoading(true), save()">
        <v-container fluid>
          <v-row class="text-no-wrap" align="center">
            <v-col v-if="vdo.link" cols="12" md="12">
              <div class="iframe-container">
                <iframe
                  :src="vdo.link"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field v-model="vdo.link" label="link" outlined></v-text-field>
            </v-col>

            <v-col>
              <v-select
                v-model="vdo.status"
                :items="status"
                item-text="text"
                item-value="value"
                label="สถานะ"
                outlined
              ></v-select>
            </v-col>

            <v-col>
              <v-select
                v-model="vdo.lang"
                :items="languages"
                item-text="language"
                item-value="lang_code"
                label="ภาษา"
                outlined
              ></v-select>
            </v-col>
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" min-width="100" class="pl-0" :loading="$store.state.isloading" @click="cancel()">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"

/**
 * CONTROLLER
 */
import { postVdo } from "@/controllers/vdo/vdo.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "VdoCreate",
  data: () => ({
    languages: LANGUAGES,
    status: STATUS,
    vdo: {}
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async save() {
      await postVdo({ vdo: this.vdo }, this.resultHandle, this.errorHandle)
      this.$router.back()
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    },
    cancel() {
      this.$router.back()
    }
  }
}
</script>

<style></style>
