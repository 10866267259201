<template>
  <v-container>
    <base-material-card icon="mdi-home" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("home_highlight") }}</div>
      </template>

      <v-tabs vertical>
        <v-tab
          v-for="(language, i) in languages"
          :key="`home_highlight-${i}`"
          @change="getHighlight(language.lang_code)"
        >
          {{ language.lang_code }}
        </v-tab>

        <v-tab-item touchless v-for="(language, i) in languages" :key="`home_market_place-${i}`">
          <v-form @submit.prevent="setLoading(true), save()" ref="form" lazy-validation>
            <v-container fluid>
              <v-row class="text-no-wrap" align="center">
                <v-col cols="12" md="6">
                  <p class="text-center">รูปด้านหน้า</p>
                  <base-upload-image
                    v-model="fileFront"
                    :defaultValue="highlight.file_front"
                    width="200px"
                    height="170px"
                    class="mb-2"
                  />
                  <p class="red--text text-center">*แนะนำขนาดรูปภาพ 200 x 170 px</p>
                </v-col>
                <v-col cols="12" md="6">
                  <p class="text-center">รูปด้านหลัง</p>
                  <base-upload-image
                    v-model="fileBack"
                    :defaultValue="highlight.file_back"
                    width="340px"
                    height="245px"
                    class="mb-2"
                  />
                  <p class="red--text text-center">*แนะนำขนาดรูปภาพ 340 x 245 px</p>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field :label="$t('title')" v-model="highlight.title" outlined />
                </v-col>
                <v-col cols="12" md="12">
                  <v-textarea
                    v-model="highlight.content"
                    :label="$t('content')"
                    rows="10"
                    outlined
                    :rules="[rules.required]"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field :label="$t('link')" v-model="highlight.link" outlined />
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="highlight.status"
                    :items="status"
                    item-text="text"
                    item-value="value"
                    :label="$t('status')"
                    :rules="[rules.required]"
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" min-width="100" class="pl-0" :loading="$store.state.isloading" @click="cancel">
                  {{ $t("cancel") }}
                </v-btn>
                <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
                  {{ $t("save") }}
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-form>
        </v-tab-item>
      </v-tabs>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"
import { PATHFILE } from "@/constants/config"

/**
 * FUNCTIONS
 */
import { putHighlight, getHighlightByLang } from "@/controllers/home/home_product_highlight.controller"

/**
 * LIBs
 */
import { mapMutations } from "vuex"

/**
 * UTILS
 */
import { rules } from "@/utils/validates"

export default {
  data: () => ({
    rules: rules,
    highlight: {},
    pathFile: PATHFILE,
    languages: LANGUAGES,
    status: STATUS,
    file: null,
    fileFront: null,
    fileBack: null
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.getHighlight()
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async getHighlight(lang = "TH") {
      this.highlight = await getHighlightByLang({ lang })
    },
    async save() {
      const isValid = this.$refs.form[0].validate()
      if (isValid) {
        await putHighlight(
          {
            highlight: this.highlight,
            file_front: this.fileFront,
            file_back: this.fileBack
          },
          this.resultHandle,
          this.errorHandle
        )
        this.setLoading(true)
        // this.$router.back()
      }
      this.setLoading(false)
    },
    cancel() {
      this.setLoading(true)
      this.$router.back()
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>
