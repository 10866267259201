<template>
  <base-material-card icon="mdi-account-hard-hat" color="primary">
    <template v-slot:after-heading>
      <div class="font-weight-light card-title mt-2">
        {{ $t("career_footer") }}
      </div>
    </template>

    <v-tabs vertical>
      <v-tab v-for="(career_footer, i) in career_footers" :key="`career_footer-tab-${i}`">
        {{ career_footer.lang }}
      </v-tab>

      <v-tab-item v-for="(career_footer, i) in career_footers" :key="`career_footer-tab-item-${i}`">
        <form @submit.prevent="save(i)">
          <v-container fluid>
            <v-row class="text-no-wrap" align="center">
              <v-col cols="12" md="12">
                <v-text-field label="E-mail" v-model="career_footer.email" outlined />
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field label="Tel" v-model="career_footer.tel" outlined />
              </v-col>

              <v-col>
                <v-select
                  v-model="career_footer.status"
                  :items="status"
                  item-text="text"
                  item-value="value"
                  label="สถานะ"
                  outlined
                ></v-select>
              </v-col>

              <!-- <v-col>
                <v-select
                  v-model="career_footer.lang"
                  :items="languages"
                  item-text="language"
                  item-value="lang_code"
                  label="ภาษา"
                  outlined
                ></v-select>
              </v-col> -->
            </v-row>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-container>
        </form>
      </v-tab-item>
    </v-tabs>
  </base-material-card>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"

export default {
  name: "CareerFooter",
  data: () => ({
    languages: LANGUAGES,
    status: STATUS
  }),
  props: {
    value: {
      type: Array
    }
  },
  computed: {
    career_footers: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    }
  },
  methods: {
    save(i) {
      this.$emit("submit", i)
    }
  }
}
</script>

<style></style>
