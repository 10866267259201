/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
; ("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_ESTIMATION_FIELD } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut, axiosCreateFile, axiosPost, axiosUpdateFile } from "@/utils/axios"

export async function getEstimationFieldAll() {
    try {
        const token = await getAuthToken()
        const url = API + EP_ESTIMATION_FIELD.FINDALL
        const { code, data } = await axiosGet(url, token)

        if (code == 200) {
            return data
        }
    } catch (error) {
        const { code, message } = error.response.data
        return message
    }
}

export async function getEstimationFieldById({ id }) {
    try {
        const token = await getAuthToken()
        const url = `${API}${EP_ESTIMATION_FIELD.FINDBYID}/${id}`
        const { code, data, message } = await axiosGet(url, token)

        if (code == 200) return data
        errorHandle(message)
        return message
    } catch (error) {
        const { message } = error.response.data
        console.log(error)
        return errorHandle(message)
    }
}

// export async function getEstimationFieldByLang({ lang }) {
//     try {
//         const token = await getAuthToken()
//         const url = `${API}${EP_ESTIMATION_FIELD.FINDBYLANG}/${lang}`
//         const { code, data, message } = await axiosGet(url, token)

//         if (code == 200) return data
//         return message
//     } catch (error) {
//         console.log(error)
//     }
// }


export async function putEstimationField({ field }, resultHandle, errorHandle) {
    try {
        const token = await getAuthToken()
        const payload = field
        const url = `${API}${EP_ESTIMATION_FIELD.UPDATE}/${field.estimation_field_id}`

        const { code, data, message } = await axiosPut(url, payload, token)

        if (code == 200) {
            resultHandle(message)
            return data
        }
        errorHandle(message)
        return data
    } catch (error) {
        console.log(error)
        return errorHandle(error)
    }
}

