<template>
  <v-container>
    <base-material-card icon="mdi-calculator-variant" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("estimation_color") }}</div>
      </template>
      <v-tabs vertical>
        <v-tab
          v-for="(language, i) in languages"
          :key="`estimationColor-${i}`"
          @change="onTabChange(language.lang_code)"
        >
          {{ language.lang_code }}
        </v-tab>

        <v-tab-item touchless v-for="(language, i) in languages" :key="`estimationColor-${i}`">
          <v-container fluid>
            <v-row class="text-no-wrap" align="center">
              <v-col>
                <v-data-table :headers="headers" :items="estimationColors" class="elevation-1">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <!-- <v-toolbar-title>{{ $t("color") }}</v-toolbar-title> -->

                      <v-spacer></v-spacer>
                      <v-btn color="primary" dark class="mx-2" @click="sortItem">
                        {{ $t("estimation_color_sort") }}
                      </v-btn>

                      <v-btn color="primary" dark class="mx-2" @click="createItem(language.lang_code)">
                        {{ $t("estimation_color_create") }}
                      </v-btn>

                      <v-dialog v-model="dialogSort" max-width="900px">
                        <v-card>
                          <v-card-title class="headline">{{ $t("estimation_color_sort") }}</v-card-title>
                          <v-form>
                            <v-card-text>
                              <v-container>
                                <base-dnd-table
                                  :headers="header_list"
                                  :items.sync="estimationColors"
                                  :dragOptions="dragOptions"
                                  order
                                >
                                  <tr v-for="item in estimationColors" :key="item.color_id">
                                    <td scope="row" style="cursor: all-scroll">
                                      <v-icon>mdi-format-align-justify</v-icon>
                                    </td>
                                    <td scope="row">{{ item.title }}</td>
                                    <td>{{ item.status }}</td>
                                    <td>{{ item.lang }}</td>
                                    <td>{{ item.sort + 1 }}</td>
                                  </tr>
                                </base-dnd-table>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="primary" dark @click="closeSort">Cancel</v-btn>
                              <v-btn color="primary" dark @click="saveSort">OK</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>

                  <template v-slot:[`item.created_date`]="{ item }">
                    {{ item.created_date | formatDate }}
                  </template>

                  <template v-slot:[`item.status`]="{ item }">
                    <v-chip :color="getEstimationColor(item.status)" dark>
                      {{ item.status }}
                    </v-chip>
                  </template>

                  <!-- <template v-slot:[`item.file`]="{ item }">
                    <v-avatar size="40" color="grey" v-if="item.file.file_path">
                      <v-img :src="pathFile + item.file.file_path"></v-img>
                    </v-avatar>
                  </template> -->

                  <template v-slot:[`item.cover`]="{ item }">
                    <v-list-item-avatar tile class="align-self-center" color="hsl(255, 3%, 18%,0)">
                      <v-img v-if="Object.keys(item.cover).length" :src="`${pathFile}${item.cover.file_path}`" />
                    </v-list-item-avatar>
                  </template>

                  <template v-slot:[`item.sort`]="{ item }">
                    {{ item.sort + 1 }}
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn class="px-2 ml-1" min-width="0" color="success" @click="editItem(item)" small>
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANTS
 */
import { STATUS } from "@/constants/status"
import { LANGUAGES } from "@/constants/language"
import { PATHFILE } from "@/constants/config"

/**
 * CONTROLLER
 */
import { getEstimationColorByLang, putEstimationColorSort } from "@/controllers/estimation/estimation_color.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "News",
  data: () => ({
    estimationColors: [],
    pathFile: PATHFILE,
    status: STATUS,
    languages: LANGUAGES,
    headers: [
      {
        text: "Id",
        align: "start",
        
        value: "estimation_color_id"
      },
      { text: "title", value: "title" },
      // { text: "GroupId", value: "group_color_id" },
      // { text: "ShadeId", value: "shade_color_id" },
      { text: "Sort", value: "sort" },
      { text: "Status", value: "status" },
      { text: "Preview", value: "file", sortable: false },
      // { text: "lang", value: "lang" },
      { text: "Actions", value: "actions", align: "center", width: "15%", sortable: false }
    ],
    dialogSort: false,

    /**
     * SORT
     */
    header_list: [
      { text: "", value: "estimation_color_id" },
      { text: "title", value: "title" },
      { text: "status", value: "status" },
      { text: "sort", value: "sort" }
    ],
    dragOptions: {
      animation: 200,
      group: "description",
      disabled: false,
      ghostClass: "ghost"
    }
  }),
  created() {
    this.setLoading(true)
    this.initialize("TH")
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize(lang) {
      try {
        this.estimationColors = await getEstimationColorByLang({ lang })
        this.setLoading(false)
      } catch (e) {
        this.errorHandle(e)
      }
    },
    createItem(lang) {
      this.$router.push({ path: `estimation-color-create?lang=${lang}` })
    },
    editItem(item) {
      this.$router.push({ path: `estimation-color-detail/${item.estimation_color_id}` })
    },
    sortItem() {
      this.dialogSort = true
    },
    async saveSort() {
      await putEstimationColorSort({ estimationColors: this.estimationColors }, this.resultHandle, this.errorHandle)
      this.closeSort()
    },
    closeSort() {
      this.dialogSort = false
    },
    getEstimationColor(status) {
      if (status === "ACTIVE") return `success`
      else if (status === "INACTIVE") return `error`
    },
    onTabChange(e) {
      this.initialize(e)
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
