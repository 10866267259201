<template>
  <v-container>
    <base-material-card icon="mdi-home" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("banner") }}</div>
      </template>

      <v-tabs vertical>
        <v-tab
          v-for="(language, i) in languages"
          :key="`branch-${i}`"
          @change="onTabChange({ lang: language.lang_code, page: 'product' })"
        >
          {{ language.lang_code }}
        </v-tab>

        <v-tab-item touchless v-for="(language, i) in languages" :key="`lang-banner-${i}`">
          <v-tabs show-arrows>
            <v-tab
              v-for="(page, i) in pages"
              :key="`banner-${i}`"
              @change="onTabChange({ lang: language.lang_code, page: page.page_code })"
            >
              {{ page.page }}
            </v-tab>

            <v-tab-item class="my-4" touchless v-for="(page, i) in pages" :key="`banner-${i}`">
              <v-container fluid>
                <v-row class="text-no-wrap" align="center">
                  <v-col>
                    <base-upload-image
                      v-model="file"
                      :defaultValue="banner.file"
                      width="760px"
                      height="175px"
                      class="mb-2"
                    />
                    <p class="text-center red--text">*แนะนำขนาดรูปภาพ 1520 x 350 px</p>

                    <v-form @submit.prevent="setLoading(true), save()" ref="form" lazy-validation>
                      <v-container fluid>
                        <v-row class="text-no-wrap" align="center">
                          <v-col cols="12" md="12">
                            <v-select
                              v-model="banner.status"
                              :items="status"
                              item-text="text"
                              item-value="value"
                              :label="$t('status')"
                              outlined
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            type="submit"
                            min-width="100"
                            class="pl-0"
                            :loading="$store.state.isloading"
                          >
                            {{ $t("save") }}
                          </v-btn>
                        </v-card-actions>
                      </v-container>
                    </v-form>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs>
        </v-tab-item>
      </v-tabs>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONTROLLER
 */
import { PAGES } from "@/constants/page"
import { STATUS } from "@/constants/status"
import { getBannerByPage, putBanner } from "@/controllers/banner/banner.controller"
import { PATHFILE } from "@/constants/config"
import { LANGUAGES } from "@/constants/language"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "Banner",
  data: () => ({
    banner: [],
    initPage: null,
    file: null,
    path_file: PATHFILE,
    status: STATUS,
    pages: PAGES,
    languages: LANGUAGES,
    headers: [
      {
        text: "ID",
        align: "start",

        value: "banner_id"
      },
      { text: "page", value: "page" },
      { text: "file", value: "file" },
      { text: "status", value: "status" },
      { text: "Actions", value: "actions", align: "center", width: "15%", sortable: false }
    ]
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },

  methods: {
    /**
     * VUEX
     */
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),

    /**
     * INIT DATA IN CREATE
     */
    async initialize(page = "product", lang = "TH") {
      try {
        this.getBanner({ page, lang })
        this.setLoading(false)
      } catch (e) {
        this.errorHandle(e)
      }
    },
    async getBanner({ page, lang }) {
      this.banner = await getBannerByPage({ page, lang })
    },
    onTabChange(e) {
      this.initialize(e.page, e.lang)
    },
    async save() {
      // const isValid = this.$refs.form.validate()
      // if (isValid) {
      await putBanner(
        {
          banner: this.banner,
          file: this.file
        },
        this.resultHandle,
        this.errorHandle
      )
      // }
      // this.setLoading(false)
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
