/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
; ("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_MENU_SUB } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut, axiosPost, axiosDelete } from "@/utils/axios"

export async function getMenuSubAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_MENU_SUB.FINDALL

    const { code, data, message } = await axiosGet(url, token)
    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getMenuSubByID({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_MENU_SUB.FINDBYID}/${id}`
    const { code, data, message } = await axiosGet(url, token)
    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getMenuSubByMenu({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_MENU_SUB.FINDBYMENU}/${id}`
    const { code, data, message } = await axiosGet(url, token)
    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getProductGroupByMenu({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_MENU_SUB.FINDPRODUCTGROUPBYMENU}/${id}`
    const { code, data, message } = await axiosGet(url, token)
    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function postMenuSub({ menuSub }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = menuSub
    const url = `${API}${EP_MENU_SUB.CREATE}`

    const { code, data, message } = await axiosPost(url, payload, token)
    if (code == 201) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putMenuSub({ menuSub }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = menuSub
    const url = `${API}${EP_MENU_SUB.UPDATE}/${menuSub.menu_sub_id}`
    const { code, data, message } = await axiosPut(url, payload, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putMenuSubSort({ menuSubs }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = menuSubs
    const url = `${API}${EP_MENU_SUB.UPDATE_SORT}`

    const { code, message, data } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putStatus({ menu_sub, status }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    const payload = { status: status }
    const url = `${API}${EP_MENU_SUB.UPDATE_STATUS}/${menu_sub.menu_sub_id}`

    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function deleteMenuSub({ menu_sub }, resultHandle, errorHandle, deleteResultHandle) {
  try {
    const token = await getAuthToken()

    const url = `${API}${EP_MENU_SUB.DELETE}/${menu_sub.menu_sub_id}`

    const { code, data, message } = await axiosDelete(url, token)

    if (code == 200) {
      deleteResultHandle()
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}
