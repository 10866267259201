<template>
  <v-container>
    <!-- CAREER BANNER -->
    <career-banner
      v-model="career_banners"
      :image="image_career_banner"
      @submit="setLoading(true), saveCareerBanner($event)"
    ></career-banner>

    <!-- CAREER DESCRIPTION -->
    <career-description
      v-model="career_descriptions"
      @submit="setLoading(true), saveCareerDescription($event)"
    ></career-description>

    <!-- CAREER EXXCUTIVE -->
    <career-executive
      v-model="career_executives"
      @submit="setLoading(true), saveCareerExecutive($event)"
      @onTabChange="onTabChangeCareerExecutive"
      @onChangeStatus="setLoading(true), onChangeEventHandlerCareerExecutive($event)"
      @update:sort="sortItemCareerExecutive($event)"
      @sort:success="sortCareerExecutiveSuccess($event)"
      @onDelete="setLoading(true), deleteItemConfirmCareerExecutive($event)"
    ></career-executive>

    <!-- CAREER LIST -->
    <career-list
      v-model="careers"
      @submit="setLoading(true), saveCareerList($event)"
      @onTabChange="onTabChangeCareerList"
      @onChangeStatus="setLoading(true), onChangeEventHandlerCareer($event)"
      @update:sort="sortItemCareerList($event)"
      @sort:success="sortCareerListSuccess($event)"
      @onDelete="setLoading(true), deleteItemConfirmCareerList($event)"
    ></career-list>

    <!-- CAREER FOOTER -->
    <career-footer v-model="career_footers" @submit="setLoading(true), saveCareerFooter($event)"></career-footer>
  </v-container>
</template>

<script>
/**
 * COMPONENT
 */
import CareerBanner from "./CareerBanner.vue"
import CareerDescription from "./CareerDescription"
import CareerExecutive from "./CareerExecutive"
import CareerFooter from "./CareerFooter"
import CareerList from "./CareerList"

/**
 * CONTROLLER
 */
import { getCareerBannerAll, putCareerBanner } from "@/controllers/career/career_banner.controller"
import { getCareerDescriptionAll, putCareerDescription } from "@/controllers/career/career_description.controller"
import {
  putCareerExecutive,
  getCareerExecutiveByLang,
  putStatus as putCareerExecutiveStatus,
  deleteCareerExecutive
} from "@/controllers/career/career_executive.controller"
import {
  getCareerByLang,
  putCareer,
  putStatus as putCareerStatus,
  deleteCareer
} from "@/controllers/career/career.controller"
import { getCareerFooterAll, putCareerFooter } from "@/controllers/career/career_footer.controller"

/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "Career",
  components: {
    CareerBanner,
    CareerDescription,
    CareerFooter,
    CareerExecutive,
    CareerList
  },
  data: () => ({
    /**
     * CAREER BANNER
     */
    career_banners: [],
    image_career_banner: [],

    /**
     * CAREER
     */
    careers: [],
    headers_career: [
      {
        text: "ID",
        align: "start",
        value: "career_id"
      },
      { text: "position", value: "position" },
      { text: "location", value: "location" },
      { text: "Actions", value: "actions", align: "center", width: "15%", sortable: false }
    ],

    /**
     * CAREER DESCRIPTIONS
     */
    career_descriptions: [],

    /**
     * CAREER EXECUTIVES
     */
    career_executives: [],

    /**
     * CAREER FOOTER
     */
    career_footers: [],

    languages: LANGUAGES,
    status: STATUS
  }),
  created() {
    this.setLoading(true)
    this.initialize("TH")
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize(lang) {
      try {
        this.career_banners = await getCareerBannerAll()
        this.career_descriptions = await getCareerDescriptionAll()
        this.career_executives = await getCareerExecutiveByLang({ lang })
        this.career_footers = await getCareerFooterAll()
        this.careers = await getCareerByLang({ lang })
        this.setLoading(false)
      } catch (err) {
        console.log(e)
      }
    },

    /**
     * UPDATE CAREER BANNER
     */
    async saveCareerBanner(e) {
      try {
        await putCareerBanner(
          { career_banner: this.career_banners[e], file: this.image_career_banner[e] },
          this.resultHandle,
          this.errorHandle
        )
      } catch (err) {
        console.log(err)
      }
    },

    /**
     * UPDATE CAREER DESCRIPTIONS
     */
    async saveCareerDescription(e) {
      try {
        await putCareerDescription(
          { career_description: this.career_descriptions[e] },
          this.resultHandle,
          this.errorHandle
        )
      } catch (err) {
        console.log(err)
      }
    },

    /**
     * CAREER LISTS
     */
    async saveCareerList({ e }) {
      try {
        await putCareer({ career: this.careers[e] }, this.resultHandle, this.errorHandle)
      } catch (err) {
        console.log(err)
      }
    },
    async onTabChangeCareerList(lang) {
      try {
        this.careers = await getCareerByLang({ lang })
        this.setLoading(false)
      } catch (err) {
        console.log(e)
      }
    },
    async sortItemCareerList(items) {
      this.careers = items.map((item) => {
        const { career_id, created_date, description, lang, location, position, sort, status } = item
        return { career_id, created_date, description, lang, location, position, sort: sort + 1, status }
      })
    },
    async sortCareerListSuccess(lang) {
      this.careers = await getCareerByLang({ lang })
    },
    async deleteItemConfirmCareerList({ delete_item, deleteResultHandle }) {
      await deleteCareer({ career: delete_item }, this.resultHandle, this.errorHandle, deleteResultHandle)
    },

    /**
     * UPDATE CAREER FOOTER
     */
    async saveCareerFooter(e) {
      try {
        await putCareerFooter({ career_footer: this.career_footers[e] }, this.resultHandle, this.errorHandle)
      } catch (err) {
        console.log(err)
      }
    },

    /**
     * CAREER EXECUTIVE
     */
    async saveCareerExecutive({ e, image }) {
      try {
        await putCareerExecutive(
          { career_executive: this.career_executives[e], file: image },
          this.resultHandle,
          this.errorHandle
        )
      } catch (err) {
        console.log(err)
      }
    },
    async onTabChangeCareerExecutive(lang) {
      try {
        this.career_executives = await getCareerExecutiveByLang({ lang })
        this.setLoading(false)
      } catch (err) {
        console.log(e)
      }
    },
    async onChangeEventHandlerCareerExecutive(e) {
      const { item, value } = e
      if (value)
        await putCareerExecutiveStatus(
          { career_executive: item, status: "ACTIVE" },
          this.resultHandle,
          this.errorHandle
        )
      else
        await putCareerExecutiveStatus(
          { career_executive: item, status: "INACTIVE" },
          this.resultHandle,
          this.errorHandle
        )
    },
    sortItemCareerExecutive(items) {
      this.career_executives = items.map((item) => {
        const { career_executive_id, bio, name, file, posion_en, posion_th, lang, sort, status } = item
        return { career_executive_id, bio, name, file, posion_en, posion_th, lang, sort: sort + 1, status }
      })
    },
    async sortCareerExecutiveSuccess(lang) {
      this.career_executives = await getCareerExecutiveByLang({ lang })
    },
    async deleteItemConfirmCareerExecutive({ delete_item, deleteResultHandle }) {
      await deleteCareerExecutive(
        { career_executive: delete_item },
        this.resultHandle,
        this.errorHandle,
        deleteResultHandle
      )
    },

    async onChangeEventHandlerCareer(e) {
      const { item, value } = await e
      if (value) await putCareerStatus({ career: item, status: "ACTIVE" }, this.resultHandle, this.errorHandle)
      else await putCareerStatus({ career: item, status: "INACTIVE" }, this.resultHandle, this.errorHandle)
    },
    /**
     * CALLBACK FUNCTION
     */
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
