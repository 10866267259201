<template>
  <v-container>
    <base-material-card icon="mdi-contacts" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("contact_detail") }}</div>
      </template>

      <v-container>
        <v-row>
          <v-col cols="12" md="12">
            <v-card
              class="preview-img text-center mx-auto justify-center align-center"
              target="_blank"
              v-if="contact.file && Object.keys(contact.file).length"
              :href="`${path_file}${contact.file.file_path}`"
            >
              <v-layout class="justify-center align-center" style="height:100%;">
                <v-icon size="40">mdi-file-download</v-icon>
              </v-layout>
            </v-card>
          </v-col>

          <v-col cols="12" md="12">
            <v-text-field v-model="contact.name" :label="$t('firstname_lastname')" outlined readonly></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            <v-text-field v-model="contact.tel" :label="$t('telphone')" outlined readonly></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            <v-text-field v-model="contact.email" :label="$t('email')" outlined readonly></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            <v-text-field v-model="contact.subject" :label="$t('subject')" outlined readonly></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            <v-textarea v-model="contact.detail" :label="$t('detail')" outlined readonly></v-textarea>
          </v-col>

          <!-- <v-col cols="12" md="12">
            <v-select
              v-model="contact.status"
              :items="status"
              item-text="text"
              item-value="value"
              :label="$t('status')"
              outlined
              readonly
            ></v-select>
          </v-col>

          <v-col cols="12" md="12">
            <v-select
              v-model="contact.lang"
              :items="languages"
              item-text="language"
              item-value="lang_code"
              :label="$t('language')"
              outlined
              readonly
            />
          </v-col> -->
        </v-row>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            min-width="100"
            class="pl-0"
            :loading="$store.state.isloading"
            @click="setLoading(true), cancel()"
          >
            {{ $t("back") }}
          </v-btn>
        </v-card-actions>
      </v-container>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"
import { PATHFILE } from "@/constants/config"

/**
 * CONTROLLER
 */
import { getContactByID } from "@/controllers/contact/contact.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "ContactDetail",
  data: () => ({
    path_file: PATHFILE,
    languages: LANGUAGES,
    status: STATUS,
    contact: {},
    file: null
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.contact = await getContactByID({
          contact_id: this.$route.params.contact_id
        })
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    cancel() {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
$gray--light: #e8e8e8;
$box-shadow-none: 0 0 0 0 !important;
$border-radius-md: 20px !important;
.v-card {
  position: relative;
  box-shadow: $box-shadow-none;
  border-radius: $border-radius-md;
  padding: 4px;
  &.preview-img {
    max-width: 100px;
    height: 100px;
    background-color: $gray--light !important;

    .icon-close {
      position: absolute;
      display: none;
      top: 0;
      right: 0;
    }
    &:hover {
      .icon-close {
        display: block;
      }
    }
  }
}
</style>
