<template>
  <v-container>
    <base-material-card icon="mdi-wall" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("create") }}</div>
      </template>

      <v-form @submit.prevent="save()" ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="text-no-wrap" align="center">
            <v-col cols="12" md="12">
              <v-text-field v-model="docs.title" :label="$t('title')" :rules="[rules.required]" outlined></v-text-field>
            </v-col>
            <v-col cols="12">
              <base-upload-file v-model="file" :label="$t('file')" :rules="[rules.required]" />
            </v-col>
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" min-width="100" class="pl-0" :loading="$store.state.isloading" @click="cancel">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { STATUS } from "@/constants/status"

/**
 * FUNCTIONS
 */
import { postProductCategoryDocs } from "@/controllers/product/product_category_docs.controller"

/**
 * LIBs
 */
import { mapMutations } from "vuex"

/**
 * UTILS
 */
import { rules } from "@/utils/validates"

export default {
  data: () => ({
    status: STATUS,
    rules: rules,
    docs: {
      title: null
    },
    file: null
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async save() {
      const isValid = this.$refs.form.validate()
      if (isValid) {
        this.setLoading(true)
        await postProductCategoryDocs(
          { cate_id: this.$route.params.productCategoryId, docs: this.docs, file: this.file },
          this.resultHandle,
          this.errorHandle
        )
        this.$router.back()
      }
      this.setLoading(false)
    },
    cancel() {
      this.setLoading(true)
      this.$router.back()
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>
