<template>
  <v-container>
    <base-material-card icon="mdi-wall" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("accessory") }}</div>
      </template>

      <v-dialog v-model="dialogDelete" max-width="300">
        <v-card>
          <v-card-title>
            <p>
              ยืนยันการลบ
            </p>
            <v-spacer />
            <v-icon aria-label="Close" @click="closeDelete">
              mdi-close
            </v-icon>
          </v-card-title>

          <v-card-text class="pb-6 text-center">
            <v-btn class="mr-1" color="error" dark @click="closeDelete"> {{ $t("cancel") }} </v-btn>
            <v-btn class="mr-1" color="success" dark @click="deleteItemConfirm"> {{ $t("delete") }} </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-tabs vertical>
        <v-tab v-for="(language, i) in languages" :key="`accessory-${i}`" @change="onTabChange(language.lang_code)">
          {{ language.lang_code }}
        </v-tab>

        <v-tab-item touchless v-for="(language, i) in languages" :key="`accessory-${i}`">
          <v-container fluid>
            <v-row class="text-no-wrap" align="center">
              <v-col>
                <v-data-table :headers="headers" :items="accessories" class="elevation-1">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <!-- <v-toolbar-title>{{ $t("accessory") }}</v-toolbar-title> -->

                      <v-spacer></v-spacer>
                      <v-btn color="primary" dark class="mx-2" @click="sortItem">
                        {{ $t("accessory_sort") }}
                      </v-btn>

                      <v-btn color="primary" dark class="mx-2" @click="createItem(language.lang_code)">
                        {{ $t("accessory_create") }}
                      </v-btn>

                      <v-dialog v-model="dialogSort" max-width="900px">
                        <v-card>
                          <v-card-title class="headline">{{ $t("accessory_sort") }}</v-card-title>
                          <v-form>
                            <v-card-text>
                              <v-container>
                                <base-dnd-table
                                  :headers="header_list"
                                  :items.sync="accessories"
                                  :dragOptions="dragOptions"
                                  order
                                >
                                  <tr v-for="item in accessories" :key="item.group_color_id">
                                    <td scope="row" style="cursor: all-scroll">
                                      <v-icon>mdi-format-align-justify</v-icon>
                                    </td>
                                    <td scope="row">{{ item.title }}</td>
                                    <td>{{ item.status }}</td>
                                    <td>{{ item.lang }}</td>
                                    <td>{{ item.sort + 1 }}</td>
                                  </tr>
                                </base-dnd-table>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="primary" dark @click="closeSort">Cancel</v-btn>
                              <v-btn color="primary" dark @click="saveSort">OK</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>

                  <template v-slot:[`item.created_date`]="{ item }">
                    {{ item.created_date | formatDate }}
                  </template>

                  <template v-slot:[`item.status`]="{ item }">
                    <base-switch
                      v-model="item.status"
                      :height="26"
                      :width="99"
                      :font-size="5"
                      :color="{ checked: '#4CAF50', unchecked: '#FF5252' }"
                      :labels="{ checked: 'ACTIVE', unchecked: 'INACTIVE' }"
                      @change="setLoading(true), onChangeEventHandler(item, $event)"
                    />
                  </template>

                  <template v-slot:[`item.file`]="{ item }">
                    <a
                      v-if="Object.keys(item.file).length"
                      :href="`${path_file}${item.file.file_path}`"
                      target="_blank"
                    >
                      <v-icon color="info">mdi-file-download</v-icon>
                    </a>
                  </template>

                  <template v-slot:[`item.cover`]="{ item }">
                    <v-list-item-avatar tile class="align-self-center" color="hsl(255, 3%, 18%,0)">
                      <v-img v-if="Object.keys(item.cover).length" :src="`${path_file}${item.cover.file_path}`" />
                    </v-list-item-avatar>
                  </template>

                  <template v-slot:[`item.sort`]="{ item }">
                    {{ item.sort + 1 }}
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn class="px-2 ml-1" min-width="0" color="success" @click="editItem(item)" small>
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn class="px-2 ml-1" min-width="0" color="error" @click="deleteItem(item)" small>
                      <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANTS
 */
import { STATUS } from "@/constants/status"
import { LANGUAGES } from "@/constants/language"
import { PATHFILE } from "@/constants/config"

/**
 * CONTROLLER
 */
import {
  getAccessoryByLang,
  putAccessorySort,
  putStatus,
  deleteAccessory
} from "@/controllers/product/accessory.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "News",
  data: () => ({
    accessories: [],
    status: STATUS,
    languages: LANGUAGES,
    headers: [
      {
        text: "Id",
        align: "start",
        
        value: "accessory_id"
      },
      { text: "title", value: "title" },
      { text: "Sort", value: "sort" },
      { text: "Status", value: "status" },
      { text: "Actions", value: "actions", align: "center", width: "15%", sortable: false }
    ],
    path_file: PATHFILE,
    dialogSort: false,

    /**
     * SORT
     */
    header_list: [
      { text: "", value: "accessory_id" },
      { text: "title", value: "title" },
      { text: "status", value: "status" },
      { text: "lang", value: "lang" },
      { text: "sort", value: "sort" }
    ],
    dialogDelete: false,
    delete_item: null,
    dragOptions: {
      animation: 200,
      group: "description",
      disabled: false,
      ghostClass: "ghost"
    }
  }),
  created() {
    this.setLoading(true)
    this.initialize("TH")
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize(lang) {
      try {
        this.accessories = await getAccessoryByLang({ lang })
        this.setLoading(false)
      } catch (e) {
        this.errorHandle(e)
      }
    },
    createItem(lang) {
      this.$router.push({ path: `accessory-create?lang=${lang}` })
    },
    editItem(item) {
      this.$router.push({ path: `accessory-detail/${item.accessory_id}` })
    },
    sortItem() {
      this.dialogSort = true
    },
    async saveSort() {
      await putAccessorySort({ accessories: this.accessories }, this.resultHandle, this.errorHandle)
      this.closeSort()
    },
    closeSort() {
      this.dialogSort = false
    },
    async onChangeEventHandler(item, { value }) {
      if (value) await putStatus({ accessory: item, status: "ACTIVE" }, this.resultHandle, this.errorHandle)
      else await putStatus({ accessory: item, status: "INACTIVE" }, this.resultHandle, this.errorHandle)
    },
    onTabChange(e) {
      this.initialize(e)
    },
    deleteItem(item) {
      this.delete_item = item
      this.dialogDelete = true
    },
    closeDelete() {
      this.delete_item = null
      this.dialogDelete = false
    },
    async deleteItemConfirm() {
      await deleteAccessory(
        { accessory: this.delete_item },
        this.resultHandle,
        this.errorHandle,
        this.deleteResultHandle
      )
      this.dialogDelete = false
    },
    deleteResultHandle() {
      this.accessories = this.accessories.filter(
        (accessory) => accessory.accessory_id !== this.delete_item.accessory_id
      )
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
