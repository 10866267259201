<template>
  <v-container>
    <base-material-card icon="mdi-wall" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">
          {{ $t("product_category_detail") }} - {{ productCategory.title || "" }}
        </div>
      </template>

      <div class="text-center">
        <base-upload-image
          v-model="banner"
          :defaultValue="productCategory.banner"
          width="760px"
          height="175px"
          class="mb-2"
        />
        <p class="text-center red--text">*แนะนำขนาดรูปภาพ 1520 x 350 px</p>
      </div>

      <div class="text-center">
        <base-upload-image
          v-model="cover_product_category"
          :defaultValue="productCategory.cover_product_category"
          width="320px"
          height="165px"
          class="mb-2"
        />
        <p class="text-center red--text">*แนะนำขนาดรูปภาพ 320 x 165 px</p>
      </div>

      <v-form @submit.prevent="setLoading(true), save()" ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="text-no-wrap" align="center">
            <v-col cols="12" md="6">
              <v-text-field
                v-model="productCategory.title"
                :label="$t('title')"
                :rules="[rules.required]"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="productCategory.product_group_id"
                :items="productGroups"
                item-text="title"
                item-value="product_group_id"
                :label="$t('product_group')"
                :rules="[rules.required]"
                outlined
              ></v-autocomplete>
            </v-col>
            <v-flex xs12></v-flex>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="productCategory.bio_short"
                label="bio short"
                :rules="[rules.required]"
                outlined
              ></v-text-field>
            </v-col>
            <v-flex xs12></v-flex>
            <v-col cols="12" md="6">
              <v-textarea
                v-model="productCategory.bio_long"
                label="bio long"
                rows="13"
                :rules="[rules.required]"
                outlined
              ></v-textarea>
            </v-col>
            <v-flex xs12></v-flex>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="productCategory.group_color_id"
                :items="groupColors"
                item-text="title"
                return-object
                :label="$t('group_color')"
                multiple
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="productCategory.shade_color_id"
                :items="shadeColors"
                item-text="title"
                return-object
                :label="`filter ${$t('shade_color')}`"
                multiple
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="productCategory.usability_id"
                :items="usabilities"
                item-text="title"
                return-object
                :label="`filter ${$t('usability')}`"
                :rules="[rules.atleastOne]"
                multiple
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <!-- :label="$t('accessory')" -->
              <v-autocomplete
                v-model="productCategory.accessory_id"
                :items="accessories"
                item-text="title"
                return-object
                label="วัสดุและอุปกรณ์ประกอบ"
                :rules="[rules.atleastOne]"
                multiple
                outlined
              ></v-autocomplete>
            </v-col>
            <v-flex xs12></v-flex>
            <v-col class="mb-auto" cols="12" md="6">
              <v-autocomplete
                v-model="productCategory.order_id"
                :items="orders"
                item-text="title"
                :label="`filter ${$t('order')}`"
                return-object
                multiple
                outlined
              ></v-autocomplete>
            </v-col>

            <v-flex xs12></v-flex>
            <v-col cols="12">
              <h4 class="mb-2">รายละเอียด</h4>
              <base-ck-editor
                v-model="productCategory.product_ref"
                :text.sync="productCategory.product_ref"
                placeholder="product ref"
              />
            </v-col>

            <v-col>
              <h4>{{ $t("usage_image") }}</h4>
            </v-col>

            <v-col class="ml-md-auto" cols="12">
              <v-select
                v-model="productCategory.usage_type"
                :items="usageTypes"
                item-text="text"
                item-value="value"
                :label="$t('province_type_menu')"
                outlined
              ></v-select>
            </v-col>
            <v-col class="d-flex flex-wrap flex-column" cols="12" v-if="productCategory.usage_type === 'URL'">
              <v-text-field v-model="productCategory.link" :label="$t('link')" outlined></v-text-field>
            </v-col>
            <v-col class="d-flex flex-wrap flex-column" cols="12" v-else>
              <base-upload-file v-model="usage_file" :label="$t('file')" />
            </v-col>
            <v-flex xs12></v-flex>
            <!-- <v-col class="ml-md-auto" cols="12" md="12">
              <v-select
                v-model="productCategory.status"
                :items="status"
                item-text="text"
                item-value="value"
                :label="$t('status')"
                outlined
              ></v-select>
            </v-col> -->
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" min-width="100" class="pl-0" :loading="$store.state.isloading" @click="cancel">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"
import { PATHFILE } from "@/constants/config"

/**
 * FUNCTIONS
 */
import { postProductCategory } from "@/controllers/product/product_category.controller"
import { getProductGroupForSelect } from "@/controllers/product/product_group.controller"
import { getGroupColorForSelect } from "@/controllers/product/group_color.controller"
import { getAccessoryForSelect } from "@/controllers/product/accessory.controller"
import { getShadeColorForSelect } from "@/controllers/product/shade_color.controller"
import { getUsabillityForSelect } from "@/controllers/product/usability.controller"
import { getOrderForSelect } from "@/controllers/product/order.controller"

/**
 * LIBs
 */
import { mapMutations } from "vuex"

/**
 * UTILS
 */
import { rules } from "@/utils/validates"

export default {
  data: () => ({
    rules: rules,
    productCategory: {
      product_group_id: 1,
      shade_color_id: [],
      group_color_id: [],
      usability_id: [],
      accessory_id: [],
      order_id: [],
      title: "",
      bio_short: "",
      bio_long: "",
      product_ref: "",
      usage_type: "URL",
      link: ""
    },
    pathFile: PATHFILE,
    languages: LANGUAGES,
    status: STATUS,
    productGroups: [],
    shadeColors: [],
    groupColors: [],
    accessories: [],
    usabilities: [],
    orders: [],
    files: [],
    usageTypes: [
      { text: "URL", value: "URL" },
      { text: "File", value: "FILE" }
    ],
    banner: null,
    cover_product_category: null,
    usage_file: null,
    previewImages: [],
    defaultImages: [],
    videos: [],
    dialogDelete: false,
    type: "",
    id: 0,
    type: ""
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  computed: {
    videoHeaders() {
      return [
        { text: "Id", value: "product_category_vdo_id" },
        { text: "title", value: "title" },
        { text: "status", value: "status" },
        { text: "Actions", value: "actions" }
      ]
    },
    docHeaders() {
      return [
        { text: "Id", value: "product_category_doc_id" },
        { text: "title", value: "title" },
        { text: "status", value: "status" },
        { text: "Actions", value: "actions" }
      ]
    }
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        if (!this.$route.params.id) this.$route.back()
        this.setSelections(parseInt(this.$route.params.id))
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async getGroupColor(lang) {
      this.groupColors = await getGroupColorForSelect({ lang })
    },
    async getAccessory(lang) {
      this.accessories = await getAccessoryForSelect({ lang })
    },
    async getUsabillity(lang) {
      this.usabilities = await getUsabillityForSelect({ lang })
    },
    async getShadeColor(lang) {
      this.shadeColors = await getShadeColorForSelect({ lang })
    },
    async getOrderForSelect(lang) {
      this.orders = await getOrderForSelect({ lang })
    },
    async setSelections(id) {
      this.productCategory.product_group_id = id
      this.productGroups = await getProductGroupForSelect()
      const index = this.productGroups.findIndex(
        (item) => item.product_group_id == this.productCategory.product_group_id
      )
      if (index == -1) this.$router.back()
      const lang = this.productGroups[index].lang
      await this.getGroupColor(lang)
      await this.getAccessory(lang)
      await this.getUsabillity(lang)
      await this.getShadeColor(lang)
      await this.getOrderForSelect(lang)
    },
    onChangeHandler() {
      this.previewImages = []
      if (this.files && this.files.length) {
        this.files.forEach((image) => {
          this.previewImages.push(URL.createObjectURL(image))
        })
      }
    },
    async save() {
      const isValid = this.$refs.form.validate()
      if (isValid) {
        await postProductCategory(
          {
            productCategory: this.productCategory,
            banner: this.banner,
            cover_product_category: this.cover_product_category,
            usage_file: this.usage_file
          },
          this.resultHandle,
          this.errorHandle
        )
        this.setLoading(true)
        this.$router.back()
      }
      this.setLoading(false)
    },
    cancel() {
      this.setLoading(true)
      this.$router.back()
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>
