/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_PRODUCT_CATEGORY } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut, axiosCreateFile, axiosPost, axiosUpdateFile, axiosDelete } from "@/utils/axios"

export async function getProductCategoryAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_PRODUCT_CATEGORY.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getProductCategoryByID({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_PRODUCT_CATEGORY.FINDBYID}/${id}?videoSort=YES&docSort=YES`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getProductCategoryByLang({ lang }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_PRODUCT_CATEGORY.FINDBYLANG}/${lang}`
    const { code, data, message } = await axiosGet(url, token)
    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function getByProductGroup({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_PRODUCT_CATEGORY.FIND_BY_PRODUCT_GROUP}/${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function getForSelect({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_PRODUCT_CATEGORY.FIND_FOR_SELECT}/${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function postProductCategory(
  { productCategory, cover_product_category, banner, usage_file },
  resultHandle,
  errorHandle
) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_PRODUCT_CATEGORY.CREATE}`
    let formData = new FormData()
    formData.append("product_group_id", productCategory.product_group_id)
    formData.append("group_color_id", JSON.stringify(productCategory.group_color_id))
    formData.append("shade_color_id", JSON.stringify(productCategory.shade_color_id))
    formData.append("usability_id", JSON.stringify(productCategory.usability_id))
    formData.append("accessory_id", JSON.stringify(productCategory.accessory_id))
    formData.append("order_id", JSON.stringify(productCategory.order_id))
    formData.append("title", productCategory.title)
    // formData.append("status", productCategory.status)
    formData.append("bio_short", productCategory.bio_short)
    formData.append("bio_long", productCategory.bio_long)
    formData.append("product_ref", productCategory.product_ref)
    formData.append("usage_type", productCategory.usage_type)
    formData.append("link", productCategory.link)

    if (cover_product_category)
      formData.append("cover_product_category", cover_product_category, cover_product_category.name)
    if (banner) formData.append("banner", banner)
    if (usage_file) formData.append("usage_file", usage_file)

    const { code, data, message } = await axiosCreateFile(url, formData, token)

    if (code == 201) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putProductCategory(
  { productCategory, cover_product_category, banner, usage_file },
  resultHandle,
  errorHandle
) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_PRODUCT_CATEGORY.UPDATE}/${productCategory.product_category_id}`
    let formData = new FormData()
    formData.append("product_group_id", productCategory.product_group_id)
    formData.append("group_color_id", JSON.stringify(productCategory.group_color_id))
    formData.append("shade_color_id", JSON.stringify(productCategory.shade_color_id))
    formData.append("usability_id", JSON.stringify(productCategory.usability_id))
    formData.append("accessory_id", JSON.stringify(productCategory.accessory_id))
    formData.append("order_id", JSON.stringify(productCategory.order_id))
    formData.append("title", productCategory.title)
    formData.append("bio_short", productCategory.bio_short)
    formData.append("bio_long", productCategory.bio_long)
    formData.append("product_ref", productCategory.product_ref)
    formData.append("usage_type", productCategory.usage_type)
    formData.append("link", productCategory.link)
    formData.append("status", productCategory.status)

    if (cover_product_category)
      formData.append("cover_product_category", cover_product_category, cover_product_category.name)
    if (banner) formData.append("banner", banner)
    if (usage_file) formData.append("usage_file", usage_file)

    const { code, data, message } = await axiosUpdateFile(url, formData, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putProductCategorySort({ productCategories }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = productCategories
    const url = `${API}${EP_PRODUCT_CATEGORY.UPDATE_SORT}`

    const { code, message, data } = await axiosPut(url, payload, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putStatus({ product_category, status }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    const payload = { status: status }
    const url = `${API}${EP_PRODUCT_CATEGORY.UPDATE_STATUS}/${product_category.product_category_id}`

    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function deleteProductCategory({ product_category }, resultHandle, errorHandle, deleteResultHandle) {
  try {
    const token = await getAuthToken()

    const url = `${API}${EP_PRODUCT_CATEGORY.DELETE}/${product_category.product_category_id}`

    const { code, data, message } = await axiosDelete(url, token)

    if (code == 200) {
      deleteResultHandle()
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}
