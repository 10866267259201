<template>
  <v-container>
    <base-material-card icon="mdi-contacts" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("contact") }}</div>
      </template>

      <v-dialog v-model="dialogDelete" max-width="300">
        <v-card>
          <v-card-title>
            <p>
              ยืนยันการลบ
            </p>
            <v-spacer />
            <v-icon aria-label="Close" @click="closeDelete">
              mdi-close
            </v-icon>
          </v-card-title>

          <v-card-text class="pb-6 text-center">
            <v-btn class="mr-1" color="error" dark @click="closeDelete"> {{ $t("cancel") }} </v-btn>
            <v-btn class="mr-1" color="success" dark @click="deleteItemConfirm"> {{ $t("delete") }} </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-container fluid>
        <v-row class="text-no-wrap" align="center">
          <v-col>
            <v-data-table
              :headers="headers"
              :loading="$store.state.isloading"
              :items="contacts"
              :server-items-length="total"
              :options.sync="options"
              class="elevation-1 table-grey"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn class="px-2 ml-1" min-width="0" color="warning" @click="editItem(item)" small>
                  <v-icon small>mdi-eye</v-icon>
                </v-btn>
                <v-btn class="px-2 ml-1" min-width="0" color="error" @click="deleteItem(item)" small>
                  <v-icon small> mdi-delete </v-icon>
                </v-btn>
              </template>

              <template v-slot:[`item.file`]="{ item }">
                <a v-if="Object.keys(item.file).length" :href="`${path_file}${item.file.file_path}`" target="_blank">
                  <v-icon color="info">mdi-file-download</v-icon>
                </a>
              </template>

              <template v-slot:[`item.created_at`]="{ item }">
                {{ item.created_at | formatDate }}
              </template>

              <template v-slot:[`item.created_date`]="{ item }">
                {{ item.created_date | formatDate }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
      <!-- </v-tab-item>
      </v-tabs> -->
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { STATUS } from "@/constants/status"
import { LANGUAGES } from "@/constants/language"
import { PATHFILE } from "@/constants/config"

/**
 * CONTROLLER
 */
import { getContactByLang, deleteContact } from "@/controllers/contact/contact.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "Contact",
  data: () => ({
    contacts: [],
    status: STATUS,
    languages: LANGUAGES,
    path_file: PATHFILE,
    options: {},
    language: "TH",
    total: null,
    page: 1,
    itemsPerPage: 10,
    dialogDelete: false,
    delete_item: null,
    headers: [
      {
        text: "ID",
        align: "start",
        
        value: "contact_id"
      },
      { text: "name", value: "name" },
      { text: "tel", value: "tel" },
      { text: "email", value: "email" },
      { text: "subject", value: "subject" },
      { text: "created_at", value: "created_at" },
      { text: "file", value: "file" },
      { text: "Actions", value: "actions", align: "center", width: "15%", sortable: false }
    ]
  }),
  created() {
    this.setLoading(true)
    this.initialize("TH", this.page, this.itemsPerPage)
  },
  watch: {
    options: {
      handler(e) {
        const { page, itemsPerPage } = e
        this.page = page
        this.itemsPerPage = itemsPerPage
        this.initialize(this.language, this.page, this.itemsPerPage)
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize(lang, page, itemsPerPage) {
      try {
        const { contacts, count } = await getContactByLang({ lang, page, itemsPerPage })
        this.contacts = contacts
        this.total = count.count
        this.setLoading(false)
      } catch (e) {
        this.errorHandle(e)
      }
    },
    onTabChange(e) {
      this.language = e
      this.initialize(e, this.page, this.itemsPerPage)
    },
    editItem(item) {
      this.$router.push({ path: `contact-detail/${item.contact_id}` })
    },
    deleteItem(item) {
      this.delete_item = item
      this.dialogDelete = true
    },
    closeDelete() {
      this.delete_item = null
      this.dialogDelete = false
    },
    async deleteItemConfirm() {
      await deleteContact({ contact: this.delete_item }, this.resultHandle, this.errorHandle, this.deleteResultHandle)
      this.dialogDelete = false
    },
    deleteResultHandle() {
      this.contacts = this.contacts.filter((contact) => contact.contact_id !== this.delete_item.contact_id)
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
