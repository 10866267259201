/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_GROUP_COLOR } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPost, axiosPut, axiosDelete } from "@/utils/axios"

export async function getGroupColorAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_GROUP_COLOR.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getGroupColorByID({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_GROUP_COLOR.FINDBYID}/${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getGroupColorByLang({ lang }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_GROUP_COLOR.FINDBYLANG}/${lang}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function getGroupColorForSelect({ lang }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_GROUP_COLOR.FOR_SELECT}/${lang}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function postGroupColor({ groupColor }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = groupColor
    const url = `${API}${EP_GROUP_COLOR.CREATE}`
    const { code, data, message } = await axiosPost(url, payload, token)

    if (code == 201) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putGroupColor({ groupColor }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = groupColor
    const url = `${API + EP_GROUP_COLOR.UPDATE}/${groupColor.group_color_id}`
    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    // const { message } = error.response.data
    console.log(error)
    return errorHandle(error)
  }
}

export async function putGroupColorSort({ groupColors }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = groupColors
    const url = `${API}${EP_GROUP_COLOR.UPDATE_SORT}`

    const { code, message, data } = await axiosPut(url, payload, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putStatus({ group_color, status }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    const payload = { status: status }
    const url = `${API}${EP_GROUP_COLOR.UPDATE_STATUS}/${group_color.group_color_id}`

    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function deleteGroupColor({ group_color }, resultHandle, errorHandle, deleteResultHandle) {
  try {
    const token = await getAuthToken()

    const url = `${API}${EP_GROUP_COLOR.DELETE}/${group_color.group_color_id}`

    const { code, data, message } = await axiosDelete(url, token)

    if (code == 200) {
      deleteResultHandle()
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}
