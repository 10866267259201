<template>
  <base-material-card icon="mdi-account-hard-hat" color="primary">
    <template v-slot:after-heading>
      <div class="font-weight-light card-title mt-2">
        {{ $t("career_list") }}
      </div>
    </template>

    <v-dialog v-model="dialogDelete" max-width="300">
      <v-card>
        <v-card-title>
          <p>
            ยืนยันการลบ
          </p>
          <v-spacer />
          <v-icon aria-label="Close" @click="closeDelete">
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="pb-6 text-center">
          <v-btn class="mr-1" color="error" dark @click="closeDelete"> {{ $t("cancel") }} </v-btn>
          <v-btn class="mr-1" color="success" dark @click="deleteItemConfirm"> {{ $t("delete") }} </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-tabs v-model="lang" vertical>
      <v-tab v-for="(language, i) in languages" :key="`career-list-${i}`" @change="onTabChange(language.lang_code)">
        {{ language.lang_code }}
      </v-tab>

      <v-tab-item touchless v-for="(language, i) in languages" :key="`career-list-${i}`">
        <v-container fluid>
          <v-row class="text-no-wrap" align="center">
            <v-col>
              <v-data-table :headers="headers" :items="careers" class="elevation-1">
                <template v-slot:top>
                  <v-toolbar flat>
                    <!-- <v-toolbar-title> {{ $t("career_list") }} </v-toolbar-title> -->

                    <v-spacer></v-spacer>
                    <v-btn color="primary" dark class="mx-2" @click="sortItem">
                      {{ $t("career_list_sort") }}
                    </v-btn>
                    <v-btn color="primary" dark class="mb-2" @click="setLoading(true), createItem(language.lang_code)">
                      {{ $t("career_list_create") }}
                    </v-btn>
                    <v-dialog v-model="dialogSort" max-width="900px">
                      <v-card>
                        <v-card-title class="headline">{{ $t("career_list_sort") }}</v-card-title>
                        <v-form>
                          <v-card-text>
                            <v-container>
                              <base-dnd-table :headers="header_list" :items.sync="itemsSort" order>
                                <tr v-for="item in itemsSort" :key="item.career_id">
                                  <td scope="row" style="cursor: all-scroll">
                                    <v-icon>mdi-format-align-justify</v-icon>
                                  </td>
                                  <td>{{ item.position }}</td>
                                  <td>{{ item.created_date | formatDate }}</td>
                                  <td>{{ item.status }}</td>
                                  <td>{{ item.sort }}</td>
                                </tr>
                              </base-dnd-table>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" dark @click="closeSort">Cancel</v-btn>
                            <v-btn color="primary" dark @click="saveSort">OK</v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>

                <template v-slot:[`item.status`]="{ item }">
                  <base-switch
                    v-model="item.status"
                    :height="26"
                    :width="99"
                    :font-size="5"
                    :color="{ checked: '#4CAF50', unchecked: '#FF5252' }"
                    :labels="{ checked: 'ACTIVE', unchecked: 'INACTIVE' }"
                    @change="onChangeEventHandler(item, $event)"
                  />
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn
                    class="px-2 ml-1"
                    min-width="0"
                    color="success"
                    @click="setLoading(true), editItem(item)"
                    small
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    class="px-2 ml-1"
                    min-width="0"
                    color="warning"
                    @click="setLoading(true), infoItem(item)"
                    small
                  >
                    <v-icon small>mdi-eye</v-icon>
                  </v-btn>
                  <v-btn class="px-2 ma-1" min-width="0" color="error" @click="deleteItem(item)" small>
                    <v-icon small> mdi-delete </v-icon>
                  </v-btn>
                </template>

                <template v-slot:[`item.created_date`]="{ item }">
                  {{ item.created_date | formatDate }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs>
  </base-material-card>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"

/**
 * CONTROLLERS
 */
import { putCareerSort } from "@/controllers/career/career.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "CareerList",
  props: {
    value: {
      type: Array,
      default: []
    }
  },
  computed: {
    careers: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    },
    itemsSort: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("update:sort", value)
      }
    },
    textLang() {
      return this.lang === 1 ? "EN" : "TH"
    }
  },
  data: () => ({
    headers: [
      {
        text: "ID",
        align: "start",

        value: "career_id"
      },
      { text: "position", value: "position" },
      { text: "sort", value: "sort" },
      { text: "location", value: "location" },
      { text: "created_date", value: "created_date" },
      { text: "status", value: "status" },
      // { text: "lang", value: "lang" },
      { text: "Actions", value: "actions", align: "center", width: "15%", sortable: false }
    ],
    header_list: [
      {
        text: "",
        value: "career_id"
      },
      { text: "position", value: "position" },
      { text: "created_date", value: "created_date" },
      { text: "status", value: "status" },
      { text: "sort", value: "sort", align: "center", width: "15%", sortable: false }
    ],
    languages: LANGUAGES,
    status: STATUS,
    dialogDelete: false,
    delete_item: null,
    dialogSort: false,
    lang: 0
  }),
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    editItem(item) {
      this.$router.push({ path: `career-detail/${item.career_id}` })
    },
    createItem(lang) {
      this.$router.push({ path: `career-create?lang=${lang}` })
    },
    onChangeEventHandler(item, { value }) {
      this.$emit("onChangeStatus", { item, value })
    },
    infoItem(item) {
      this.$router.push({ path: `apply-job/${item.career_id}` })
    },
    onTabChange(e) {
      this.$emit("onTabChange", e)
    },
    sortItem() {
      this.dialogSort = true
    },
    async saveSort() {
      await putCareerSort({ careers: this.careers }, this.resultHandle, this.errorHandle)
      this.$emit("sort:success", this.textLang)
      this.dialogSort = false
    },
    closeSort() {
      this.dialogSort = false
    },
    deleteItem(item) {
      this.delete_item = item
      this.dialogDelete = true
    },
    closeDelete() {
      this.delete_item = null
      this.dialogDelete = false
    },
    async deleteItemConfirm() {
      this.$emit("onDelete", { delete_item: this.delete_item, deleteResultHandle: this.deleteResultHandle })
      this.dialogDelete = false
    },
    deleteResultHandle() {
      this.careers = this.careers.filter((career) => career.career_id !== this.delete_item.career_id)
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
