/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
"use strict"

/**
 * Module dependencies.
 */

/**
 * CONSTANTS
 */
import { API } from "@/constants/config"
import { EP_STANDARD_CATEGORY } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut, axiosUpdateFile, axiosCreateFile, axiosDelete } from "@/utils/axios"

export async function getStandardCategoryAll() {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_STANDARD_CATEGORY.FINDALL}/1`
    const { code, data } = await axiosGet(url, token)
    if (code == 200) return data
  } catch (error) {
    const { code, message } = error.response.data
    return errorHandle(message)
  }
}

export async function getStandardCategoryByID({ standard_category_id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_STANDARD_CATEGORY.FINDBYID}/${standard_category_id}`
    const { code, data, message } = await axiosGet(url, token)
    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    return errorHandle(message)
  }
}

export async function postStandardCategory({ standard_category, standard_id, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    let formData = new FormData()
    formData.append("standard_id", standard_id)
    formData.append("title", standard_category.title)
    formData.append("standard_category", standard_category.standard_category)
    formData.append("status", standard_category.status)
    formData.append("lang", standard_category.lang)
    if (file) formData.append("file", file, file.name)
    const url = `${API}${EP_STANDARD_CATEGORY.CREATE}`
    const { code, data, message } = await axiosCreateFile(url, formData, token)
    if (code == 201) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    // const { message } = error.response.data
    return errorHandle(error)
  }
}

export async function putStandardCategory({ standard_category, standard_id, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    let formData = new FormData()
    formData.append("standard_id", standard_id)
    formData.append("title", standard_category.title)
    formData.append("standard_category", standard_category.standard_category)
    formData.append("status", standard_category.status)
    formData.append("lang", standard_category.lang)
    if (file) formData.append("file", file, file.name)
    const url = `${API}${EP_STANDARD_CATEGORY.UPDATE}/${standard_category.standard_category_id}`
    const { code, data, message } = await axiosUpdateFile(url, formData, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    // const { message } = error.response.data
    return errorHandle(error)
  }
}

export async function putStandardCategorySort({ standard_category }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = standard_category
    const url = `${API}${EP_STANDARD_CATEGORY.UPDATE_SORT}`
    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    return errorHandle(error)
  }
}

export async function putStandardStatus({ standard, status }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    const payload = { status: status }
    const url = `${API}${EP_STANDARD_CATEGORY.UPDATE_STATUS}/${standard.standard_category_id}`

    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    return errorHandle(error)
  }
}

export async function deleteStandardCategory(
  { standard_category, delete_index },
  resultHandle,
  errorHandle,
  deleteResultHandle
) {
  try {
    const token = await getAuthToken()

    const url = `${API}${EP_STANDARD_CATEGORY.DELETE}/${standard_category.standard_category_id}`

    const { code, data, message } = await axiosDelete(url, token)

    if (code == 200) {
      deleteResultHandle({ standard_category, delete_index })
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    return errorHandle(error)
  }
}
