<template>
  <v-container>
    <base-material-card icon="mdi-wall" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("group_color_create") }}</div>
      </template>

      <v-form @submit.prevent="setLoading(true), save()" ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="text-no-wrap" align="center">
            <v-col cols="12" md="12">
              <v-text-field
                v-model="groupColor.title"
                :label="$t('title')"
                :rules="[rules.required]"
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              ><v-select
                v-model="groupColor.lang"
                :items="languages"
                item-text="language"
                item-value="lang_code"
                :label="$t('language')"
                :rules="[rules.required]"
                outlined
              ></v-select
            ></v-col>
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" min-width="100" class="pl-0" :loading="$store.state.isloading" @click="cancel">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"

/**
 * FUNCTIONS
 */
import { postGroupColor } from "@/controllers/product/group_color.controller"

/**
 * LIBs
 */
import { mapMutations } from "vuex"

/**
 * UTILS
 */
import { rules } from "@/utils/validates"

export default {
  data: () => ({
    rules: rules,
    groupColor: {
      title: null,
      lang: "TH"
    },
    languages: LANGUAGES
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.groupColor.lang = this.$route.query.lang
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async save() {
      const isValid = this.$refs.form.validate()
      if (isValid) {
        await postGroupColor({ groupColor: this.groupColor }, this.resultHandle, this.errorHandle)
        this.setLoading(true)
        this.$router.back()
      }
      this.setLoading(false)
    },
    cancel() {
      this.setLoading(true)
      this.$router.back()
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>
