<template>
  <v-container>
    <base-material-card icon="mdi-calculator-variant" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("estimation_formula_shape") }}</div>
      </template>
      <v-container fluid>
        <v-row class="text-no-wrap" align="center">
          <v-col>
            <v-data-table :headers="headers" :items="shapes" class="elevation-1">
              <template v-slot:top>
                <v-toolbar flat>
                  <!-- <v-toolbar-title>{{ $t("estimation_formula_shape") }}</v-toolbar-title> -->

                  <v-spacer></v-spacer>
                </v-toolbar>
              </template>

              <template v-slot:[`item.created_date`]="{ item }">
                {{ item.created_date | formatDate }}
              </template>

              <template v-slot:[`item.file`]="{ item }">
                <a v-if="Object.keys(item.file).length" :href="`${path_file}${item.file.file_path}`" target="_blank">
                  <v-icon color="info">mdi-file-download</v-icon>
                </a>
              </template>

              <template v-slot:[`item.cover`]="{ item }">
                <v-list-item-avatar tile class="align-self-center" color="hsl(255, 3%, 18%,0)">
                  <v-img v-if="Object.keys(item.cover).length" :src="`${path_file}${item.cover.file_path}`" />
                </v-list-item-avatar>
              </template>

              <template v-slot:[`item.sort`]="{ item }">
                {{ item.sort + 1 }}
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-btn class="px-2 ml-1" min-width="0" color="warning" @click="infoItem(item)" small>
                  <v-icon small>mdi-eye</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          min-width="100"
          class="pl-0"
          :loading="$store.state.isloading"
          @click="setLoading(true), cancel()"
        >
          {{ $t("back") }}
        </v-btn>
      </v-card-actions>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANTS
 */
import { STATUS } from "@/constants/status"
import { LANGUAGES } from "@/constants/language"
import { PATHFILE } from "@/constants/config"

/**
 * CONTROLLER
 */
import { getByProductCategoryId as getEstimateShape } from "@/controllers/estimation/estimation_shape.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "News",
  data: () => ({
    shapes: [],
    status: STATUS,
    languages: LANGUAGES,
    headers: [
      {
        text: "Id",
        align: "start",
        
        value: "estimation_shape_id"
      },
      { text: "title", value: "title" },
      { text: "title_en", value: "title_en" },
      { text: "Actions", value: "actions", align: "center", width: "15%", sortable: false }
    ],
    path_file: PATHFILE
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize() {
      try {
        this.shapes = await getEstimateShape({ id: this.$route.params.estimationProductCategoryId })
        this.setLoading(false)
      } catch (e) {
        this.errorHandle(e)
      }
    },
    infoItem(item) {
      this.$router.push({ path: `/estimation-formula/${item.estimation_formula_id}/estimation-formula-res` })
    },
    sortItem() {
      this.dialogSort = true
    },
    cancel() {
      this.$router.back()
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
