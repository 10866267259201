<template>
  <v-container>
    <base-material-card icon="mdi-dock-top" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("logo") }}</div>
      </template>

      <v-tabs vertical>
        <v-tab v-for="(language, i) in languages" :key="`logo-${i}`" @change="getLogo(language.lang_code)">
          {{ language.lang_code }}
        </v-tab>

        <v-tab-item touchless v-for="(language, i) in languages" :key="`logo-${i}`">
          <v-form @submit.prevent="setLoading(true), save()" ref="form" lazy-validation>
            <v-container fluid>
              <v-row class="text-no-wrap" align="center">
                <v-col cols="12" md="6">
                  <p class="text-center">รูปใหญ่</p>
                  <base-upload-image v-model="file" :defaultValue="logo.file" class="mb-2" />
                  <p class="text-center red--text">*แนะนำขนาดรูปภาพ 189 px x 189 px</p>
                </v-col>
                <v-col cols="12" md="6">
                  <p class="text-center">รูปเล็ก</p>
                  <base-upload-image v-model="file_small" :defaultValue="logo.file_small" class="mb-2" />
                  <p class="text-center red--text">*แนะนำขนาดรูปภาพ 152 px x 152 px</p>
                </v-col>
                <!-- <v-col cols="12" md="12">
                  <v-select
                    v-model="logo.status"
                    :items="status"
                    item-text="text"
                    item-value="value"
                    :label="$t('status')"
                    outlined
                  ></v-select>
                </v-col> -->
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
                  {{ $t("save") }}
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-form>
        </v-tab-item>
      </v-tabs>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"
import { PATHFILE } from "@/constants/config"

/**
 * FUNCTIONS
 */
import { putLogo, getLogoByLang } from "@/controllers/logo/logo.controller"

/**
 * LIBs
 */
import { mapMutations } from "vuex"

/**
 * UTILS
 */
import { rules } from "@/utils/validates"

export default {
  data: () => ({
    rules: rules,
    logo: {},
    pathFile: PATHFILE,
    languages: LANGUAGES,
    status: STATUS,
    file: null,
    file_small: null
  }),
  created() {
    this.setLoading(true)
    this.initialize()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize(lang = "TH") {
      try {
        this.getLogo(lang)
        this.setLoading(false)
      } catch (e) {
        console.log(e)
      }
    },
    async getLogo(lang) {
      this.logo = await getLogoByLang({ lang })
    },
    async save() {
      await putLogo(
        {
          logo: this.logo,
          file: this.file,
          file_small: this.file_small
        },
        this.resultHandle,
        this.errorHandle
      )
      this.setLoading(false)
    },
    resultHandle(message) {
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
