/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_COOKIE } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut } from "@/utils/axios"

export async function getCookieAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_COOKIE.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function putCookie({ cookie }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = {
      cookie_policy: cookie.cookie_policy,
      lang: cookie.lang,
      status: cookie.status
    }
    const url = `${API}${EP_COOKIE.UPDATE}/${cookie.cookie_policy_id}`

    const { code, data, message } = await axiosPut(url, payload, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}
