<template>
  <v-container>
    <base-material-card icon="mdi-home" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">{{ $t("home_banner") }}</div>
      </template>

      <v-dialog v-model="dialogDelete" max-width="300">
        <v-card>
          <v-card-title>
            <p>ยืนยันการลบ</p>
            <v-spacer />
            <v-icon aria-label="Close" @click="closeDelete"> mdi-close </v-icon>
          </v-card-title>

          <v-card-text class="pb-6 text-center">
            <v-btn class="mr-1" color="error" dark @click="closeDelete"> {{ $t("cancel") }} </v-btn>
            <v-btn class="mr-1" color="success" dark @click="deleteItemConfirm"> {{ $t("delete") }} </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-tabs vertical>
        <v-tab v-for="(language, i) in languages" :key="`branch-${i}`" @change="onTabChange(language.lang_code)">
          {{ language.lang_code }}
        </v-tab>

        <v-tab-item touchless v-for="(language, i) in languages" :key="`lang-home-banner-${i}`">
          <v-container fluid>
            <v-row class="text-no-wrap" align="center">
              <v-col>
                <v-data-table :headers="headers" :items="home_banner" class="elevation-1">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" dark class="mx-2" @click="sortItem">
                        {{ $t("home_banner_sort") }}
                      </v-btn>

                      <v-btn color="primary" dark class="mx-2" @click="createItem(language.lang_code)">
                        {{ $t("home_banner_create") }}
                      </v-btn>

                      <v-dialog v-model="dialogSort" max-width="900px">
                        <v-card>
                          <v-card-title class="headline">{{ $t("home_banner_sort") }}</v-card-title>
                          <v-form>
                            <v-card-text>
                              <v-container>
                                <base-dnd-table
                                  :headers="header_list"
                                  :items.sync="itemsSort"
                                  :dragOptions="dragOptions"
                                  @update:items="updateSortItems"
                                  order
                                >
                                  <tr v-for="item in home_banner" :key="item.home_banner_id">
                                    <td scope="row" style="cursor: all-scroll">
                                      <v-icon>mdi-format-align-justify </v-icon>
                                    </td>
                                    <td scope="row">
                                      <v-avatar rounded class="ma-2">
                                        <v-img :src="`${path_file}${item.file.file_path}`" />
                                      </v-avatar>
                                    </td>
                                    <td>{{ item.status }}</td>
                                    <td>{{ item.sort }}</td>
                                  </tr>
                                </base-dnd-table>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="primary" dark @click="closeSort">Cancel</v-btn>
                              <v-btn color="primary" dark @click="saveSort">OK</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>

                  <template v-slot:[`item.status`]="{ item }">
                    <base-switch
                      v-model="item.status"
                      :height="26"
                      :width="99"
                      :font-size="5"
                      :color="{ checked: '#4CAF50', unchecked: '#FF5252' }"
                      :labels="{ checked: 'ACTIVE', unchecked: 'INACTIVE' }"
                      @change="setLoading(true), onChangeEventHandler(item, $event)"
                    />
                  </template>

                  <template v-slot:[`item.file`]="{ item }">
                    <v-list-item
                      :href="`${path_file}${item.file.file_path}`"
                      target="_blank"
                      :ripple="false"
                      v-if="Object.keys(item.file).length"
                    >
                      <v-list-item-avatar tile class="align-self-center mr-auto px-0" color="hsl(255, 3%, 18%,0)">
                        <v-img aspect-ratio="1" contain :src="`${path_file}${item.file.file_path}`" />
                      </v-list-item-avatar>
                    </v-list-item>
                  </template>

                  <template v-slot:[`item.sort`]="{ item }">
                    {{ item.sort }}
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn class="px-2 ml-1" min-width="0" color="success" @click="editItem(item)" small>
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn class="px-2 ml-1" min-width="0" color="error" @click="deleteItem(item)" small>
                      <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs>
    </base-material-card>

    <base-material-card icon="mdi-home" color="primary">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">Banner Sub Menu {{ $t("contact_us") }}</div>
      </template>

      <v-tabs v-model="tab" vertical>
        <v-tab v-for="(language, i) in languages" :key="`sub-branch-${i}`" @change="onSubTabChange(language.lang_code)">
          {{ language.lang_code }}
        </v-tab>

        <v-tab-item v-for="(item, i) in headerSubs" :key="`item-tab-item-${i}`">
          <v-container>
            <v-form @submit.prevent="updateHeaderSub()" lazy-validation ref="submenu">
              <v-container fluid>
                <v-row class="text-no-wrap" align="center">
                  <v-col cols="12" class="text-center grey--text">
                    <base-upload-image
                      v-model="fileHeaderSubs[currentLocale]"
                      :defaultValue="item.file"
                      width="80%"
                      height="200px"
                    />
                    <p class="text-center red--text">*แนะนำขนาดรูปภาพ 1520 x 350 px</p>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      v-model="item.status"
                      :items="status"
                      item-text="text"
                      item-value="value"
                      :label="$t('status')"
                      outlined
                    />
                  </v-col>
                </v-row>
              </v-container>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" type="submit" min-width="100" class="pl-0" :loading="$store.state.isloading">
                  {{ $t("save") }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-container>
        </v-tab-item>
      </v-tabs>
    </base-material-card>
  </v-container>
</template>

<script>
/**
 * CONSTANS
 */
import { LANGUAGES } from "@/constants/language"
import { STATUS } from "@/constants/status"
import { PATHFILE } from "@/constants/config"

/**
 * FUNCTIONS
 */
import { getBannerByLang, putBannerSort, putStatus, deleteBanner } from "@/controllers/home/home_banner.controller"
import { getHeaderSubMenuAll, postHeaderSubMenu, putHeaderSubMenu } from "@/controllers/home/home_header_sub.controller"

/**
 * LIBs
 */
import { mapMutations } from "vuex"

/**
 * UTILS
 */
import { rules } from "@/utils/validates"

export default {
  data: () => ({
    rules: rules,
    home_banner: [],
    path_file: PATHFILE,
    languages: LANGUAGES,
    status: STATUS,
    file: null,
    fileHeaderSubs: {
      TH: null,
      EN: null
    },
    headers: [
      {
        text: "Id",
        align: "start",

        value: "home_banner_id"
      },
      { text: "Image", value: "file", align: "center" },
      { text: "Status", value: "status" },
      { text: "sort", value: "sort" },
      { text: "Actions", value: "actions", align: "center", width: "15%", sortable: false }
    ],
    headerSubs: [
      {
        status: "ACTIVE",
        lang: "TH"
      },
      {
        status: "ACTIVE",
        lang: "TH"
      }
    ],
    tab: 0,
    dialogSort: false,
    dialogDelete: false,
    delete_item: null,

    /**
     * SORT
     */
    header_list: [
      { text: "", value: "home_banner_id" },
      { text: "Image", value: "file", align: "center" },
      { text: "status", value: "status" },
      // { text: "lang", value: "lang" },
      { text: "sort", value: "sort" }
    ],
    dragOptions: {
      animation: 200,
      group: "description",
      disabled: false,
      ghostClass: "ghost"
    }
  }),
  computed: {
    itemsSort: {
      get() {
        return this.home_banner
      },
      set(items) {
        this.home_banner = items
      }
    },
    currentLocale() {
      return this.tab === 0 ? "TH" : "EN"
    }
  },
  created() {
    this.setLoading(true)
    this.initialize()
    this.initializeSubMenu()
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize(lang = "TH") {
      try {
        this.home_banner = await getBannerByLang({ lang })
        this.setLoading(false)
      } catch (e) {
        this.errorHandle(e)
      }
    },
    createItem(lang) {
      this.$router.push({ path: `home-banner-create?lang=${lang}` })
    },
    editItem(item) {
      this.$router.push({ path: `home-banner-detail/${item.home_banner_id}` })
    },
    sortItem() {
      this.dialogSort = true
    },
    updateSortItems(items) {
      this.itemsSort = items.map((item) => {
        const { home_banner_id, file, lang, link, sort, status, created_at, updated_at } = item
        return { home_banner_id, file, lang, link, sort: sort + 1, status, created_at, updated_at }
      })
    },
    async saveSort() {
      await putBannerSort({ banners: this.home_banner }, this.resultHandle, this.errorHandle)
      this.closeSort()
    },
    closeSort() {
      this.dialogSort = false
    },
    async onChangeEventHandler(item, { value }) {
      if (value) await putStatus({ banner: item, status: "ACTIVE" }, this.resultHandle, this.errorHandle)
      else await putStatus({ banner: item, status: "INACTIVE" }, this.resultHandle, this.errorHandle)
    },
    onTabChange(e) {
      this.initialize(e)
    },
    deleteItem(item) {
      this.delete_item = item
      this.dialogDelete = true
    },
    closeDelete() {
      this.delete_item = null
      this.dialogDelete = false
    },
    async deleteItemConfirm() {
      await deleteBanner({ banner: this.delete_item }, this.resultHandle, this.errorHandle, this.deleteResultHandle)
      this.dialogDelete = false
    },
    deleteResultHandle() {
      this.home_banner = this.home_banner.filter(
        (home_banner) => home_banner.home_banner_id !== this.delete_item.home_banner_id
      )
    },

    /** For Sub Header Menu */
    async initializeSubMenu() {
      try {
        this.headerSubs[this.tab].lang = this.currentLocale
        let _headerSubs = await getHeaderSubMenuAll()
        const lang = ["TH", "EN"]
        this.headerSubs = _headerSubs.sort((a, b) => lang.indexOf(a.lang) - lang.indexOf(b.lang))
        this.setLoading(false)
      } catch (e) {
        this.errorHandle(e)
      }
    },
    onSubTabChange(e) {
      this.initializeSubMenu(e)
    },
    async createHeaderSub() {
      await postHeaderSubMenu(
        {
          headerSubMenu: this.headerSubs[this.tab],
          file: this.fileHeaderSubs[this.currentLocale]
        },
        this.resultHandle,
        this.errorHandle
      )
    },
    async updateHeaderSub() {
      await putHeaderSubMenu(
        {
          headerSubMenu: this.headerSubs[this.tab],
          file: this.fileHeaderSubs[this.currentLocale]
        },
        this.resultHandle,
        this.errorHandle
      )
    },

    /** Callback Function */
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
