/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_HOME_HEADER_CONTACT_US } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPost, axiosPut, axiosCreateFile, axiosPatchFile, axiosDelete } from "@/utils/axios"

export async function getHeaderSubMenuAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_HOME_HEADER_CONTACT_US.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getHeaderSubMenuById({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_HOME_HEADER_CONTACT_US.FINDBYID}/${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getHeaderSubMenuByLang({ lang, sort = "YES" }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_HOME_HEADER_CONTACT_US.FINDALL}?lang=${lang}?sort=${sort}`
    const { code, data, message } = await axiosGet(url, token)
    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function postHeaderSubMenu({ headerSubMenu, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    // const payload = headerSubMenu
    const url = `${API}${EP_HOME_HEADER_CONTACT_US.CREATE}`
    let formData = new FormData()
    formData.append("lang", headerSubMenu.lang)
    formData.append("status", headerSubMenu.status)
    if (file) formData.append("file", file, file.name)

    const { code, data, message } = await axiosCreateFile(url, formData, token)

    if (code == 201) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putHeaderSubMenu({ headerSubMenu, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const url = `${API + EP_HOME_HEADER_CONTACT_US.UPDATE}/${headerSubMenu.contact_file_id}`
    let formData = new FormData()
    formData.append("lang", headerSubMenu.lang)
    formData.append("status", headerSubMenu.status)
    if (file) formData.append("file", file, file.name)

    const { code, data, message } = await axiosPatchFile(url, formData, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    // const { message } = error.response.data
    console.log(error)
    return errorHandle(error)
  }
}
