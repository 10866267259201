<template>
  <base-material-card icon="mdi-home" color="primary">
    <template v-slot:after-heading>
      <div class="font-weight-light card-title mt-2">{{ $t("menu") }}</div>
    </template>

    <v-dialog v-model="dialogDelete" max-width="300">
      <v-card>
        <v-card-title>
          <p>
            ยืนยันการลบ
          </p>
          <v-spacer />
          <v-icon aria-label="Close" @click="closeDelete">
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="pb-6 text-center">
          <v-btn class="mr-1" color="error" dark @click="closeDelete"> {{ $t("cancel") }} </v-btn>
          <v-btn class="mr-1" color="success" dark @click="deleteItemConfirm"> {{ $t("delete") }} </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-tabs vertical>
      <v-tab
        v-for="(language, i) in languages"
        :key="`estimation_product_group-${i}`"
        @change="onTabChange(language.lang_code)"
      >
        {{ language.lang_code }}
      </v-tab>
      <v-tab-item touchless v-for="(language, i) in languages" :key="`menu-${i}`">
        <v-container fluid>
          <v-row class="text-no-wrap" align="center">
            <v-col>
              <v-data-table :headers="headers" :items="menus" class="elevation-1">
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>{{ $t("menu") }}</v-toolbar-title>

                    <v-spacer></v-spacer>
                    <v-btn color="primary" dark class="mb-2 mx-1" @click="sortItem">
                      {{ $t("menu_sort") }}
                    </v-btn>
                    <v-btn color="primary" dark class="mb-2" @click="createMenu(language.lang_code)">
                      {{ $t("menu_create") }}
                    </v-btn>

                    <v-dialog v-model="dialogSort" max-width="900px">
                      <v-card>
                        <v-card-title class="headline"> {{ $t("menu_sort") }} </v-card-title>
                        <v-card-text>
                          <v-container>
                            <base-dnd-table :headers="header_list" :items.sync="menus" :dragOptions="dragOptions" order>
                              <tr v-for="item in menus" :key="item.menu_id">
                                <td scope="row" style="cursor: all-scroll">
                                  <v-icon>mdi-format-align-justify</v-icon>
                                </td>
                                <td>{{ item.title }}</td>
                                <td>{{ item.link }}</td>

                                <td>{{ item.sort + 1 }}</td>
                              </tr>
                            </base-dnd-table>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" dark @click="closeSort">{{ $t("cancel") }}</v-btn>
                          <v-btn color="primary" dark @click="saveSort">{{ $t("save") }}</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>

                <template v-slot:[`item.sort`]="{ item }"> {{ item.sort + 1 }} </template>

                <template v-slot:[`item.status`]="{ item }">
                  <base-switch
                    v-model="item.status"
                    :height="26"
                    :width="99"
                    :font-size="5"
                    :color="{ checked: '#4CAF50', unchecked: '#FF5252' }"
                    :labels="{ checked: 'ACTIVE', unchecked: 'INACTIVE' }"
                    @change="setLoading(true), onChangeEventHandler(item, $event)"
                  />
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn class="px-2 ml-1" min-width="0" color="success" @click="editItem(item)" small>
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn class="px-2 ml-1" min-width="0" color="warning" @click="infoItem(item)" small>
                    <v-icon small> mdi-eye </v-icon>
                  </v-btn>
                  <v-btn class="px-2 ml-1" min-width="0" color="error" @click="deleteItem(item)" small>
                    <v-icon small> mdi-delete </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs>
  </base-material-card>
</template>

<script>
/**
 * CONSTANTS
 */
import { STATUS } from "@/constants/status"
import { LANGUAGES } from "@/constants/language"

/**
 * CONTROLLER
 */
import { getMenuByLang, putMenuSort, putStatus, deleteMenu } from "@/controllers/menu/menu.controller"

/**
 * LIB
 */
import { mapMutations } from "vuex"

export default {
  name: "Menu",
  data: () => ({
    status: STATUS,
    languages: LANGUAGES,
    menus: [],
    headers: [
      {
        text: "ID",
        align: "start",
        value: "menu_id"
      },
      { text: "title", value: "title" },
      { text: "link", value: "link" },
      { text: "status", value: "status" },
      { text: "sort", value: "sort" },
      { text: "Actions", value: "actions", align: "center", width: "15%", sortable: false }
    ],

    /**
     * SORT
     */
    header_list: [
      { text: "", value: "menu_id" },
      { text: "title", value: "title" },
      { text: "link", value: "link" },
      { text: "sort", value: "sort" }
    ],
    dialogDelete: false,
    delete_item: null,
    dialogSort: false,
    dragOptions: {
      animation: 200,
      group: "description",
      disabled: false,
      ghostClass: "ghost"
    }
  }),
  created() {
    this.initialize("TH")
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_IS_LOADING",
      setNoti: "SET_IS_NOTIFICATION"
    }),
    async initialize(lang) {
      try {
        this.menus = await getMenuByLang({ lang })
        this.setLoading(false)
      } catch (e) {
        this.errorHandle(e)
      }
    },
    editItem(item) {
      this.$router.push({ path: `menu-detail/${item.menu_id}` })
    },
    infoItem(item) {
      this.$router.push({ path: `menu/${item.menu_id}/menu-sub` })
    },
    createMenu(lang) {
      this.$router.push({ path: `menu-create?lang=${lang}` })
    },
    deleteItem(item) {
      this.delete_item = item
      this.dialogDelete = true
    },
    closeDelete() {
      this.delete_item = null
      this.dialogDelete = false
    },
    async deleteItemConfirm() {
      await deleteMenu({ menu: this.delete_item }, this.resultHandle, this.errorHandle, this.deleteResultHandle)
      this.dialogDelete = false
    },
    deleteResultHandle() {
      this.menus = this.menus.filter((menu) => menu.menu_id !== this.delete_item.menu_id)
    },
    /**
     * SORT
     */
    sortItem() {
      this.dialogSort = true
    },
    async saveSort() {
      await putMenuSort({ menu: this.menus }, this.resultHandle, this.errorHandle)
      this.setLoading(false)
      this.dialogSort = false
    },
    closeSort() {
      this.dialogSort = false
    },
    onTabChange(e) {
      this.initialize(e)
    },
    async onChangeEventHandler(item, { value }) {
      if (value) await putStatus({ menu: item, status: "ACTIVE" }, this.resultHandle, this.errorHandle)
      else await putStatus({ menu: item, status: "INACTIVE" }, this.resultHandle, this.errorHandle)
    },
    resultHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "success", message: message })
    },
    errorHandle(message) {
      this.setLoading(false)
      this.setNoti({ snackbar: true, color: "error", message: message })
    }
  }
}
</script>

<style></style>
