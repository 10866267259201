/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API } from "@/constants/config"
import { EP_PROVINCE } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPost, axiosPut, axiosDelete } from "@/utils/axios"

export async function getProvinceAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_PROVINCE.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getProvinceById({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API + EP_PROVINCE.FINDBYID}/${id}`
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getProvinceByCountryId({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API + EP_PROVINCE.FINDBY_COUNTRY}/${id}`
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function postProvince({ province }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = province
    const url = `${API}${EP_PROVINCE.CREATE}`
    const { code, data, message } = await axiosPost(url, payload, token)

    if (code == 201) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    return errorHandle(error)
  }
}

export async function putProvince({ province }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = province
    const url = `${API + EP_PROVINCE.UPDATE}/${province.province_id}`
    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    return errorHandle(error)
  }
}

export async function deleteProvince({ province }, resultHandle, errorHandle, deleteResultHandle) {
  try {
    const token = await getAuthToken()

    const url = `${API}${EP_PROVINCE.DELETE}/${province.province_id}`

    const { code, data, message } = await axiosDelete(url, token)

    if (code == 200) {
      deleteResultHandle()
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    return errorHandle(error)
  }
}
