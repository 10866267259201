<template>
  <v-snackbar v-model="isActive" color="transparent" elevation="0" right top @input="close">
    <v-alert :type="color" border="left">
      {{ this.message }}
      <template v-slot:append>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-alert>
  </v-snackbar>
</template>

<script>
import { mapMutations } from "vuex"

export default {
  name: "Notification",
  props: {
    message: {
      type: String
    },
    value: {
      type: Boolean
    },
    color: {
      type: String,
      default: "success"
    }
  },
  computed: {
    isActive: {
      get() {
        return this.value
      },
      set(value) {
        this.setNoti({ snackbar: value })
      }
    }
  },
  methods: {
    ...mapMutations({
      setNoti: "SET_IS_NOTIFICATION"
    }),
    close() {
      this.setNoti({ snackbar: false })
    }
  }
}
</script>

<style></style>
