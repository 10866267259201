<template>
  <v-dialog v-model="loading" fullscreen>
    <v-container fluid fill-height style="background-color: rgba(255, 255, 255, 0.5);">
      <v-layout justify-center align-center>
        <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  name: "Load",
  //   data: {
  //     loading: true
  //   },
  //   mounted() {
  //     this.loading = false
  //   },
  computed: {
    loading: {
      get() {
        return this.$store.state.isloading
      },
      set(value) {
        this.$store.state.isloading
      }
    }
  }
  //   computed: {
  //     data() {
  //       return this.$store.state.isloading
  //     }
  //   }
}
</script>

<style></style>
