/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
;("use strict")

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_SITE_REF_PRODUCT_GROUP } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut, axiosCreateFile, axiosUpdateFile, axiosDelete } from "@/utils/axios"

export async function getSiteRefProductGroupAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_SITE_REF_PRODUCT_GROUP.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return message
  }
}

export async function getSiteRefProductGroupByID({ id }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_SITE_REF_PRODUCT_GROUP.FINDBYID}/${id}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function getSiteRefProductGroupByLang({ lang }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_SITE_REF_PRODUCT_GROUP.FINDBYLANG}/${lang}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function getSiteRefProductGroupForSelect() {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_SITE_REF_PRODUCT_GROUP.FOR_SELECT}`
    const { code, data, message } = await axiosGet(url, token)

    if (code == 200) return data
    return message
  } catch (error) {
    console.log(error)
  }
}

export async function postSiteRefProductGroup({ productGroup, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    // const payload = productGroup
    const url = `${API}${EP_SITE_REF_PRODUCT_GROUP.CREATE}`
    let formData = new FormData()
    formData.append("title", productGroup.title)
    // formData.append("lang", productGroup.lang)

    if (file) formData.append("file", file, file.name)

    const { code, data, message } = await axiosCreateFile(url, formData, token)

    if (code == 201) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putSiteRefProductGroup({ productGroup, file }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    // const payload = productGroup
    const url = `${API + EP_SITE_REF_PRODUCT_GROUP.UPDATE}/${productGroup.product_group_id}`
    let formData = new FormData()
    // formData.append("title", productGroup.title)
    formData.append("status", productGroup.status)
    // formData.append("lang", productGroup.lang)

    if (file) formData.append("file", file, file.name)

    const { code, data, message } = await axiosUpdateFile(url, formData, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    // const { message } = error.response.data
    console.log(error)
    return errorHandle(error)
  }
}

export async function putSiteRefProductGroupSort({ productGroups }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = productGroups
    const url = `${API}${EP_SITE_REF_PRODUCT_GROUP.UPDATE_SORT}`

    const { code, message, data } = await axiosPut(url, payload, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
  } catch (error) {
    const { message } = error.response.data
    console.log(error)
    return errorHandle(message)
  }
}

export async function putStatus({ product_group, status }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    const payload = { status: status }
    const url = `${API}${EP_SITE_REF_PRODUCT_GROUP.UPDATE_STATUS}/${product_group.product_group_id}`

    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function deleteSiteRefProductGroup({ product_group }, resultHandle, errorHandle, deleteResultHandle) {
  try {
    const token = await getAuthToken()

    const url = `${API}${EP_SITE_REF_PRODUCT_GROUP.DELETE}/${product_group.product_group_id}`

    const { code, data, message } = await axiosDelete(url, token)

    if (code == 200) {
      deleteResultHandle()
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}
