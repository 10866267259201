/*
 * Copyright (C) 2021 Depwhite Software
 *
 * This file is part of the Depwhite Software project.
 *
 * Depwhite Software project can not be copied and/or distributed without the express
 */
"use strict"

/**
 * Module dependencies.
 */
import axios from "axios"

/**
 * CONSTANTS
 */
import { API, APITOKEN } from "@/constants/config"
import { EP_CAREER } from "@/constants/endpoint"

/**
 * CONTROLLER
 */
import { getAuthToken } from "@/controllers/auth/login.controller"

/**
 * UTILS & SERVICE
 */
import { axiosGet, axiosPut, axiosPost, axiosDelete } from "@/utils/axios"

export async function getCareerAll() {
  try {
    const token = await getAuthToken()
    const url = API + EP_CAREER.FINDALL
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return errorHandle(message)
  }
}

export async function getCareerByID({ career_id }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_CAREER.FINDBYID}/${career_id}`
    const { code, data, message } = await axiosGet(url, token)
    if (code == 200) return data
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    return errorHandle(message)
  }
}

export async function getCareerByLang({ lang }) {
  try {
    const token = await getAuthToken()
    const url = `${API}${EP_CAREER.FINDBYLANG}/${lang}?sort=YES`
    const { code, data } = await axiosGet(url, token)

    if (code == 200) {
      return data
    }
  } catch (error) {
    const { code, message } = error.response.data
    return errorHandle(message)
  }
}

export async function postCareer({ career }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    const payload = {
      description: career.description,
      location: career.location,
      position: career.position,
      created_date: career.created_date,
      lang: career.lang
    }

    const url = `${API}${EP_CAREER.CREATE}`

    const { code, data, message } = await axiosPost(url, payload, token)

    if (code == 201) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    // const { message } = error.response.data
    console.log(error)
    return errorHandle(error)
  }
}

export async function putCareer({ career }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    const payload = {
      description: career.description,
      location: career.location,
      position: career.position,
      created_date: career.created_date,
      status: career.status,
      lang: career.lang
    }

    const url = `${API}${EP_CAREER.UPDATE}/${career.career_id}`
    const { code, data, message } = await axiosPut(url, payload, token)
    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return message
  } catch (error) {
    const { message } = error.response.data
    return errorHandle(message)
  }
}

export async function putStatus({ career, status }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()

    const payload = { status: status }
    const url = `${API}${EP_CAREER.UPDATE_STATUS}/${career.career_id}`

    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function putCareerSort({ careers }, resultHandle, errorHandle) {
  try {
    const token = await getAuthToken()
    const payload = careers.map((item) => {
      return {
        id: item.career_id,
        sort: item.sort
      }
    })
    const url = `${API}${EP_CAREER.UPDATE_SORT}`

    const { code, data, message } = await axiosPut(url, payload, token)

    if (code == 200) {
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}

export async function deleteCareer({ career }, resultHandle, errorHandle, deleteResultHandle) {
  try {
    const token = await getAuthToken()

    const url = `${API}${EP_CAREER.DELETE}/${career.career_id}`

    const { code, data, message } = await axiosDelete(url, token)

    if (code == 200) {
      deleteResultHandle()
      resultHandle(message)
      return data
    }
    errorHandle(message)
    return data
  } catch (error) {
    console.log(error)
    return errorHandle(error)
  }
}
