<template>
  <div>
    <v-simple-table class="table table-striped">
      <thead class="thead-dark">
        <tr>
          <th v-for="(header, index) in headers" :key="'headerTable' + index" scope="col">{{ header.text }}</th>
          <th v-if="sort">sort</th>
        </tr>
      </thead>
      <draggable v-model="items_list" tag="tbody" v-bind="dragOptions" @end="updateItemsort">
        <slot></slot>
        <!-- <tr v-for="item in items_list" :key="item.name">
          <td scope="row" style="cursor: all-scroll"><v-icon>mdi-format-align-justify</v-icon></td>
          <td scope="row">{{ item.id }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.sport }}</td>
          <td v-if="sort">{{ item.sort + 1 }}</td>
        </tr> -->
      </draggable>
    </v-simple-table>
  </div>
</template>

<script>
import draggable from "vuedraggable"

export default {
  name: "DndTable",
  components: {
    draggable
  },
  props: {
    headers: {
      type: Array
    },
    items: {
      type: Array
    },
    dragOptions: {
      type: Object,
      default: () => ({
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      })
    },
    sort: {
      type: Boolean,
      default: false
    },
    sortType: {
      type: String,
      default: () => "ASC"
    }
  },
  computed: {
    items_list: {
      get() {
        return this.items
      },
      set(items) {
        this.$emit("update:items", items)
      }
    }
  },
  methods: {
    updateItemsort() {
      if (this.sortType === "DESC") {
        this.items_list = this.items_list.map(function (item, index, items) {
          return { ...item, sort: items.length - 1 - index }
        })
      } else {
        this.items_list = this.items_list.map(function (item, index) {
          return { ...item, sort: index }
        })
      }
    }
  }
}
</script>

<style></style>
